import { Container, Stack, Grid, GridItem, Image } from '@chakra-ui/react';
import React from 'react';
import AuthPic from '../../../components/Repeat/AuthPic';
import LoginForm from '../../../components/Auth/LoginForm';
import shapew from '../../../assets/images/icon/white.png';

export default function Login() {
  return (
    <>
      <Stack
        w={'100%'}
        h={'100vh'}
        overflow={'hidden'}
        alignItems={'center'}
        justifyContent={'center'}
        position={'relative'}
        bg={'primaryGreen.100'}
        backgroundImage={shapew}
      >
        <AuthPic display={{ base: 'none', xl: 'initial' }} Picsrc={''} />
        <Container maxW={'8xl'}>
          <Grid
            templateColumns="repeat(12, 1fr)"
            gap={{ base: 2, sm: 0 }}
            alignItems={'center'}
          >
            <GridItem colSpan={{ base: '4', md: '6' }}>
              <LoginForm />
            </GridItem>
            <GridItem colSpan={{ base: '4', md: '6' }}></GridItem>
          </Grid>
        </Container>
      </Stack>
    </>
  );
}
