import { Stack, Heading, Text, Box } from '@chakra-ui/react';
import CountUp from "react-countup";

export default function TopInfoBox({ title, value, subIndicator }) {
    return (
        <Stack className="btn" w={'24%'}>
            <Box
                padding={'10px'}
                bg={'primaryYellow.100'}
                color={'#000'}
                fontWeight={'500'}
                border={'2px solid'}
                borderColor="primaryGreen.100"
                borderRadius={'8px'}
                w='full'
                height="120px"
            >
                <Text as='span' display={'block'}>{title ?? 'loading'}</Text>
                <Heading color={'primaryGreen.100'} as='span' display='flex' alignItems={'flex-end'} gap="10px" fontSize="55px" fontWeight="500">
                    <CountUp style={{transform: 'rotate(0deg)'}} start={"0"} end={value ?? 0} duration={2} />
                    {
                        subIndicator &&
                        <Text mb={"8px"} fontWeight={'500'} color="#000">{subIndicator}</Text>
                    }</Heading>
            </Box>
        </Stack>
    )
}