import { Text, Box, OrderedList, ListItem, Stack, Image } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
// Components
import Header from "../../components/Website/Header/header";
import HeroBanner from '../../components/Website/Home/heroBanner';
import Footer from "../../components/Website/Footer/Footer";
// Images
import BlackOver from '../../assets/images/icon/black.png';
import Privacy from '../../assets/images/website/privacy.svg';
import { useEffect } from "react";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, []); // Empty dependency array ensures that this effect runs only once after initial mount

    return (
        <>
            <Header />

            <Box>
                <HeroBanner text={"Privacy Policy"} />
            </Box>

            <Box p={"40px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Stack direction={{ base: 'column', xl: 'row' }}>
                        <Box flex={1}>
                            <Box p={"25px 0px"}>
                                <Text fontSize={"16px"} lineHeight={"22px"}>At AimHalal, we prioritize the privacy and security of our users. Our commitment is to provide a seamless and trustworthy experience for the Muslim communities in Canada seeking Halal solutions. Please read our privacy policy to understand how we collect, use, and protect your information.</Text>
                            </Box>
                            <Box>
                                <Box mb={"20px"}>
                                    <Text fontSize={"23px"} fontWeight={"600"} lineHeight={"44px"} mb={"20px"} color={'primaryGreen.100'}>Information Collection:</Text>
                                    <Text fontSize={"16px"} lineHeight={"22px"}>We collect user information to enhance your experience and tailor our services to meet your Halal needs. This includes data provided during account creation, product searches, and preferences, ensuring a personalized and efficient platform.</Text>
                                </Box>
                                <Box mb={"20px"}>
                                    <Text fontSize={"23px"} fontWeight={"600"} lineHeight={"44px"} mb={"20px"} color={'primaryGreen.100'}>Data Usage:</Text>
                                    <Text fontSize={"16px"} lineHeight={"22px"}>Your information is utilized to improve our platform, personalize recommendations, and provide relevant content. We prioritize transparency and ensure that your data is used solely for enhancing your Halal experience.</Text>
                                    <Text fontSize={"23px"} color={'primaryGreen.100'} fontWeight={"600"} lineHeight={"44px"} my={"20px"}>We use your information to:</Text>
                                    <OrderedList fontSize={"14px"} lineHeight={"22px"}>
                                        <ListItem>Administer your account.</ListItem>
                                        <ListItem>Complete your bookings of travel packages, hotels and/or AimHalal pass</ListItem>
                                        <ListItem>Send product updates, warranty information, and newsletters. (Opt-out available)</ListItem>
                                        <ListItem>Send marketing communications.</ListItem>
                                        <ListItem>Conduct research and analysis</ListItem>
                                        <ListItem>Communicate with you by addressing questions and concerns.</ListItem>
                                        <ListItem>Retain personal data for necessary service and business purposes.</ListItem>
                                        <ListItem>Delete or anonymize data upon request, unless legally obligated to retain specific information (e.g., fraud prevention, user security).</ListItem>
                                    </OrderedList>
                                </Box>
                                <Box mb={"20px"}>
                                    <Text color={'primaryGreen.100'} fontSize={"23px"} fontWeight={"600"} lineHeight={"44px"} mb={"20px"}>Security Measures:</Text>
                                    <Text fontSize={"16px"} lineHeight={"22px"}>AimHalal employs state-of-the-art security measures to protect your data. Our dedicated team works tirelessly to maintain the confidentiality and integrity of your information, ensuring a secure environment for your Halal journey.</Text>
                                </Box>
                                <Box mb={"20px"}>
                                    <Text color={'primaryGreen.100'} fontSize={"23px"} fontWeight={"600"} lineHeight={"44px"} mb={"20px"}>Third-Party Partnerships:</Text>
                                    <Text fontSize={"16px"} lineHeight={"22px"}>We may collaborate with trusted partners to enhance our services. However, rest assured that any shared information adheres to strict privacy standards, aligning with our commitment to protecting your data.</Text>
                                </Box>
                                <Box mb={"20px"}>
                                    <Text color={'primaryGreen.100'} fontSize={"23px"} fontWeight={"600"} lineHeight={"44px"} mb={"20px"}>Cookies and Analytics:</Text>
                                    <Text fontSize={"16px"} lineHeight={"22px"}>AimHalal uses cookies and analytics tools to optimize our platform and provide valuable insights. These technologies help us understand user behavior and preferences, contributing to an improved and personalized user experience.</Text>
                                </Box>
                                <Box mb={"20px"}>
                                    <Text color={'primaryGreen.100'} fontSize={"23px"} fontWeight={"600"} lineHeight={"44px"} mb={"20px"}>Your Consent Matters:</Text>
                                    <Text fontSize={"16px"} lineHeight={"22px"}>By using AimHalal, you consent to the terms outlined in this privacy policy. We encourage you to review this policy regularly, as updates may occur to adapt to evolving privacy standards and improve our services.</Text>
                                </Box>
                                <Box mb={"20px"}>
                                    <Text color={'primaryGreen.100'} fontSize={"23px"} fontWeight={"600"} lineHeight={"44px"} mb={"20px"}>Contact Us:</Text>
                                    <Text fontSize={"16px"} lineHeight={"22px"}>If you have any questions or concerns regarding your privacy or our practices, please contact our dedicated support team at connect@aimhalal.com</Text>
                                    <Text fontSize={"16px"} lineHeight={"22px"}>AimHalal is committed to providing a secure and enriching experience for our valued users in the Muslim communities of Canada. Your trust is our priority, and we aim to continually uphold the highest standards of privacy and security in delivering your one-stop solution for Halal needs.</Text>
                                </Box>
                            </Box>
                            <Box p={"40px 0px"}>

                            </Box>
                        </Box>
                        <Box flex={1}>
                            <Image src={Privacy} />
                        </Box>
                    </Stack>
                </Container>
            </Box>

            <Footer />
        </>
    )
}

export default PrivacyPolicy;