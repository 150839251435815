import { Stack, Text, Box, Image } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import bannerImg from '../../../assets/images/website/home/bannerImg.png';
import WhiteOver from '../../../assets/images/icon/white.png';
const HeroBanner = ({ text }) => {
    return (
        <>
            <Box bg={"#28551A"} bgImage={WhiteOver} borderRadius={"10px"} position={"relative"}>
                <Container maxW={"1440px"}>
                    <Stack h={{base: "120px", xl: "245px"}} justifyContent={"center"}>
                        <Text color={"primaryYellow.100"} fontSize={{ base: "18px", lg: "59.259px" }} fontWeight={"600"} lineHeight={"61.111px"} maxW={"80%"}>{text}</Text>
                    </Stack>
                    <Box position={"absolute"} bottom={"0"} right={{ base: "100%", xl: "0" }}>
                        <Image src={bannerImg} />
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default HeroBanner;