import React, { useEffect } from 'react'
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Stack,
  Radio,
  Text,
  RadioGroup,
  Image,
  Link,
  Grid,
  GridItem,
  useToast
} from '@chakra-ui/react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import shapeb from '../../../assets/images/icon/black.png'
import LoggedInPic from '../../DashNav/LoggedInPic.js'
import TopInfoBox from '../../Dashboard/Home/TopInfoBox.js'
import OrderTable from './OrderTable.js'
import RegisteredPartners from './RegisteredPartners/RegisteredPartners.js'
import { useState } from 'react'
import HotDog from '../../../assets/images/icon/hotdog.svg'
import UserDetailsRow from '../UsersWrap/UserDetailsRow.js'
import DashboardHeader from '../../Header/DashboardHeader.js'
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { GET } from '../../../utilities/ApiProvider.js';
import { useSelector } from 'react-redux';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Your Sales',
    },
  },
};

export default function HomeWrap() {

  const [weeklyOrder, setWeeklyOrder] = useState([0,0,0,0,0,0,0]);
  const [labels, setLabels] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
  const toast = useToast();
  const navigate = useNavigate();
  const user = useSelector(state => state?.value);
  const [homeData, setHomeData] = useState({});

  const getHomeData = async () => {
    try {
      let res = await GET(`/admin/home`, { authorization: `Bearer ${user?.verificationToken}` });
      if (res.status === 200) {
        setHomeData(res?.data);
      } else {
        toast({
          description: res.message,
          status: 'error',
          position: 'top-right',
          duration: 2500
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
  }

  useEffect(() => {
    if (user) getHomeData();
  }, [user])

  useEffect(() => {
    let label = [];
    let orders = [];
    homeData?.weeklyOrders?.length > 0 &&
      homeData?.weeklyOrders.map(val => {
        label.push(val?.label)
        orders.push(val?.orders)
      });
    setLabels(label);
    setWeeklyOrder(orders);
  }, [homeData])

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: weeklyOrder?.length > 0 ? weeklyOrder : [],
        backgroundColor: '#28551A',
      }
    ],
  };

  return (
    <>
      <DashboardHeader />
      <Stack
        overflowY="auto"
        margin={'0 !important'}
        gap={8}
        borderRadius={'30px 0 0 0'}
        py={10}
        px={6}
        bg={'#fff'}
        height={'100%'}
        backgroundImage={shapeb}
        bgPos={'-32px -100px'}
      >
        <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'} gap={'2%'} pr={'60px'}>
          <TopInfoBox title="Total Users" value={homeData?.totalUsers ?? 0} />
          <TopInfoBox title="Total Partners" value={homeData?.totalPartners ?? 0} />
          <TopInfoBox title="Total Riders" value={homeData?.totalRiders ?? 0} />
          <TopInfoBox title="Total Revenue" value={homeData?.totalRevenue ?? 0} />
        </Stack>
        <Stack>
          <Grid
            templateColumns="repeat(12, 1fr)"
            gap={{ base: 2, sm: 12 }}
          >
            <GridItem colSpan={{ base: '4', md: '7' }}>
              <Bar options={options} data={data} />
            </GridItem>
            <GridItem colSpan={{ base: '4', md: '5' }}>
              <RegisteredPartners registeredPartners={homeData?.registeredPartners} />
            </GridItem>
          </Grid>
        </Stack>
        <Stack borderRadius={'5px'} padding="15px 35px" boxShadow={'0px 0px 35px -15px #000'} bgColor="#fff">
          <Stack mb="30px" direction="row" alignItems="center" justifyContent="space-between">
            <Heading fontSize="20px">Partners</Heading>
            <Stack className='btn'>
              <Button
                onClick={() => navigate('/super-dashboard/partners')}
                justifyContent={'center'}
                padding={'10px 20px'}
                bg={"primaryGreen.100"}
                color={'primaryYellow.100'}
                bgImage={greenBtnTexture}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                fontWeight={'400'}
                border={'2px solid'}
                borderColor={"primaryGreen.100"}
                textAlign={'left'}
                _hover={{
                  bg: "primaryGreen.100",
                  borderColor: "primaryGreen.100",
                  color: '#fff'
                }}
              >
                <Text as={'span'}>View All</Text>
              </Button>
            </Stack>
          </Stack>
          {
            homeData?.users?.length > 0 &&
            homeData?.users.map(val =>
              <Stack
                borderBottom={'1px solid #adadad'}
                key={val.name}
                direction="row"
                flexWrap="wrap"
                spacing="0"
                py={'10px'}
                my="10px"
                alignItems="center"
              >
                <UserDetailsRow downShowBlockBtns={true} {...val} />
              </Stack>
            )
          }
        </Stack>
      </Stack >
    </>
  )
}
