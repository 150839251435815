import { Container, Stack, Grid, GridItem, Image } from '@chakra-ui/react';
import React from 'react';
import RegisterForm from '../../../components/Auth/RegisterForm';
import R3img from '../../../assets/images/auth/r3.jpg';
import shapew from '../../../assets/images/icon/white.png';
import AuthPic from '../../../components/Repeat/AuthPic';
import ForgotPass from '../../../components/Auth/ForgotPass';

export default function ForgotPassword() {
  return (
    <>
    <Stack
      w={'100%'}
      h={'100vh'}
      overflow={'hidden'}
      alignItems={'center'}
      justifyContent={'center'}
      position={'relative'}
      bg={'primaryGreen.100'}
      backgroundImage={shapew}
    >
   <AuthPic Picsrc={R3img} />
      <Container maxW={'8xl'}>
        <Grid
          templateColumns="repeat(12, 1fr)"
          gap={{ base: 2, sm: 0 }}
          alignItems={'center'}
        >
          <GridItem colSpan={{ base: '4', md: '6' }}>
           <ForgotPass/>
          </GridItem>
          <GridItem colSpan={{ base: '4', md: '6' }}></GridItem>
        </Grid>
      </Container>
    </Stack>
  </>
  )
}
