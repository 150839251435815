import { Stack, Image, Box, Heading, Text, Icon, Button, Flex } from "@chakra-ui/react";
import foodImg from '../../../../assets/images/auth/r6.jpg';
import { FaStar, FaHeart, FaChevronRight } from 'react-icons/fa';
import { imgUrl } from "../../../../utilities/config";
import { useNavigate } from 'react-router-dom'

export default function PopularItems({ _id, store, w }) {
    const navigate = useNavigate();
    return (
        <Stack w={w ?? 'initial'} key={_id} direction={'row'} alignItems="center" mb="30px" mt="0 !important" p="15px 0 !important" borderBottom="1px solid #BFC6CC">
            <Image src={store?.logo ? `${imgUrl}/${store?.logo}` : foodImg} w="92px" h="92px" objectFit={'cover'} borderRadius="12px" />
            <Flex justifyContent="space-between" w="full">
                <Box>
                    <Heading fontSize="16px" fontWeight="600" textTransform="capitalize" mb="5px">{store?.store_name ?? 'Loading'}</Heading>
                    <Text fontSize="14px" color="#616161" mb="10px">{store?.area ?? 'Loading'}</Text>
                    {/* <Text fontSize="14px" color="#78828A"><Icon as={FaStar} color="primaryYellow.100" fontSize="12px" /> {rating ?? 'loading'}</Text> */}
                </Box>
                <Box
                    display="flex"
                    flexDir={'column'}
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    {/* <Icon cursor="pointer" color="primaryYellow.100" as={FaHeart} fontSize="22px" /> */}
                    <Button onClick={() => navigate(`/super-dashboard/partner/${store?._id}`)} w="fit-content" p="0" minW="0" minH="0" bg="transparent" alignItems="flex-end">
                        <Icon as={FaChevronRight} fontSize="20px" color="#78828A" />
                    </Button>
                </Box>
            </Flex>
        </Stack>
    )
}