import { Box, Image, Text, Button, Stack } from '@chakra-ui/react'
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { imgUrl } from '../../../utilities/config.js'
import { useNavigate } from 'react-router-dom'
import foodImg from '../../../assets/images/auth/r6.jpg'

export default function CategoryItem({ _id, name, picture }) {
    const navigate = useNavigate();
    return (
        <Box key={_id} w="11%" mb="0px !important">
            {/* <Image src={picture ? `${imgUrl}/${picture}` : foodImg} w="65px" h="65px" marginX="auto" mb="10px" objectFit={'cover'} borderRadius={'100%'} /> */}
            <Stack className='btn'>
                <Button
                    onClick={() => navigate('/dashboard/menu')}
                    justifyContent={'center'}
                    padding={'10px 20px'}
                    bg={"primaryGreen.100"}
                    color={'primaryYellow.100'}
                    fontWeight={'400'}
                    bgImage={greenBtnTexture}
                    bgPos={'center'}
                    bgSize={'cover'}
                    bgRepeat={'no-repeat'}
                    border={'2px solid'}
                    fontSize="14px"
                    borderColor={"primaryGreen.100"}
                    textAlign={'left'}
                    _hover={{
                        bg: "primaryGreen.100",
                        borderColor: "primaryGreen.100",
                        color: '#fff'
                    }}
                >
                    <Text as={'span'} textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'} >{name}</Text>
                </Button>
            </Stack>
        </Box>
    )
}