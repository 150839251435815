import { Avatar, Menu, MenuButton, MenuItem, MenuList, Stack, Text, Button, Icon, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { FaRegBell } from 'react-icons/fa'
import { FaCircle } from 'react-icons/fa'
import { FiChevronDown } from 'react-icons/fi'
import { useDispatch } from 'react-redux';
import greenBtnTexture from '../../assets/images/greenBtnTexture.svg'
import { logout } from '../../reducers/useReducers.js'
import Cookies from 'js-cookie'
import { PUT } from '../../utilities/ApiProvider.js'
import { imgUrl } from '../../utilities/config.js'
import { loadStoreAvail } from '../../reducers/useReducers.js'

export default function LoggedInPic() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const user = useSelector(state => state.value);
    const [isLoading, setIsLoading] = useState(false);

    const changeAvailability = async () => {
        setIsLoading(true)
        try {
            let res = await PUT('/vendor/store/availability', { isAvailable: !user?.isAvailable }, { authorization: `Bearer ${user?.verificationToken}` });
            if (res?.status === 200) {
                toast({
                    description: 'Store Availability Updated!',
                    status: 'success',
                    isCloseable: true,
                    duration: 3000,
                    position: 'top-right'
                });
                dispatch(loadStoreAvail(res?.data?.isAvailable))
            } else {
                toast({
                    description: res?.message,
                    status: 'error',
                    isCloseable: true,
                    duration: 3000,
                    position: 'top-right'
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                isCloseable: true,
                duration: 3000,
                position: 'top-right'
            });
        }
        setIsLoading(false)
    }

    return (
        <Stack direction={'row'} alignItems={'center'} gap={{ base: 1, lg: 2, 'xl': 12 }} py={4}>
            {
                user?.role?.name === 'vendor' &&
                <Stack className='btn'>
                    <Button
                        isLoading={isLoading}
                        onClick={changeAvailability}
                        padding={'10px'}
                        justifyContent="center"
                        w={'193px !important'}
                        bg={user?.isAvailable ? 'primaryGreen.100' : 'transparent'}
                        color={user?.isAvailable ? '#fff' : 'primaryGreen.100'}
                        bgImage={user?.isAvailable ? greenBtnTexture : 'none'}
                        bgPos={'center'}
                        bgSize={'cover'}
                        bgRepeat={'no-repeat'}
                        fontWeight={'500'}
                        border={'2px solid'}
                        borderColor="primaryGreen.100"
                        textAlign={'left'}
                        _hover={{
                            bg: 'transparent',
                            borderColor: 'primaryGreen.100',
                            color: 'primaryGreen.100'
                        }}
                    >
                        <Text as={'span'}>{user?.isAvailable ? 'Available' : 'Un-Available'}</Text>
                    </Button>
                </Stack>
            }
            {/* <Menu>
                <MenuButton textAlign={'center'} as={Button} backgroundColor={'transparent'} padding={0} _hover={{ backgroundColor: 'transparent' }} _active={{ backgroundColor: 'transparent' }}>
                    <Icon as={FaRegBell} fontSize={'24px'} />
                </MenuButton>
                <MenuList fontSize={14}>
                    <MenuItem><FaCircle size={6} color={'#f79e22'} /><Text ml={2}>Dummy Notification to fill up some space.</Text></MenuItem>
                    <MenuItem><FaCircle size={6} color={'#f79e22'} /><Text ml={2}>Dummy Notification to fill up some space.</Text></MenuItem>
                </MenuList>
            </Menu> */}
            <Menu>
                <MenuButton className="loggedBtn" display={'flex'} as={Button} backgroundColor={'transparent'} padding={0} _hover={{ backgroundColor: 'transparent' }} _active={{ backgroundColor: 'transparent' }}>
                    <Avatar size={{ base: 'sm', md: 'md' }} name={user?.username ?? 'Shahbaz'} src={user?.store?.logo ? `${imgUrl}/${user?.store?.logo}` : ''} border={'2px solid #f79e22'} bg={'#f79e22'} color={'#fff'} />
                    <Text textTransform={'capitalize'} fontWeight={500} fontSize={{ base: 13, md: 14 }}>{user?.username ?? 'Shahbaz'}</Text>
                    <Icon as={FiChevronDown} />
                </MenuButton>
                <MenuList fontSize={14}>
                    {/* <MenuItem as={ReactLink} to={'/Dashboard/OverView'}>Dashboard</MenuItem>
                    <MenuItem as={ReactLink} to={'/Dashboard/UserProfile'}>My Account</MenuItem>
                    <MenuItem as={ReactLink} to='/Dashboard/Help'>Help</MenuItem>
                    <MenuItem as={ReactLink} to='/'>Home</MenuItem> */}
                    <MenuItem onClick={() => {
                        dispatch(logout())
                        navigate('/')
                    }}>Logout</MenuItem>
                </MenuList>
            </Menu>
            <Menu>
                {/* <MenuButton textAlign={'center'} as={Button} backgroundColor={'transparent'} padding={0} _hover={{ backgroundColor: 'transparent' }} _active={{ backgroundColor: 'transparent' }}>
                    <FaBell size={24} color={'#666e82'} />
                </MenuButton>
                <MenuList fontSize={14}>
                    <MenuItem><FaCircle size={6} color={'#f79e22'} /><Text ml={2}>Dummy Notification to fill up some space.</Text></MenuItem>
                    <MenuItem><FaCircle size={6} color={'#f79e22'} /><Text ml={2}>Dummy Notification to fill up some space.</Text></MenuItem>
                </MenuList> */}
            </Menu>
        </Stack>
    )
}
