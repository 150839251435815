import { Stack, Heading, Tooltip, Text, Box, Table, Thead, Tr, Td, Tbody, Spinner, AccordionIcon, useToast, Textarea, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, FormLabel, ModalFooter, useDisclosure, } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiMenuAlt1, HiOutlineClock } from 'react-icons/hi'
import { useEffect, useState } from 'react'
import foodImg from '../../../assets/images/auth/r6.jpg';
import { useSelector } from 'react-redux';
import { GET, POST, PUT } from '../../../utilities/ApiProvider.js';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { years } from '../../../utilities/helper.js'

export default function HelpWrap() {

    const toast = useToast();
    const user = useSelector(state => state?.value);
    const [isLoading, setIsLoading] = useState(false);

    const [LogsData, setLogsData] = useState([]);

    const [tableHead, setTableHead] = useState([
        'Date',
        'Description',
        'User Info',
    ]);

    const getLogs = async () => {
        setIsLoading(true)
        try {
            let res = await GET('/admin/logs', { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                setLogsData(res?.data);
            } else {
                toast({
                    description: res?.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 3000
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (user) getLogs();

        return () => { }
    }, []);

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'} mr="60px !important">
                    <Box>
                        <Heading fontSize="24px" fontWeight="600">Activity Logs</Heading>
                    </Box>
                </Stack>
                <Table>
                    {/* ... Existing table headers */}
                    <Thead className='enerpriseRiskTableHead'>
                        <Tr bgColor={'primaryGreen.100'} border={'1px solid #CBCBCB'}>
                            {tableHead.map((v, i) => (
                                <Td key={i} color={'#fff'} fontSize={'14px'} fontWeight={'500'}>
                                    {v}
                                </Td>
                            ))} {/* Add a closing parenthesis for the map function */}
                        </Tr>
                    </Thead>
                    <Tbody className='enterpriseRiskTable'>
                        {
                            isLoading ? <Spinner /> :
                                LogsData?.map((v, i) => {
                                    let splittedDate = String(v?.createdAt).split('T')[0].split('-');
                                    return (
                                        <Tr key={i}>
                                            <Td color={'#262626'} fontSize={'14px'} fontWeight={'500'}>
                                                <Text>{years[splittedDate[1]]} {splittedDate[2]}, {splittedDate[0]}</Text>
                                            </Td>
                                            <Td color={'#262626'} fontSize={'14px'} fontWeight={'500'}>
                                                <Tooltip label={v?.action ?? '-'} placement="top" hasArrow>
                                                    <Box maxW="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                                        {v?.action ?? '-'}
                                                    </Box>
                                                </Tooltip>
                                            </Td>
                                            <Td color={'#262626'} fontSize={'14px'} fontWeight={'500'}>
                                                <Text>
                                                    Username: {v?.adminId?.username ?? '-'}
                                                </Text>
                                                <Text>
                                                    Email: {v?.adminId?.email ?? '-'}
                                                </Text>
                                            </Td>
                                        </Tr>
                                    )
                                })
                        }
                    </Tbody>
                </Table>
            </Stack>
        </>
    )
}