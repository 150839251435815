import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import OrdersWrap from '../../../components/Dashboard/Orders/OrdersWrap';
import { Stack } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png';

export default function DashboardOrders() {
  return (
    <Stack backgroundImage={shapeb} overflowY="hidden">
      <MainDashboard>
        <OrdersWrap />
      </MainDashboard>
    </Stack>
  );
}
