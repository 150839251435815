import React, { createContext } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import RouteSwitch from './RouteSwitch';
import NotFound from '../views/NotFound'
import Home from '../views/Website/Home.js';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Register from '../views/Dashboard/Auth/Register';
import Login from '../views/Dashboard/Auth/Login';
import ForgotPassword from '../views/Dashboard/Auth/ForgotPassword';
import Verification from '../views/Dashboard/Auth/Verification';
import NewPassword from '../views/Dashboard/Auth/NewPassword';
import CreateStore from '../views/Dashboard/Auth/CreateStore';
import AboutUs from '../views/Website/AboutUs.js';
import Groceries from '../views/Website/Groceries.js';
import Chef from '../views/Website/Chef.js';
import Directories from '../views/Website/Directories.js';
import Butcher from '../views/Website/Butcher.js';
import Events from '../views/Website/Events.js';
import Eatries from '../views/Website/Eatries.js';
import Funeral from '../views/Website/Funeral.js';
import Culture from '../views/Website/Culture.js';
import Quran from '../views/Website/Quran.js';
import HalalWeb from '../views/Website/HalalWeb.js';
import Food from '../views/Website/Food.js';
import PrivacyPolicy from '../views/Website/PrivacyPolicy.js';
import Blog from '../views/Website/Blog.js';
import SingleBlog from '../views/Website/SingleBlog.js';
import Event from '../views/Website/Event.js';
import SingleEvent from '../views/Website/SingleEvent.js';
import ContactUs from '../views/Website/ContactUs.js';
import DashboardHome from '../views/Dashboard/Home/index.js'
import DashboardOrders from '../views/Dashboard/Orders/Index.js'
import DashboardStore from '../views/Dashboard/Store/Index.js'
import DashboardCategory from '../views/Dashboard/Category/index.js'
import DashboardAddMenu from '../views/Dashboard/AddMenu/index.js'
import DashboardAnalytics from '../views/Dashboard/Analytics/index.js'
import DashboardHelp from '../views/Dashboard/Help/index.js'
import DashboardSetting from '../views/Dashboard/Setting/index.js'
import DashboardPromotion from '../views/Dashboard/Promotion/index.js'
import SuperDashboardHome from '../views/SuperDashboard/Home/index.js'
import SuperDashboardStoreReq from '../views/SuperDashboard/StoreReq/index.js'
import SuperDashboardPartners from '../views/SuperDashboard/Partners/index.js'
import SuperDashboardPartnerPage from '../views/SuperDashboard/Partners/PartnerView.js'
import SuperDashboardHelp from '../views/SuperDashboard/Help/index.js'
import LogsMainDashboard from '../views/SuperDashboard/Logs/index.js'
import SuperDashboardUsers from '../views/SuperDashboard/Users/index.js'
import SuperDashboardUsersInfo from '../views/SuperDashboard/Users/UserInfo.js'
import SuperDashboardAnalytics from '../views/SuperDashboard/Analytics/index.js'
import SuperDashboardSetting from '../views/SuperDashboard/Setting/index.js'
import SuperDashboardEvent from '../views/SuperDashboard/Events/index.js'
import SuperDashboardEventDetails from '../views/SuperDashboard/Events/EventDetails.js'
import SuperDashboardCreateEvent from '../views/SuperDashboard/Events/CreateEvent.js'
import SuperDashboardBlog from '../views/SuperDashboard/Blogs/index.js'
import SuperDashboardBlogDetails from '../views/SuperDashboard/Blogs/BlogDetails.js'
import SuperDashboardBanner from '../views/SuperDashboard/Banners/index.js'
import SuperDashboardCreateBlog from '../views/SuperDashboard/Blogs/CreateBlog.js'
import SingleMenuItemDashboard from '../views/Dashboard/AddMenu/SingleMenuItem.js';
import SingleMenuEditDashboard from '../views/Dashboard/AddMenu/SingleMenuEdit.js';
import PromotionListDashboard from '../views/Dashboard/Promotion/PromotionList.js';
import EditPromotionDashboard from '../views/Dashboard/Promotion/EditPromotion.js';
import EditStoreDashboard from '../views/Dashboard/Store/EditStore.js';
import SuperPromotionDashboard from '../views/SuperDashboard/Promotion/index.js';
import SuperPromotionListDashboard from '../views/SuperDashboard/Promotion/PromotionList.js';
import CreatePartnerDashboard from '../views/SuperDashboard/CreatePartner/index.js';
import CatListDashboard from '../views/SuperDashboard/Categories/index.js';
import WebStore from '../views/Website/Store.js';
import StoreListCategoryWise from '../views/Website/StoreList.js';
import RiderRegister from '../views/Dashboard/Auth/RiderRegister.js';
import SuperDashboardRiders from '../views/SuperDashboard/Riders/index.js';
import RiderReqDashboard from '../views/SuperDashboard/RiderReq/index.js';
import RiderVehicleReqDashboard from '../views/SuperDashboard/RiderVehicleReq/index.js';
import RiderInfoMainDash from '../views/SuperDashboard/Rider/index.js';
import ContestPage from '../views/Website/contestPage.js';
import Contest from '../views/Dashboard/Contest/index.js';

export const UserContext = createContext()

export default function AppRoute() {

    return (
        <div>
            <Router>
                {/* <Header /> */}
                <RouteSwitch>
                    <Route exact path="/" element={<Home />}></Route>
                    <Route exact path="/aboutus" element={<AboutUs />}></Route>
                    <Route exact path="/contest" element={<ContestPage />}></Route>
                    <Route exact path="/app-feature/groceries" element={<Groceries />}></Route>
                    <Route exact path="/app-feature/home-chef" element={<Chef />}></Route>
                    <Route exact path="/app-feature/directories" element={<Directories />}></Route>
                    <Route exact path="/app-feature/butchers" element={<Butcher />}></Route>
                    <Route exact path="/app-feature/events" element={<Events />}></Route>
                    <Route exact path="/app-feature/eatries" element={<Eatries />}></Route>
                    <Route exact path="/app-feature/funeral-service" element={<Funeral />}></Route>
                    <Route exact path="/app-feature/culture" element={<Culture />}></Route>
                    <Route exact path="/app-feature/quran-learning" element={<Quran />}></Route>
                    <Route exact path="/app-feature/halal-web" element={<HalalWeb />}></Route>
                    <Route exact path="/app-feature/food-share" element={<Food />}></Route>
                    <Route exact path="/privacypolicy" element={<PrivacyPolicy />}></Route>
                    <Route exact path="/blog" element={<Blog />}></Route>
                    <Route exact path="/singleblog/:id" element={<SingleBlog />}></Route>
                    <Route exact path="/event" element={<Event />}></Route>
                    <Route exact path="/singleevent/:id" element={<SingleEvent />}></Route>
                    <Route exact path="/contactus" element={<ContactUs />}></Route>
                    <Route exact path="/store/:id" element={<WebStore />}></Route>
                    <Route exact path="/category-wise-store/:name" element={<StoreListCategoryWise />}></Route>
                    {/* Vendor Dashboard Routes */}
                    <Route exact path="/login" element={<Login />}></Route>
                    <Route exact path="/register" element={<Register />}></Route>
                    <Route exact path="/rider-register" element={<RiderRegister />}></Route>
                    <Route exact path="/forgotpassword" element={<ForgotPassword />}></Route>
                    <Route exact path="/verification" element={<Verification />}></Route>
                    <Route exact path="/newpassword" element={<NewPassword />}></Route>
                    <Route exact path="/createnewstore" element={<CreateStore />}></Route>
                    <Route exact path="/dashboard" element={<DashboardHome />}></Route>
                    <Route exact path="/dashboard/orders" element={<DashboardOrders />}></Route>
                    <Route exact path="/dashboard/store" element={<DashboardStore />}></Route>
                    <Route exact path="/dashboard/edit-store" element={<EditStoreDashboard />}></Route>
                    {/* <Route exact path="/dashboard/contest" element={<Contest />}></Route> */}
                    <Route exact path="/dashboard/menu" element={<DashboardCategory />}></Route>
                    <Route exact path="/dashboard/add-menu" element={<DashboardAddMenu />}></Route>
                    <Route exact path="/dashboard/single-item/:id" element={<SingleMenuItemDashboard />}></Route>
                    <Route exact path="/dashboard/edit-menu/:id" element={<SingleMenuEditDashboard />}></Route>
                    <Route exact path="/dashboard/analytics" element={<DashboardAnalytics />}></Route>
                    <Route exact path="/dashboard/help" element={<DashboardHelp />}></Route>
                    <Route exact path="/dashboard/setting" element={<DashboardSetting />}></Route>
                    <Route exact path="/dashboard/promotion" element={<DashboardPromotion />}></Route>
                    <Route exact path="/dashboard/edit-promotion/:id" element={<EditPromotionDashboard />}></Route>
                    <Route exact path="/dashboard/promotion-list" element={<PromotionListDashboard />}></Route>
                    {/* Admin Dashboard Routes */}
                    <Route exact path="/super-dashboard/" element={<SuperDashboardHome />}></Route>
                    <Route exact path="/super-dashboard/store-request" element={<SuperDashboardStoreReq />}></Route>
                    <Route exact path="/super-dashboard/partners" element={<SuperDashboardPartners />}></Route>
                    <Route exact path="/super-dashboard/partner/:id" element={<SuperDashboardPartnerPage />}></Route>
                    <Route exact path="/super-dashboard/create-store-by-admin" element={<CreatePartnerDashboard />}></Route>
                    <Route exact path="/super-dashboard/categories" element={<CatListDashboard />}></Route>
                    <Route exact path="/super-dashboard/help" element={<SuperDashboardHelp />}></Route>
                    <Route exact path="/super-dashboard/logs" element={<LogsMainDashboard />}></Route>
                    <Route exact path="/super-dashboard/users" element={<SuperDashboardUsers />}></Route>
                    <Route exact path="/super-dashboard/user/:id" element={<SuperDashboardUsersInfo />}></Route>
                    <Route exact path="/super-dashboard/riders" element={<SuperDashboardRiders />}></Route>
                    <Route exact path="/super-dashboard/rider/:id" element={<RiderInfoMainDash />}></Route>
                    <Route exact path="/super-dashboard/rider-requests" element={<RiderReqDashboard />}></Route>
                    <Route exact path="/super-dashboard/rider-vehicle-request" element={<RiderVehicleReqDashboard />}></Route>
                    <Route exact path="/super-dashboard/analytics" element={<SuperDashboardAnalytics />}></Route>
                    <Route exact path="/super-dashboard/setting" element={<SuperDashboardSetting />}></Route>
                    <Route exact path="/super-dashboard/contest" element={<Contest />}></Route>
                    <Route exact path="/super-dashboard/promotion" element={<SuperPromotionDashboard />}></Route>
                    <Route exact path="/super-dashboard/promotion-list" element={<SuperPromotionListDashboard />}></Route>
                    <Route exact path="/super-dashboard/events" element={<SuperDashboardEvent />}></Route>
                    <Route exact path="/super-dashboard/event/:id" element={<SuperDashboardEventDetails />}></Route>
                    <Route exact path="/super-dashboard/add-event" element={<SuperDashboardCreateEvent />}></Route>
                    <Route exact path="/super-dashboard/blogs" element={<SuperDashboardBlog />}></Route>
                    <Route exact path="/super-dashboard/blog/:id" element={<SuperDashboardBlogDetails />}></Route>
                    <Route exact path="/super-dashboard/banners" element={<SuperDashboardBanner />}></Route>
                    <Route exact path="/super-dashboard/add-blog" element={<SuperDashboardCreateBlog />}></Route>
                    <Route exact path="*" element={<NotFound />}></Route>
                </RouteSwitch>
                {/* <Footer /> */}
            </Router>
        </div>
    )
}