import { Stack, Heading, Icon, Text, Box, Button, Image, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, useToast, Spinner, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, FormLabel, ModalFooter, useDisclosure, Select } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiMenuAlt1, HiOutlineClock, HiOutlineSearch } from 'react-icons/hi'
import { useEffect, useRef, useState } from 'react'
import foodImg from '../../../assets/images/auth/r6.jpg';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { GET, POST, DELETE } from '../../../utilities/ApiProvider.js';
import { useSelector } from 'react-redux';
import SingleCatItem from './SingleCatItem.js'

export default function HelpWrap() {

    const toast = useToast();
    const user = useSelector(state => state?.value);
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [catList, setCatList] = useState([]);
    const [fileName, setFileName] = useState({
        name: 'Choose Image (120px x 120px)'
    });
    const imgRef = useRef(null);
    const [parentCat, setParentCat] = useState({});

    const getStore = async () => {
        setIsLoading(true);
        try {
            let res = await GET(`/admin/roles/categorywithStoresCount`, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                setCatList(res?.data)
            } else {
                toast({
                    description: res?.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    const createCatHandler = async () => {
        setIsLoading(true);
        let form = document.getElementById('creatCatForm');
        let data = new FormData(form);
        // let cat = JSON.parse(parentCat);
        // data.append('parent', cat?.parent)
        // data.append('group', cat?.group)
        try {
            let res = await POST(`/admin/category/store`, data, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                toast({
                    description: 'Store Category Created!',
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
                getStore();
                onClose();
            } else {
                toast({
                    description: res?.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    const deleteCat = async (id) => {
        setIsLoading(true);
        try {
            let res = await DELETE(`/admin/category/store/${id}`, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                toast({
                    description: 'Store Deleted!',
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
                getStore();
            } else {
                toast({
                    description: res?.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (user) getStore();
    }, [user])

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Stack
                    mr="60px !important"
                >
                    <Stack direction="row" justifyContent="space-between">
                        <Box>
                            <Heading fontSize="20px">Categories</Heading>
                            <Text color="#606060" mb="20px !important">You've {catList?.length ?? 0} categories</Text>
                        </Box>
                        <Stack className='btn'>
                            <Button
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={"primaryGreen.100"}
                                onClick={onOpen}
                                isDisabled={isLoading}
                                bgImage={greenBtnTexture}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                color={'primaryYellow.100'}
                                fontWeight={'400'}
                                border={'2px solid'}
                                borderColor={"primaryGreen.100"}
                                textAlign={'left'}
                                _hover={{
                                    bg: "primaryGreen.100",
                                    borderColor: "primaryGreen.100",
                                    color: '#fff'
                                }}
                            >
                                <Text as={'span'}>Create Category</Text>
                            </Button>
                        </Stack>
                    </Stack>
                    <Stack direction="row" flexWrap="wrap" gap="1.5%" spacing="0" pb={'20px'} justifyContent={'space-between'}>
                        {
                            catList?.length > 0 &&
                            catList?.map(val =>
                                <SingleCatItem {...val} deleteCat={deleteCat} getStore={getStore} token={user?.verificationToken} />
                            )
                        }
                    </Stack>
                </Stack>
            </Stack>
            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent
                    bgSize="cover"
                    bgPos="center"
                    backgroundImage={shapeb}
                    p="20px 40px">
                    <ModalHeader fontWeight="600" fontSize={'24px'} textAlign="center">Create Store Category</ModalHeader>
                    <ModalBody>
                        <form id="creatCatForm">
                            <FormLabel mt="20px !important" fontWeight="600">Category Name</FormLabel>
                            <Box className="Ifiled">
                                <Input
                                    type={'text'}
                                    placeholder="Enter here"
                                    name="name"
                                />
                            </Box>
                            <FormLabel mt="20px !important" fontWeight="600">Category Image</FormLabel>
                            <Box className="Ifiled" onClick={() => imgRef?.current.click()}>
                                <Input
                                    cursor={'pointer'}
                                    readOnly
                                    value={fileName?.name ?? 'Select File'}
                                />
                                <Input
                                    ref={imgRef}
                                    onChange={e => setFileName(e.target.files[0])}
                                    display={'none'}
                                    type={'file'}
                                    placeholder="Enter here"
                                    name="picture"
                                />
                            </Box>
                            {/* <FormLabel mt="20px !important" fontWeight="600">Parent Category</FormLabel>
                            <Box className="Ifiled">
                                <Select onChange={e => setParentCat(e.target.value)}>
                                    <option style={{ display: 'none' }}>Select</option>
                                    {
                                        catList?.length > 0 &&
                                        catList?.map(val => <option key={val?._id} value={JSON.stringify({
                                            parent: val?._id,
                                            group: val?.name
                                        })}>{val?.name}</option>)
                                    }
                                </Select>
                            </Box> */}
                        </form>
                    </ModalBody>
                    <ModalFooter justifyContent="center">
                        <Stack className='btn'>
                            <Button
                                onClick={createCatHandler}
                                justifyContent={'center'}
                                isLoading={isLoading}
                                padding={'10px 20px'}
                                bg={"primaryGreen.100"}
                                w="200px"
                                color={'primaryYellow.100'}
                                fontWeight={'400'}
                                border={'2px solid'}
                                bgImage={greenBtnTexture}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                borderColor={"primaryGreen.100"}
                                textAlign={'left'}
                                _hover={{
                                    bg: "primaryGreen.100",
                                    borderColor: "primaryGreen.100",
                                    color: '#fff'
                                }}
                            >
                                <Text as={'span'}>Submit</Text>
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    )
}