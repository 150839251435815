import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import RiderVehicleReqWrap from '../../../components/SuperDashboard/RiderVehicleReq/StoreReqWrap';

export default function RiderVehicleReqDashboard() {
    return (
        <Stack
            backgroundImage={shapeb}
            overflowY="hidden"
        >
            <MainDashboard type="admin">
                <RiderVehicleReqWrap />
            </MainDashboard>
        </Stack>
    );
}