import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard.js';
import CreateBlogWrap from '../../../components/SuperDashboard/Blogs/CreateBlogWrap.js';
import { Stack } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'

export default function DashboardHome() {
    return (
        <Stack
            backgroundImage={shapeb}
            overflowY="hidden"
        >
            <MainDashboard type='admin'>
                <CreateBlogWrap />
            </MainDashboard>
        </Stack>
    );
}
