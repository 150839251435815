import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import RidersWrap from '../../../components/SuperDashboard/RidersWrap/RidersWrap.js';

export default function SuperDashboardRiders() {
    return (
        <Stack
            backgroundImage={shapeb}
            overflowY="hidden"
        >
            <MainDashboard type='admin'>
                <RidersWrap />
            </MainDashboard>
        </Stack>
    );
}
