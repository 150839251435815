import { Stack, Text, Box, Image, OrderedList, Button, useToast, Icon } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
// Components
import CounterBox from "../../components/Website/Home/counterBox";
import EventCard from "../../components/Website/Event/EventCard";
import Header from "../../components/Website/Header/header";
import HeroBanner from '../../components/Website/Home/heroBanner';
import Footer from "../../components/Website/Footer/Footer";
// Images
import SBlog from "../../assets/images/website/blog/SBlog.png";
import BlackOver from '../../assets/images/icon/black.png';
import { moreEvent } from "../../assets/data/data";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET } from "../../utilities/ApiProvider";
import { imgUrl } from "../../utilities/config";
import { FaInfoCircle, FaPhone, FaUser, FaMapMarker, FaCalendarAlt, FaLink, FaClock, FaDollarSign, FaCreditCard } from "react-icons/fa";
import greenBtnTexture from '../../assets/images/greenBtnTexture.svg'
import { years } from "../../utilities/helper";

const SingleEvent = () => {

    const params = useParams();
    const toast = useToast();
    const [blogData, setBlogData] = useState(null);
    const [recentData, setRecentData] = useState(null);
    const options = { day: "numeric", month: "short", year: "numeric" };
    const [customDate, setCustomDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);

    const getBlogData = async () => {
        try {
            let res = await GET(`/admin/posts/${params?.id}`);
            if (res.status === 200) {
                setBlogData(res?.data);
                setRecentData(res?.recent?.filter(val => val?.isArchive === false));
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
    }

    useEffect(() => {
        getBlogData()
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, [params]); // Empty dependency array ensures that this effect runs only once after initial mount

    useEffect(() => {
        if (blogData) {
            const googleMapIframe = document.getElementById('googleMaps');
            googleMapIframe.src = `https://maps.google.com/maps?q=${blogData?.latitude},${blogData?.longitude}&hl=es;&output=embed`

            let splittedDate = String(blogData?.date).split('T')[0].split('-');
            setCustomDate(splittedDate)
            let splittedEndDate = String(blogData?.endDate).split('T')[0].split('-');
            setCustomEndDate(splittedEndDate)
        }
    }, [blogData])


    return (
        <>
            <Header />

            <Box>
                <HeroBanner text={blogData ? blogData?.name : ''} />
            </Box>

            <Box p={"40px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box p={"20px 0px"} w={"100%"} display={'flex'} flexDirection={{ base: 'column', lg: 'row' }} justifyContent={'space-between'}>
                        <Box w={{ base: "100%", lg: "40%" }}>
                            <Image src={blogData ? `${imgUrl}/${blogData?.picture}` : SBlog} w="100%" height={{ base: '200px', lg: '500px' }} objectFit={'cover'} />
                        </Box>
                        <Box w={{ base: "100%", lg: "58%" }}>
                            {
                                blogData?.isRegistrationReq &&
                                <Stack mt={{ base: '20px', lg: '0' }} borderBottom={'1px solid #adadad'} py="10px !important" direction={{ base: 'column', lg: 'row' }} alignItems={'center'} justifyContent={'space-between'}>
                                    <Text fontWeight={'500'} color={'red.700'}><Icon fontSize="20" m="0 5px -5px 0" as={FaInfoCircle} />This Event Requires Registration</Text>
                                    <Stack className='btn'>
                                        <Button
                                            onClick={() => window.open(`${blogData?.googleFormLink ?? ''}`, '_blank')}
                                            justifyContent={'center'}
                                            padding={'10px 20px'}
                                            bg={"primaryGreen.100"}
                                            color={'primaryYellow.100'}
                                            w={'180px'}
                                            bgImage={greenBtnTexture}
                                            bgPos={'center'}
                                            bgSize={'cover'}
                                            bgRepeat={'no-repeat'}
                                            fontWeight={'400'}
                                            border={'2px solid'}
                                            borderColor={"primaryGreen.100"}
                                            textAlign={'left'}
                                            _hover={{
                                                bg: "primaryGreen.100",
                                                borderColor: "primaryGreen.100",
                                                color: '#fff'
                                            }}
                                        >
                                            <Text as={'span'}>Register</Text>
                                        </Button>
                                    </Stack>
                                </Stack>
                            }
                            {
                                blogData?.isVirtual &&
                                <Stack mt={{ base: '20px', lg: '0' }} borderBottom={'1px solid #adadad'} py="10px !important" direction={{ base: 'column', lg: 'row' }} alignItems={'center'} justifyContent={'space-between'}>
                                    <Text fontWeight={'500'} color={'red.700'}><Icon fontSize="20" m="0 5px -5px 0" as={FaInfoCircle} />This Event is Virtual</Text>
                                    <Stack className='btn'>
                                        <Button
                                            onClick={() => window.open(`${blogData?.conferanceLink ?? ''}`, '_blank')}
                                            justifyContent={'center'}
                                            padding={'10px 20px'}
                                            bg={"primaryGreen.100"}
                                            color={'primaryYellow.100'}
                                            w={'180px'}
                                            bgImage={greenBtnTexture}
                                            bgPos={'center'}
                                            bgSize={'cover'}
                                            bgRepeat={'no-repeat'}
                                            fontWeight={'400'}
                                            border={'2px solid'}
                                            borderColor={"primaryGreen.100"}
                                            textAlign={'left'}
                                            _hover={{
                                                bg: "primaryGreen.100",
                                                borderColor: "primaryGreen.100",
                                                color: '#fff'
                                            }}
                                        >
                                            <Text as={'span'}>Visit</Text>
                                        </Button>
                                    </Stack>
                                </Stack>
                            }
                            <Stack py="20px !important" borderBottom={'1px solid #adadad'} direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                                <Box flex={1}>
                                    <Text fontSize={{ base: "14px", lg: "16px" }} fontWeight={'500'} lineHeight={"22px"} mb={"15px"}><Icon m="0 5px -3px 0" as={FaUser} /> {blogData ? `${blogData?.contactPerson}` : 'Loading'}</Text>
                                    <Text as={'a'} href={`tel:${blogData?.contactNumber}`} fontSize={{ base: "14px", lg: "16px" }} fontWeight={'500'} lineHeight={"22px"} mb={"15px"}><Icon m="0 5px -3px 0" as={FaPhone} />{blogData?.contactNumber ? `${blogData?.contactNumber}` : '-'}</Text>
                                    <Text fontSize={{ base: "14px", lg: "16px" }} fontWeight={'500'} lineHeight={"22px"} mt={"15px"}><Icon m="0 5px -3px 0" as={FaCreditCard} />{blogData?.paid ? `C$ ${blogData?.price}` : 'Free'}</Text>
                                </Box>
                                <Box flex={1} textAlign={'right'}>
                                    <Text fontSize={{ base: "14px", lg: "16px" }} fontWeight={'500'} lineHeight={"22px"} mb={"15px"}><Icon m="0 5px -3px 0" as={FaCalendarAlt} />{customDate ? `${years[customDate[1]]} ${customDate[2]}, ${customDate[0]}` : 'March 31, 2022'} - {customEndDate ? `${years[customEndDate[1]]} ${customEndDate[2]}, ${customEndDate[0]}` : ''}</Text>
                                    <Text fontSize={{ base: "14px", lg: "16px" }} fontWeight={'500'} lineHeight={"22px"} mb={"15px"}><Icon m="0 5px -3px 0" as={FaClock} />{blogData?.time ? String(blogData?.time) : null} - {blogData?.endtime ? String(blogData?.endtime) : null}</Text>

                                    {
                                        blogData?.isVirtual === true ?
                                            <></>
                                            :
                                            <>
                                                <Text fontSize={{ base: "14px", lg: "16px" }} fontWeight={'500'} lineHeight={"22px"} mb={"15px"}><Icon m="0 5px -3px 0" as={FaMapMarker} />{blogData ? blogData?.location : 'Loading'}</Text>
                                                {
                                                    blogData?.fullAddress !== '' &&
                                                    <Text fontSize={{ base: "14px", lg: "16px" }} fontWeight={'500'} lineHeight={"22px"} mb={"15px"}>
                                                        <Icon m="0 5px -3px 0" as={FaMapMarker} />{blogData ? blogData?.fullAddress : 'Loading'}
                                                    </Text>
                                                }
                                            </>
                                    }
                                </Box>
                            </Stack>
                            {/* <Text fontSize={"20px"} fontWeight={'600'} lineHeight={"22px"} m={"20px 0 20px 0"}>Description:</Text> */}
                            <Stack py="20px" direction={{ base: 'column', lg: 'row' }} gap="8">
                                <Text w={{ base: '100%', lg: '60%' }} fontSize={{ base: "14px", lg: "16px" }} lineHeight={"22px"} >{blogData ? blogData?.description : ''}</Text>
                                <iframe
                                    style={{ width: { base: '100%', lg: '45%' }, height: '300px', flex: 1, border: '1px solid #adadad' }}
                                    id="googleMaps"
                                >
                                </iframe>
                            </Stack>
                        </Box>
                    </Box >
                    <Box maxW={"1220px"}>
                        {/* <Text fontSize={"16px"} lineHeight={"22px"} mb={"20px"}>{blogData ? blogData?.description : ''}</Text> */}
                        {/* <Text fontSize={"18.8px"} lineHeight={"22px"} fontWeight={"500"} mb={"20px"}>Here's what you can expect from our Halal Markets feature:</Text>
                        <OrderedList>
                            <ListItem fontSize={"14.3px"} lineHeight={"22px"} mb={"20px"}>Wide Range of Categories**: Aimhalal's Halal Markets cover a vast array of product categories, including food, cosmetics, personal care, pharmaceuticals, healthcare, tourism, and apparel fashion. Whether you're searching for Halal-certified food items, ethically produced cosmetics, or travel options that align with your cultural and religious values, our Halal Markets have got you covered</ListItem>
                            <ListItem fontSize={"14.3px"} lineHeight={"22px"} mb={"20px"}>Find Halal Groceries Nearby**: With Aimhalal's Halal Groceries feature, you can effortlessly locate Halal grocery stores in your vicinity. Our user-friendly interface allows you to search for Halal groceries based on your current location, making it convenient to find the nearest stores that offer a wide range of Halal products.</ListItem>
                        </OrderedList>
                        <Text fontSize={"14.3px"} lineHeight={"22px"} mb={"20px"}>At Aimhalal, we recognize the importance of sourcing high-quality Halal meat from trusted suppliers. That's why we're thrilled to introduce our Halal Butchers feature, a reliable resource for finding Halal meat providers that adhere to strict Halal standards</Text>
                        <Text fontSize={"14.3px"} lineHeight={"22px"}>At Aimhalal, we understand the importance of easily accessing Halal groceries that meet your dietary needs and align with your cultural and religious values. That's why we've developed our Halal Groceries feature, a convenient one-window solution for finding Halal grocery stores near you.</Text> */}
                    </Box>
                </Container >
            </Box >

            <Box p={"30px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box mb={"30px"}>
                        <Text fontSize={"28px"} lineHeight={"22px"} fontWeight={"600"}>Related Events</Text>
                    </Box>
                    <Stack direction={"row"} flexWrap={'wrap'} alignItems={"center"} mb={"30px"}>
                        {recentData?.length > 0 && recentData?.map((val, key) => {
                            return (
                                <Box key={key}>
                                    <EventCard picture={val?.picture} date={val?.createdAt} text={val?.name} href={val?._id} />
                                </Box>
                            )
                        })}
                    </Stack>
                    {/* <Box p={"40px 0px"}>
                        <CounterBox />
                    </Box> */}
                </Container>
            </Box>

            <Footer />
        </>
    )
}

export default SingleEvent;