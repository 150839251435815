import { Container, Stack, Grid, GridItem, Image } from '@chakra-ui/react';
import React from 'react';
import RegisterForm from '../../../components/Auth/RegisterForm';
import R5img from '../../../assets/images/auth/r5.jpg';
import shapew from '../../../assets/images/icon/white.png';
import AuthPic from '../../../components/Repeat/AuthPic';
import NewPassform from '../../../components/Auth/NewPassform';

export default function NewPassword() {
  return (
    <>
      <Stack
        w={'100%'}
        h={'100vh'}
        overflow={'hidden'}
        alignItems={'center'}
        justifyContent={'center'}
        position={'relative'}
        bg={'primaryGreen.100'}
        backgroundImage={shapew}
      >
     <AuthPic Picsrc={R5img} />
        <Container maxW={'8xl'}>
          <Grid
            templateColumns="repeat(12, 1fr)"
            gap={{ base: 2, sm: 0 }}
            alignItems={'center'}
          >
            <GridItem colSpan={{ base: '4', md: '6' }}>
             <NewPassform/>
            </GridItem>
            <GridItem colSpan={{ base: '4', md: '6' }}></GridItem>
          </Grid>
        </Container>
      </Stack>
    </>
  )
}
