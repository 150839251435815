import {
  Stack,
  Box,
  Image,
  Text,
  Heading,
  Icon,
  Checkbox,
  FormLabel,
  Input,
  Button,
  Link,
  InputGroup,
  InputLeftAddon,
  Select,
  useToast,
} from '@chakra-ui/react';

import { BiArrowBack } from 'react-icons/bi';
import React, { useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import shapeb from '../../assets/images/icon/black.png';
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg'
import { POST } from '../../utilities/ApiProvider.js'
import { localBaseUrl } from '../../utilities/config.js'

export default function ForgotPass() {

  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const submitRequest = async () => {
    setIsLoading(true);
    try {
      if (email === '') {
        toast({
          description: 'Email is required!',
          status: 'error',
          position: 'top-right',
          duration: '2000'
        })
      } else {
        let res = await POST(`/auth/request-password`, { email });
        if (res.status === 200) {
          toast({
            description: res.message,
            status: 'success',
            position: 'top-right',
            duration: '2000'
          });
          localStorage.setItem('emailForOtpVerification', email);
          navigate('/verification');
        } else {
          toast({
            description: res.message,
            status: 'error',
            position: 'top-right',
            duration: '2000'
          });
        }
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: '2000'
      })
    }
    setIsLoading(false);
  }

  return (
    <>
      <Stack
        w={'70%'}
        bg={'#fff'}
        py={'50px'}
        m={'0 auto'}
        px={'20px'}
        border={'5px solid'}
        borderColor="primaryYellow.100"
        borderRadius={'8px'}
        backgroundImage={shapeb}
      >
        <Stack mb={8}>
          <Box className="boxlink">
            <Link as={ReactLink} to={'/login'}>
              <Icon fontSize={'17px'} color={'#000'} as={BiArrowBack} />
            </Link>
          </Box>
        </Stack>
        <Stack className="AH mh p1" pb={6}>
          <Heading as={'h3'}>Forgot Password</Heading>
          <Text>
            Enter you email address and we will send you a verification code
            through email
          </Text>
        </Stack>
        <Stack className="regwarp" gap={12}>
          <Stack>
            <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
              Email
            </FormLabel>
            <Box className="Ifiled">
              <Input type="email" onChange={e => setEmail(e.target.value)} placeholder="Enter here" />
            </Box>
          </Stack>

          <Stack className="btn">
            <Button
              isLoading={isLoading}
              onClick={submitRequest}
              padding={'10px 50px'}
              bg={'#FFC00F'}
              bgImage={yellowBtnTexture}
              bgPos={'center'}
              bgSize={'cover'}
              bgRepeat={'no-repeat'}
              color={'primaryGreen.100'}
              fontWeight={'500'}
              w={'fit-content'}
              border={'2px solid'}
              borderColor="primaryGreen.100"
              textTransform={'uppercase'}
            >
              <Text as={'span'}>Continue</Text>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
