import { Stack, Image, Box, Heading, Text, Icon, Button, Flex } from "@chakra-ui/react";
import foodImg from '../../../../assets/images/auth/r6.jpg';
import { imgUrl } from '../../../../utilities/config.js'
import { FaStar, FaHeart, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from "react-router";

export default function PopularSingleItem({ _id, item, reviews, pictures, address }) {
    const navigate = useNavigate();
    return (
        <Stack key={_id} direction={'row'} alignItems="center" mb="30px" mt="0 !important" p="15px 0 !important" borderBottom="1px solid #BFC6CC">
            <Image src={pictures[0] ? `${imgUrl}/${pictures[0]}` : foodImg} w="92px" h="92px" objectFit={'cover'} borderRadius="12px" />
            <Flex justifyContent="space-between" w="full" alignItems={'center'}>
                <Box>
                    <Heading fontSize="16px" fontWeight="600" textTransform="capitalize" mb="5px">{item ?? 'Loading'}</Heading>
                    <Text fontSize="14px" color="#616161" mb="10px">{address ?? 'Loading'}</Text>
                    <Text fontSize="14px" color="#78828A"><Icon as={FaStar} color="primaryYellow.100" fontSize="12px" /> {reviews?.length > 0 ? reviews?.length : 0 ?? 'loading'}</Text>
                </Box>
                <Box
                    display="flex"
                    flexDir={'column'}
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    {/* <Icon cursor="pointer" color="primaryYellow.100" as={FaHeart} fontSize="22px" /> */}
                    <Button onClick={()=> navigate('/dashboard/menu')} h="0" w="fit-content" p="0" minW="0" minH="0" bg="transparent" alignItems="flex-end">
                        <Icon as={FaChevronRight} fontSize="20px" color="#78828A" />
                    </Button>
                </Box>
            </Flex>
        </Stack>
    )
}