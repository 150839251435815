import { Stack, Text, Box, Image, Heading, useToast, Button, Icon, Container, Spinner } from "@chakra-ui/react";
// Components
import Header from "../../components/Website/Header/header";
import Footer from "../../components/Website/Footer/Footer";

import storeBg from '../../assets/images/bg2.jpg';
import storePic from '../../assets/images/storePic.svg';
import BlackOver from '../../assets/images/icon/black.png';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router';
import { GET } from "../../utilities/ApiProvider";
import { imgUrl } from "../../utilities/config";
import { FaTimes, FaStar } from 'react-icons/fa'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import foodPic from '../../assets/images/auth/r6.jpg'

const WebStore = () => {
    const params = useParams()
    const toast = useToast();
    const [storeData, setStoreData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [storeItems, setStoreItems] = useState(null);
    const [selectedCat, setSelectedCat] = useState(null);

    const getStoreData = async (_id) => {
        console.log(_id)
        setIsLoading(true);
        try {
            let res = await GET(`/web/store/${_id}`);
            console.log(res)
            if (res.status === 200) {
                setStoreItems(res?.data.products);
                setStoreData(res.data);
            } else {
                toast({
                    description: res?.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 3000
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (params) { getStoreData(params?.id) }

        return () => { }
    }, [params]);

    useEffect(() => {
        if (selectedCat) {
            storeData?.product?.length > 0 &&
                storeData?.product?.map(val => {
                    if (val?.name === selectedCat) setStoreItems(val?.products)
                })
        }

        return () => { }
    }, [selectedCat]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, []); // Empty dependency array ensures that this effect runs only once after initial mount

    return (
        <>

            <Header />

            <Box bgImage={BlackOver}>
                {
                    isLoading ?
                        <Container maxW="8xl" display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100vh'}>
                            <Spinner />
                        </Container>
                        :
                        <Container maxW={'8xl'}>
                            <Image borderRadius={"12px"} objectFit={'cover'} width="100%" height={{ base: "150px", xl: "325px" }} src={storeData?.banner ? `${imgUrl}/${storeData?.banner}` : storeBg} />
                            <Stack
                                margin={'0 !important'}
                                gap={8}
                                pb={10}
                                px={6}
                                mr={{ base: "0px !important", xl: "60px !important" }}
                            >
                                <Stack
                                    direction={{ base: "column", xl: "row" }}
                                    justifyContent="space-between"
                                    mt="20px"
                                    alignItems="flex-start"
                                >
                                    <Box flex={2} display={{ base: "block", xl: "flex" }} gap="30px">
                                        <Image border={'4px solid #fff'} borderRadius={'100%'} src={storeData?.logo ? `${imgUrl}/${storeData?.logo}` : storePic} w={{ base: "120px", xl: "180px" }} h={{ base: "120px", xl: "180px" }} objectFit="cover" mt="-70px" />
                                        <Box>
                                            <Heading color="#171725" fontSize={{ base: "20px", xl: "32px" }} mb="10px" fontWeight="600">{storeData?.store_name ?? 'Loading'}</Heading>
                                            <Text fontSize={{ base: "14px", xl: "16px" }} fontWeight="500" mb="10px">{storeData?.area ?? 'Loading'}</Text>
                                            <Text fontSize={{ base: "14px", xl: "16px" }} fontWeight="500" mb="10px">{storeData?.aboutus ?? 'Loading'}</Text>
                                            <Text fontSize={{ base: "14px", xl: "16px" }} fontWeight="500" mb="10px">
                                                <Text mr="5px" as="span" color={storeData?.opening_hours[new Date().getDay() - 1]['startTime'] ? 'green.600' : 'red.600'}>
                                                    {storeData?.opening_hours[new Date().getDay() - 1]['startTime'] ? 'Open Today' : 'Close Today'}:
                                                </Text>
                                                {storeData?.opening_hours && storeData?.opening_hours[new Date().getDay() - 1]['startTime']} - {storeData?.opening_hours && storeData?.opening_hours[new Date().getDay() - 1]['endTime']}
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box display="flex" gap="10px" alignItems="center">
                                        <Text h="22px" borderBottom="2px solid #171725" color="#171725" fontSize="14px">Reviews</Text>
                                        <Icon as={FaStar} color="primaryYellow.100" />
                                        <Text display={'flex'}>4.8 <Text color="#66707A" as="span" fontWeight="500">({storeData?.reviews?.length})</Text></Text>
                                        <Icon as={HiOutlineShoppingBag} />
                                        <Text display={'flex'} color="#66707A" fontWeight="500">{storeData?.totalOrders} orders</Text>
                                        {/* <Button h="0px" minW="0px"><Icon fontSize='22px' as={HiOutlineChevronDown} /></Button> */}
                                    </Box>
                                </Stack>
                                <Stack direction="row" flexWrap="wrap" gap={{ base: "0%", md: "2.5%" }} spacing="0">
                                    {
                                        storeItems?.length > 0 &&
                                        storeItems?.map(val =>
                                            <Box key={val?._id} boxShadow="0px 0px 30px 0px #adadad" w={{ base: '100%', md: '330px' }} pb="20px" mb="35px !important" position="relative" borderRadius="12px" overflow="hidden">
                                                <Image src={val?.pictures[0] ? `${imgUrl}/${val?.pictures[0]}` : foodPic} w="330px" h="174px" objectFit="cover" mb={"20px"} />
                                                <Box px="20px">
                                                    <Heading fontSize="18px" fontWeight="600" mb="10px">{val?.item ?? 'Loading'}</Heading>
                                                    <Text color="#78828A"><Icon color="primaryYellow.100" as={FaStar} /> {val?.review?.length > 0 ? `4.8(${val?.review?.length})` : `0`} | {val?.tags} </Text>
                                                </Box>
                                            </Box>)
                                    }

                                </Stack>
                                {
                                    storeData?.store?.menu2?.length > 0 &&
                                    <Heading fontSize="24px" fontWeight="600" my="20px">Digital Menus</Heading>
                                }

                            </Stack>
                        </Container>
                }
            </Box>

            <Footer />
        </>
    )
}

export default WebStore;