import { Stack, Heading, Grid, GridItem, Text, Button, Select, Box, Input, Checkbox, Icon, useToast, Image } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { useEffect, useRef, useState } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { useSelector } from 'react-redux';
import { DELETE, GET, POST } from '../../../utilities/ApiProvider.js';
import { useNavigate } from 'react-router-dom';
import { imgUrl } from '../../../utilities/config.js';

export default function SuperProList() {

    const toast = useToast()
    const imgRef = useRef(null);
    const navigate = useNavigate();
    const user = useSelector(state => state.value);
    const [isLoading, setIsLoading] = useState(false);
    const [isPromotingStore, setIsPromotingStore] = useState(false);
    const [isPickUp, setIsPickUp] = useState(false);
    const [isDelivery, setIsDelivery] = useState(false);
    const [bannerImg, setBannerImg] = useState(null);
    const [itemList, setItemList] = useState([]);

    const getItemsList = async () => {
        try {
            let res = await GET('/admin/promotions', { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                setItemList(res?.data);
            } else {
                toast({
                    description: res?.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 3000
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
    }

    const removePromotion = async (id) => {
        setIsLoading(true);
        try {
            let res = await DELETE(`/admin/promotions/${id}`, { Authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                getItemsList()
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (user) getItemsList()
    }, [user])

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Stack mr="60px !important">
                    <Stack direction={'row'} justifyContent={'space-between'} mb="40px" mr="60px !important">
                        <Box>
                            <Heading fontSize="24px" fontWeight="600">Promotion</Heading>
                            <Text>{itemList?.length} item in promotion</Text>
                        </Box>
                        <Stack className='btn'>
                            <Button
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={"primaryGreen.100"}
                                onClick={() => navigate(`/dashboard/promotion`)}
                                bgImage={greenBtnTexture}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                color={'primaryYellow.100'}
                                fontWeight={'400'}
                                border={'2px solid'}
                                borderColor={"primaryGreen.100"}
                                textAlign={'left'}
                                _hover={{
                                    bg: "primaryGreen.100",
                                    borderColor: "primaryGreen.100",
                                    color: '#fff'
                                }}
                            >
                                <Text as={'span'}>Create Promotions</Text>
                            </Button>
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} w="100%" mr="60px !important" justifyContent={'space-between'} gap="20px" flexWrap={'wrap'} spacing={0}>
                        {
                            itemList?.length > 0 ?
                                itemList?.map(val =>
                                    <Box p="10px" borderBottom={'1px solid #adadad'} w="48%" justifyContent={'space-between'} key={val?._id} display={'flex'} gap="20px" alignItems={'center'}>
                                        <Box display={'flex'} alignItems={'center'} gap="20px">
                                            <Image objectFit={'cover'} src={`${imgUrl}/${val?.banner}`} w="92px" h="92px" borderRadius={'12px'} overflow={'hidden'} />
                                            <Box>
                                                <Heading fontWeight={'500'} fontSize={'18px'} mb="10px">{val?.itemData?.store_name}</Heading>
                                            </Box>
                                        </Box>
                                        <Box display={'flex'} flexDir={'column'} alignItems={'flex-end'} gap={'20px'}>
                                            <Box display={'flex'} gap="20px">
                                                {/* <Stack className='btn'>
                                                    <Button
                                                        justifyContent={'center'}
                                                        padding={'10px 20px'}
                                                        bg={"primaryGreen.100"}
                                                        onClick={() => navigate(`/dashboard/edit-promotion/${val?._id}`, { state: val })}
                                                        bgImage={greenBtnTexture}
                                                        bgPos={'center'}
                                                        bgSize={'cover'}
                                                        bgRepeat={'no-repeat'}
                                                        color={'primaryYellow.100'}
                                                        fontWeight={'400'}
                                                        border={'2px solid'}
                                                        borderColor={"primaryGreen.100"}
                                                        textAlign={'left'}
                                                        _hover={{
                                                            bg: "primaryGreen.100",
                                                            borderColor: "primaryGreen.100",
                                                            color: '#fff'
                                                        }}
                                                    >
                                                        <Text as={'span'}>Edit</Text>
                                                    </Button>
                                                </Stack> */}
                                                <Stack className='btn'>
                                                    <Button
                                                        isLoading={isLoading}
                                                        justifyContent={'center'}
                                                        padding={'10px 20px'}
                                                        bg={"primaryGreen.100"}
                                                        onClick={() => removePromotion(val?._id)}
                                                        bgImage={greenBtnTexture}
                                                        bgPos={'center'}
                                                        bgSize={'cover'}
                                                        bgRepeat={'no-repeat'}
                                                        color={'primaryYellow.100'}
                                                        fontWeight={'400'}
                                                        border={'2px solid'}
                                                        borderColor={"primaryGreen.100"}
                                                        textAlign={'left'}
                                                        _hover={{
                                                            bg: "primaryGreen.100",
                                                            borderColor: "primaryGreen.100",
                                                            color: '#fff'
                                                        }}
                                                    >
                                                        <Text as={'span'}>Remove</Text>
                                                    </Button>
                                                </Stack>
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                                :
                                null
                        }
                    </Stack>
                </Stack>
            </Stack >
        </>
    )
}