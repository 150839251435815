import { Stack, Heading, Icon, Text, Box, Button, Image, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, useToast, Spinner, Input, } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiMenuAlt1, HiOutlineClock, HiOutlineSearch } from 'react-icons/hi'
import { useEffect, useState } from 'react'
import foodImg from '../../../assets/images/auth/r6.jpg';
import StoreReqRow from './StoreReqRow.js';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { GET, POST } from '../../../utilities/ApiProvider.js';
import { useSelector } from 'react-redux';

export default function RiderVehicleReqWrap() {

    const toast = useToast();
    const user = useSelector(state => state?.value);
    const [isLoadingStoreInfo, setIsLoadingStoreInfo] = useState(false);
    const [selectedRequests, setSelectedRequests] = useState(true);
    const [requestsList, setRequestsList] = useState([]);
    const [acceptedReqList, setAcceptedReqList] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const getRequests = async (searchVal) => {
        setIsLoadingStoreInfo(true);
        try {
            let res = await GET(searchVal !== '' ? `/admin/stores/viewVehicleRequest/?search=${searchVal}` : `/admin/stores/viewVehicleRequest`, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                let pendingReqs = res?.data?.result?.filter(val => val.isActive === false);
                setRequestsList(pendingReqs);
                // let acceptedReqList = res?.data?.filter(val => val.status === 'accepted');
                // setAcceptedReqList(acceptedReqList);
            } else {
                toast({
                    description: res?.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 3000
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoadingStoreInfo(false);
    }

    useEffect(() => {
        getRequests(searchValue);
    }, [searchValue])


    useEffect(() => {
        if (user) getRequests();
    }, [user])

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Stack>
                    <Stack direction={'row'} alignItems={'flex-end'} justifyContent={'space-between'} mr="60px !important">
                        <Box>
                            <Box>
                                <Heading fontSize="24px" fontWeight="600">Vehicle Requests {isLoadingStoreInfo && <Spinner color='primaryGreen.100' size={'sm'} />}</Heading>
                                <Text fontSize="16px" mb="20px !important">You've {requestsList?.length} requests</Text>
                            </Box>
                            {/* <Stack direction={'row'} alignItems={'center'} gap="20px">
                                <Stack className='btn'>
                                    <Button
                                        onClick={() => setSelectedRequests(true)}
                                        justifyContent={'flex-start'}
                                        padding={'10px 20px'}
                                        bg={selectedRequests ? "primaryGreen.100" : '#D5DADE'}
                                        color={selectedRequests ? 'primaryYellow.100' : '#000'}
                                        fontWeight={'400'}
                                        border={'2px solid'}
                                        bgImage={selectedRequests ? greenBtnTexture : 'none'}
                                        bgPos={'center'}
                                        bgSize={'cover'}
                                        bgRepeat={'no-repeat'}
                                        borderColor={selectedRequests ? "primaryGreen.100" : '#D5DADE'}
                                        textAlign={'left'}
                                        _hover={{
                                            bg: selectedRequests ? "primaryGreen.100" : '#D5DADE',
                                            borderColor: selectedRequests ? "primaryGreen.100" : '#D5DADE',
                                            color: selectedRequests ? '#fff' : '#000'
                                        }}
                                    >
                                        <Text as={'span'}>Pending Requests ({requestsList?.length})</Text>
                                    </Button>
                                </Stack>
                                <Stack className='btn'>
                                    <Button
                                        onClick={() => setSelectedRequests(false)}
                                        justifyContent={'flex-start'}
                                        padding={'10px 20px'}
                                        bg={!selectedRequests ? "primaryGreen.100" : '#D5DADE'}
                                        color={!selectedRequests ? 'primaryYellow.100' : '#000'}
                                        fontWeight={'400'}
                                        bgImage={!selectedRequests ? greenBtnTexture : 'none'}
                                        bgPos={'center'}
                                        bgSize={'cover'}
                                        bgRepeat={'no-repeat'}
                                        border={'2px solid'}
                                        borderColor={!selectedRequests ? "primaryGreen.100" : '#D5DADE'}
                                        textAlign={'left'}
                                        _hover={{
                                            bg: !selectedRequests ? "primaryGreen.100" : '#D5DADE',
                                            borderColor: !selectedRequests ? "primaryGreen.100" : '#D5DADE',
                                            color: !selectedRequests ? '#fff' : '#000'
                                        }}
                                    >
                                        <Text as={'span'}>Accepted Requests ({acceptedReqList?.length})</Text>
                                    </Button>
                                </Stack>
                            </Stack> */}
                        </Box>
                        <Box w="367px" bgColor="#ECF1F6" borderRadius="50px" display="flex" alignItems="center" px="10px" gap="5px">
                            <Icon as={HiOutlineSearch} fontSize="24px" color="#9CA4AB" />
                            <Input onChange={e => setSearchValue(e.target.value)} placeholder="Search by email..." border="0" fontSize="14px" />
                        </Box>
                    </Stack>
                </Stack>
                <Stack mr="60px !important" borderRadius={'5px'} padding="15px 35px" boxShadow={'0px 0px 35px -15px #000'} bgColor="#fff">
                    {
                        selectedRequests ?
                            requestsList.map((val, ind) =>
                                <Stack borderBottom={'1px solid #adadad'} key={ind} my="5px" py="5px" direction="row" justifyContent="space-between">
                                    <StoreReqRow {...val} getRequests={getRequests} />
                                </Stack>
                            )
                            :
                            acceptedReqList.map((val, ind) =>
                                <Stack borderBottom={'1px solid #adadad'} key={ind} my="5px" py="5px" direction="row" justifyContent="space-between">
                                    <StoreReqRow {...val} hideActionBtn={true} getRequests={getRequests} />
                                </Stack>
                            )

                    }
                </Stack>
            </Stack>
        </>
    )
}