import { Stack, Box, useToast, Text } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
// Components
import EventCard from "../../components/Website/Event/EventCard";
import SignCard from "../../components/Website/Home/signCard";
import CounterBox from "../../components/Website/Home/counterBox";
import Header from "../../components/Website/Header/header";
import HeroBanner from '../../components/Website/Home/heroBanner';
import Footer from "../../components/Website/Footer/Footer";
// Images
import { eventCard, signData } from "../../assets/data/data";
import BlackOver from '../../assets/images/icon/black.png';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET } from "../../utilities/ApiProvider";

const Event = () => {

    const toast = useToast();
    const navigate = useNavigate();
    const [past, setPast] = useState([]);
    const [blogList, setBlogList] = useState({
        curEvents: [],
    });
    const options = { day: "numeric", month: "short", year: "numeric" };

    const getBlogs = async () => {
        console.log("Api HIT")
        try {
            let res = await GET(`/admin/adminPosts?type=event`);
            if (res.status === 200) {
                setPast(res?.data?.past);
                let upComingEves = res?.data?.upcoming;
                let curEvents = [];
                let arcEvents = [];
                upComingEves?.filter(val => {
                    if (val?.isArchive === true) {
                        arcEvents.push(val);
                    } else {
                        curEvents.push(val);
                    }
                });
                setBlogList({
                    curEvents,
                    arcEvents
                })
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
    }

    useEffect(() => {

        getBlogs();

        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, []); // Empty dependency array ensures that this effect runs only once after initial mount

    return (
        <>
            <Header />

            <Box>
                <HeroBanner text={"Events"} />
            </Box>

            <Box p={"50px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <Stack spacing={0} direction={"row"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"} gap={"20px"}>
                            {
                                blogList?.curEvents?.length > 0 &&
                                blogList?.curEvents?.map((val, key) => {
                                    return (
                                        <>
                                            <Box key={key} w={{ base: '100%', md: '48%', lg: 'initial' }}>
                                                <EventCard picture={val?.picture} img={eventCard[0]['img']} date={val?.date} endDate={val?.endDate} time={val?.time} endtime={val?.endtime} text={val?.name} description={val?.description} href={val?._id} />
                                            </Box>
                                        </>
                                    )
                                })
                            }
                        </Stack>
                    </Box>
                </Container>
            </Box>

            <Box p={"30px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box mb={"30px"}>
                        <Text fontSize={"28px"} lineHeight={"22px"} fontWeight={"600"}>Past Events</Text>
                    </Box>
                    <Stack direction={"row"} flexWrap={'wrap'} alignItems={"center"} mb={"30px"}>
                        {past?.length > 0 && past?.map((val, key) => {
                            return (
                                <Box key={key}>
                                    <EventCard picture={val?.picture} date={val?.createdAt} endDate={val?.endDate} text={val?.name} time={val?.time} endtime={val?.endtime} href={val?._id} />
                                </Box>
                            )
                        })}
                    </Stack>
                    {/* <Box p={"40px 0px"}>
                        <CounterBox />
                    </Box> */}
                </Container>
            </Box>

            <Box p={"50px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <Stack
                            direction={{ base: "column", xl: "row" }}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            {signData.map((val, key) => {
                                return (
                                    <SignCard img={val.img} text1={val.text1} text2={val.text2} main={val.main} btn={val.btn} href={val.href} key={key} />
                                )
                            })
                            }
                        </Stack>
                    </Box>
                    <Box pt={"40px"}>
                        {/* <CounterBox /> */}
                    </Box>
                </Container>
            </Box>

            <Footer />
        </>
    )
}

export default Event;