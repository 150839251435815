import { Text, Box, Image, Heading } from "@chakra-ui/react";
import BlackOver from '../../../assets/images/icon/black.png';

const AppFeatureCard = ({ img, heading, text, index }) => {
    return (
        <Box
            bgImage={BlackOver}
            overflow={'hidden'}
            border="4px solid"
            borderColor={'primaryGreen.100'}
            borderRadius={{base: "10px", xl: "25px"}}
            display="flex"
            justifyContent={'center'}
            alignItems={'center'}
            flexDir={index % 2 === 0 ? 'column' : 'column-reverse'}
            bgColor="primaryYellow.100"
            padding={{base: '0 0px', xl: '0 15px'}}
            marginX={{base: '3px', xl: '15px'}}
        >
            <Box textAlign={'center'}>
                <Heading fontSize={{base: '12px', xl: '22px'}} color={'#000'} m="25px 0 15px 0">{heading}</Heading>
                <Text display={{base: 'none', xl: 'initial'}} h="84px" fontSize={'14px'} mb="20px">{text}</Text>
            </Box>
            <Box>
                <Image src={img} w={{ base: "100px", '2xl': '220px' }} h={{ base: '150px', '2xl': "300px" }} objectFit={'contain'} />
            </Box>
        </Box>
    )
}

export default AppFeatureCard;