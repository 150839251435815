'use client'

import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    Image,
    Container,
} from '@chakra-ui/react'
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
} from '@chakra-ui/icons'
import { Link, useLocation } from 'react-router-dom'
import { headItem } from '../../../assets/data/data'
import yellowBtnTexture from '../../../assets/images/yellowBtnTexture.svg'
import headLogo from '../../../assets/images/logo/logo.svg'
import BlackOver from '../../../assets/images/icon/black.png';

export default function WithSubnavigation() {
    const { isOpen, onToggle } = useDisclosure()

    return (
        <Box
            bgImage={BlackOver}
            bgPosition={'26px 6px'}
            bgSize={'612px'}
            bgRepeat={'repeat'}
        >
            <Container maxW={{ base: "1366px", '2xl': "1440px" }}>

                <Flex
                    color={useColorModeValue('gray.600', 'white')}
                    minH={'60px'}
                    py={{ base: 2 }}
                    px={{ base: 4 }}
                    borderBottom={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.900')}
                    align={'center'}>
                    <Flex
                        flex={{ base: 1, md: 'auto' }}
                        ml={{ base: -2 }}
                        display={{ base: 'flex', lg: 'none' }}>
                        <IconButton
                            onClick={onToggle}
                            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
                            variant={'ghost'}
                            aria-label={'Toggle Navigation'}
                        />
                    </Flex>
                    <Flex flex={{ base: 1 }} justifyContent={{ base: 'flex-end', lg: 'space-between' }} alignItems={'center'}>
                        <Link to={"/"} >
                            <Image w={{ base: '80px !important', md: 'auto' }} src={headLogo} />
                        </Link>

                        <Flex display={{ base: 'none', lg: 'flex' }} ml={10}>
                            <DesktopNav />
                            <Button
                                as={Link}
                                to={"/login"}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                bgImage={yellowBtnTexture}
                                transform={"skew(-6deg)"}
                                p={"8px 0px"}
                                w={{ base: "unset", sm: "unset", md: "unset", lg: "85px", xl: "110px" }}
                                textAlign={"center"}
                                color={"#28551A"}
                                border={"1.5px solid #28551A"}
                                ml={{ base: "20px", '2xl': "40px" }}
                                _hover={{ bg: "none", border: "1.5px solid #28551A", color: "#28551A" }}
                            >
                                <Text as={"span"} transform={"skew(8deg)"} fontSize={{ base: "13px", sm: "unset", md: "unset", lg: "13px", xl: "13px", '2xl': "15px" }} fontWeight={"500"}>Login</Text>
                            </Button>

                            <Button
                                as={Link}
                                to={"/register"}
                                bg={"#28551A"}
                                transform={"skew(-8deg)"}
                                p={"8px 0px"}
                                w={{ base: "unset", sm: "unset", md: "unset", lg: "85px", xl: "110px" }}
                                textAlign={"center"}
                                color={"primaryYellow.100"}
                                ml={{ base: "20px", '2xl': "40px" }}
                                border={"1.5px solid #28551A"}
                                _hover={{ bg: "none", border: "1.5px solid #28551A", color: "#28551A" }}
                            >
                                <Text as={"span"} transform={"skew(8deg)"} fontSize={{ base: "13px", sm: "unset", md: "unset", lg: "13px", xl: "13px", '2xl': "15px" }} fontWeight={"500"}>Signup</Text>
                            </Button>
                        </Flex>
                    </Flex>

                    {/* <Stack
                    flex={{ base: 1, md: 0 }}
                    justify={'flex-end'}
                    direction={'row'}
                    spacing={6}>
                    <Button as={'a'} fontSize={'sm'} fontWeight={400} variant={'link'} href={'#'}>
                        Sign In
                    </Button>
                    <Button
                        as={'a'}
                        display={{ base: 'none', md: 'inline-flex' }}
                        fontSize={'sm'}
                        fontWeight={600}
                        color={'white'}
                        bg={'pink.400'}
                        href={'#'}
                        _hover={{
                            bg: 'pink.300',
                        }}>
                        Sign Up
                    </Button>
                </Stack> */}
                </Flex>

                <Collapse in={isOpen} animateOpacity>
                    <MobileNav />
                </Collapse>
            </Container>
        </Box>
    )
}

const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200')
    const linkHoverColor = useColorModeValue('gray.800', 'white')
    const popoverContentBgColor = useColorModeValue('white', 'gray.800')
    const location = useLocation();
    return (
        <Stack direction={'row'} spacing={4}>
            {headItem.map((navItem) => (
                <Box key={navItem.name}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <Button
                                as={Link}
                                textTransform={'uppercase'}
                                bgImage={location.pathname === navItem.href ? yellowBtnTexture : 'none'}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                to={navItem.href}
                                bg={location.pathname === navItem.href ? "primaryYellow.100" : "none"}
                                color={location.pathname === navItem.href ? "primaryGreen.100" : "primaryGreen.100"}
                                transform={"skew(-8deg)"}
                                p={"8px 10px"}
                                textAlign={"center"}
                                border={location.pathname === navItem.href ? "1.5px solid #28551A" : "1px solid transparent"}
                                _hover={{
                                    bgImage: yellowBtnTexture,
                                    border: "1.5px solid #28551A",
                                    color: "primaryGreen.100"
                                }}
                            >
                                <Text as={"span"} transform={"skew(8deg)"} fontSize={{ base: "13px", sm: "unset", md: "unset", lg: "13px", xl: "13px", '2xl': "15px" }} fontWeight={"500"}>{navItem.name}</Text>
                            </Button>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}>
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav key={child.label} {...child} />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    )
}

const DesktopSubNav = ({ label, href, subLabel }) => {
    const location = useLocation();
    return (
        <Button
            as={Link}
            textTransform={'uppercase'}
            bgImage={location.pathname === href ? yellowBtnTexture : 'none'}
            bgPos={'center'}
            bgSize={'cover'}
            bgRepeat={'no-repeat'}
            to={href}
            bg={location.pathname === href ? "primaryYellow.100" : "none"}
            color={location.pathname === href ? "primaryGreen.100" : "primaryGreen.100"}
            transform={"skew(-8deg)"}
            p={"8px 10px"}
            textAlign={"center"}
            border={location.pathname === href ? "1.5px solid #28551A" : "1px solid transparent"}
            _hover={{
                bgImage: yellowBtnTexture,
                color: "#28551A"
            }}
        >
            <Text as={"span"} transform={"skew(8deg)"} fontSize={{ base: "unset", sm: "unset", md: "unset", lg: "13px", xl: "15px" }} fontWeight={"500"}>{label}</Text>
        </Button>
    )
}

const MobileNav = () => {
    return (
        <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ lg: 'none' }}>
            {headItem.map((navItem) => (
                <MobileNavItem key={navItem} {...navItem} />
            ))}
        </Stack>
    )
}

const MobileNavItem = ({ name, children, href }) => {
    const { isOpen, onToggle } = useDisclosure()

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Box
                py={2}
                as={Link}
                to={href}
                justifyContent="space-between"
                alignItems="center"
                _hover={{
                    textDecoration: 'none',
                }}
                display={'flex'}
            >
                <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
                    {name}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Box>
            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Box as={Link} key={child.label} py={2} to={child.href}>
                                {child.label}
                            </Box>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    )
}

// import { Stack, Text, Box, Image, Button } from "@chakra-ui/react";
// import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
// import { Link, useLocation } from "react-router-dom";
// // Images
// import headLogo from '../../../assets/images/website/home/headLogo.png';
// import BlackOver from '../../../assets/images/icon/black.png';
// import { headItem } from "../../../assets/data/data";
// import yellowBtnTexture from '../../../assets/images/yellowBtnTexture.svg'

// const Header=()=>{
//     const location = useLocation();
//     return(
//         <>
//         <Box bgImage={BlackOver}>
//             <Container maxW={"1440px"}>
//                 <Box p={"10px 0px"}>
//                     <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
//                         <Box>
//
//                         </Box>

//                         <Stack direction={"row"} alignItems={"center"} gap={{base:"unset",sm:"unset",md:"unset",lg:"5px",xl:"10px"}}>
//                             {headItem.map((val,key)=>{
//                                 return(
//                                     <Button
//                                     key={key}
//                                     as={Link}
//                                     bgImage={location.pathname === val.href ? yellowBtnTexture : 'none'}
//                                     bgPos={'center'}
//                                     bgSize={'cover'}
//                                     bgRepeat={'no-repeat'}
//                                     to={val.href}
//                                     bg={location.pathname === val.href ? "primaryYellow.100" : "none"}
//                                     color={location.pathname === val.href ? "primaryGreen.100" : "#000"}
//                                     transform={"skew(-8deg)"}
//                                     p={"8px 10px"}
//                                     textAlign={"center"}
//                                     border={location.pathname === val.href ? "1.5px solid #28551A" : "1px solid transparent"}
//                                     _hover={{
//                                         bgImage:yellowBtnTexture,
//                                         border:"1.5px solid #28551A",
//                                         color:"#28551A"
//                                         }}
//                                         >
//                                         <Text as={"span"} transform={"skew(8deg)"} fontSize={{base:"unset",sm:"unset",md:"unset",lg:"13px",xl:"15px"}} fontWeight={"500"}>{val.name}</Text>
//                                     </Button>
//                                 )
//                             })}

//                             <Button
//                                 as={Link}
//                                 to={"/login"}
//                                 bgPos={'center'}
//                                 bgSize={'cover'}
//                                 bgRepeat={'no-repeat'}
//                                 bgImage={yellowBtnTexture}
//                                 transform={"skew(-6deg)"}
//                                 p={"8px 0px"}
//                                 w={{base:"unset",sm:"unset",md:"unset",lg:"85px",xl:"110px"}}
//                                 textAlign={"center"}
//                                 color={"#28551A"}
//                                 border={"1.5px solid #28551A"}
//                                 _hover={{bg:"none",border:"1.5px solid #28551A",color:"#28551A"}}
//                                 >
//                                 <Text as={"span"} transform={"skew(8deg)"} fontSize={{base:"unset",sm:"unset",md:"unset",lg:"13px",xl:"15px"}} fontWeight={"500"}>Login</Text>
//                             </Button>

//                             <Button
//                                 as={Link}
//                                 to={"/register"}
//                                 bg={"#28551A"}
//                                 transform={"skew(-8deg)"}
//                                 p={"8px 0px"}
//                                 w={{base:"unset",sm:"unset",md:"unset",lg:"85px",xl:"110px"}}
//                                 textAlign={"center"}
//                                 color={"primaryYellow.100"}
//                                 border={"1.5px solid #28551A"}
//                                 _hover={{bg:"none",border:"1.5px solid #28551A",color:"#28551A"}}
//                                 >
//                                 <Text as={"span"} transform={"skew(8deg)"} fontSize={{base:"unset",sm:"unset",md:"unset",lg:"13px",xl:"15px"}} fontWeight={"500"}>Signup</Text>
//                             </Button>
//                         </Stack>
//                     </Stack>
//                 </Box>
//             </Container>
//         </Box>
//         </>
//     )
// }

// export default Header;