import { Stack, Text, Box, Image, Heading } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
// Components
import Header from "../../components/Website/Header/header";
import HalalAdd from "../../components/Website/Home/halalAdd";
import SignCard from "../../components/Website/Home/signCard";
import CounterBox from "../../components/Website/Home/counterBox";
import HeroBanner from "../../components/Website/Home/heroBanner";
import Footer from "../../components/Website/Footer/Footer";
// Images
import about1 from '../../assets/images/website/aboutUs/about1.png';
import about2 from '../../assets/images/website/aboutUs/about2.png';
import about3 from '../../assets/images/website/aboutUs/rocket.png';
import target from '../../assets/images/website/aboutUs/target.svg';
import miniLogo from '../../assets/images/website/aboutUs/miniLogo.svg';

import BlackOver from '../../assets/images/icon/black.png';
import { teamMember, signData } from "../../assets/data/data";
import { useEffect } from "react";

const AboutUs = () => {

    const aboutInfo = [
        {
            id: 1,
            heading: 'What We Do?',
            content: "AimHalal is dedicated to revolutionizing your Halal lifestyle through a powerful and user-friendly app. We provide a one-stop solution for Halal products and services, covering areas such as food, cosmetics, healthcare, tourism, and more. We offer a comprehensive and innovative digital platform that caters to the diverse needs of the Muslim community in Canada."
        },
        {
            id: 2,
            heading: 'Who We Are?',
            content: "We are a team of passionate individuals, driven by a shared vision to redefine the Halal industry. Our global community includes Muslim researchers and the Muslim community in Canada, working together to inspire positive change and create a world where Muslims can confidently embrace their cultural identity and religious beliefs."
        },
        {
            id: 3,
            heading: 'What We Offer?',
            content: "Through strategic partnerships and cutting-edge technology, AimHalal ensures accessibility, authenticity, and convenience for our users. From Halal product insights to fostering connections among individuals, businesses, and organizations, we are dedicated to enhancing the Halal experience."
        }
    ];

    const coreInfo = [
        {
            id: 1,
            heading: 'Authenticity',
            content: 'We prioritize delivering genuine and reliable Halal information, fostering trust through transparency.',
        },
        {
            id: 2,
            heading: 'Integrity',
            content: 'Tailoring our services to the unique needs and values of the Muslim community.'
        },
        {
            id: 3,
            heading: 'Muslim-Centric Approach:',
            content: 'Tailoring our services to the unique needs and values of the Muslim community.'
        },
        {
            id: 4,
            heading: 'Innovation',
            content: 'Embracing technological advancements to provide a seamless and enriching user experience.'
        },
        {
            id: 5,
            heading: 'Collaboration',
            content: 'Partnering with certifiers, experts, and the community to strengthen the accessibility and accuracy of Halal information.'
        },
        {
            id: 6,
            heading: 'Empowerment',
            content: 'Providing a platform that educates and empowers individuals to make informed Halal choices.'
        },
        {
            id: 7,
            heading: 'Respect for Diversity',
            content: 'Celebrating the diversity within the global Muslim community and respecting various cultural practices.'
        },
        {
            id: 8,
            heading: 'Continuous Improvement',
            content: 'Committed to ongoing learning and evolution to stay current with dynamic Halal requirements.'
        },
    ]

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, []); // Empty dependency array ensures that this effect runs only once after initial mount

    return (
        <>

            <Header />

            <Box>
                <HeroBanner text={"About Us"} />
            </Box>

            <Box p={"80px 0 50px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Stack direction={{ base: "column", xl: "row" }} alignItems={"center"} justifyContent={"space-between"}>
                        <Box display={{ base: 'none', xl: 'initial' }} position={"relative"} transform={{ base: 'translate(-20%, -40px)', xl: 'translateX(0, 0)' }}>
                            <Image w={"472px"} h={"387px"} src={about1} />
                            <Box position={"absolute"} bottom={"-35px"} right={"-180px"}>
                                <Image w={"303px"} h={"274px"} src={about2} />
                            </Box>
                        </Box>
                        <Box maxW={"674px"} mt={{ base: '30px !important', xl: '0 !important' }}>
                            <Text color={'primaryGreen.100'} fontSize={"35px"} fontWeight={"600"} lineHeight={"23px"} mb={"30px"}>About AimHalal</Text>
                            <Text color={'primaryGreen.100'} fontSize={"16px"} lineHeight={"23px"} mb={"15px"}>With AimHalal, accessing accurate data on all Halal Products is easier than ever before. Through our cutting-edge technology and direct verification approach - backed by an expert team of Muslim Researchers, and Muslim Community in Canada– you can now explore insights about Halal Aspects of all kinds of products while always adhering to Islamic values. Experience a powerful yet user-friendly tool with this one of its kind app, which will revolutionise your Halal lifestyle.</Text>
                            {/* <Text color={'primaryGreen.100'} fontSize={"16px"} lineHeight={"23px"}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ndesktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</Text> */}
                        </Box>
                    </Stack>
                </Container>
            </Box>

            <Box bg={"primaryYellow.100"} bgImage={BlackOver} mt={"50px"}>
                <Container maxW={"1440px"}>
                    <Stack direction={{ base: "column", xl: "row" }} justifyContent={"center"} alignItems={"center"} gap={"20px"}>
                        <Box
                            m={{ base: "40px auto !important", xl: "auto" }}
                            bgImage={BlackOver} bgColor={'#fff'} bgPos={'3px 14px'} borderRadius={"10px"} w={{ base: "350px", xl: "674px" }}>
                            <Box
                                maxW={{ base: "350px", xl: "520px" }}
                                p={"30px 20px"}
                            >
                                <Text fontSize={"35px"} fontWeight={"700"} color={"primaryGreen.100"} mb={"20px"}>Our Mission</Text>
                                <Text color={'primaryGreen.100'} fontSize={"16px"} fontWeight={'500'} mb={"15px"}>To provide a comprehensive digital platform for diverse Halal needs, ensuring accessibility, authenticity, and convenience while promoting ethical practices.</Text>
                                <Text fontSize={"35px"} fontWeight={"700"} color={"primaryGreen.100"} mb={"20px"}>Our Vision</Text>
                                <Text color={'primaryGreen.100'} fontSize={"16px"} fontWeight={"500"} mb={"20px"}>AimHalal envisions a global community embracing a Halal lifestyle, connecting Muslims worldwide and fostering unity, empowerment, and positive impact.</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Image w="60%" marginX={'auto'} src={about3} />
                        </Box>
                    </Stack>
                </Container>
            </Box >

            <Box p={"80px 0 50px 0px"} bgImage={BlackOver} position={'relative'}>
                <Container maxW={"1440px"}>
                    <Stack direction={{ base: "column", xl: "row" }} flexWrap={'wrap'} justifyContent={"space-between"} position={'relative'}>
                        {
                            aboutInfo?.map(val => {
                                return (
                                    <Box key={val.id} w={{ base: "100%", xl: "48%" }} mt="40px !important">
                                        <Image bgColor={'primaryYellow.100'} borderRadius={'12px'} padding={'5px 10px'} src={miniLogo} />
                                        <Heading fontSize={'35px'} fontWeight={'700'} my="25px" color={'primaryGreen.100'}>{val.heading}</Heading>
                                        <Text color={'primaryGreen.100'} mb="25px">{val.content}</Text>
                                    </Box>
                                )
                            })
                        }
                    </Stack>
                </Container>
            </Box>

            <Box bg={"primaryYellow.100"} bgImage={BlackOver} mt={"50px"} py={'40px'}>
                <Container maxW={"1440px"}>
                    <Heading color={'primaryGreen.100'} fontWeight={'700'} fontSize={'35px'} textAlign={'center'} margin={'0 0 30px 0'}>Core Values</Heading>
                    <Stack direction={{ base: "column", xl: "row" }} gap={"2%"} justifyContent={'space-between'} flexWrap={'wrap'} spacing={0}>
                        {
                            coreInfo.map(val => {
                                return (
                                    <Box p="20px" h="" w={{ base: "100%", xl: "23%" }} key={val.id} bgColor={'#fff'} bgPos={'3px 14px'} borderRadius={"10px"} my="20px !important">
                                        <Heading fontSize={'20px'} color={'primaryGreen.100'} mb="20px">{val.heading}</Heading>
                                        <Text fontSize={'14px'} color={'primaryGreen.100'}>{val.content}</Text>
                                    </Box>
                                )
                            })
                        }
                    </Stack>
                </Container>
            </Box>

            <Box p={"40px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box p={"50px 0px"}>
                        <Text fontSize={"35px"} fontWeight={"600"} textAlign={"center"} color={'primaryGreen.100'}>Meet Our Team</Text>
                    </Box>
                    <Stack direction={"row"} flexWrap={{ base: 'wrap', xl: 'nowrap' }} alignItems={"center"} justifyContent={"space-between"}>
                        {teamMember.map((val, key) => {
                            return (
                                <>
                                    <Box
                                        key={key}
                                        position={"relative"}
                                        width={{ base: "100%", md: "48%", xl: "24%" }}
                                        justifyContent={'space-between'}
                                        marginTop={{ base: '40px !important', xl: '0 !important' }}
                                        className="aboutBox"
                                        _hover={{
                                            transform: "scale(1.1)",
                                            transition: "all 0.4s"
                                        }}
                                    >
                                        <Image src={val.img} width={"320px"} objectFit="contain" marginX={'auto'} height={"445px"} />
                                        <Box
                                            bg={"#28551A"}
                                            position={"absolute"}
                                            w={"100%"}
                                            p={"10px 0px"}
                                            bottom={"-25px"}
                                            borderRadius={"12px"}
                                        >
                                            <Box maxW={'230px'} m={"auto"}>
                                                <Text fontSize={"20px"} fontWeight={"500"} textAlign={"center"} color={"primaryYellow.100"} lineHeight={"34px"}>{val.des}</Text>
                                                <Text fontSize={"14px"} fontWeight={"500"} textAlign={"center"} color={"primaryYellow.100"} lineHeight={"34px"} mb={"5px"}>{val.name}</Text>
                                                <Box className="aboutDes">
                                                    <Text fontSize={"14px"} textAlign={"center"} color={"primaryYellow.100"}>{val.details}</Text>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </>
                            )
                        })}
                    </Stack>
                </Container>
            </Box>

            <Box p={"100px 0px 40px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <HalalAdd />
                    </Box>
                </Container>
            </Box>

            <Box p={"30px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <Stack
                            direction={{ base: "column", xl: "row" }}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            {signData.map((val, key) => {
                                return (
                                    <SignCard img={val.img} text1={val.text1} text2={val.text2} main={val.main} btn={val.btn} href={val.href} key={key} />
                                )
                            })
                            }
                        </Stack>
                    </Box>
                    <Box pt={"40px"}>
                        {/* <CounterBox /> */}
                    </Box>
                </Container>
            </Box>

            <Footer />
        </>
    )
}

export default AboutUs;