import { Stack, Text, Box, Image, Button, OrderedList, ListItem } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import { Link } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
// Components
import HalalAdd from "../../components/Website/Home/halalAdd";
import SignCard from "../../components/Website/Home/signCard";
import CounterBox from "../../components/Website/Home/counterBox";
import HeroBanner from '../../components/Website/Home/heroBanner';
import Header from "../../components/Website/Header/header";
import Footer from "../../components/Website/Footer/Footer";
// Images
import tabmain from '../../assets/images/website/appFeature/tabmain.png'
import funeral from '../../assets/images/website/appFeature/funeral.png'
import culture from '../../assets/images/website/appFeature/culture.png'
import chef from '../../assets/images/website/appFeature/chef.png'
import directories from '../../assets/images/website/appFeature/directories.png'
import butch from '../../assets/images/website/appFeature/butch.png'
import event from '../../assets/images/website/appFeature/event.jpg'
import eat from '../../assets/images/website/appFeature/eat.jpg'
import quran from '../../assets/images/website/appFeature/quran.jpg'
import share from '../../assets/images/website/appFeature/share.png'
import feature1 from '../../assets/images/website/appFeature/feature1.png';
import BlackOver from '../../assets/images/icon/black.png';
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg';
import { signData, tabList } from "../../assets/data/data";
import { useEffect } from "react";

const AppFeature = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, []); // Empty dependency array ensures that this effect runs only once after initial mount

    return (
        <>
            <Header />

            <Box>
                <HeroBanner text={"Butchers"} />
            </Box>

            {/* <Box p={"40px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Stack direction={{ base: "column", lg: "row" }} alignItems={"Center"} justifyContent={"space-between"}>
                        <Box>
                            <Image src={feature1} w={"646px"} />
                        </Box>
                        <Box maxW={"629px"}>
                            <Text fontSize={"26px"} fontWeight={"600"} mb={"20px"}>AIMHalal Ambition</Text>
                            <Text fontSize={"16px"} mb={"30px"}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ndesktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</Text>
                            <Button as={Link} to={"#"} bg={"primaryYellow.100"} bgImage={yellowBtnTexture} bgPos={'center'} bgSize={'cover'} bgRepeat={'no-repeat'} p={'0px 30px'} transform={"skew(-8deg)"} border={"1px solid #28551A"} _hover={{ bg: "none" }}>
                                <Text as={'span'} transform={"skew(8deg)"} color={"#28551A"}>Read More</Text>
                            </Button>
                        </Box>
                    </Stack>
                </Container>
            </Box> */}

            <Box bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box p={"30px 0px"}>
                        <Stack direction={{ base: "column", xl: "row" }} flexDirection={{ base: "column-reverse", xl: 'initial' }} alignItems={"center"} justifyContent={"space-between"}>
                            <Box maxW={"607px"}>
                                <Text color={'primaryGreen.100'} fontSize={"24px"} fontWeight={"700"} letterSpacing={"-0.48px"} mb={"20px"}>Halal Buthchers</Text>
                                <Text color={'primaryGreen.100'} fontSize={"15px"} letterSpacing={"-0.24px"} mb={"20px"}>This feature connects you with trusted Halal meat suppliers in your area. Enjoy the convenience of sourcing high-quality, ethically sourced meats, all certified by recognized Halal authorities. With a commitment to transparency, traceability, and adherence to Halal practices, our platform ensures that every meal you prepare is a testament to your dedication to Halal living.</Text>
                                <Text color={'primaryGreen.100'} fontSize={"15px"} letterSpacing={"-0.24px"} fontWeight={"600"} mb={"20px"}>Here's what you can expect from our Halal Butchers feature:</Text>
                                {/* <OrderedList fontSize={"15px"} letterSpacing={"-0.24px"}>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}>Trusted Halal Meat Suppliers**: AIMHalal's Halal Butchers feature connects you with a network of trusted Halal meat suppliers. We carefully vet each butcher to ensure they meet stringent Halal certification requirements, giving you the peace of mind that the meat you purchase aligns with your dietary and religious needs.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}>Variety of Meat Options**: Our Halal Butchers feature offers a wide variety of meat options to cater to your preferences. Whether you're looking for premium cuts of beef, tender chicken, succulent lamb, or specialty meats, you'll find a diverse selection of Halal options to choose from. Enjoy the convenience of finding all your Halal meat needs in one place.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}>Quality Assurance**: We understand that quality is of utmost importance when it comes to meat. AIMHalal's Halal Butchers feature ensures that the meat providers listed uphold high standards of quality and freshness. You can rely on our platform to connect you with reputable butchers who prioritize the quality and taste of their Halal meat products</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}>User Reviews and Recommendations**: Gain insights from the AIMHalal community through user reviews and recommendations. Our platform allows users to share their experiences, ratings, and feedback on the Halal butchers they have visited. This helps you make informed decisions and choose the best Halal meat provider for your needs.</ListItem>
                                </OrderedList> */}
                                <OrderedList fontSize={"16px"} letterSpacing={"-0.24px"}>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}><b>Wide Range of Categories</b>:<br /> Explore a variety of categories for all your needs.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}><b>Verified Halal Certification</b>:<br /> Trust in our products with verified Halal certification.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}><b>User-Friendly Interface</b>:<br /> Enjoy easy navigation with our user-friendly interface.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}><b>Supporting Halal Businesses</b>:<br /> Support Halal businesses directly through our platform.</ListItem>
                                    {/* <ListItem color={'primaryGreen.100'}>Experience the convenience and peace of mind that AIMHalal's Halal Markets feature offers. Join our growing community and explore a world of Halal products right at your fingertips. Download the AIMHalal app today on Android or iOS and unlock the possibilities of Halal living like never before. Together, let's embrace a Halal lifestyle with AIMHalal's Halal Markets feature.</ListItem> */}
                                </OrderedList>
                                {/* <Text color={'primaryGreen.100'} fontSize={"15px"} letterSpacing={"-0.24px"} fontWeight={"600"} mb={"20px"}>Discover trusted and quality Halal meat providers with AIMHalal's Halal Butchers feature. Experience the convenience of finding reliable sources for Halal meat while upholding your dietary requirements and religious values.
                                    < br />
                                    Download the AIMHalal app today on Android or iOS and unlock the convenience of sourcing Halal meat from reputable providers.
                                    < br />
                                    Together, let's embrace a Halal lifestyle with confidence and savor the finest quality meat from AIMHalal's Halal Butchers.</Text> */}
                            </Box>
                            <Box>
                                <Image src={butch} />
                            </Box>
                        </Stack>
                    </Box>
                </Container>
            </Box >

            <Box p={"100px 0px 40px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <HalalAdd />
                    </Box>
                </Container>
            </Box>

            <Box p={"30px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <Stack
                            direction={{ base: "column", xl: "row" }}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            {signData.map((val, key) => {
                                return (
                                    <SignCard img={val.img} text1={val.text1} text2={val.text2} main={val.main} btn={val.btn} href={val.href} key={key} />
                                )
                            })
                            }
                        </Stack>
                    </Box>
                    <Box pt={"40px"}>
                        {/* <CounterBox /> */}
                    </Box>
                </Container>
            </Box>

            <Footer />
        </>
    )
}

export default AppFeature;