import {
    Stack,
    Heading,
    Icon,
    Text,
    Box,
    Button,
    Image,
    useToast,
} from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png';
import DashboardHeader from '../../Header/DashboardHeader.js';
import { HiMenuAlt1, HiOutlineClock } from 'react-icons/hi';
import { useState, useEffect } from 'react';
import foodImg from '../../../assets/images/auth/r6.jpg';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import { useSelector } from 'react-redux';
import { imgUrl, socketUrl } from '../../../utilities/config.js';
import { GET, POST } from '../../../utilities/ApiProvider.js';
import { useDispatch } from 'react-redux';
import { loadVendorData } from '../../../reducers/useReducers.js';
import io from 'socket.io-client';
import notification from '../../../assets/audio/winner-bell-game-show-91932.mp3';

const storedUser = JSON.parse(localStorage.getItem('aimHalalUser'));
const socket = io.connect(`${socketUrl}?storeId=${storedUser?.store?._id}`);

export default function OrdersWrap() {
    const toast = useToast();
    const user = useSelector(state => state.value);
    const [selectedFilter, setSelectedFilter] = useState('newOrder');
    const filterButtons = [
        'newOrder',
        'accepted',
        'preparing',
        'dispatched',
        'delivered',
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [currentOrders, setCurrentOrders] = useState({});
    const [listOfOrder, setListOfOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);

    let orderListOption = {
        newOrder: currentOrders?.newOrder,
        prepare: currentOrders?.prepare,
        delivered: currentOrders?.delivered,
    };

    // const getVendorDashboardData = async () => {
    //     try {
    //         let res = await GET('/vendor/orders', { authorization: `Bearer ${user?.verificationToken}` });
    //         if (res.status === 200) setCurrentOrders(res?.data);
    //     } catch (err) {
    //         toast({
    //             description: err.message,
    //             status: 'error',
    //             position: 'top-right',
    //             duration: 2500
    //         });
    //     }
    // }

    // const acceptOrderHandler = async () => {
    //     setIsLoading(true);
    //     try {
    //         let res = await POST(`/vendor/orders/status/${selectedOrder?._id}`,
    //             {
    //                 orderStatus,
    //                 estimatedTime: selectedOrder?.estimatedTime
    //             },
    //             { authorization: `Bearer ${user?.verificationToken}` });
    //         if (res.status === 200) {
    //             toast({
    //                 description: `Order Accepted!`,
    //                 status: 'success',
    //                 position: 'top-right',
    //                 duration: 2500
    //             });
    //             getVendorDashboardData();
    //             setSelectedOrder(null);
    //         } else {
    //             toast({
    //                 description: res?.message,
    //                 status: 'error',
    //                 position: 'top-right',
    //                 duration: 3000
    //             });
    //         }
    //     } catch (err) {
    //         toast({
    //             description: err.message,
    //             status: 'error',
    //             position: 'top-right',
    //             duration: 2500
    //         });
    //     }
    //     setIsLoading(false);
    // }

    // useEffect(() => {
    //     if (currentOrders) {
    //         orderListOption = {
    //             newOrder: currentOrders?.newOrder,
    //             prepare: currentOrders?.prepare,
    //             delivered: currentOrders?.delivered
    //         }
    //         setListOfOrders(orderListOption['newOrder']);
    //     }

    //     return () => { }
    // }, [currentOrders]);

    // useEffect(() => {
    //     if (user) getVendorDashboardData();

    //     return () => { }
    // }, [user]);

    // useEffect(() => {
    //     console.log(orderListOption['delivered'])
    //     console.log(orderListOption[selectedFilter]);
    //     console.log(listOfOrder);
    //     console.log(selectedFilter)
    //     return () => { }
    // }, [selectedFilter]);

    // SOCKET CONNECTION

    useEffect(() => {
        let isMounted = true;
        if(user?.store?._id) {
            socket.emit('orders', { storeId: user?.store?._id });
            socket.on('orders', orderList => {
                if (isMounted) {
                    setSelectedOrder(null);
                    console.log('Socket Hit', orderList);
                    setListOfOrders(orderList);
                }
            });
            socket.emit('newOrder', { storeId: user?.store?._id });
            socket.on('newOrder', orderList => {
                if (isMounted) {
                    console.log('New Order Hit', orderList);
                    playNotificationSound();
                    toast({
                        description: 'New order received!',
                        status: 'success',
                        position: 'top-right',
                        duration: 2500,
                    });
                    setSelectedFilter('newOrder');
                }
            });
        } else {
            toast({
                description: 'I didnt get store ID',
                status: 'error',
                position: 'top-right',
                duration: 2500
            })
        }


        return () => {
            isMounted = false;
        };
    }, [socket, user]);

    const playNotificationSound = () => {
        let audio = new Audio(notification);
        audio.play();
    };

    const updateOrderStatus = async action => {
        let orderStatus =
            selectedFilter === 'newOrder'
                ? 'accepted'
                : selectedFilter === 'accepted'
                    ? 'preparing'
                    : 'delivered';

        console.log('Sending Status to update', orderStatus);

        socket.emit('onPrepare', {
            token: user?.verificationToken,
            orderid: selectedOrder?._id,
            orderStatus,
            estimatedTime: selectedOrder?.estimatedTime,
        });

        socket.emit('orders', { storeId: user?.store?._id });

        socket.on('orders', orderList => {
            setSelectedOrder(null);
            setListOfOrders(orderList);
        });

        console.log('Sending completed', orderStatus);
    };

    const updateStatusCancel = async () => {
        socket.emit('onPrepare', {
            token: user?.verificationToken,
            orderid: selectedOrder?._id,
            orderStatus: 'cancelled',
            estimatedTime: selectedOrder?.estimatedTime,
        });

        socket.emit('orders', { storeId: user?.store?._id });
        socket.on('orders', orderList => {
            setSelectedOrder(null);
            setListOfOrders(orderList);
        });
    };

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Stack direction="row" gap="40px" mr="60px">
                    <Stack
                        w="40%"
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box>
                            <Heading fontSize="24px" fontWeight="600">
                                Orders List
                            </Heading>
                        </Box>
                        {/* <Box>
                            <Button minW="0px" h="0px" p="0" bgColor="transparent" fontSize="20px">
                                <Icon as={HiMenuAlt1} />
                            </Button>
                        </Box> */}
                    </Stack>
                    <Stack
                        w="60%"
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-end"
                    >
                        <Box>
                            <Heading
                                fontSize={'20px'}
                                color="primaryGreen.100"
                                fontWeight="600"
                            >
                                New
                            </Heading>
                            <Text fontSize="14px">Order execution starts automatically</Text>
                        </Box>
                        <Box>
                            <Text>
                                Manager -{' '}
                                <Text as="span" fontWeight="600" textTransform={'capitalize'}>
                                    {user?.username}
                                </Text>
                            </Text>
                        </Box>
                    </Stack>
                </Stack>
                <Stack direction="row" gap="40px" mr="60px !important">
                    <Stack w="40%">
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            {filterButtons.map(val => (
                                <Stack className="btn" key={val}>
                                    <Button
                                        onClick={() => {
                                            setSelectedFilter(val);
                                            setSelectedOrder(null);
                                        }}
                                        justifyContent={'flex-start'}
                                        padding={'10px 15px'}
                                        bgImage={val === selectedFilter ? greenBtnTexture : 'none'}
                                        bgPos={'center'}
                                        bgSize={'cover'}
                                        bgRepeat={'no-repeat'}
                                        bg={
                                            val === selectedFilter
                                                ? 'primaryGreen.100'
                                                : 'transparent'
                                        }
                                        color={val === selectedFilter ? '#fff' : '#000'}
                                        fontWeight={'400'}
                                        border={'2px solid'}
                                        borderColor={
                                            val === selectedFilter
                                                ? 'primaryGreen.100'
                                                : 'transparent'
                                        }
                                        textAlign={'left'}
                                        fontSize={'14px'}
                                        _hover={{
                                            bg:
                                                val === selectedFilter ? 'primaryGreen.100' : '#D5DADE',
                                            borderColor:
                                                val === selectedFilter ? 'primaryGreen.100' : '#D5DADE',
                                            color: val === selectedFilter ? '#fff' : '#000',
                                        }}
                                    >
                                        <Text as={'span'}>
                                            {val === 'newOrder'
                                                ? 'New Order'
                                                : val === 'accepted'
                                                    ? 'Accepted'
                                                    : val === 'preparing'
                                                        ? 'Preparing'
                                                        : val === 'dispatched'
                                                            ? 'Dispatched'
                                                            : 'Completed'}
                                        </Text>
                                    </Button>
                                </Stack>
                            ))}
                        </Stack>
                        <Box>
                            {listOfOrder[selectedFilter]?.length > 0 &&
                                listOfOrder[selectedFilter].map(val => (
                                    <Box
                                        cursor={'pointer'}
                                        position="relative"
                                        _before={{
                                            content: '""',
                                            position: 'absolute',
                                            left: 0,
                                            top: 0,
                                            bottom: 0,
                                            margin: 'auto',
                                            transition: '0.3s',
                                            backgroundColor: 'primaryYellow.100',
                                            w: '6px',
                                            h: selectedOrder?._id === val?._id ? '100%' : '0%',
                                            borderRadius: '50px',
                                        }}
                                        onClick={() => setSelectedOrder(val)}
                                        bgColor="#fff"
                                        m="10px 0"
                                        boxShadow="0px 5px 25px -15px #000"
                                        key={val?._id}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        p="10px 20px"
                                        borderRadius="5px"
                                    >
                                        <Box>
                                            <Heading fontSize="18px" fontWeight="500" mb="10px">
                                                Order #{val.orderNo}
                                            </Heading>
                                            <Text color="#7D7D7D">
                                                <Icon as={HiOutlineClock} m="0 5px -2px 0" />
                                                {val?.estimatedTime}
                                            </Text>
                                        </Box>
                                        <Text color="primaryGreen.100" fontWeight="600">
                                            C$ {val?.totalPrice}
                                        </Text>
                                    </Box>
                                ))}
                        </Box>
                    </Stack>
                    {selectedOrder && (
                        <Stack w="60%">
                            <Heading mb="20px" fontSize="24px" fontWeight="600">
                                Order Info #{selectedOrder?.orderNo}
                            </Heading>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                mb="5px !important"
                            >
                                <Box>
                                    <Heading mb="5px" fontSize="15px" fontWeight="500">
                                        Preparing Time
                                    </Heading>
                                    <Text fontSize="20px" fontWeight="500">
                                        {selectedOrder?.estimatedTime ?? '0'}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading mb="5px" fontSize="15px" fontWeight="500">
                                        Address
                                    </Heading>
                                    <Text fontSize="20px" fontWeight="500">
                                        {selectedOrder?.customer?.address ?? '--'}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading mb="5px" fontSize="15px" fontWeight="500">
                                        {selectedOrder?.customer?.username ?? '--'}
                                    </Heading>
                                    <Text fontSize="20px" fontWeight="500">
                                        {selectedOrder?.customer?.number ?? '--'}
                                    </Text>
                                </Box>
                            </Stack>
                            {selectedOrder?.items?.length > 0 &&
                                selectedOrder?.items.map(val => (
                                    <Box
                                        key={val?._id}
                                        p="15px 0 !important"
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        borderBottom="1px solid #A8A8A8"
                                    >
                                        <Box display="flex" alignItems="center" gap="10px">
                                            <Image
                                                src={
                                                    val?.item?.pictures[0]
                                                        ? `${imgUrl}/${val?.item?.pictures[0]}`
                                                        : foodImg
                                                }
                                                w="92px"
                                                h="92px"
                                                borderRadius="5px"
                                            />
                                            <Text fontSize="18px" fontWeight="500">
                                                {val?.item?.item ?? 'The Halal Demo Item'}
                                            </Text>
                                        </Box>
                                        <Text fontSize="20px" fontWeight="500">
                                            x{val?.qty ?? '0'}
                                        </Text>
                                        {val?.incredients?.length > 0 &&
                                            val?.incredients.map(val => (
                                                <>
                                                    <Text fontSize="18px" fontWeight="500">
                                                        {val.item ?? 'The Halal Demo Item'}
                                                    </Text>
                                                    <Text fontSize="20px" fontWeight="500">
                                                        C${val.price * val?.quantity}
                                                    </Text>
                                                </>
                                            ))}
                                    </Box>
                                ))}
                            <Stack direction="column" alignItems="flex-end">
                                <Heading fontSize="26px" fontWeight="600" m="10px 0">
                                    C${selectedOrder?.totalPrice ?? '0'} - {selectedOrder?.paymentType === 'card' ? 'Paid' : 'Unpaid'}
                                </Heading>
                                <Stack
                                    direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    w="100%"
                                >
                                    {(selectedFilter === 'newOrder' ||
                                        selectedFilter === 'accepted') && (
                                            <Stack className="btn">
                                                <Button
                                                    isLoading={isLoading}
                                                    onClick={updateStatusCancel}
                                                    justifyContent={'center'}
                                                    padding={'10px 20px'}
                                                    bg={'#CE1004'}
                                                    color={'#fff'}
                                                    fontWeight={'400'}
                                                    bgPos={'center'}
                                                    bgSize={'cover'}
                                                    bgRepeat={'no-repeat'}
                                                    border={'2px solid'}
                                                    borderColor={'#CE1004'}
                                                    textAlign={'left'}
                                                    _hover={{
                                                        bg: 'transparent',
                                                        borderColor: '#CE1004',
                                                        color: '#CE1004',
                                                    }}
                                                >
                                                    <Text as={'span'}>Cancel Order</Text>
                                                </Button>
                                            </Stack>
                                        )}
                                    {selectedFilter === 'newOrder' ? (
                                        <Stack className="btn">
                                            <Button
                                                isLoading={isLoading}
                                                onClick={updateOrderStatus}
                                                justifyContent={'center'}
                                                padding={'10px 20px'}
                                                bg={'primaryGreen.100'}
                                                color={'primaryYellow.100'}
                                                fontWeight={'400'}
                                                bgImage={greenBtnTexture}
                                                bgPos={'center'}
                                                bgSize={'cover'}
                                                bgRepeat={'no-repeat'}
                                                border={'2px solid'}
                                                borderColor={'primaryGreen.100'}
                                                textAlign={'left'}
                                                _hover={{
                                                    bg: 'primaryGreen.100',
                                                    borderColor: 'primaryGreen.100',
                                                    color: '#fff',
                                                }}
                                            >
                                                <Text as={'span'}>
                                                    {selectedFilter === 'newOrder'
                                                        ? 'Accept Order'
                                                        : selectedFilter === 'accepted'
                                                            ? 'Prepare Order'
                                                            : selectedFilter === 'prepare'
                                                                ? 'Deliver Order'
                                                                : 'Complete Order'}
                                                </Text>
                                            </Button>
                                        </Stack>
                                    ) : null}
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </>
    );
}
