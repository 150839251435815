import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Collapse, Divider, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { useState } from "react";

export const RuleItem = ({ title, content, image }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Box>
        <Flex 
            align="center" 
            justify="space-between" 
            onClick={() => setIsOpen(!isOpen)}
            cursor="pointer"
            py={{ base: 2, md: 2,xl: 4 }}
        >
            <Flex align="center" gap="10px">
            <Image
                src={image}
                alt="image here"
                boxSize={{ base: 6, md: 10, xl: 12 }}
            />
            <Text fontWeight="bold" color="green.700" fontSize={{ base: "16px", md: "20px",xl: "24px" }}>{title}</Text>
            </Flex>
            <Icon 
            as={isOpen ? ChevronUpIcon : ChevronDownIcon} 
            color="green.500"
            fontSize={{ base: "16px", md: "18px",xl: "24px" }}
            />
        </Flex>
        <Collapse in={isOpen}>
            <Text pl={8} pt={2} pb={4} color="gray.600" fontSize={{ base: "14px", md: "16px",xl: "18px" }} textAlign="left">
            {content}
            </Text>
        </Collapse>
        <Divider />
        </Box>
    );
};
