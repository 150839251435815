import { Box, Image, Text, Button, Stack, Heading, Icon, Input, useToast } from '@chakra-ui/react'
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { imgUrl } from '../../../utilities/config.js'
import { useNavigate } from 'react-router-dom'
import foodImg from '../../../assets/images/auth/r6.jpg'
import { FaEdit } from 'react-icons/fa'
import { useEffect, useRef, useState } from 'react'
import { PUT } from '../../../utilities/ApiProvider.js'

export default function SingleCatItem({ _id, name, picture, deleteCat, getStore, token, stores }) {

    const toast = useToast();
    const imgRef = useRef(null);
    const [imagehere, setImagehere] = useState(null);

    const changeCatImage = async () => {
        let data = new FormData();
        data.append('picture', imagehere)
        try {
            let res = await PUT(`/admin/category/store/${_id}`, data, { authorization: `Bearer ${token}` });
            if (res.status === 200) {
                setImagehere(null)
                getStore()
                toast({
                    description: res?.message,
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
            } else {
                toast({
                    description: res?.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
    }

    useEffect(() => {
        if (imagehere !== null) {
            changeCatImage()
        }
    }, [imagehere])


    return (
        <Box key={_id} w="48%" mb="30px !important" p="25px" boxShadow={'0px 0px 35px -15px #000'} display="flex" alignItems="center" justifyContent="space-between">
            <Stack direction="row" gap="10px" alignItems="center">
                <Box position="relative">
                    <Image src={picture ? `${imgUrl}/${picture}` : foodImg} w="65px" h="65px" objectFit={'cover'} borderRadius={'100%'} />
                    <Box cursor="pointer" onClick={() => imgRef?.current.click()} position="absolute" right="0" bottom="0" bgColor="primaryGreen.100" borderRadius="100%" w="25px" h="25px" display="Flex" alignItems="center" justifyContent="center">
                        <Icon color="#fff" as={FaEdit} borderRadius='100%' fontSize="14px" />
                    </Box>
                </Box>
                <Box>
                    <Heading fontSize="18px">{name}</Heading>
                    <Text fontSize="14px">No. of Stores: <Text as="span" fontWeight={'600'}>{stores}</Text></Text>
                </Box>
            </Stack>
            <Stack direction="row" gap="10px" alignItems="center">
                <Stack className='btn'>
                    <Button
                        onClick={() => deleteCat(_id)}
                        justifyContent={'center'}
                        padding={'10px 20px'}
                        bg={"primaryGreen.100"}
                        color={'primaryYellow.100'}
                        fontWeight={'400'}
                        bgImage={greenBtnTexture}
                        bgPos={'center'}
                        bgSize={'cover'}
                        bgRepeat={'no-repeat'}
                        border={'2px solid'}
                        fontSize="14px"
                        borderColor={"primaryGreen.100"}
                        textAlign={'left'}
                        _hover={{
                            bg: "primaryGreen.100",
                            borderColor: "primaryGreen.100",
                            color: '#fff'
                        }}
                    >
                        <Text as={'span'} textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'} >Delete</Text>
                    </Button>
                </Stack>
            </Stack>
            <Input display={'none'} name="picture" ref={imgRef} type="file" onChange={e => {
                setImagehere(e.target.files[0])
            }} />
        </Box>
    )
}