import { Stack, Text, Box, Button, FormControl, FormLabel, Input, Textarea, Select, useToast } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
// External CSS
import '../../assets/css/website/contact.css';
// Components
import CounterBox from "../../components/Website/Home/counterBox";
import Header from "../../components/Website/Header/header";
import HeroBanner from '../../components/Website/Home/heroBanner';
import Footer from "../../components/Website/Footer/Footer";
// Images
import BlackOver from '../../assets/images/icon/black.png';
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg';
import { POST } from "../../utilities/ApiProvider";

const ContactUs = () => {

    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [phone, setPhone] = useState('');

    const fillQuery = async () => {
        setIsLoading(true);
        let form = document.getElementById('queryForm');
        let data = new FormData(form);
        try {
            let res = await POST(`/web/inquiry`, data);
            toast({
                description: res.message,
                status: res?.status === 200 ? 'success' : 'error',
                position: 'top-right',
                duration: 2000
            })
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2000
            })
        }
        setIsLoading(false);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, []); // Empty dependency array ensures that this effect runs only once after initial mount

    return (
        <>
            <Header />

            <Box>
                <HeroBanner text={"Contact Us"} />
            </Box>

            <Box p={"50px 0px"} bgImage={BlackOver}>
                <Container maxW={"container.xl"}>
                    {/* <Box maxW={{ base: "initial", xl: "523px" }}>
                        <Text fontSize={"35px"} lineHeight={"22px"} fontWeight={"600"} mb={"20px"} color={'primaryGreen.100'}>Get In Touch</Text>
                        <Text color={'primaryGreen.100'} fontWeight={'500'} fontSize={"16px"} lineHeight={"22px"}>Subscribe now to keep getting updates about new features and discount offers. Coming soon</Text>
                    </Box> */}
                    <Box p={"40px 0px"}>
                        <form id="queryForm">
                            <Stack
                                direction={{ base: "column", xl: "row" }}
                                alignItems={"center"} justifyContent={"space-between"} mb={"40px"}>
                                <Box w="100%">
                                    <FormControl>
                                        <FormLabel color={'primaryGreen.100'} htmlFor="who-input" fontSize={"15px"} fontWeight={"500"}>Who Are You?</FormLabel>
                                        {/* <Input color={'primaryGreen.100'} id="who-input" type={'text'} bg={"#EAEAEA"} borderRadius={"10px"} width={{ base: "80%", xl: "610px" }} border={"none"} _focusVisible={{ boxShadow: "none" }} _placeholder={{ fontWeight: "500", color: "primaryGreen.100" }} h={"auto"} p={"12px 30px"} placeholder="Customer" /> */}
                                        <Select
                                            name="usertype"
                                            color={'primaryGreen.100'}
                                            bg={"#EAEAEA"}
                                            borderRadius={"10px"}
                                            width={{ base: "80%", xl: "610px" }}
                                            border={"none"}
                                            _focusVisible={{ boxShadow: "none" }}
                                            _placeholder={{ fontWeight: "500", color: "primaryGreen.100" }}
                                            h={"47px"}
                                            placeholder="Select"
                                        >
                                            <option value="vendor">Vendor</option>
                                            <option value="customer">Customer</option>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box w="100%">
                                    <FormControl>
                                        <FormLabel color={'primaryGreen.100'} htmlFor="name-input" fontSize={"15px"} fontWeight={"500"}>Name</FormLabel>
                                        <Input name="name" color={'primaryGreen.100'} id="name-input" type={'text'} bg={"#EAEAEA"} borderRadius={"10px"} width={{ base: "80%", xl: "610px" }} border={"none"} _focusVisible={{ boxShadow: "none" }} _placeholder={{ fontWeight: "500", color: "primaryGreen.100" }} h={"auto"} p={"12px 30px"} placeholder="Enter here" />
                                    </FormControl>
                                </Box>
                            </Stack>
                            <Stack direction={{ base: "column", xl: "row" }} alignItems={"center"} justifyContent={"space-between"} mb={"40px"}>
                                <Box w="100%">
                                    <FormControl>
                                        <FormLabel color={'primaryGreen.100'} htmlFor="email-input" fontSize={"15px"} fontWeight={"500"}>Email Address</FormLabel>
                                        <Input name="email" color={'primaryGreen.100'} id="email-input" type={'email'} bg={"#EAEAEA"} borderRadius={"10px"} width={{ base: "80%", xl: "610px" }} border={"none"} _focusVisible={{ boxShadow: "none" }} _placeholder={{ fontWeight: "500", color: "primaryGreen.100" }} h={"auto"} p={"12px 30px"} placeholder="Enter here" />
                                    </FormControl>
                                </Box>
                                <Box w="100%">
                                    <FormControl>
                                        <FormLabel color={'primaryGreen.100'} htmlFor="phone-input" fontSize={"15px"} fontWeight={"500"}>Phone Number</FormLabel>
                                        <PhoneInput
                                            name="phone_number"
                                            style={{
                                                width: '100%',
                                                background: "#EAEAEA",
                                                borderRadius: "10px",
                                                height: "auto",
                                                padding: "6px 30px"
                                            }}
                                            containerStyle={{ background: "none", border: "none" }}
                                            inputStyle={{ background: "none", border: "none" }}
                                            dropdownStyle={{ background: "none", border: "none" }}
                                            id="phone-input"
                                            defaultCountry="ca"
                                            value={phone}
                                            onChange={(phone) => setPhone(phone)}
                                            inputClass="custom-phone-input"
                                        />
                                        {/* <Input type={'text'} bg={"#EAEAEA"} borderRadius={"10px"} width={"277px"} border={"none"} _focusVisible={{boxShadow:"none"}} _placeholder={{fontWeight:"500",color:"primaryGreen.100"}} h={"auto"} p={"12px 30px"} placeholder="Enter here"/> */}
                                    </FormControl>
                                </Box>
                            </Stack>
                            <Stack direction={{ base: "column", xl: "row" }} alignItems={"center"} justifyContent={"space-between"} mb={"40px"}>
                                <Box w="100%">
                                    <FormControl>
                                        <FormLabel color={'primaryGreen.100'} htmlFor="subject-input" fontSize={"15px"} fontWeight={"500"}>Subject</FormLabel>
                                        <Input name="subject" color={'primaryGreen.100'} id="subject-input" type={'text'} bg={"#EAEAEA"} borderRadius={"10px"} width={{ base: "100%", xl: "100%" }} border={"none"} _focusVisible={{ boxShadow: "none" }} _placeholder={{ fontWeight: "500", color: "primaryGreen.100" }} h={"auto"} p={"12px 30px"} placeholder="Inquiry" />
                                    </FormControl>
                                </Box>

                                {/* <Box w="100%">
                                <FormControl>
                                    <FormLabel color={'primaryGreen.100'} htmlFor="phone-input" fontSize={"15px"} fontWeight={"500"}>Phone Number</FormLabel>
                                    <Input color={'primaryGreen.100'} id="phone-input" type={'number'} bg={"#EAEAEA"} borderRadius={"10px"} width={{ base: "80%", xl: "940px" }} border={"none"} _focusVisible={{ boxShadow: "none" }} _placeholder={{ fontWeight: "500", color: "primaryGreen.100" }} h={"auto"} p={"12px 30px"} placeholder="Enter here" />
                                </FormControl>
                            </Box> */}
                            </Stack>
                            <Box mb={"30px"}>
                                <FormControl>
                                    <FormLabel color={'primaryGreen.100'} htmlFor="message-input" fontSize={"15px"} fontWeight={"500"}>Message</FormLabel>
                                    <Textarea name="message" id="message-input" bg={"#EAEAEA"} _focusVisible={{ boxShadow: "none" }} borderRadius={"10px"} placeholder="Enter here" _placeholder={{ fontWeight: "500", color: "primaryGreen.100" }} p={"15px 20px"} rows={5} />
                                </FormControl>
                            </Box>
                            <Box>
                                <Box mb={"50px"}>
                                    <Text fontSize={"14px"}>By making this action you agree to our <Text as={Link} to={"#"} color={"#28551A"} textDecorationLine={"underline ! important"}>Terms of Use</Text> and <Text as={Link} to={"/privacypolicy"} color={"#28551A"} textDecorationLine={"underline ! important"}>Privacy Policy</Text></Text>
                                </Box>
                                <Box textAlign={"center"}>
                                    <Button
                                        onClick={fillQuery}
                                        bgImage={yellowBtnTexture}
                                        isLoading={isLoading}
                                        bgPos={'center'}
                                        bgSize={'cover'}
                                        bgRepeat={'no-repeat'}
                                        border={"2px solid #28551A"}
                                        color={"primaryGreen.100"}
                                        fontWeight={"600"}
                                        transform={"Skew(-8deg)"}
                                        h={"auto"}
                                        p={"12px 40px"}
                                        w={"316px"}
                                        m={"auto"}
                                        _hover={{
                                            background: "none"
                                        }}>
                                        <Text as={"span"} transform={"Skew(8deg)"}>Send</Text>
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Container>
            </Box>

            <Box p={"30px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    {/* <CounterBox /> */}
                </Container>
            </Box>

            <Footer />
        </>
    )
}

export default ContactUs;