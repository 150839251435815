import { Stack, Heading, Grid, GridItem, Text, Button, Select, Box, Input, Checkbox, Icon, useToast, Image } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { useEffect, useRef, useState } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { useSelector } from 'react-redux';
import { GET, POST, PUT } from '../../../utilities/ApiProvider.js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { imgUrl } from '../../../utilities/config.js';

export default function HelpWrap() {

    const params = useParams();
    const location = useLocation();
    const toast = useToast()
    const imgRef = useRef(null);
    const navigate = useNavigate();
    const user = useSelector(state => state.value);
    const [isLoading, setIsLoading] = useState(false);
    const [isPromotingStore, setIsPromotingStore] = useState(false);
    const [isPickUp, setIsPickUp] = useState(false);
    const [isDelivery, setIsDelivery] = useState(false);
    const [bannerImg, setBannerImg] = useState(null);
    const [itemList, setItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    const getItemsList = async () => {
        try {
            let res = await GET('/vendor/item/all', { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                setItemList(res?.data);
            } else {
                toast({
                    description: res?.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 3000
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
    }

    const updatePromotion = async () => {
        setIsLoading(true);
        try {
            let shouldProceedWithNetwork = true;
            let form = document.getElementById('promotionForm');
            let data = new FormData(form);
            if (isPromotingStore) {
                data.delete('item');
                data.delete('price');
                if (data.get('banner')['name'] === '') {
                    toast({
                        description: `Banner is required!`,
                        status: 'error',
                        position: 'top-right',
                        isClosable: true,
                        duration: 4000
                    });
                    shouldProceedWithNetwork = false;
                } else {
                    if (shouldProceedWithNetwork) {
                        data.append('type', 'store');
                        let res = await PUT('/vendor/promotion', data, { authorization: `Bearer ${user?.verificationToken}` });
                        if (res.status === 200) {
                            toast({
                                description: 'Promotion created successfully!',
                                status: 'success',
                                position: 'top-right',
                                duration: 3000
                            });
                        } else {
                            toast({
                                description: res?.message,
                                status: 'success',
                                position: 'top-right',
                                duration: 3000
                            });
                        }
                    }
                }
            } else {
                data.append('delivery_types', (isPickUp && isDelivery) ? 'pickup,delivery' : isPickUp ? 'pickup' : isDelivery ? 'delivery' : '')
                for (const pair of data.entries()) {
                    console.log(pair[0], pair[1])
                    if (pair[1] === '') {
                        shouldProceedWithNetwork = false;
                        let errorKey = pair[0];
                        if (errorKey.includes('_')) {
                            errorKey = `${errorKey.split('_')[0]} ${errorKey.split('_')[1]}`
                        }
                        toast({
                            description: `${errorKey} is required!`,
                            status: 'error',
                            position: 'top-right',
                            isClosable: true,
                            duration: 4000
                        })
                        break;
                    }
                }
                if (data.get('banner')['name'] === '') {
                    toast({
                        description: `Banner is required!`,
                        status: 'error',
                        position: 'top-right',
                        isClosable: true,
                        duration: 4000
                    });
                    shouldProceedWithNetwork = false;
                }
                if (shouldProceedWithNetwork) {
                    // Create a new Date object
                    let currentDate = new Date();

                    // Get the current date components
                    let day = currentDate.getDate(); // Returns the day of the month (1-31)
                    let month = currentDate.getMonth() + 1; // Returns the month (0-11), so we add 1 to get the correct month
                    let year = currentDate.getFullYear(); // Returns the year (four digits)
                    let finalDate = `${year}-${month}-${day}`
                    data.append('startDate', finalDate);
                    data.append('type', 'item');
                    data.set('item', selectedItem);
                    let res = await PUT(`/vendor/promotion/${location?.state?._id}`, data, { authorization: `Bearer ${user?.verificationToken}` });
                    if (res.status === 200) {
                        navigate('/dashboard/promotion-list')
                        toast({
                            description: 'Promotion created successfully!',
                            status: 'success',
                            position: 'top-right',
                            duration: 3000
                        });
                    } else {
                        toast({
                            description: res?.message,
                            status: 'error',
                            position: 'top-right',
                            duration: 3000
                        });
                    }
                }
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (user) getItemsList()
    }, [user])

    useEffect(() => {
        if (location?.state) {
            setSelectedItem(location?.state?.itemData?._id)
            console.log(location?.state)
            setIsPickUp(location?.state?.delivery_types.includes('pickup'))
            setIsDelivery(location?.state?.delivery_types.includes('delivery'))
        } else {
            navigate('/dashboard/promotion-list')
        }
    }, [location])

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Stack mr="60px !important">
                    <Stack direction={'row'} justifyContent={'space-between'} mr="60px !important">
                        <Heading fontSize="24px" fontWeight="600" mb="40px">Update Promotion</Heading>
                        <Stack className='btn'>
                            <Button
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={"primaryGreen.100"}
                                onClick={() => navigate(`/dashboard/promotion-list`)}
                                bgImage={greenBtnTexture}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                color={'primaryYellow.100'}
                                fontWeight={'400'}
                                border={'2px solid'}
                                borderColor={"primaryGreen.100"}
                                textAlign={'left'}
                                _hover={{
                                    bg: "primaryGreen.100",
                                    borderColor: "primaryGreen.100",
                                    color: '#fff'
                                }}
                            >
                                <Text as={'span'}>View Promotions</Text>
                            </Button>
                        </Stack>
                    </Stack>
                    <form id="promotionForm">
                        <Grid
                            templateColumns="repeat(12, 1fr)"
                            gap={{ base: 2, sm: 0 }}
                            alignItems={'flex-start'}
                            justifyContent="space-between"
                            mr="60px !important"
                        >
                            <GridItem className='addItemForm' colSpan={{ base: '4', md: '5' }}>
                                <Text fontWeight={'600'} mb="15px">What would you like to promote?</Text>
                                <Stack direction={'row'} alignItems={'center'} gap="30px">
                                    <Stack className='btn'>
                                        <Button
                                            onClick={() => setIsPromotingStore(false)}
                                            justifyContent={'center'}
                                            padding={'10px 20px'}
                                            w="230px"
                                            bgImage={!isPromotingStore ? greenBtnTexture : 'none'}
                                            bgPos={'center'}
                                            bgSize={'cover'}
                                            bgRepeat={'no-repeat'}
                                            bg={!isPromotingStore ? "primaryGreen.100" : 'transparent'}
                                            color={!isPromotingStore ? 'primaryYellow.100' : 'primaryGreen.100'}
                                            fontWeight={'400'}
                                            border={'2px solid'}
                                            borderColor={"primaryGreen.100"}
                                            textAlign={'left'}
                                            _hover={{
                                                bg: "primaryGreen.100",
                                                borderColor: "primaryGreen.100",
                                                color: '#fff'
                                            }}
                                        >
                                            <Text as={'span'}>Items</Text>
                                        </Button>
                                    </Stack>
                                </Stack>
                                <Text fontWeight={'600'} m="20px 0 5px">Choose Item</Text>
                                <Select
                                    name='item'
                                    defaultValue={location?.state?.itemData?._id}
                                    disabled={(itemList?.length === 0 || isPromotingStore) ? true : false}
                                    onChange={e => setSelectedItem(e.target.value)}
                                >
                                    <option style={{ display: 'none' }}>{location?.state?.itemData?.item}</option>
                                    {
                                        itemList?.length > 0 &&
                                        itemList?.map(val =>
                                            <option key={val?._id} value={val?._id}>{val?.item}</option>
                                        )
                                    }
                                </Select>
                                <Stack direction="row" m="20px 0 5px" justifyContent="space-between" alignItems="center" spacing="0" gap="40px">
                                    <Box w="50%">
                                        <Text fontWeight={'600'}>Price</Text>
                                        <Input name='price' type='number' disabled={isPromotingStore} placeholder="C$0" defaultValue={location?.state?.price} />
                                    </Box>
                                    <Box w="50%">
                                        <Text fontWeight={'600'}>Duration</Text>
                                        <Input name='duration' type='number' disabled={isPromotingStore} placeholder="3 Days" defaultValue={location?.state?.duration} />
                                    </Box>
                                </Stack>
                                <Stack m="20px 0 5px">
                                    <Box w="50%" display="flex" justtifyContent="space-between">
                                        <Checkbox value={isPickUp} defaultChecked={location?.state?.delivery_types.includes('pickup')} onChange={e => setIsPickUp(e.target.checked)} w="50%" disabled={isPromotingStore}>
                                            <Text fontSize={'14px'} color={'#66707A'} fontWeight="500">
                                                Pickup
                                            </Text>
                                        </Checkbox>
                                        <Checkbox value={isDelivery} defaultChecked={location?.state?.delivery_types.includes('delivery')} onChange={e => setIsDelivery(e.target.checked)} w="50%" disabled={isPromotingStore}>
                                            <Text fontSize={'14px'} color={'#66707A'} fontWeight="500">
                                                Delivery
                                            </Text>
                                        </Checkbox>
                                    </Box>
                                </Stack>
                                <Stack className='btn' mt="120px">
                                    <Button
                                        disabled={selectedItem === null ? true : false}
                                        isLoading={isLoading}
                                        onClick={updatePromotion}
                                        justifyContent={'center'}
                                        padding={'10px 20px'}
                                        w="230px"
                                        bg={"primaryGreen.100"}
                                        bgImage={greenBtnTexture}
                                        bgPos={'center'}
                                        bgSize={'cover'}
                                        bgRepeat={'no-repeat'}
                                        color={'primaryYellow.100'}
                                        fontWeight={'400'}
                                        border={'2px solid'}
                                        borderColor={"primaryGreen.100"}
                                        textAlign={'left'}
                                        _hover={{
                                            bg: "primaryGreen.100",
                                            borderColor: "primaryGreen.100",
                                            color: '#fff'
                                        }}
                                    >
                                        <Text as={'span'}>Update Promotion</Text>
                                    </Button>
                                </Stack>
                            </GridItem>
                            <GridItem colSpan={{ base: '4', md: '3' }}>
                            </GridItem>
                            <GridItem colSpan={{ base: '4', md: '4' }}>
                                <Text fontWeight={'600'} m="0px 0 15px">Upload Promotion Banner <sub>(330px x 174px)</sub></Text>
                                <Input display={'none'} onChange={e => setBannerImg(e.target.files[0])} name="banner" type='file' ref={imgRef} />
                                <Button
                                    w="100%"
                                    h="115px"
                                    border="1px solid #BBBBBB"
                                    onClick={() => imgRef?.current.click()}
                                >
                                    <Text display="block" w="100%">
                                        <Icon as={BsPlusCircle} display="block" margin="5px auto" />
                                        {bannerImg?.name ?? 'Add'}</Text>
                                </Button>
                                <Text m="20px 0 10px 0" fontWeight={'600'}>Default Banner</Text>
                                <Image src={`${imgUrl}/${location?.state?.banner}`} />
                            </GridItem>
                        </Grid>
                    </form>
                </Stack>
            </Stack >
        </>
    )
}