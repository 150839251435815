import {
  Box,
  Text,
  Stack,
  Image,
  Button,
  Icon,
  Heading,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  ModalFooter,
  FormLabel,
  Input,
  Checkbox,
} from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';
import { useState } from 'react';
import PopularItemsInfo from './PopularItemInfo';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import { imgUrl } from '../../../utilities/config';
import { DELETE, POST, PUT } from '../../../utilities/ApiProvider.js';
import shapeb from '../../../assets/images/icon/black.png';
import { PhoneInput } from 'react-international-phone';

export default function StoreReqRow({
  hideDelete,
  logo,
  slug,
  store_name,
  area,
  reviews,
  topProducts,
  _id,
  getPartners,
  setSearchValue,
  isAdminCreated,
  user,
  isActive,
  isDelivery
}) {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [localIsDelivery, setLocalIsDelivery] = useState(isDelivery);
  const currentUser = useSelector(state => state?.value);
  const [isLoading, setIsLoading] = useState(false);
  const [changeInfo, setChangeInfo] = useState({
    email: '',
    number: '',
  });

  const deletePartner = async id => {
    setIsLoading(true);
    try {
      let res = await DELETE(`/admin/remove-store/${user?._id}`, {
        authorization: `Bearer ${currentUser?.verificationToken}`,
      });
      if (res?.status === 200) {
        getPartners('');
        setSearchValue('');
        toast({
          description: 'Partner Removed!',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (err) {
      toast({
        description: err?.message,
        status: 'error',
        duration: 3000,
        position: 'top-right',
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const changeInfoHandler = async () => {
    setIsLoading(true);
    try {
      if (changeInfo?.email !== '' || changeInfo?.number !== '') {
        let res = await PUT(`/admin/update/${user._id}`, {
          ...changeInfo,
          isDelivery: localIsDelivery
        }, {
          authorization: `Bearer ${currentUser?.verificationToken}`,
        });
        if (res.status === 200) {
          toast({
            description: 'Info Updated Successfully!',
            status: 'success',
            position: 'top-right',
            duration: 3000,
          });
          setChangeInfo({
            email: '',
            number: '',
          });
          setSearchValue('');
          getPartners('');
          onClose();
        } else {
          toast({
            description: res?.message,
            status: 'error',
            position: 'top-right',
            duration: 3000,
          });
        }
      } else {
        toast({
          description: 'All fields are Required!',
          status: 'error',
          position: 'top-right',
          duration: 2500,
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Box
        flex={'1'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        mb="15px"
        pb="15px"
        borderBottom={'1px solid #adadad'}
      >
        <Stack direction={'row'} alignItems={'center'} gap={'5px'}>
          <Image
            src={`${imgUrl}/${logo}`}
            w="50px"
            h="50px"
            borderRadius={'12px'}
            objectFit={'cover'}
          />
          <Box>
            <Text fontWeight={'500'}>{store_name}</Text>
            <Text color="#616161" fontSize={'12px'}>
              {area}
            </Text>
            {user?.isCustomer && (
              <Text
                padding={'3px 10px'}
                color={'#2EC487'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                w={'fit-content'}
                bg={'rgba(46, 196, 135, 0.16)'}
              >
                Customer
              </Text>
            )}
          </Box>
        </Stack>
        {isAdminCreated ? (
          <Stack className="btn">
            <Button
              justifyContent={'center'}
              padding={'10px 20px'}
              bg={'primaryGreen.100'}
              onClick={onOpen}
              bgImage={greenBtnTexture}
              bgPos={'center'}
              bgSize={'cover'}
              bgRepeat={'no-repeat'}
              color={'primaryYellow.100'}
              fontWeight={'400'}
              border={'2px solid'}
              borderColor={'primaryGreen.100'}
              textAlign={'left'}
              _hover={{
                bg: 'primaryGreen.100',
                borderColor: 'primaryGreen.100',
                color: '#fff',
              }}
            >
              <Text as={'span'}>Change Store Info</Text>
            </Button>
          </Stack>
        ) : (
          <Text fontSize={'14px'} color={'#66707A'}>
            <Icon as={FaStar} mr="5px" color="primaryYellow.100" />
            {reviews ?? '0'}
          </Text>
        )}
      </Box>
      {/* <Stack>
                <Heading fontSize="18px" fontWeight="600" mb="5px !important">Most Popular Items</Heading>
                {
                    topProducts?.length > 0 &&
                    topProducts.map(val =>
                        <Stack>
                            <PopularItemsInfo {...val} />
                        </Stack>
                    )
                }
            </Stack> */}
      <Stack className="btn" alignSelf="center" direction="row">
        <Button
          onClick={() =>
            hideDelete
              ? navigate(`/store/${slug}`)
              : navigate(`/super-dashboard/partner/${_id}`)
          }
          justifyContent={'flex-start'}
          padding={'10px 20px'}
          w="fit-content"
          bg={'primaryGreen.100'}
          color={'primaryYellow.100'}
          bgImage={greenBtnTexture}
          bgPos={'center'}
          bgSize={'cover'}
          bgRepeat={'no-repeat'}
          fontWeight={'400'}
          border={'2px solid'}
          borderColor={'primaryGreen.100'}
          textAlign={'left'}
          _hover={{
            bg: 'primaryGreen.100',
            borderColor: 'primaryGreen.100',
            color: '#fff',
          }}
        >
          <Text as={'span'}>View Full Profile</Text>
        </Button>
        {!hideDelete && (
          <Button
            isLoading={isLoading}
            onClick={() => deletePartner(_id)}
            justifyContent={'flex-start'}
            padding={'10px 20px'}
            w="fit-content"
            bg={'#CE1004'}
            color={'#fff'}
            bgPos={'center'}
            bgSize={'cover'}
            bgRepeat={'no-repeat'}
            fontWeight={'400'}
            border={'2px solid'}
            borderColor={'#CE1004'}
            textAlign={'left'}
            _hover={{
              bg: 'primaryGreen.100',
              borderColor: 'primaryGreen.100',
              color: '#fff',
            }}
          >
            <Text as={'span'}>{isActive ? 'Block' : 'Un-block'}</Text>
          </Button>
        )}
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent
          bgSize="cover"
          bgPos="center"
          backgroundImage={shapeb}
          p="20px 40px"
        >
          <ModalHeader fontWeight="600" fontSize={'24px'} textAlign="center">
            Change Info of {store_name}
          </ModalHeader>
          <ModalBody>
            <FormLabel mt="20px !important" fontWeight="600">
              Email
            </FormLabel>
            <Box className="Ifiled">
              <Input
                type={'text'}
                placeholder="Enter here"
                defaultValue=""
                value={changeInfo?.email}
                onChange={e =>
                  setChangeInfo({
                    ...changeInfo,
                    email: e.target.value,
                  })
                }
              />
            </Box>
            <FormLabel mt="20px !important" fontWeight="600">
              Phone
            </FormLabel>
            <Box className="Ifiled">
              <PhoneInput
                name="number"
                style={{
                  width: '100%',
                  background: '#EAEAEA',
                  borderRadius: '10px',
                  height: 'auto',
                  padding: '6px 30px',
                }}
                containerStyle={{ background: 'none', border: 'none' }}
                inputStyle={{ background: 'none', border: 'none' }}
                dropdownStyle={{ background: 'none', border: 'none' }}
                value={changeInfo?.number}
                onChange={phone =>
                  setChangeInfo({
                    ...changeInfo,
                    number: phone,
                  })
                }
                id="phone-input"
                defaultCountry="ca"
                inputClass="custom-phone-input"
              />
            </Box>
            <FormLabel mt="20px !important" fontWeight="600">
              Is delivery allowed?
              <Checkbox marginLeft={'20px'} marginTop={'4px'} defaultChecked={isDelivery} value={localIsDelivery}  onChange={e => setLocalIsDelivery(e.target.checked)}/>
            </FormLabel>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Stack className="btn">
              <Button
                onClick={changeInfoHandler}
                justifyContent={'center'}
                isLoading={isLoading}
                padding={'10px 20px'}
                bg={'primaryGreen.100'}
                w="200px"
                color={'primaryYellow.100'}
                fontWeight={'400'}
                border={'2px solid'}
                bgImage={greenBtnTexture}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                borderColor={'primaryGreen.100'}
                textAlign={'left'}
                _hover={{
                  bg: 'primaryGreen.100',
                  borderColor: 'primaryGreen.100',
                  color: '#fff',
                }}
              >
                <Text as={'span'}>Submit</Text>
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
