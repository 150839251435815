import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import HomeWrap from '../../../components/SuperDashboard/Home/HomeWrap';
import { Stack } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'

export default function DashboardHome() {
    return (
        <Stack
            backgroundImage={shapeb}
            overflowY="hidden"
        >
            <MainDashboard type="admin">
                <HomeWrap />
            </MainDashboard>
        </Stack>
    );
}