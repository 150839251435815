import { Text, Box, Image } from "@chakra-ui/react";
import { imgUrl } from "../../../utilities/config";
import { useNavigate } from 'react-router-dom'

const OrderCard = ({ picture, name, stores, _id }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box cursor="pointer" onClick={() => navigate(`/category-wise-store/${name}`)} margin={{ base: '0 20px 0 0 !important', lg: "auto" }} bg={'#e4e0dd'} width={{ base: 'initial', lg: "210px !important" }} borderRadius={"12px"}>
        <Box p={"15px 0px"}>
          <Image borderRadius="12px" src={`${imgUrl}/${picture}`} width={{ base: "65px", lg: "150px" }} height={{ base: "65px", lg: "150px" }} m={"auto"} />
        </Box>
        <Box bg='#28551A' p={{ base: "7px 5px", xl: "7px 20px" }} borderRadius={"0px 0px 12px 12px"}>
          <Text fontSize={{ base: "14px", xl: "17px" }} textTransform={'capitalize'} fontWeight={"700"} color={"primaryYellow.100"}>{name}</Text>
          <Text fontSize={"12px"} fontWeight={"400"} color={"#fff"}>{stores ?? '0'}</Text>
        </Box>
      </Box>
    </>
  )
}

export default OrderCard;