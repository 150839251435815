import { Stack, Text, Box, Image, Button, OrderedList, ListItem } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import { Link } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
// Components
import HalalAdd from "../../components/Website/Home/halalAdd";
import SignCard from "../../components/Website/Home/signCard";
import CounterBox from "../../components/Website/Home/counterBox";
import HeroBanner from '../../components/Website/Home/heroBanner';
import Header from "../../components/Website/Header/header";
import Footer from "../../components/Website/Footer/Footer";
// Images
import tabmain from '../../assets/images/website/appFeature/tabmain.png'
import funeral from '../../assets/images/website/appFeature/funeral.png'
import culture from '../../assets/images/website/appFeature/culture.png'
import chef from '../../assets/images/website/appFeature/chef.png'
import directories from '../../assets/images/website/appFeature/directories.png'
import butch from '../../assets/images/website/appFeature/butch.png'
import event from '../../assets/images/website/appFeature/event.jpg'
import eat from '../../assets/images/website/appFeature/eat.jpg'
import quran from '../../assets/images/website/appFeature/quran.jpg'
import share from '../../assets/images/website/appFeature/share.png'
import feature1 from '../../assets/images/website/appFeature/feature1.png';
import BlackOver from '../../assets/images/icon/black.png';
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg';
import { signData, tabList } from "../../assets/data/data";
import { useEffect } from "react";

const AppFeature = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, []); // Empty dependency array ensures that this effect runs only once after initial mount

    return (
        <>
            <Header />

            <Box>
                <HeroBanner text={"Food Share"} />
            </Box>

            {/* <Box p={"40px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Stack direction={{ base: "column", lg: "row" }} alignItems={"Center"} justifyContent={"space-between"}>
                        <Box>
                            <Image src={feature1} w={"646px"} />
                        </Box>
                        <Box maxW={"629px"}>
                            <Text fontSize={"26px"} fontWeight={"600"} mb={"20px"}>AimHalal Ambition</Text>
                            <Text fontSize={"16px"} mb={"30px"}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ndesktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</Text>
                            <Button as={Link} to={"#"} bg={"primaryYellow.100"} bgImage={yellowBtnTexture} bgPos={'center'} bgSize={'cover'} bgRepeat={'no-repeat'} p={'0px 30px'} transform={"skew(-8deg)"} border={"1px solid #28551A"} _hover={{ bg: "none" }}>
                                <Text as={'span'} transform={"skew(8deg)"} color={"#28551A"}>Read More</Text>
                            </Button>
                        </Box>
                    </Stack>
                </Container>
            </Box> */}

            <Box bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box p={"30px 0px"}>
                        <Stack direction={{ base: "column", xl: "row" }} flexDirection={{ base: "column-reverse", xl: 'initial' }} alignItems={"center"} justifyContent={"space-between"}>
                            <Box flex={1} >
                                <Text color={'primaryGreen.100'} fontSize={"24px"} fontWeight={"700"} letterSpacing={"-0.48px"} mb={"20px"}>Food Share</Text>
                                <Text color={'primaryGreen.100'} fontSize={"15px"} letterSpacing={"-0.24px"} mb={"20px"}>Experience the joy of giving and sharing with us. Connect with individuals or organizations involved in charitable efforts, contributing to the mission of providing Halal food to those in need. Every initiative featured on our platform is vetted for Halal certification, ensuring that the simple act of sharing is carried out with integrity and alignment with the values of the modern Muslim lifestyle.</Text>
                                <Text color={'primaryGreen.100'} fontSize={"15px"} letterSpacing={"-0.24px"} fontWeight={"600"} mb={"20px"}>Here's what you can expect from our Halal Markets feature:</Text>
                                {/* <OrderedList fontSize={"15px"} letterSpacing={"-0.24px"}>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}>Donate Surplus Food**: AimHalal's Food Share feature allows individuals and businesses to donate surplus Halal food to organizations and individuals who can benefit from it the most. Whether you have excess food from events, restaurants, or home-cooked meals, you can contribute to reducing food waste and making a positive impact on people's lives.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}>Charitable Initiatives**: Our Food Share feature extends beyond just food donations. You can also contribute clothing, essentials, and other items to support charitable initiatives. By donating items that are in good condition, you can help those in need while fostering a culture of giving and compassion within the AimHalal community.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}>Connect with Local Organizations**: AimHalal's Food Share feature connects you with local organizations and initiatives that accept and distribute donated items. You can easily find and engage with reputable organizations dedicated to helping individuals and communities facing food insecurity or other challenges. Together, we can make a difference and uplift those who need our support.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}>Promote Sustainable Practices**: Through our Food Share feature, we promote sustainable practices by reducing food waste and ensuring that valuable resources are utilized effectively. By redistributing surplus food and donating usable items, we contribute to a more sustainable and equitable society.</ListItem>
                                </OrderedList> */}
                                <OrderedList fontSize={"16px"} letterSpacing={"-0.24px"}>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}><b>Wide Range of Categories</b>:<br /> Explore a variety of categories for all your needs.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}><b>Verified Halal Certification</b>:<br /> Trust in our products with verified Halal certification.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}><b>User-Friendly Interface</b>:<br /> Enjoy easy navigation with our user-friendly interface.</ListItem>
                                    <ListItem color={'primaryGreen.100'} mb={"16px"}><b>Supporting Halal Businesses</b>:<br /> Support Halal businesses directly through our platform.</ListItem>
                                    {/* <ListItem color={'primaryGreen.100'}>Experience the convenience and peace of mind that AimHalal's Halal Markets feature offers. Join our growing community and explore a world of Halal products right at your fingertips. Download the AimHalal app today on Android or iOS and unlock the possibilities of Halal living like never before. Together, let's embrace a Halal lifestyle with AimHalal's Halal Markets feature.</ListItem> */}
                                </OrderedList>
                                {/* <Text color={'primaryGreen.100'} fontSize={"15px"} letterSpacing={"-0.24px"} fontWeight={"600"} mb={"20px"}>Join us in spreading kindness and nourishing those in need with AimHalal's Food Share feature. Together, we can make a positive impact on individuals and communities, fostering a sense of unity and support.

                                    <br />
                                    Download the AimHalal app today on Android or iOS and participate in our Food Share initiative to create a meaningful difference in the lives of others.
                                    <br />
                                    TTogether, let's share the blessings of Halal food and extend a helping hand through AimHalal's Food Share feature.</Text> */}
                            </Box>
                            <Box flex={1}>
                                <Image src={share} />
                            </Box>
                        </Stack>
                    </Box>
                </Container>
            </Box>

            <Box p={"100px 0px 40px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <HalalAdd />
                    </Box>
                </Container>
            </Box>

            <Box p={"30px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <Stack
                            direction={{ base: "column", xl: "row" }}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            {signData.map((val, key) => {
                                return (
                                    <SignCard img={val.img} text1={val.text1} text2={val.text2} main={val.main} btn={val.btn} href={val.href} key={key} />
                                )
                            })
                            }
                        </Stack>
                    </Box>
                    <Box pt={"40px"}>
                        {/* <CounterBox /> */}
                    </Box>
                </Container>
            </Box>

            <Footer />
        </>
    )
}

export default AppFeature;