import {
  Stack,
  Box,
  Image,
  Text,
  Heading,
  Icon,
  Checkbox,
  FormLabel,
  Input,
  Button,
  Link,
  InputGroup,
  InputLeftAddon,
  Select,
  useToast,
  Modal,
  useDisclosure,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  Spinner,
} from '@chakra-ui/react';
import PartyFace from '../../assets/images/partyFace.svg'
import { BiArrowBack } from 'react-icons/bi';
import React, { useEffect, useMemo, useState } from 'react';
import shapeb from '../../assets/images/icon/black.png';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { GET, POST } from '../../utilities/ApiProvider';
import { baseUrl } from '../../utilities/config';
import countryList from 'react-select-country-list';
import { useSelector } from 'react-redux';
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg'

export default function RegisterForm() {

  const toast = useToast();
  const navigate = useNavigate();
  const user = useSelector(state => state.value);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [storeTypes, setStoreTypes] = useState([]);
  const [inputFileVal, setInputFileVal] = useState({
    name: 'Select File'
  });
  const [showSubType, setShowSubType] = useState(false);
  const [SubTypeVal, setSubTypeVal] = useState(null);
  const [subTypesData, setSubTypesData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const options = useMemo(() => countryList().getData(), []);
  const [isHalal, setIsHalal] = useState(false);

  const submitSignUp = async () => {
    setIsLoading(true);
    try {
      let hasEmptyValues = false;
      let formTag = document.getElementById('signUpForm');
      let data = new FormData(formTag);
      if (customerData) {
        data.append('username', customerData.name);
        data.append('email', customerData.email);
        data.append('number', customerData.phone);
        data.append('customer', customerData.id);
      }
      for (const pair of data.entries()) {
        if (pair[1] === '') {
          hasEmptyValues = true;
          break;
        }
      }

      if (hasEmptyValues) {
        toast({
          description: 'All fields are required!',
          status: 'error',
          position: 'top-right',
          duration: 2000
        })
      } else {
        data.append('role', 'vendor');
        if (showSubType) {
          data.set('types', SubTypeVal)
        }
        let res = await POST(`/auth`, data);
        if (res.status === 200) {
          onOpen();
        } else {
          toast({
            description: res.message,
            status: 'error',
            position: 'top-right',
            duration: 2000
          })
        }
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2000
      })
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setCustomerData(JSON.parse(localStorage.getItem('custData')));
    console.log(user)
    if (user) {
      if (user?.role?.name === 'vendor') {
        if (user?.store === null) {
          navigate('/createnewstore')
        } else {
          navigate('/dashboard')
        }
      } else {
        navigate('/dashboard');
      }
    } else {
      (async () => {
        setIsLoading(true);
        let storesRes = await GET(`/admin/roles/vendor1`);
        if (storesRes.status === 200) {
          setStoreTypes(storesRes.data);
          setSubTypesData(storesRes.data.filter(val => { if (val?.name === 'Halal Web') { return val?.childs } }))

        } else {
          toast({
            description: 'Unable to find store types, please check internet connection or try later!',
            status: 'error',
            position: 'top-right',
            duration: 3000
          })
        }
        setIsLoading(false);
      })();
    }
  }, [user])

  return (
    <>
      <Stack
        w={{ base: '95%', xl: '70%' }}
        m={'0 auto'}
        bg={'#fff'}
        py={'50px'}
        px={'20px'}
        border={'5px solid'}
        borderColor="primaryYellow.100"
        borderRadius={'8px'}
        backgroundImage={shapeb}
      >
        <Stack mb={2}>
          <Box className="boxlink">
            <Link to={'/'} as={ReactLink}>
              <Icon fontSize={'17px'} color={'#000'} as={BiArrowBack} />
            </Link>
          </Box>
        </Stack>
        <Box className="AH mh p1" pb={4}>
          <Heading fontSize={{ base: '22px', xl: '30px' }}>Sign up New Account</Heading>
          <Text fontSize={{ base: '12px', xl: '16px' }}>Enter your details to create your account</Text>
        </Box>
        <form id="signUpForm">
          <Stack className="regwarp" gap={2}>
            <Stack>
              <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
                Full Name
              </FormLabel>
              <Box className="Ifiled">
                <Input disabled={customerData?.name} value={customerData?.name} name='username' type="text" placeholder="Enter here" />
              </Box>
            </Stack>
            <Stack>
              <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
                Email Address
              </FormLabel>
              <Box className="Ifiled">
                <Input disabled={customerData?.email} value={customerData?.email} name='email' type="email" placeholder="Enter here" />
              </Box>
            </Stack>
            <Stack direction="row">
              <Box flex="1">
                <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
                  Country
                </FormLabel>
                <Box className="Ifiled">
                  <Select style={{ color: '#000' }} name="country">
                    <option style={{ display: 'none' }}>
                      Country
                    </option>
                    {options.length > 0 &&
                      options.map(val => (
                        <option key={val?.value} value={val?.label}>
                          {val?.label}
                        </option>
                      ))}
                  </Select>
                </Box>
              </Box>
              <Box flex="2">
                <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
                  Phone
                </FormLabel>
                <Box className="Ifiled">
                  <Input name='number' disabled={customerData?.phone} value={customerData?.phone} type="phone" placeholder="Enter here" />
                </Box>
              </Box>
            </Stack>
            <Stack>
              <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
                Select Store Type
              </FormLabel>
              <Box className="Ifiled" position={'relative'}>
                <Spinner display={isLoading ? 'initial' : 'none'} color="primaryGreen.100" size={'sm'} position={'absolute'} right="5px" zIndex={1} top="15px" />
                <Select onChange={e => { e.target.value === 'Halal Web' ? setShowSubType(true) : setShowSubType(false) }} disabled={isLoading} name='type' textTransform={'capitalize'}>
                  <option style={{ display: 'none' }}>Select Store</option>
                  {
                    storeTypes.length > 0 &&
                    storeTypes.map(val => <option key={val?._id} value={val?.name} textTransform="capitalize">{val?.name}</option>)
                  }
                </Select>
              </Box>
            </Stack>
            {
              showSubType ?
                <Stack direction="row" justifyContent={'space-between'}>
                  <Stack w="100%">
                    <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                      Select Sub-Store Type
                    </FormLabel>
                    <Box className="Ifiled" position={'relative'}>
                      <Spinner display={isLoading ? 'initial' : 'none'} color="primaryGreen.100" size={'sm'} position={'absolute'} right="5px" zIndex={1} top="15px" />
                      <Select onChange={e => setSubTypeVal(e.target.value)} disabled={isLoading} name='types' textTransform={'capitalize'}>
                        <option style={{ display: 'none' }}>Select Sub-Store</option>
                        {
                          subTypesData?.length > 0 &&
                          subTypesData[0]['childs']?.map(val => <option key={val?._id} value={val?.name} textTransform="capitalize">{val?.name}</option>)
                        }
                      </Select>
                    </Box>
                  </Stack>
                </Stack>
                : null
            }
            {
              !showSubType ?
                <Stack>
                  <Box>
                    <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
                      Upload Halal Certificates
                    </FormLabel>
                    <Box className="Ifiled">
                      <Input onChange={e => setInputFileVal(e.target.files[0])} name='document' className='filetype' type="file" placeholder="Enter here" _after={{ content: `"${inputFileVal.name}"` }} />
                    </Box>
                  </Box>
                </Stack>
                : null
            }
            <Stack className="cterm">
              <Checkbox defaultChecked={isPolicyAccepted} onChange={e => setIsPolicyAccepted(e.target.checked)} colorScheme='yellow'>
                <Text fontSize={'11px'}>
                  I have reviewed and agreed to our{' '}
                  <Link
                    as={ReactLink}
                    fontWeight={600}
                    fontSize={'11px'}
                    color={'#203863'}
                    to={'/'}
                  >
                    Terms of Use
                  </Link>{' '}
                  and{' '}
                  <Link
                    as={ReactLink}
                    fontWeight={600}
                    color={'#203863'}
                    fontSize={'11px'}
                    to={'/'}
                  >
                    Privacy Policy
                  </Link>
                </Text>
              </Checkbox>
            </Stack>
            <Stack className='btn'>
              <Button
                isLoading={isLoading}
                onClick={submitSignUp}
                disabled={!isPolicyAccepted}
                padding={'10px 50px'}
                bg={'#FFC00F'}
                bgImage={yellowBtnTexture}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                color={'primaryGreen.100'}
                fontWeight={'500'}
                w={'fit-content'}
                border={'2px solid'}
                borderColor="primaryGreen.100"
                textTransform={'uppercase'}
              >
                <Text as={'span'}>Submit</Text>
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose} size={'lg'} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent p="40px 0" textAlign={'center'}>
          <ModalHeader>
            <ModalCloseButton onClick={() => navigate('/login')} />
          </ModalHeader>
          <Image w="100px" marginX={'auto'} src={PartyFace} />
          <Heading color="primaryGreen.100" fontSize={'32px'} m="20px 0">Thank you!</Heading>
          <Text fontSize={'16px'} fontWeight={'500'}>You request has been successfully submit. We will <br /> review and get back to you soon!</Text>
        </ModalContent>
      </Modal>
    </>
  );
}
