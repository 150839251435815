import { Stack, Text, Box, Image, Button, Input, Icon, useToast } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import { Link } from "react-router-dom";
import BlackOver from '../../../assets/images/icon/black.png';
import WhiteOver from '../../../assets/images/icon/white.png';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import FooterLogo from '../../../assets/images/website/home/headLogo.png';
import Down1 from '../../../assets/images/website/home/down1.png';
import Down2 from '../../../assets/images/website/home/down2.png';
import social1 from '../../../assets/images/website/home/social1.png';
import social2 from '../../../assets/images/website/home/social2.png';
import social3 from '../../../assets/images/website/home/social3.png';
import social4 from '../../../assets/images/website/home/social4.png';
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import { useState } from "react";
import { POST } from "../../../utilities/ApiProvider";

const socialIcons = [
    {
        img: FaFacebook,
        href: "https://www.facebook.com/aimhalal",
        color: '#316FF6'
    },
    {
        img: FaInstagram,
        href: "https://www.instagram.com/aimhalal/",
        color: '#cd486b'
    },
    {
        img: FaLinkedin,
        href: "https://www.linkedin.com/company/aim-halal/",
        color: '#0077b5'
    },
    {
        img: FaWhatsapp,
        href: "https://api.whatsapp.com/send/?phone=14162755181&text&type=phone_number&app_absent=0",
        color: '#075e54'
    },
]
const legalPage = [
    {
        name: "Terms and Conditions",
        href: "#"
    },
    {
        name: "Privacy",
        href: "#"
    },
    {
        name: "Cookies",
        href: "#"
    },
]

const impPage = [
    // {
    //     name: "Get help",
    //     href: "#"
    // },
    {
        name: "Contact Us",
        href: "/contactus"
    },
    {
        name: "Delivery Partner",
        href: "#"
    },
    {
        name: "Partner With Us",
        href: "/register"
    },
]

const Footer = () => {

    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const submitNewsLetterPolicy = async () => {
        setIsLoading(true);
        let form = document.getElementById('newsLetterPopFormFooter');
        let data = new FormData(form);
        try {
            let res = await POST(`/web/news-letter`, data);
            if (res?.status === 200) {
                toast({
                    description: `News Letter Subscribed!`,
                    status: 'success',
                    isClosable: true,
                    position: 'top-right',
                    duration: 2500
                });
            } else {
                toast({
                    description: `${res?.message}`,
                    status: 'error',
                    isClosable: true,
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                isClosable: true,
                position: 'top-right',
                duration: 2500
            })
        }
        setIsLoading(false);
    }

    return (
        <Stack bgImage={BlackOver}>
            <Container maxW={{ base: "1366px", '2xl': "1440px" }}>
                <Box borderRadius={'12px 12px 0px 0px'} bg={"primaryYellow.100"} bgImage={BlackOver} p={"30px 0px 30px 0px"}>
                    <Container maxW={"1440px"}>
                        <Box>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                flexWrap={"wrap"}
                                gap={{ base: "10px" }}
                            >
                                <Box>
                                    <Link to={"/"}>
                                        <Image src={FooterLogo} />
                                    </Link>
                                    <Stack direction={"row"} alignItems={"center"} gap={"10px"} mb={"20px"} mt={"30px"}>
                                        <Link to={"#"}><Image
                                            onClick={() => window.open('https://apps.apple.com/pk/app/aimhalal/id6446046649', '_blank')}
                                            src={Down1} w={"121px"} /></Link>
                                        <Link to={"#"}><Image
                                            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.aimhalal.app', '_blank')}
                                            src={Down2} w={"121px"} /></Link>
                                    </Stack>
                                    <Text fontSize={"13px"} mb={"20px"}>Mississauga, ON, Canada</Text>
                                    <Text fontSize={"13px"}>+14162755181</Text>
                                </Box>
                                <Box >
                                    <Text fontSize={"15px"} fontWeight={"700"} color={"#28551A"} lineHeight={"35px"}>Get Exclusive Deals in your Inbox</Text>
                                    <form id="newsLetterPopFormFooter">
                                        <Box position={"relative"} maxW={"440px"} transform={"skew(-10deg)"} mt={"30px"}>
                                            <Input
                                                type="email"
                                                bg={"#D9D9D9"}
                                                w={{base: "260px", xl: "324px"}}
                                                h={"auto"}
                                                p={"10px 15px"}
                                                fontSize={"13px"}
                                                name="email"
                                                placeholder="youremail@gmail.com"
                                                _placeholder={{ color: "rgba(0, 0, 0, 0.60);", fontSize: "13px", lineHeight: "36px", transform: "skew(10deg)" }}
                                                _focusVisible={{ boxShadow: "none", border: "none" }}
                                            />
                                            <Button
                                                isLoading={isLoading}
                                                onClick={submitNewsLetterPolicy}
                                                pos={"absolute"}
                                                h={"auto"}
                                                p={"12px 15px"}
                                                w={{base: "100px", xl: "150px"}}
                                                bg={"#28551A"}
                                                bgImage={greenBtnTexture}
                                                bgSize="cover"
                                                bgPosition="center"
                                                bgRepeat="no-repeat"
                                                borderRadius={"5px"}
                                                color={"primaryYellow.100"}
                                                right={"0px"}
                                                zIndex={"1"}
                                                border={"1px solid transparent"}
                                                _hover={{ bg: "#D9D9D9", color: "#28551A", border: "1px solid #28551A" }}
                                            >
                                                <Text
                                                    as={'span'}
                                                    fontSize={"15px"}
                                                    fontWeight={"500"}
                                                    transform={"skew(10deg)"}
                                                >
                                                    Subscribe
                                                </Text>
                                            </Button>
                                        </Box>
                                    </form>
                                    <Text fontSize={"11px"} lineHeight={"36px"} mb={"10px"}>we wont spam, read our <Text as={Link} to={"#"} textDecorationLine={"underline ! important"}>email policy</Text></Text>
                                    <Stack direction={"row"} alignItems={"center"} gap={"5px"}>
                                        {socialIcons.map((val, key) => {
                                            return (
                                                <a style={{ color: val?.color }} key={key} href={val.href} target="_blank"><Icon as={val.img} w={"37px"} h={"37px"} /></a>
                                            )
                                        })}
                                    </Stack>
                                </Box>
                                {/* <Box>
                                <Text fontSize={"15px"} fontWeight={"700"} color={"#28551A"} lineHeight={"35px"}>Legal Pages</Text>
                                <Stack mt={"15px"} gap={"10px"}>
                                    {legalPage.map((val, key) => {
                                        return (
                                            <Link key={key} to={val.href} style={{ fontSize: "14px", fontWeight: 500 }}>
                                                {val.name}
                                            </Link>
                                        )
                                    })}
                                </Stack>
                            </Box> */}
                                <Box>
                                    <Text fontFamily={"poppins"} fontSize={"15px"} fontWeight={"700"} color={"#28551A"} lineHeight={"35px"}>Important Links</Text>
                                    <Stack mt={"15px"} gap={"10px"}>
                                        {impPage.map((val, key) => {
                                            return (
                                                <Link key={key} to={val.href} style={{ fontSize: "14px", fontWeight: 500 }}>
                                                    {val.name}
                                                </Link>
                                            )
                                        })}
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </Container>
                </Box>
                <Box
                    bg={"#28551A"}
                    p={"30px 0px"}
                    bgImage={WhiteOver}
                >
                    <Container maxW={"1440px"}>
                        <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            flexWrap={"wrap"}
                        >
                            <Text
                                fontSize={{ base: "12px", md: "13px" }}
                                mb={{ base: '20px !important', md: '0 !important' }}
                                color={"primaryYellow.100"}
                            >
                                Aim Innovators Inc Copyright 2023, All Rights Reserved.
                            </Text>
                            <Stack
                                m={"0px ! important"}
                                direction={{ base: "column", md: "row" }}
                                alignItems={{ base: "flex-start", md: "center" }}
                                gap={{ base: "15px", md: "30px" }}
                            >
                                <Text
                                    as={Link}
                                    to={"/privacypolicy"}
                                    fontSize={"13px"}
                                    color={"primaryYellow.100"}
                                >
                                    Privacy Policy
                                </Text>
                                <Text
                                    as={Link}
                                    to={"#"}
                                    fontSize={"13px"}
                                    color={"primaryYellow.100"}
                                    m={"0px ! important"}
                                >
                                    Terms
                                </Text>
                                <Text
                                    fontSize={"13px"}
                                    color={"primaryYellow.100"}
                                    m={"0px ! important"}
                                >
                                    Do not sell or share my personal information
                                </Text>
                            </Stack>
                        </Stack>
                    </Container>
                </Box>
            </Container >
        </Stack>
    )
}
export default Footer;