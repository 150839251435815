import { Stack, Heading, Icon, Text, Box, Button, useDisclosure, Input, Select, Spinner, useToast, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Checkbox } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiMenuAlt1, HiOutlineClock, HiOutlineSearch } from 'react-icons/hi'
import { useEffect, useState } from 'react'
import foodImg from '../../../assets/images/auth/r6.jpg';
import SinglePartnerBox from './SinglePartnerBox.js';
import { GET, POST } from '../../../utilities/ApiProvider.js';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { loadStore } from '../../../reducers/useReducers.js';
import { BsPlusCircle } from 'react-icons/bs';
import Autocomplete from 'react-google-autocomplete';
import yellowBtnTexture from '../../../assets/images/yellowBtnTexture.svg'
import { PhoneInput } from 'react-international-phone';

export default function HelpWrap() {

    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const user = useSelector(state => state.value);
    const [isLoading, setIsLoading] = useState(false);
    const [showSubType, setShowSubType] = useState(false);
    const [SubTypeVal, setSubTypeVal] = useState(null);
    const [storeTypes, setStoreTypes] = useState([]);
    const [subTypesData, setSubTypesData] = useState([]);
    const [inputFileVal, setInputFileVal] = useState({
        banner: {
            name: 'Choose Image (1600px x 325px)'
        },
        logo: {
            name: 'Choose Image (180px x 180px)'
        },
        certificate: {
            name: 'Choose Image'
        }
    });
    const [isHalal, setIsHalal] = useState(false);
    const [isPhysical, setIsPhysical] = useState(false);

    const [openingHours, setOpeningHours] = useState([
        {
            on: true,
            day: 'Monday',
            startTime: '09:00',
            endTime: '22:00'
        },
        {
            on: true,
            day: 'Tuesday',
            startTime: '09:00',
            endTime: '22:00'
        },
        {
            on: true,
            day: 'Wednesday',
            startTime: '09:00',
            endTime: '22:00'
        },
        {
            on: true,
            day: 'Thursday',
            startTime: '09:00',
            endTime: '22:00'
        },
        {
            on: true,
            day: 'Friday',
            startTime: '09:00',
            endTime: '22:00'
        },
        {
            on: true,
            day: 'Saturday',
            startTime: '09:00',
            endTime: '22:00'
        },
        {
            on: true,
            day: 'Sunday',
            startTime: '09:00',
            endTime: '22:00'
        }
    ]);

    function changeOnStatus(itemIndex, value) {
        let modifiedArray = openingHours.map((val, ind) => {
            if (ind === itemIndex) {
                return {
                    ...val,
                    on: value
                }
            } else return val
        });
        setOpeningHours(modifiedArray);
    }

    function changeStartingTime(itemIndex, value) {
        let modifiedArray = openingHours.map((val, ind) => {
            if (ind === itemIndex) {
                return {
                    ...val,
                    startTime: value
                }
            } else return val
        });
        setOpeningHours(modifiedArray);
    }

    function changeEndingTime(itemIndex, value) {
        let modifiedArray = openingHours.map((val, ind) => {
            if (ind === itemIndex) {
                return {
                    ...val,
                    endTime: value
                }
            } else {
                return val
            }
        });
        setOpeningHours(modifiedArray);
    }

    const submitCreateStore = async () => {
        setIsLoading(true);
        try {
            let hasEmptyValues = false;
            let formTag = document.getElementById('storeForm');
            let data = new FormData(formTag);
            data.append('isHalal', isHalal)

            for (const pair of data.entries()) {
                if (!isPhysical && showSubType) {
                    data.delete('area')
                    data.delete('zone')
                }
                if (pair[1] === '') {
                    hasEmptyValues = true;
                    break;
                }
            }

            if (hasEmptyValues) {
                toast({
                    description: 'All fields are required!',
                    status: 'error',
                    position: 'top-right',
                    duration: 2000
                })
            } else {
                if (showSubType) {
                    data.append('isPhysical', isPhysical)
                    data.set('types', SubTypeVal)
                }
                data.append('opening_hours', JSON.stringify(openingHours));
                let res = await POST(`/admin/store`, data, {
                    authorization: `Bearer ${user?.verificationToken}`
                });
                if (res.status === 200) {
                    toast({
                        description: 'Store Created!',
                        status: 'success',
                        position: 'top-right',
                        duration: 2000
                    })
                    navigate('/super-dashboard/partners')
                } else {
                    toast({
                        description: res.message,
                        status: 'error',
                        position: 'top-right',
                        duration: 2000
                    })
                }
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2000
            })
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (user) {
            (async () => {
                setIsLoading(true);
                let storesRes = await GET(`/admin/roles/vendor1`);
                if (storesRes.status === 200) {
                    setStoreTypes(storesRes.data);
                    setSubTypesData(storesRes.data.filter(val => { if (val?.name === 'Halal Web') { return val?.childs } }))
                } else {
                    toast({
                        description: storesRes.message,
                        status: 'error',
                        position: 'top-right',
                        duration: 3000
                    })
                }
                setIsLoading(false);
            })();
        }
    }, [user])

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mr="60px">
                    <Heading fontSize="24px" fontWeight="600">Create New Store</Heading>
                </Stack>
                <form id="storeForm">
                    <Stack className="regwarp" gap={2} mr="60px !important">
                        <Stack direction="row" justifyContent="space-between">
                            <Stack w="49%">
                                <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                    Store Name
                                </FormLabel>
                                <Box className="Ifiled">
                                    <Input name="store_name" type="text" placeholder="Enter here" />
                                </Box>
                            </Stack>
                            <Stack w="49%">
                                <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                    Email Address
                                </FormLabel>
                                <Box className="Ifiled">
                                    <Input name="email" type="text" placeholder="Enter here" />
                                </Box>
                            </Stack>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack w="49%">
                                <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                    Website Url
                                </FormLabel>
                                <Box className="Ifiled">
                                    <Input name="store_url" type="text" placeholder="Enter here" />
                                </Box>
                            </Stack>
                            <Stack w="49%">
                                <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                    Phone
                                </FormLabel>
                                <Box className="Ifiled">
                                    <PhoneInput
                                        name="number"
                                        style={{
                                            width: '100%',
                                            background: "#EAEAEA",
                                            borderRadius: "10px",
                                            height: "auto",
                                            padding: "6px 30px"
                                        }}
                                        containerStyle={{ background: "none", border: "none" }}
                                        inputStyle={{ background: "none", border: "none" }}
                                        dropdownStyle={{ background: "none", border: "none" }}
                                        id="phone-input"
                                        defaultCountry="ca"
                                        inputClass="custom-phone-input"
                                    />
                                </Box>
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} alignItems={'end'} gap={4}>
                            <Stack w="49%">
                                <FormLabel
                                    fontWeight={500}
                                    m={0}
                                    color={'#000'}
                                    fontSize={'14px'}
                                >
                                    Opening Hours
                                </FormLabel>
                                <Button
                                    w="100%"
                                    border="1px solid #BBBBBB"
                                    onClick={onOpen}
                                >
                                    <Text w="100%" display={'flex'} alignItems={'center'} gap={'5px'} justifyContent={'center'}>
                                        <Icon as={BsPlusCircle} />
                                        Add</Text>
                                </Button>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" w="49%">
                                <Stack w="49%">
                                    <FormLabel
                                        fontWeight={500}
                                        m={0}
                                        color={'#000'}
                                        fontSize={'14px'}
                                    >
                                        Upload Banner
                                    </FormLabel>
                                    <Box className="Ifiled">
                                        <Input onChange={e => setInputFileVal({
                                            ...inputFileVal,
                                            banner: e.target.files[0]
                                        })} name='banner' className='filetype' type="file" placeholder="Enter here" _after={{ content: `"${inputFileVal.banner.name}"`, fontSize: '12px' }} />
                                    </Box>
                                </Stack>
                                <Stack w="49%">
                                    <FormLabel
                                        fontWeight={500}
                                        m={0}
                                        color={'#000'}
                                        fontSize={'14px'}
                                    >
                                        Upload Logo
                                    </FormLabel>
                                    <Box className="Ifiled">
                                        <Input onChange={e => setInputFileVal({
                                            ...inputFileVal,
                                            logo: e.target.files[0]
                                        })} name='logo' className='filetype' type="file" placeholder="Enter here" _after={{ content: `"${inputFileVal.logo.name}"`, fontSize: '12px' }} />
                                    </Box>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack direction="row" justifyContent={'space-between'}>
                            <Stack w="49%">
                                <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                    Select Store Type
                                </FormLabel>
                                <Box className="Ifiled" position={'relative'}>
                                    <Spinner display={isLoading ? 'initial' : 'none'} color="primaryGreen.100" size={'sm'} position={'absolute'} right="5px" zIndex={1} top="15px" />
                                    <Select onChange={e => { e.target.value === 'Halal Web' ? setShowSubType(true) : setShowSubType(false) }} disabled={isLoading} name='types' textTransform={'capitalize'}>
                                        <option style={{ display: 'none' }}>Select Store</option>
                                        {
                                            storeTypes.length > 0 &&
                                            storeTypes.map(val => <option key={val?._id} value={val?.name} textTransform="capitalize">{val?.name}</option>)
                                        }
                                    </Select>
                                </Box>
                            </Stack>
                            <Stack w="49%" direction="row">
                                <Box flex={1}>
                                    <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
                                        Halal Vendor
                                    </FormLabel>
                                    <Checkbox colorScheme='yellow' onChange={e => setIsHalal(e.target.checked)}></Checkbox>
                                </Box>
                                <Box flex={2} visibility={isHalal ? 'visible' : 'hidden'}>
                                    <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                        Upload Halal Certificates
                                    </FormLabel>
                                    <Box className="Ifiled">
                                        <Input
                                            onChange={e => setInputFileVal({
                                                ...inputFileVal,
                                                certificate: e.target.files[0]
                                            })}
                                            name='document'
                                            className='filetype'
                                            type="file"
                                            placeholder="Enter here"
                                            _after={{ content: `"${inputFileVal.certificate.name}"` }}
                                        />
                                    </Box>
                                </Box>
                            </Stack>
                        </Stack>
                        {
                            showSubType ?
                                <Stack direction="row" justifyContent={'space-between'}>
                                    <Stack w="49%">
                                        <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                            Select Sub-Store Type
                                        </FormLabel>
                                        <Box className="Ifiled" position={'relative'}>
                                            <Spinner display={isLoading ? 'initial' : 'none'} color="primaryGreen.100" size={'sm'} position={'absolute'} right="5px" zIndex={1} top="15px" />
                                            <Select onChange={e => setSubTypeVal(e.target.value)} disabled={isLoading} name='types' textTransform={'capitalize'}>
                                                <option style={{ display: 'none' }}>Select Sub-Store</option>
                                                {
                                                    subTypesData?.length > 0 &&
                                                    subTypesData[0]['childs']?.map(val => <option key={val?._id} value={val?.name} textTransform="capitalize">{val?.name}</option>)
                                                }
                                            </Select>
                                        </Box>
                                    </Stack>
                                    <Stack w="49%" direction="row">
                                        <Box flex={1}>
                                            <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
                                                Physical Operations?
                                            </FormLabel>
                                            <Checkbox colorScheme='yellow' onChange={e => setIsPhysical(e.target.checked)}></Checkbox>
                                        </Box>
                                    </Stack>
                                </Stack>
                                : null
                        }
                        <Stack direction="row" justifyContent={'space-between'}>
                            <Stack w="49%">
                                <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                    Address
                                </FormLabel>
                                <Box className="Ifiled">
                                    <Input name="area" type='text' as={Autocomplete}
                                        apiKey={'AIzaSyCGhPs5s8YsqLZuNgu8mbq24-DTXrMlMJU'}
                                    />
                                </Box>
                            </Stack>
                            <Stack w="49%">
                                <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                    Postal Code
                                </FormLabel>
                                <Box className="Ifiled">
                                    <Input name='zone' type="text" placeholder="Enter here" />
                                </Box>
                            </Stack>
                        </Stack>

                        <Stack className="btn">
                            <Button
                                onClick={
                                    // () => navigate('/dashboard')
                                    submitCreateStore
                                }
                                isLoading={isLoading}
                                padding={'10px 50px'}
                                bgImage={yellowBtnTexture}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                bg={'#FFC00F'}
                                color={'primaryGreen.100'}
                                fontWeight={'500'}
                                w={'fit-content'}
                                border={'2px solid'}
                                textTransform={'uppercase'} borderColor="primaryGreen.100"
                            >
                                <Text as={'span'}>Submit</Text>
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Stack >
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader borderBottom={'1px solid'} borderColor={'primaryGreen.100'}>
                        <Heading textAlign={'left'} fontSize={'20px'}>Opening Hours</Heading>
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        <Stack direction={'row'} alignItems={'center'} fontWeight={'600'} my={'20px'}>
                            <Text flex="1">Week Days</Text>
                            <Text flex="1" textAlign={'center'}>Start Time</Text>
                            <Text flex="1" textAlign={'center'}>End Time</Text>
                        </Stack>
                        {
                            openingHours.map((val, ind) =>
                                <Stack key={val.day} direction={'row'} alignItems={'center'} mb="8px !important">
                                    <Text flex={'1'}>
                                        <Checkbox colorScheme='yellow' defaultChecked={val?.on} onChange={e => changeOnStatus(ind, e.target.checked)} mr="5px" transform="translateY(2px)"></Checkbox>
                                        {val.day}:
                                    </Text>
                                    <Text flex={'1'}><Input onChange={e => changeStartingTime(ind, e.target.value)} type='time' defaultValue={val.startTime} value={val.startTime} /></Text>
                                    <Text flex={'1'}><Input onChange={e => changeEndingTime(ind, e.target.value)} type='time' defaultValue={val.endTime} value={val.endTime} /></Text>
                                </Stack>
                            )
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}