import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import StoreWrap from '../../../components/Dashboard/Store/StoreWrap';
import { Stack } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'

export default function SinglePartner() {
    return (
        <Stack
            backgroundImage={shapeb}
            overflowY="hidden"
        >
            <MainDashboard type={'admin'}>
                <StoreWrap />
            </MainDashboard>
        </Stack>
    );
}
