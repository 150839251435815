import {
  Stack,
  Text,
  Box,
  Image,
  Heading,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Icon,
  Flex,
  useToast,
  InputGroup,
  InputRightElement,
  InputLeftAddon,
} from '@chakra-ui/react';
import { Container } from '@chakra-ui/react/dist/chakra-ui-react.cjs';
// Components
import Header from '../../components/Website/Header/header';
import Footer from '../../components/Website/Footer/Footer';
// Images
import about1 from '../../assets/images/website/aboutUs/about1.png';
import mobileapp from '../../assets/images/website/home/aimhalal-app.png';
import card1 from '../../assets/images/website/home/aimhalal_card_1.png';
import card2 from '../../assets/images/website/home/aimhalal_card_2.png';
import card3 from '../../assets/images/website/home/aimhalal_card_3.png';
import rule1 from '../../assets/images/website/home/aimhalal_eligibility.png';
import rule2 from '../../assets/images/website/home/aimhalal_video_content.png';
import rule3 from '../../assets/images/website/home/aimhalal_originality.png';
import rule4 from '../../assets/images/website/home/aimhalal_video_quality.png';
import rule5 from '../../assets/images/website/home/aimhalal_posting.png';
import rule6 from '../../assets/images/website/home/aimhalal_submission.png';
import rule7 from '../../assets/images/website/home/aimhalal_deadline.png';
import rule8 from '../../assets/images/website/home/aimhalal_videosharing.png';
import canadaFlag from '../../assets/images/website/home/aimhalal_canada_flag.png'

import BlackOver from '../../assets/images/icon/black.png';
import { useEffect, useRef } from 'react';
import ContestBanner from '../../components/Website/Home/contestBanner';
import { useState } from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import { CriteriaCard } from '../../components/Website/Home/ContestCriteriariteriaCard';
import { RuleItem } from '../../components/Website/Home/RuleItem';
import { POST } from '../../utilities/ApiProvider';

const ContestPage = () => {
  const toast = useToast();
  const [openRuleId, setOpenRuleId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isemailVerified, setIsEmailVerified] = useState(false);
  const debounceTimeoutRef = useRef(null);
  const criteria = [
    {
      image: card1,
      title: 'Creativity (25%)',
      description: 'How engaging and creative is your video?',
      color: 'primaryGreen.100',
    },
    {
      image: card2,
      title: 'Originality and Exclusivity (25%)',
      description:
        'Content must be original and exclusively recorded for AimHalal.',
      color: 'primaryGreen.100',
    },
    {
      image: card3,
      title: 'Community Engagement (50%)',
      description: 'Number of likes, comments, and shares on your post.',
      color: 'primaryGreen.100',
    },
  ];

  const rules = [
    {
      title: 'Eligibility:',
      content:
        'Open to residents of Canada only. Participants must download the AimHalal App and follow us on Instagram. The email used to register with the App must be used in the entry form as well.',
      image: rule1,
    },
    {
      title: 'Video Content:',
      content: `Record up to 30 - 60 sec videos showing how you integrate halal into your life. Examples:
    - Shopping for halal products
    - Preparing or enjoying halal food
    - Supporting local halal businesses
    - Other aspects of everyday life related to halal (fashion, lifestyle, values).
    
    Video must be in a portrait format.`,
      image: rule2,
    },
    {
      title: 'Originality:',
      content:
        'All submissions must be original content created by the participant. Videos may not have been previously submitted, distributed, or aired or have won any other award in any other competition. You MUST own the complete, transferable rights to ALL elements of the submission. This includes but is not limited to text, audio, video, and images.',
      image: rule3,
    },
    {
      title: 'Video Quantity:',
      content:
        'Only one video per submission. Multiple submissions are welcomed.',
      image: rule4,
    },
    {
      title: 'Posting:',
      content: `After you submit the video to the contest, you can post the video on your social media if you want but it is not required. If you choose to post please tag @AimHalal and use the hashtags #HalalinMyLife #AimHalalVideoContest.`,
      image: rule5,
    },

    {
      title: 'Submission:',
      content: 'Submit the video using the online form below.',
      image: rule6,
    },
    {
      title: 'Deadline:',
      content:
        'All entries must be submitted by 11:59 PM EST on September 30, 2024.',
      image: rule7,
    },
    {
      title: 'Video Sharing',
      content:
        'Once your video is submitted and shortlisted, it will be uploaded on our social media so please follow us to stay updated. One of the selection criteria is community engagement so please try to send the maximum traffic to your video to increase your chances of winning. We will be reviewing video engagement until October 10th 2024.',
      image: rule8,
    },
  ];

  // Function to toggle rule details
  const toggleRule = ruleId => {
    setOpenRuleId(openRuleId === ruleId ? null : ruleId);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const handleClick = () => {
    const element = document.getElementById('contest_form');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // async function onSubmit(event) {
  //   event.preventDefault();
  //   setIsLoading(true);

  //   // Maximum file size in bytes (250MB)
  //   const MAX_FILE_SIZE = 250 * 1024 * 1024;

  //   console.log('Form submitted');
  //   const formData = new FormData(event.target);
  //   console.log('Val', formData.get('agreed'));

  //   // Check if agreed is true
  //   if (!formData.get('agreed')) {
  //     setIsLoading(false);
  //     toast({
  //       description: 'You must agree to the contest rules',
  //       status: 'error',
  //       position: 'top-right',
  //       duration: 2500,
  //     });
  //     return;
  //   }

  //   // Get the phone number and prepend +1
  //   const phoneNumber = formData.get('phone');
  //   if (phoneNumber) {
  //       formData.set('phone', `+1${phoneNumber}`);
  //   }

  //   // Get the video file
  //   const videoFile = formData.get('videoUrl');

  //   // Check if the file exceeds the size limit
  //   if (videoFile && videoFile.size > MAX_FILE_SIZE) {
  //     toast({
  //       description:
  //         'File size exceeds the 250MB limit. Please upload a smaller video.',
  //       status: 'error',
  //       position: 'top-right',
  //       duration: 3000,
  //     });
  //     setIsLoading(false); // Stop loading if the file is too large
  //     return;
  //   }
  //   try {
  //     let res = await POST('/contest', formData);
  //     if (res.status === 200) {
  //       setIsLoading(false);
  //       toast({
  //         description: res.message || 'Contest submission successful!',
  //         status: 'success',
  //         position: 'top-right',
  //         duration: 2500,
  //       });
  //       formData.reset();
  //     } else {
  //       setIsLoading(false);
  //       toast({
  //         description: res.message,
  //         status: 'error',
  //         position: 'top-right',
  //         duration: 2500,
  //       });
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     toast({
  //       description: 'Failed to submit contest submission',
  //       status: 'error',
  //       position: 'top-right',
  //       duration: 2500,
  //     });
  //     console.log(error);
  //   }
  // }

  async function onSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    // Maximum file size in bytes (250MB)
    const MAX_FILE_SIZE = 250 * 1024 * 1024;

    console.log('Form submitted');
    const formData = new FormData(event.target);
    console.log('Val', formData.get('agreed'));

    // Check if agreed is true
    if (!formData.get('agreed')) {
        setIsLoading(false);
        toast({
            description: 'You must agree to the contest rules',
            status: 'error',
            position: 'top-right',
            duration: 2500,
        });
        return;
    }

    // Get the phone number and prepend +1
    const phoneNumber = formData.get('phone');
    if (phoneNumber) {
        formData.set('phone', `+1 ${phoneNumber}`);
    }

    // Get the video file
    const videoFile = formData.get('videoUrl');

    // Check if the file exceeds the size limit
    if (videoFile && videoFile.size > MAX_FILE_SIZE) {
        toast({
            description: 'File size exceeds the 250MB limit. Please upload a smaller video.',
            status: 'error',
            position: 'top-right',
            duration: 3000,
        });
        setIsLoading(false); // Stop loading if the file is too large
        return;
    }

    // Add country to formData
    formData.append('country', 'Canada');

    try {

        // Convert FormData to an object for easier logging
        const data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });

        console.log({ data });
        let res = await POST('/contest', formData);
        if (res.status === 200) {
            setIsLoading(false);
            toast({
                description: res.message || 'Contest submission successful!',
                status: 'success',
                position: 'top-right',
                duration: 2500,
            });
            event.target.reset(); 
            const emptyemail = ""
            emailValidator(emptyemail)
        } else {
            setIsLoading(false);
            toast({
                description: res.message,
                status: 'error',
                position: 'top-right',
                duration: 2500,
            });
        }
    } catch (error) {
        setIsLoading(false);
        toast({
            description: 'Failed to submit contest submission',
            status: 'error',
            position: 'top-right',
            duration: 2500,
        });
        console.log(error);
    }
  }

  const emailValidator = async emailInput => {
    if (emailInput.trim() === "") {
      setIsEmailVerified(false);
      return;
    }
    try {
      let res = await POST('/contest/emails', {
        email: emailInput.toLowerCase(),
      });
      console.log(res);
      if (res.status === 200) {
        setIsEmailVerified(true);
      } else {
        setIsEmailVerified(false);
      }
    } catch (error) {
      console.error('Error validating email:', error);
    }
  };

  const handleEmailChange = e => {
    const email = e.target.value;

    // Clear previous debounce timer
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set a new debounce timer
    debounceTimeoutRef.current = setTimeout(() => {
      emailValidator(email);
    }, 500); // Delay of 500ms
  };


  return (
    <>
      <Header />

      <Box>
        <ContestBanner
          text1={'Halal in My Life'}
          text2={'Video Contest by AimHalal'}
          text3={'The winner will take away a cash prize of CAD 250!'}
          para1={'Welcome to the AimHalal Video Contest.'}
          para2={
            'To participate in this contest, please create and submit videos showing how you incorporate halal practices in your everyday life.'
          }
        />
      </Box>

      <Box
        bgImage={BlackOver}
        py={8}
        px={{ base: 4, md: 16 }}
        align="center"
        mx="auto"
        borderRadius="lg"
      >
        <Heading as="h2" size="xl" textAlign="center" color="green.700" mb={6}>
          Contest Rules:
        </Heading>
        <VStack spacing={2} align="stretch" maxW="1200px">
          {rules.map((rule, index) => (
            <RuleItem key={index} {...rule} />
          ))}
        </VStack>
        <Button
          color="white"
          bg="primaryGreen.100"
          _hover={{ bg: 'primaryYellow.200' }}
          size={{ base: 'sm', md: 'md', xl: 'lg' }}
          width="full"
          maxW={{ base: '400px' }}
          my="40px"
          sx={{
            transform: 'skew(-8deg)',
            transition: 'background-color 0.2s',
            '&:hover': {
              bg: 'primaryYellow.100',
            },
          }}
          onClick={handleClick} // Move onClick here, outside the sx prop
        >
          Let's Participate
        </Button>
      </Box>

      <Box py={10} bgImage={BlackOver}>
        <Heading as="h2" size="xl" textAlign="center" color="green.700" mb={8}>
          Winner Selection Criteria:
        </Heading>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          mb="30px"
          wrap="wrap"
          alignItems="center"
          justifyContent="center"
          gap="25px"
        >
          {criteria.map((item, index) => (
            <CriteriaCard key={index} {...item} />
          ))}
        </Flex>
      </Box>

      <Box
        bgImage={BlackOver}
        bg="primaryYellow.100"
        py="50px"
        mt="40px"
        maxW="98%"
        margin="0 auto"
        borderRadius="10px"
      >
        <Container maxW="1440px" align="center">
          <Heading
            color="primaryGreen.100"
            fontWeight="700"
            maxW={{ base: '100%', md: '80%', xl: '65%' }}
            fontSize={{ base: '24px', md: '30px', xl: '44px' }}
            textAlign="center"
            mb="30px"
          >
            The winner will be announced on our social media channels on
          </Heading>

          <Stack
            direction="column"
            align="center"
            justifyContent="center"
            bg="primaryGreen.100"
            borderRadius="50px"
            p="10px 20px"
            mx="auto"
            mb="20px"
            maxW={{ base: '90%', md: '70%', xl: '50%' }}
          >
            <Text
              fontSize={{ base: '16px', md: '22px', xl: '28px' }}
              color="primaryYellow.100"
              fontWeight="700"
            >
              October 14th, 2024.
            </Text>
          </Stack>

          <Text
            fontSize={{ base: '12px', md: '14px', xl: '16px' }}
            color="primaryGreen.100"
            textAlign="center"
          >
            Follow us on Facebook, LinkedIn and Instagram for more updates!
          </Text>
        </Container>
      </Box>

      <Box bgImage={BlackOver} py="50px">
        <Container maxW="1440px" id="contest_form">
          <Heading
            color="primaryGreen.100"
            fontWeight="700"
            fontSize={{ base: '24px', md: '30px', xl: '48px' }}
            textAlign="center"
            mb="20px"
          >
            Contest Entry Form
          </Heading>
          <Stack
            direction={{ base: 'column', xl: 'row' }}
            align="center"
            spacing="20px"
          >
            <Box w={{ base: '100%', xl: '50%' }} p="20px">
              <Text
                fontSize="18px"
                fontWeight="700"
                color="primaryGreen.100"
                mb="10px"
              >
                To enter please complete all the steps below:
              </Text>
              <Text
                mb="20px"
                fontSize={{ base: '14px', md: '16px' }}
                color="primaryGreen.100"
              >
                <Text
                  fontSize={{ base: '14px', md: '16px' }}
                  fontWeight="700"
                  color="primaryGreen.100"
                >
                  Step 1:
                </Text>{' '}
                Follow AimHalal on Instagram at{' '}
                <a href="https://instagram.com/aimhalal" target="_blank">
                  https://instagram.com/aimhalal
                </a>
                <br />
                <Text
                  fontSize={{ base: '14px', md: '16px' }}
                  fontWeight="700"
                  color="primaryGreen.100"
                >
                  Step 2:
                </Text>
                Download the AimHalal App at{' '}
                <a href="https://aimhalal.com" target="_blank">
                  https://aimhalal.com
                </a>
                <br />
                <Text
                  fontSize={{ base: '14px', md: '16px' }}
                  fontWeight="700"
                  color="primaryGreen.100"
                >
                  Step 3:
                </Text>{' '}
                Record a video according to the above guidelines and fill the
                form below to upload your video.
              </Text>

              <form onSubmit={onSubmit}>
                <FormControl mb="15px" isRequired>
                  <FormLabel color="primaryGreen.100">Name</FormLabel>
                  <Input name="name" placeholder="Enter full name" size="md" />
                </FormControl>

                <FormControl mb="15px" isRequired>
                  <FormLabel color="primaryGreen.100">Email</FormLabel>
                  <InputGroup size="md">
                    <Input
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      onChange={handleEmailChange}
                    />
                    {isemailVerified && (
                      <InputRightElement>
                        <Icon as={CheckIcon} color="green.500" />{' '}
                        {/* Green tick icon on verification */}
                      </InputRightElement>
                    )}
                  </InputGroup>
                </FormControl>

                {/* <FormControl mb="15px" isRequired>
                  <FormLabel color="primaryGreen.100">Phone Number</FormLabel>
                  <Input
                    type="tel"
                    placeholder="Enter phone number"
                    size="md"
                    name="phone"
                  />
                </FormControl> */}
                <FormControl mb="15px" isRequired>
                  <FormLabel color="primaryGreen.100">Phone Number</FormLabel>
                  <InputGroup size="md">
                    {/* <InputLeftAddon children="+1"  mr="10px"/> */}
                    <InputLeftAddon mr="10px">
                      <Image
                        src={canadaFlag}
                        alt="Canada flag"
                        boxSize="20px"
                        mr="5px"
                      />
                      +1
                    </InputLeftAddon>
                    <Input
                      type="tel"
                      placeholder="Enter phone number"
                      size="md"
                      name="phone"
                    />
                  </InputGroup>
                </FormControl>
                {/* <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing="10px"
                  mb="15px"
                > */}
                <FormControl isRequired  mb="15px">
                  <FormLabel color="primaryGreen.100">City</FormLabel>
                  <Input placeholder="Enter city" size="md" name="city" />
                </FormControl>

                  {/* <FormControl isRequired>
                    <FormLabel color="primaryGreen.100">State/Region</FormLabel>
                    <Input
                      placeholder="Enter state/region"
                      size="md"
                      name="state"
                    />
                  </FormControl> */}
                {/* </Stack> */}
                <FormControl isRequired  mb="15px">
                  <FormLabel color="primaryGreen.100">Zip Code</FormLabel>
                  <Input
                    placeholder="Enter state/region"
                    size="md"
                    name="zipcode"
                  />
                </FormControl>

                <FormControl mb="15px" isRequired>
                  <FormLabel color="primaryGreen.100">Upload Video</FormLabel>
                  <Input
                    type="file"
                    size="md"
                    name="videoUrl"
                    accept="video/*"
                    border="none"
                    pl="5px"
                  />
                  <Text color="primaryGreen.100" fontSize="14px" mt="5px">
                  We only accept video files in the following formats: MP4, AVI, MOV, and MKV. Each file must not exceed the maximum upload size of 250 MB.
                  </Text>
                </FormControl>

                <Checkbox
                  name="agreed"
                  colorScheme="green"
                  mb="15px"
                  isRequired
                  value={true}
                >
                  I have read and agreed to the terms and conditions.
                </Checkbox>

                <Button
                  isLoading={isLoading}
                  isDisabled={isLoading || !isemailVerified}
                  type="submit"
                  _disabled={{
                    bg: 'gray.300',
                    color: 'gray.500',
                    cursor: 'not-allowed',
                  }}
                  color="white"
                  bg="primaryYellow.100"
                  _hover={{ bg: 'primaryYellow.200' }}
                  size={{ base: 'sm', md: 'md', xl: 'lg' }}
                  width="full"
                  transform={'Skew(-8deg)'}
                >
                  Submit
                </Button>
              </form>
            </Box>

            <Box
              w={{ base: '100%', xl: '50%' }}
              display="flex"
              justifyContent="center"
            >
              <Image
                src={mobileapp}
                alt="Contest App Image"
                objectFit="contain"
                maxH={{ base: '400px', md: '500px', xl: '700px' }}
                display={{ base: 'none', md: 'block' }}
              />
            </Box>
          </Stack>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default ContestPage;
