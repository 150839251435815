import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Stack, FormLabel, Input, Heading, Button, Text, Grid, GridItem, Select, Box, Checkbox, Icon, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, useDisclosure, ModalFooter } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { BsPlusCircle } from 'react-icons/bs'
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from 'react-redux';
import { GET, POST } from '../../../utilities/ApiProvider.js';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router';

export default function AddMenuWrap() {

  let staticCatFetched = [];
  const toast = useToast();
  const navigate = useNavigate();
  const user = useSelector(state => state?.value);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const imgRef = useRef(null);
  const excelRef = useRef(null);
  const [isLoadingCat, setIsLoadingCat] = useState(false);
  const [uploadingBulk, setUploadingBulk] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isPickUp, setIsPickUp] = useState(false);
  const [isDelivery, setIsDelivery] = useState(false);
  const [menuImage, setMenuImage] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [pricing, setPricing] = useState({
    price: '',
    discount: '',
    disPerc: '',
    disVal: ''
  })
  const [incredients, setIncredients] = useState([]);
  const [categoryValue, setCategoryValue] = useState('');

  const getCategoryList = async () => {
    setIsLoadingCat(true);
    try {
      let res = await GET(`/admin/category?store_id=${user?.store?._id}`, { authorization: `Bearer ${user?.verificationToken}` });
      if (res.status === 200) {
        let revisedCatList = res?.data?.map(val => {
          return {
            label: val?.name,
            value: val?._id
          }
        })
        setCategoryList(revisedCatList);
      } else {
        toast({
          description: res?.message,
          status: 'error',
          position: 'top-right',
          duration: 3000
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
    setIsLoadingCat(false);
  }

  const addNewMenu = async () => {
    setIsLoading(true);
    try {
      let shouldProceedWithNetwork = true;
      let form = document.getElementById('newItemForm');
      let data = new FormData(form);

      let finalCatValue = selected.map(val => val?.value);

      data.append('category', JSON.stringify(finalCatValue));
      data.append('incredients', JSON.stringify(incredients));
      data.append('delivery_types', (isPickUp && isDelivery) ? 'pickup,delivery' : isPickUp ? 'pickup' : isDelivery ? 'delivery' : '')

      for (const pair of data.entries()) {
        if (pair[1] === '') {
          shouldProceedWithNetwork = false;
          let errorKey = pair[0];
          if (errorKey.includes('_')) {
            errorKey = `${errorKey.split('_')[0]} ${errorKey.split('_')[1]}`
          }
          toast({
            description: `${errorKey} is required!`,
            status: 'error',
            position: 'top-right',
            isClosable: true,
            duration: 4000
          })
          break;
        }
      }
      if (shouldProceedWithNetwork) {
        let res = await POST('/vendor/item', data, { authorization: `Bearer ${user?.verificationToken}` });
        if (res.status === 200) {
          toast({
            description: 'Item Added successfully!',
            status: 'success',
            position: 'top-right',
            duration: 3000
          });
          navigate('/dashboard/store');
        } else {
          toast({
            description: res?.message,
            status: 'error',
            position: 'top-right',
            duration: 3000
          });
        }
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
    setIsLoading(false);
  }

  const addCategory = async () => {
    setIsLoading(true);
    try {
      if (categoryValue !== '') {
        let res = await POST('/admin/category', { name: categoryValue }, { authorization: `Bearer ${user?.verificationToken}` });
        if (res.status === 200) {
          toast({
            description: 'Category Added successfully!',
            status: 'success',
            position: 'top-right',
            duration: 3000
          });
          setCategoryValue('');
          getCategoryList();
          onClose();
        } else {
          toast({
            description: res?.message,
            status: 'error',
            position: 'top-right',
            duration: 3000
          });
        }
      } else {
        toast({
          description: 'Category cannot be Empty!',
          status: 'error',
          position: 'top-right',
          duration: 2500
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
    setIsLoading(false);
  }

  const removeIngredientItem = (index) => {
    let newArr = incredients.filter((v, i) => i !== index);
    setIncredients(newArr);
  }

  const updateIngredientVal = (index, identifier, value) => {
    const updatedIncredients = [...incredients];
    updatedIncredients[index][identifier] = identifier === 'price' ? Number(value) : value;
    setIncredients(updatedIncredients);
  }

  function isXLSXFile(file) {
    setExcelFile(file)
    // Get the file name
    const fileName = file.name;

    // Get the file extension
    const fileExtension = fileName.split('.').pop();

    // Check if the file extension is "xlsx" (case insensitive)
    return fileExtension.toLowerCase() === 'xlsx';
  }

  const excelFileHandler = async (file) => {
    setUploadingBulk(true);
    let isExcel = isXLSXFile(file);
    if (isExcel) {
      setExcelFile(file)
      let form = document.getElementById('excelSheetForm');
      let data = new FormData(form);
      try {
        let res = await POST('/vendor/item/bulk-import', data, { authorization: `Bearer ${user?.verificationToken}` });
        if (res.status === 200) {
          toast({
            description: 'Products uploaded!',
            status: 'success',
            position: 'top-right',
            duration: 2500
          });
        } else {
          toast({
            description: res?.message,
            status: 'error',
            position: 'top-right',
            duration: 3000
          });
        }
      } catch (err) {
        toast({
          description: err.message,
          status: 'error',
          position: 'top-right',
          duration: 2500
        });
      }
    } else {
      toast({
        description: 'Only excel file is acceptable',
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 2500
      })
    }
    setUploadingBulk(false);
  }

  useEffect(() => {
    if (user) getCategoryList();

    return () => { }
  }, [user]);

  useEffect(() => {
    setPricing({
      ...pricing,
      discount: '',
      disPerc: '',
      disVal: ''
    })
  }, [pricing.price]);

  useMemo(() => {
    let percentage = ((pricing.discount / pricing.price) * 100).toFixed(0);
    let difference = pricing.price - pricing.discount;

    setPricing({
      ...pricing,
      discount: pricing.discount,
      disPerc: percentage,
      disVal: difference
    })
  }, [pricing.discount]);

  return (
    <>
      <DashboardHeader />
      <Stack
        overflowY="auto"
        margin={'0 !important'}
        borderRadius={'30px 0 0 0'}
        py={10}
        px={6}
        bg={'#fff'}
        height={'100%'}
        backgroundImage={shapeb}
        bgPos={'-32px -100px'}
      >
        <Stack direction="row" justifyContent="space-between" mr="60px">
          <Heading fontSize="24px" fontWeight="600">Add New Item</Heading>
          <Box display={'flex'} alignItems={'center'} gap="20px">
            <Stack className='btn'>
              <Button
                onClick={() => onOpen()}
                isLoading={uploadingBulk}
                justifyContent={'center'}
                padding={'10px 20px'}
                bg={"primaryGreen.100"}
                bgImage={greenBtnTexture}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                color={'primaryYellow.100'}
                fontWeight={'400'}
                border={'2px solid'}
                borderColor={"primaryGreen.100"}
                textAlign={'left'}
                _hover={{
                  bg: "primaryGreen.100",
                  borderColor: "primaryGreen.100",
                  color: '#fff'
                }}
              >
                <Text as={'span'}>Add New Category</Text>
              </Button>
            </Stack>
            <Stack className='btn'>
              <Button
                onClick={() => excelRef?.current.click()}
                isLoading={uploadingBulk}
                justifyContent={'center'}
                padding={'10px 20px'}
                bg={"primaryGreen.100"}
                bgImage={greenBtnTexture}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                color={'primaryYellow.100'}
                fontWeight={'400'}
                border={'2px solid'}
                borderColor={"primaryGreen.100"}
                textAlign={'left'}
                _hover={{
                  bg: "primaryGreen.100",
                  borderColor: "primaryGreen.100",
                  color: '#fff'
                }}
              >
                <Text as={'span'}>Import Bulk Items</Text>
              </Button>
            </Stack>
            <form id="excelSheetForm">
              <Input onChange={e => excelFileHandler(e.target.files[0])} ref={excelRef} type='file' name="file" display={'none'} />
            </form>
          </Box>
        </Stack>
        <form id="newItemForm">
          <Grid
            templateColumns="repeat(12, 1fr)"
            gap={{ base: 2, sm: 0 }}
            alignItems={'flex-start'}
            justifyContent="space-between"
            mr="60px !important"
          >
            <GridItem colSpan={{ base: '4', md: '5' }}>
              <Stack className='addItemForm'>
                <FormLabel>Add New Item</FormLabel>
                <Input name="item" type='text' placeholder="Enter Here" />
                <FormLabel>Choose Category</FormLabel>
                <MultiSelect
                  hasSelectAll={false}
                  isLoading={isLoadingCat}
                  options={categoryList}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                />
                <FormLabel>Other</FormLabel>
                <Input name="other" type="text" placeholder="Enter Here" />
                <FormLabel>Price</FormLabel>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing="0" gap="40px">
                  <Box w="50%">
                    <Input onChange={e => setPricing({ ...pricing, price: Number(e.target.value) })} value={pricing.price} type='number' name="price" placeholder="C$0" />
                  </Box>
                  <Box w="50%" display="flex" justtifyContent="space-between">
                    <Checkbox value={isPickUp} onChange={e => setIsPickUp(e.target.checked)} w="50%">
                      <Text fontSize={'14px'} color={'#66707A'} fontWeight="500">
                        Pickup
                      </Text>
                    </Checkbox>
                    <Checkbox value={isDelivery} onChange={e => setIsDelivery(e.target.checked)} w="50%">
                      <Text fontSize={'14px'} color={'#66707A'} fontWeight="500">
                        Delivery
                      </Text>
                    </Checkbox>
                  </Box>
                </Stack>
                <Stack direction="row" gap="20px" spacing="0">
                  <FormLabel w="30%">Discount</FormLabel>
                  <FormLabel w="31%">Percentage</FormLabel>
                  <FormLabel w="31%">Discount Amount</FormLabel>
                </Stack>
                <Stack direction="row" gap="20px" spacing="0">
                  <Input onChange={e => setPricing({ ...pricing, discount: Number(e.target.value) })} value={pricing.discount} name="discount" type='number' placeholder="C$0" />
                  <Input onChange={e => setPricing({ ...pricing, disPerc: Number(e.target.value) })} value={pricing.disPerc} name="discount_percentage" type='number' placeholder="%" readOnly cursor={'not-allowed'} />
                  <Input onChange={e => setPricing({ ...pricing, disVal: Number(e.target.value) })} value={pricing.disVal} name='discounted_price' type='number' placeholder="Difference" readOnly cursor={'not-allowed'} />
                </Stack>
                <Stack mt="15px !important" direction="row" justifyContent="space-between" alignItems="center">
                  <FormLabel>Add-on</FormLabel>
                  <Button h="0" minW="0" p="0" color="#66707A" gap="10px" onClick={() => {
                    setIncredients(prev => [...prev, {
                      item: '',
                      price: 0
                    }
                    ])
                  }}>
                    <Icon as={BsPlusCircle} fontSize={'22px'} />
                    <Text fontSize="16px" color="#66707A">Add</Text>
                  </Button>
                </Stack>
                {
                  incredients.length > 0 &&
                  incredients.map((val, ind) =>
                    <Stack key={ind} direction="row" gap="20px" spacing="0">
                      <Input val={val?.item} onChange={e => updateIngredientVal(ind, 'item', e.target.value)} type='text' placeholder="Name" />
                      <Input val={val?.price} onChange={e => updateIngredientVal(ind, 'price', e.target.value)} type='number' placeholder="C$0" />
                      <Stack className='btn'>
                        <Button
                          onClick={() => removeIngredientItem(ind)}
                          justifyContent={'center'}
                          padding={'10px 20px'}
                          bg={"primaryGreen.100"}
                          color={'primaryYellow.100'}
                          fontWeight={'400'}
                          bgImage={greenBtnTexture}
                          bgPos={'center'}
                          bgSize={'cover'}
                          bgRepeat={'no-repeat'}
                          border={'2px solid'}
                          borderColor={"primaryGreen.100"}
                          textAlign={'left'}
                          _hover={{
                            bg: "primaryGreen.100",
                            borderColor: "primaryGreen.100",
                            color: '#fff'
                          }}
                        >
                          <Text as={'span'}>Remove</Text>
                        </Button>
                      </Stack>
                    </Stack>
                  )
                }
                <Input onChange={e => setMenuImage(e.target.files[0])} ref={imgRef} type='file' name="pictures" display={'none'} />
                <Stack className='btn' mt="60px !important">
                  <Button
                    isLoading={isLoading}
                    onClick={addNewMenu}
                    justifyContent={'center'}
                    padding={'10px 20px'}
                    bg={"primaryGreen.100"}
                    color={'primaryYellow.100'}
                    fontWeight={'400'}
                    bgImage={greenBtnTexture}
                    bgPos={'center'}
                    bgSize={'cover'}
                    bgRepeat={'no-repeat'}
                    border={'2px solid'}
                    borderColor={"primaryGreen.100"}
                    textAlign={'left'}
                    _hover={{
                      bg: "primaryGreen.100",
                      borderColor: "primaryGreen.100",
                      color: '#fff'
                    }}
                  >
                    <Text as={'span'}>Add Menu</Text>
                  </Button>
                </Stack>
              </Stack>
            </GridItem>
            <GridItem colSpan={{ base: '4', md: '3' }}>
            </GridItem>
            <GridItem colSpan={{ base: '4', md: '4' }}>
              <FormLabel mt="15px" fontWeight="600">Upload Menu Photo <sub>(330px x 174px)</sub></FormLabel>
              <Button
                onClick={() => imgRef?.current.click()}
                w="100%"
                h="115px"
                border="1px solid #BBBBBB"
              >
                <Text display="block" w="100%">
                  <Icon as={BsPlusCircle} display="block" margin="5px auto" />
                  {menuImage?.name ?? "Add"}</Text>
              </Button>
            </GridItem>
          </Grid>
        </form>
      </Stack >
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent
          bgSize="cover"
          bgPos="center"
          backgroundImage={shapeb}
          p="20px 40px">
          <ModalHeader fontWeight="600" fontSize={'24px'} textAlign="center">Add New Category</ModalHeader>
          <ModalBody>
            <FormLabel mt="20px !important" fontWeight="600">Category Name</FormLabel>
            <Box className="Ifiled">
              <Input
                type={'text'}
                placeholder="Enter here"
                value={categoryValue}
                onChange={e => setCategoryValue(e.target.value)}
              />
            </Box>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Stack className='btn'>
              <Button
                onClick={addCategory}
                justifyContent={'center'}
                isLoading={isLoading}
                padding={'10px 20px'}
                bg={"primaryGreen.100"}
                w="200px"
                color={'primaryYellow.100'}
                fontWeight={'400'}
                border={'2px solid'}
                bgImage={greenBtnTexture}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                borderColor={"primaryGreen.100"}
                textAlign={'left'}
                _hover={{
                  bg: "primaryGreen.100",
                  borderColor: "primaryGreen.100",
                  color: '#fff'
                }}
              >
                <Text as={'span'}>Submit</Text>
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal >
    </>
  )
}
