import { Box, Button, Heading, Image, Spinner, Stack, Text, useToast, Icon } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import DashboardHeader from '../../Header/DashboardHeader'
import { imgUrl } from '../../../utilities/config'
import { useNavigate, useParams } from 'react-router-dom'
import { DELETE, GET } from '../../../utilities/ApiProvider'
import shapeb from '../../../assets/images/icon/black.png';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { useSelector } from 'react-redux'
import { MdDeleteForever } from 'react-icons/md'

export default function SingleItemWrap() {

  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const toast = useToast();
  const user = useSelector(state => state?.value);

  const getSingleItemDetails = async () => {
    setIsLoading(true);
    try {
      let res = await GET(`/vendor/item/${params?.id}`);
      if (res.status === 200) {
        setData(res?.data);
      } else {
        toast({
          description: res.message,
          status: 'error',
          position: 'top-right',
          duration: 2500
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
    setIsLoading(false);
  }

  const removeItem = async () => {
    setIsLoading(true);
    try {
      let res = await DELETE(`/vendor/item/${params?.id}`, { Authorization: `Bearer ${user?.verificationToken}` });
      if (res.status === 200) {
        navigate('/dashboard/menu')
      } else {
        toast({
          description: res.message,
          status: 'error',
          position: 'top-right',
          duration: 2500
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
    setIsLoading(false);
  }

  const removeCatFromItem = async (catId) => {
    setIsLoading(true);
    try {
      let res = await DELETE(`/vendor/product/${params?.id}/category/${catId}`, { Authorization: `Bearer ${user?.verificationToken}` });
      if (res.status === 200) {
        getSingleItemDetails()
        toast({
          description: res.message,
          status: 'success',
          position: 'top-right',
          duration: 2500
        });
      } else {
        toast({
          description: res.message,
          status: 'error',
          position: 'top-right',
          duration: 2500
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (params) getSingleItemDetails();
  }, [params]); // Empty dependency array ensures that this effect runs only once after initial mount

  return (
    <>
      <DashboardHeader />
      <Stack
        overflowY="auto"
        margin={'0 !important'}
        borderRadius={'30px 0 0 0'}
        py={10}
        px={6}
        bg={'#fff'}
        height={'100%'}
        backgroundImage={shapeb}
        bgPos={'-32px -100px'}
      >
        <Stack direction="row" justifyContent="space-between" mr="60px" mb="40px !important">
          <Heading fontSize="24px" fontWeight="600">Item Details {isLoading && <Spinner color='primaryGreen.100' />} </Heading>
          <Box display={'flex'} alignItems={'center'} gap="20px">
            <Stack className='btn'>
              <Button
                justifyContent={'center'}
                padding={'10px 20px'}
                bg={"primaryGreen.100"}
                onClick={() => navigate(`/dashboard/edit-menu/${params?.id}`)}
                bgImage={greenBtnTexture}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                color={'primaryYellow.100'}
                fontWeight={'400'}
                border={'2px solid'}
                borderColor={"primaryGreen.100"}
                textAlign={'left'}
                _hover={{
                  bg: "primaryGreen.100",
                  borderColor: "primaryGreen.100",
                  color: '#fff'
                }}
              >
                <Text as={'span'}>Edit</Text>
              </Button>
            </Stack>
            <Stack className='btn'>
              <Button
                justifyContent={'center'}
                padding={'10px 20px'}
                bg={"primaryGreen.100"}
                bgImage={greenBtnTexture}
                onClick={removeItem}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                color={'primaryYellow.100'}
                fontWeight={'400'}
                border={'2px solid'}
                borderColor={"primaryGreen.100"}
                textAlign={'left'}
                _hover={{
                  bg: "primaryGreen.100",
                  borderColor: "primaryGreen.100",
                  color: '#fff'
                }}
              >
                <Text as={'span'}>Remove</Text>
              </Button>
            </Stack>
          </Box>
        </Stack>
        <Stack direction="row" mr="60px !important">
          <Box flex="1">
            <Image src={`${imgUrl}/${data?.pictures[0]}`} w="365px" h="271px" objectFit={'cover'} />
          </Box>
          <Box flex="2">
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Heading fontSize={'28px'} fontWeight={'600'}>{data?.item}</Heading>
              {
                data?.discount === 0 ?
                  <Heading fontSize={'28px'} fontWeight={'600'}>C${data?.price}</Heading>
                  :
                  <Heading fontSize={'28px'} fontWeight={'600'}>C${data?.discounted_price} <sub style={{ fontSize: '14px' }}>({data?.price})</sub></Heading>
              }
            </Box>
            <Text fontSize={'16px'} fontWeight={'600'} color="primaryGreen.100">{data?.tags}</Text>
            <Text fontSize={'14px'} my="20px">{data?.description}</Text>
            <Stack direction={'row'}>
              {
                data?.category?.length > 0 &&
                data?.category?.map(val =>
                  <Box
                    key={val?.name}
                    position="relative"
                  >
                    <Button
                      _hover={{
                        bgColor: 'primaryGreen.100',
                        color: 'primaryYellow.100'
                      }}
                      _activeFocus={{
                        bgColor: '#E3E9ED',
                        color: '#434E58'
                      }}
                      _activeVisible={{
                        bgColor: '#E3E9ED',
                        color: '#434E58'
                      }}
                      bgColor={"#E3E9ED"}
                      p="5px 25px"
                      borderRadius="50px"
                      color={"#434E58"}
                      fontWeight="600"
                      m="0 10px 10px 0"
                      cursor={'unset'}
                    >
                      {val?.name ?? 'Loading'}
                    </Button>
                    <Box position="absolute" right="4px" bottom="4px" bgColor="red.700" color="#fff" w="fit-content" borderRadius="100%" display="flex" alignItems="center" justifyContent="center">
                      <Icon
                        fontSize="22px"
                        as={MdDeleteForever}
                        cursor="pointer"
                        color="#fff"
                        onClick={() => { removeCatFromItem(val?._id) }}
                      />
                    </Box>
                  </Box>
                )
              }
            </Stack>
            {
              data?.incredients?.length > 0 &&
              <Heading fontSize={'20px'} p="15px 0 15px 0" mt="15px" borderTop="1px solid #adadad">Add-on:</Heading>
            }
            {
              data?.incredients?.length > 0 ?
                data?.incredients?.map(val =>
                  <Box py="5px" key={val?._id}>
                    <Text fontSize="16px">{val?.item}: C${val?.price}</Text>
                  </Box>
                )
                :
                null
            }
          </Box>
        </Stack>
      </Stack >
    </>
  )
}
