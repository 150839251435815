import { Stack, Text, Box, Button, Image, useToast, FormLabel } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiMenuAlt1, HiOutlineClock } from 'react-icons/hi'
import { useState, useEffect } from 'react'
import evePic from '../../../assets/images/eventPic.svg';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { formatTime } from '../../../utilities/helper.js';
import { GET, PUT } from '../../../utilities/ApiProvider.js';
import { imgUrl } from '../../../utilities/config.js';
import { years } from '../../../utilities/helper.js';

export default function HelpWrap() {

    const params = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const user = useSelector(state => state?.value);

    const [isLoading, setIsLoading] = useState(false);
    const [eventData, setEventData] = useState(null);
    const [customDate, setCustomDate] = useState(null);
    const options = { day: "numeric", month: "short", year: "numeric" };

    const getEventData = async () => {
        try {
            let res = await GET(`/admin/posts/${params?.id}`, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                setEventData(res?.data);
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
    }

    const updateArcStatus = async () => {
        setIsLoading(true);
        try {
            let res = await PUT(`/admin/posts/${params?.id}`, { isArchive: !eventData?.isArchive }, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                toast({
                    description: 'Blog updated!',
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
                navigate('/super-dashboard/blogs');
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (user) getEventData();
    }, [user]);

    useEffect(() => {
        if (eventData) {
            let splittedDate = String(eventData?.createdAt).split('T')[0].split('-');
            setCustomDate(splittedDate)
        }
    }, [eventData]);

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Stack direction={'row'} alignItems={'center'} gap="20px" justifyContent={'space-between'} mr="60px !important">
                    <Box display={'flex'} alignItems={'center'} gap="20px">
                        {
                            customDate &&
                            <Text fontSize={'16px'} color={'#5A5D64'} mb="10px">Date: {years[customDate[1]]} {customDate[2]}, {customDate[0]}</Text>
                        }
                        {/* <Text fontSize={'16px'} color={'#5A5D64'}>Time: <Text as="span" color={'#000'} fontWeight={'500'}>{eventData?.time ? String(eventData?.time).split('T')[1].split('.')[0] : null}</Text></Text> */}
                    </Box>
                    <Stack className='btn'>
                        <Button
                            isLoading={isLoading}
                            onClick={updateArcStatus}
                            justifyContent={'center'}
                            padding={'10px 20px'}
                            bg={"primaryGreen.100"}
                            color={'primaryYellow.100'}
                            w={'180px'}
                            bgImage={greenBtnTexture}
                            bgPos={'center'}
                            bgSize={'cover'}
                            bgRepeat={'no-repeat'}
                            fontWeight={'400'}
                            border={'2px solid'}
                            borderColor={"primaryGreen.100"}
                            textAlign={'center'}
                            _hover={{
                                bg: "primaryGreen.100",
                                borderColor: "primaryGreen.100",
                                color: '#fff'
                            }}
                        >
                            <Text as={'span'}>{eventData?.isArchive ? 'Restore' : 'Archive'}</Text>
                        </Button>
                    </Stack>
                </Stack>
                <Stack direction={'row'} spacing={0} gap="40px">
                    <Box>
                        <FormLabel textAlign={'center'}>Title Picture</FormLabel>
                        <Image marginX={'auto'} lazy src={eventData?.picture ? `${imgUrl}/${eventData?.picture}` : evePic} w="450px" h="350px" overflow={'hidden'} objectFit={'contain'} borderRadius={'10px'} mb="10px" />
                    </Box>
                    <Box>
                        <FormLabel textAlign={'center'}>Cover Picture</FormLabel>
                        <Image marginX={'auto'} lazy src={eventData?.picture ? `${imgUrl}/${eventData?.cover}` : evePic} w="665px" h="400px" overflow={'hidden'} objectFit={'contain'} borderRadius={'10px'} mb="10px" />
                    </Box>
                </Stack>
                <Text fontWeight={'600'} fontSize={'22px'} mb="20px" maxW={'650px'}>{eventData?.name}</Text>
                <div dangerouslySetInnerHTML={{ __html: eventData?.description }} />
                {/* <Text fontSize={'16px'} color={'#5A5D64'} mb="10px" maxW={'650px'}>{eventData?.description}</Text> */}
            </Stack >
        </>
    )
}