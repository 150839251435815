import { Container, Stack, Grid, GridItem, Image, Box } from '@chakra-ui/react';
import React from 'react';
import RegisterForm from '../../../components/Auth/RegisterForm';
import R2img from '../../../assets/images/auth/r2.png';
import shapew from '../../../assets/images/icon/white.png';
import AuthPic from '../../../components/Repeat/AuthPic';

export default function Register() {
  return (
    <>
      <Stack
        w={'100%'}
        h={'100vh'}
        overflow={'hidden'}
        alignItems={'center'}
        justifyContent={'center'}
        position={'relative'}
        bg={'primaryGreen.100'}
        backgroundImage={shapew}
      >
        <Box display={{ base: 'none', xl: 'initial' }}>
          <AuthPic Picsrc={R2img} />
        </Box>
        <Container maxW={'8xl'}>
          <Grid
            templateColumns="repeat(12, 1fr)"
            gap={{ base: 2, sm: 0 }}
            alignItems={'center'}
          >
            <GridItem colSpan={{ base: '12', xl: '6' }}>
              <RegisterForm />
            </GridItem>
            <GridItem colSpan={{ base: '0', xl: '6' }}></GridItem>
          </Grid>
        </Container>
      </Stack>
    </>
  );
}
