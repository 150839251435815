import React, { useEffect } from 'react'
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Stack,
  Radio,
  Text,
  RadioGroup,
  Image,
  Icon,
  Grid,
  GridItem,
  useToast,
  Input
} from '@chakra-ui/react';
import { HiOutlineSearch } from 'react-icons/hi'
import { Link as ReactLink } from 'react-router-dom';
import shapeb from '../../../assets/images/icon/black.png'
import LoggedInPic from '../../DashNav/LoggedInPic.js'
import TopInfoBox from '../../Dashboard/Home/TopInfoBox.js'
import { useState } from 'react'
import HotDog from '../../../assets/images/icon/hotdog.svg'
import UserDetailsRow from './UserDetailsRow.js'
import DashboardHeader from '../../Header/DashboardHeader.js'
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { useSelector } from 'react-redux';
import { GET, POST } from '../../../utilities/ApiProvider.js';
import ReactPaginate from 'react-paginate';

export default function HomeWrap() {

  const toast = useToast();
  const user = useSelector(state => state?.value);
  const [selectedUsers, setSelectedUsers] = useState(true);
  const [PageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [isBlocking, setIsBlocking] = useState(false);
  const [usersData, setUsersData] = useState({
    totalUsers: 0,
    blockUsers: 0
  });
  const [activeUsers, setActiveUsers] = useState([]);
  const [blockedUsers, setBlockedUsers] = useState([]);

  const getUsers = async (searchVal) => {
    try {
      if (searchVal !== undefined) {
        let res = await GET(`/admin/customers?search=${searchVal}&page=${currentPage}`, { authorization: `Bearer ${user?.verificationToken}` });
        if (res.status === 200) {
          setUsersData({
            totalUsers: res?.data.totalUsers,
            blockUsers: res?.data.blockUsers
          });
          setActiveUsers(res?.data.activeUsers);
          setBlockedUsers(res?.data.blockedUsers)
          setPageCount(res?.data?.pagination?.totalPages)
        } else {
          toast({
            description: res.message,
            status: 'error',
            position: 'top-right',
            duration: 2500
          });
        }
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
  }

  const changeBlockUser = async (id, status) => {
    setIsBlocking(true);
    try {
      let res = await POST('/admin/customers/block', { user: id, isActive: !status }, { authorization: `Bearer ${user?.verificationToken}` });
      if (res.status === 200) {
        toast({
          description: res.message,
          status: 'success',
          position: 'top-right',
          duration: 2500
        });
        getUsers();
      } else {
        toast({
          description: res.message,
          status: 'error',
          position: 'top-right',
          duration: 2500
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
    setIsBlocking(false);
  }

  useEffect(() => {
    if (user) getUsers(searchValue);
  }, [user, currentPage, searchValue])

  return (
    <>
      <DashboardHeader />
      <Stack
        overflowY="auto"
        margin={'0 !important'}
        gap={8}
        borderRadius={'30px 0 0 0'}
        py={10}
        px={6}
        bg={'#fff'}
        height={'100%'}
        backgroundImage={shapeb}
        bgPos={'-32px -100px'}
      >
        <Stack direction={'row'} alignItems={'flex-start'} gap={'2%'} pr={'60px'}>
          <TopInfoBox title="Total Users" value={usersData?.totalUsers} />
          <TopInfoBox title="Blocked Users" value={usersData?.blockUsers} />
        </Stack>
        <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
          <Box>
            <Heading fontSize="24px" fontWeight="600">Users Activities</Heading>
            <Text fontSize="16px" mb="20px !important">Manage total users, orders and their progress.</Text>
            <Stack direction={'row'} alignItems={'center'} gap="20px">
              <Stack className='btn'>
                <Button
                  onClick={() => setSelectedUsers(true)}
                  justifyContent={'flex-start'}
                  padding={'10px 20px'}
                  bg={selectedUsers ? "primaryGreen.100" : '#D5DADE'}
                  color={selectedUsers ? 'primaryYellow.100' : '#000'}
                  fontWeight={'400'}
                  bgImage={selectedUsers ? greenBtnTexture : 'none'}
                  bgPos={'center'}
                  bgSize={'cover'}
                  bgRepeat={'no-repeat'}
                  border={'2px solid'}
                  borderColor={selectedUsers ? "primaryGreen.100" : '#D5DADE'}
                  textAlign={'left'}
                  _hover={{
                    bg: selectedUsers ? "primaryGreen.100" : '#D5DADE',
                    borderColor: selectedUsers ? "primaryGreen.100" : '#D5DADE',
                    color: selectedUsers ? '#fff' : '#000'
                  }}
                >
                  <Text as={'span'}>Active Users ({activeUsers.length})</Text>
                </Button>
              </Stack>
              <Stack className='btn'>
                <Button
                  onClick={() => setSelectedUsers(false)}
                  justifyContent={'flex-start'}
                  padding={'10px 20px'}
                  bg={!selectedUsers ? "primaryGreen.100" : '#D5DADE'}
                  color={!selectedUsers ? 'primaryYellow.100' : '#000'}
                  fontWeight={'400'}
                  border={'2px solid'}
                  bgImage={!selectedUsers ? greenBtnTexture : 'none'}
                  bgPos={'center'}
                  bgSize={'cover'}
                  bgRepeat={'no-repeat'}
                  borderColor={!selectedUsers ? "primaryGreen.100" : '#D5DADE'}
                  textAlign={'left'}
                  _hover={{
                    bg: !selectedUsers ? "primaryGreen.100" : '#D5DADE',
                    borderColor: !selectedUsers ? "primaryGreen.100" : '#D5DADE',
                    color: !selectedUsers ? '#fff' : '#000'
                  }}
                >
                  <Text as={'span'}>Blocked Users ({blockedUsers.length})</Text>
                </Button>
              </Stack>
            </Stack>
          </Box>
          <Box>
            <Box mb="15px" display="flex" justifyContent="flex-end">
              <ReactPaginate
                previousLabel={'<<'}
                nextLabel={'>>'}
                breakLabel={'...'}
                pageCount={PageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={(data) => { setCurrentPage(data.selected + 1) }}
                containerClassName={'pagination justify-content-center'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </Box>
            <Box w="367px" bgColor="#ECF1F6" borderRadius="50px" display="flex" alignItems="center" px="10px" gap="5px">
              <Icon as={HiOutlineSearch} fontSize="24px" color="#9CA4AB" />
              <Input onChange={e => setSearchValue(e.target.value)} placeholder="Search here..." border="0" fontSize="14px" />
            </Box>
          </Box>
        </Stack>
        <Stack borderRadius={'5px'} padding="15px 35px" boxShadow={'0px 0px 35px -15px #000'}>
          {
            selectedUsers ?
              activeUsers?.map(val =>
                <Stack
                  borderBottom={'1px solid #adadad'}
                  key={val?._id}
                  direction="row"
                  flexWrap="wrap"
                  spacing="0"
                  py={'10px'}
                  my="10px"
                  alignItems="center"
                >
                  <UserDetailsRow {...val} blockThem={true} changeBlockUser={changeBlockUser} isBlocking={isBlocking} />
                </Stack>
              )
              :
              blockedUsers?.map(val =>
                <Stack
                  borderBottom={'1px solid #adadad'}
                  key={val.name}
                  direction="row"
                  flexWrap="wrap"
                  spacing="0"
                  py={'10px'}
                  my="10px"
                  alignItems="center"
                >
                  <UserDetailsRow {...val} blockThem={false} changeBlockUser={changeBlockUser} isBlocking={isBlocking} />
                </Stack>
              )
          }
        </Stack>
      </Stack >
    </>
  )
}
