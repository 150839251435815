import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { GET } from '../utilities/ApiProvider'
import Cookies from 'js-cookie'

// Basic Global State
const initialState = {};

// State Updating Functions
const userReducer = createSlice({
    name: 'person',
    initialState,
    reducers: {
        loadUser: (state, action) => {
            state.value = action.payload;
            localStorage.setItem('aimHalalUser', JSON.stringify(action.payload));
        },
        loadUserName: (state, action) => {
            state.value.username = action.payload;
            localStorage.setItem('aimHalalUser', JSON.stringify(state.value));
        },
        loadStoreAvail: (state, action) => {
            state.value.isAvailable = action.payload;
            localStorage.setItem('aimHalalUser', JSON.stringify(state.value));
        },
        loadStoreLogo: (state, action) => {
            state.value.store.logo = action.payload;
            localStorage.setItem('aimHalalUser', JSON.stringify(state.value));
        },
        loadAdminPic: (state, action) => {
            state.value.picture = action.payload;
            localStorage.setItem('aimHalalUser', JSON.stringify(state.value));
        },
        loadStore: (state, action) => {
            state.value.store = action.payload;
            localStorage.setItem('aimHalalUser', JSON.stringify({
                ...state.value,
                ...action.payload
            }));
        },
        loadVendorData: (state, action) => {
            state.vendorDashboard = action.payload
        },
        logout: (state, action) => {
            localStorage.clear();
            Cookies.remove('user');
            state.value = null;
        }
    }
})

// Exporting All the State Updating Functions
export const { loadUser, logout, loadStore, loadVendorData, loadUserName, loadStoreAvail, loadStoreLogo, loadAdminPic } = userReducer.actions
export default userReducer.reducer
