function formatTime(dateString) {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const meridiem = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    // Add leading zero to minutes if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${hours}:${formattedMinutes} ${meridiem}`;
}

const years = {
    '1': 'Jan',
    '01': 'Jan',
    '2': 'Feb',
    '02': 'Feb',
    '3': 'Mar',
    '03': 'Mar',
    '4': 'Apr',
    '04': 'Apr',
    '5': 'May',
    '05': 'May',
    '6': 'June',
    '06': 'June',
    '7': 'July',
    '07': 'July',
    '8': 'Aug',
    '08': 'Aug',
    '9': 'Sep',
    '09': 'Sep',
    '10': 'OCt',
    '11': 'Nov',
    '12': 'Dec',
}

export { formatTime, years }
