import {
  Stack,
  Box,
  Image,
  Text,
  Heading,
  Icon,
  Checkbox,
  FormLabel,
  Input,
  Button,
  Link,
  OrderedList,
  ListItem,
  UnorderedList,
  Modal,
  useDisclosure,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Textarea,
  useToast,
} from '@chakra-ui/react';

import { BiArrowBack } from 'react-icons/bi';
import React, { useEffect, useState, useRef } from 'react';
import shapeb from '../../assets/images/icon/black.png';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { BsPlusCircle } from 'react-icons/bs';
import { POST } from '../../utilities/ApiProvider';
import { baseUrl } from '../../utilities/config';
import { loadStore, logout } from '../../reducers/useReducers';
import { useDispatch, useSelector } from 'react-redux';
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg'
import greenBtnTexture from '../../assets/images/greenBtnTexture.svg'
import AutoComplete from "react-google-autocomplete";
import halalLogo from '../../assets/images/halalLogo.PNG'
import { FaInfoCircle, FaTimes } from 'react-icons/fa'
import html2canvas from 'html2canvas'
import SignatureCanvas from "react-signature-canvas";

export default function CreateStoreform() {

  const toast = useToast();
  const dispatch = useDispatch();
  const signPadRef = useRef(null);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDisclaimerOpen, onOpen: onDisclaimerOpen, onClose: onDisclaimerClose } = useDisclosure();
  const user = useSelector(state => state.value);
  const [isLoading, setIsLoading] = useState(false);
  const [inputFileVal, setInputFileVal] = useState({
    banner: {
      name: 'Choose Image (1600px x 325px)'
    },
    logo: {
      name: 'Choose Image (180px x 180px)'
    }
  });
  const [inputFileValDisclaim, setInputFileValDisclaim] = useState({
    name: "Choose Image"
  });
  const [openingHours, setOpeningHours] = useState([
    {
      on: true,
      day: 'Monday',
      startTime: '09:00',
      endTime: '22:00'
    },
    {
      on: true,
      day: 'Tuesday',
      startTime: '09:00',
      endTime: '22:00'
    },
    {
      on: true,
      day: 'Wednesday',
      startTime: '09:00',
      endTime: '22:00'
    },
    {
      on: true,
      day: 'Thursday',
      startTime: '09:00',
      endTime: '22:00'
    },
    {
      on: true,
      day: 'Friday',
      startTime: '09:00',
      endTime: '22:00'
    },
    {
      on: true,
      day: 'Saturday',
      startTime: '09:00',
      endTime: '22:00'
    },
    {
      on: true,
      day: 'Sunday',
      startTime: '09:00',
      endTime: '22:00'
    }
  ]);
  const [isHalal, setIsHalal] = useState(false);

  function changeOnStatus(itemIndex, value) {
    let modifiedArray = openingHours.map((val, ind) => {
      if (ind === itemIndex) {
        return {
          ...val,
          on: value
        }
      } else return val
    });
    setOpeningHours(modifiedArray);
  }

  function changeStartingTime(itemIndex, value) {
    let modifiedArray = openingHours.map((val, ind) => {
      if (ind === itemIndex) {
        return {
          ...val,
          startTime: value
        }
      } else return val
    });
    setOpeningHours(modifiedArray);
  }

  function changeEndingTime(itemIndex, value) {
    let modifiedArray = openingHours.map((val, ind) => {
      if (ind === itemIndex) {
        return {
          ...val,
          endTime: value
        }
      } else {
        return val
      }
    });
    setOpeningHours(modifiedArray);
  }

  const submitCreateStore = async () => {
    setIsLoading(true);
    try {
      let hasEmptyValues = false;
      let formTag = document.getElementById('storeForm');
      let data = new FormData(formTag);

      for (const pair of data.entries()) {
        if (pair[1] === '') {
          hasEmptyValues = true;
          break;
        }
      }

      if (hasEmptyValues) {
        toast({
          description: 'All fields are required!',
          status: 'error',
          position: 'top-right',
          duration: 2000
        })
      } else {
        data.append('opening_hours', JSON.stringify(openingHours));
        data.append('isHalal', isHalal)

        if (isHalal === false) {
          data.delete('disclaimerform')
        }

        let res = await POST(`/vendor/store`, data, {
          authorization: `Bearer ${user?.verificationToken}`
        });
        if (res.status === 200) {
          dispatch(loadStore(res?.data));
          navigate('/dashboard')
        } else {
          toast({
            description: res.message,
            status: 'error',
            position: 'top-right',
            duration: 2000
          })
        }
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2000
      })
    }
    setIsLoading(false);
  }

  const [productInfo, setProductInfo] = useState([]);
  const [hidingEl, setHidingEl] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (hidingEl) {
      setIsDownloading(true);
      const element = document.getElementById("disclaimerForm");
      if (!element) {
        return;
      }
      html2canvas(element).then((canvas) => {
        let image = canvas.toDataURL("image/jpeg");
        const a = document.createElement('a');
        a.href = image;
        a.download = "disclaimerForm.jpeg";
        a.click();
      }).catch(err => {
        console.error('We cannot take the screenshot of your element at the moment =>', err.message)
      })
      setIsDownloading(false);
      setHidingEl(false);
    }
  }, [hidingEl])

  useEffect(() => {
    if (user) {
      if (user.role.name === 'vendor') {
        if (user.store === null) {
          navigate('/createnewstore')
          if (user?.document === "") {
            setIsHalal(false)
          } else {
            setIsHalal(true)
          }
        } else {
          navigate('/dashboard')
        }
      } else {
        navigate('/super-dashboard')
      }
    }
  }, [user])

  useEffect(() => {
    console.log(isHalal)
  }, [isHalal])

  return (
    <>
      <Stack
        w={'70%'}
        m={'0 auto'}
        bg={'#fff'}
        py={'50px'}
        px={'20px'}
        border={'5px solid'}
        borderColor="primaryYellow.100"
        borderRadius={'8px'}
        backgroundImage={shapeb}
      >
        <Stack mb={2}>
          <Box className="boxlink">
            <Link onClick={() => {
              dispatch(logout())
              navigate('/')
            }}>
              <Icon fontSize={'17px'} color={'#000'} as={BiArrowBack} />
            </Link>
          </Box>
        </Stack>
        <Box className="AH mh p1" pb={4}>
          <Heading as={'h3'}>Create New Store</Heading>
          <Text>Enter your Store details to create your account</Text>
        </Box>
        <form id="storeForm">
          <Stack className="regwarp" gap={2}>
            <Stack>
              <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                Store Name
              </FormLabel>
              <Box className="Ifiled">
                <Input name="store_name" type="text" placeholder="Enter here" />
              </Box>
            </Stack>
            <Stack>
              <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                Website Url
              </FormLabel>
              <Box className="Ifiled">
                <Input name="store_url" type="text" placeholder="Enter here" />
              </Box>
            </Stack>
            <Stack direction="row" gap="4">
              <Box flex={1}>
                <FormLabel
                  fontWeight={500}
                  m={0}
                  color={'#000'}
                  fontSize={'14px'}
                >
                  Opening Hours
                </FormLabel>
                <Button
                  w="100%"
                  border="1px solid #BBBBBB"
                  onClick={onOpen}
                >
                  <Text w="100%" display={'flex'} alignItems={'center'} gap={'5px'} justifyContent={'center'}>
                    <Icon as={BsPlusCircle} />
                    Add</Text>
                </Button>
              </Box>
              <Box flex={1}>
                <FormLabel
                  fontWeight={500}
                  m={0}
                  color={'#000'}
                  fontSize={'14px'}
                >
                  Halal Vendor
                </FormLabel>
                <Checkbox
                  colorScheme='yellow'
                  defaultChecked={isHalal}
                  value={isHalal}
                  isDisabled={(user && user?.document !== "") ? true : false}
                  onChange={e => setIsHalal(e.target.checked)}
                ></Checkbox>
              </Box>
            </Stack>
            {
              (user?.document === "" && isHalal) ?
                <Stack direction="row">
                  <Box flex="1">
                    <FormLabel
                      fontWeight={500}
                      m={0}
                      color={'#000'}
                      fontSize={'14px'}
                    >
                      Sign Disclaimer Form
                    </FormLabel>
                    <Button
                      w="100%"
                      border="1px solid #BBBBBB"
                      onClick={onDisclaimerOpen}
                    >
                      <Text w="100%" display={'flex'} alignItems={'center'} gap={'5px'} justifyContent={'center'}>
                        Open Form</Text>
                    </Button>
                  </Box>
                  <Box flex="1">
                    <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
                      Upload Disclaimer Form
                    </FormLabel>
                    <Box className="Ifiled">
                      <Input onChange={e => setInputFileValDisclaim(e.target.files[0])} name='disclaimerform' className='filetype' type="file" placeholder="Enter here" _after={{ content: `"${inputFileValDisclaim.name}"` }} />
                    </Box>
                  </Box>
                </Stack>
                : null
            }
            <Stack direction={'row'} alignItems={'end'} gap={4}>
              <Stack>
                <FormLabel
                  fontWeight={500}
                  m={0}
                  color={'#000'}
                  fontSize={'14px'}
                >
                  Upload Banner
                </FormLabel>
                <Box className="Ifiled">
                  <Input onChange={e => setInputFileVal({
                    ...inputFileVal,
                    banner: e.target.files[0]
                  })} name='banner' className='filetype' type="file" placeholder="Enter here" _after={{ content: `"${inputFileVal.banner.name}"`, fontSize: '12px' }} />
                </Box>
              </Stack>
              <Stack>
                <FormLabel
                  fontWeight={500}
                  m={0}
                  color={'#000'}
                  fontSize={'14px'}
                >
                  Upload Logo
                </FormLabel>
                <Box className="Ifiled">
                  <Input onChange={e => setInputFileVal({
                    ...inputFileVal,
                    logo: e.target.files[0]
                  })} name='logo' className='filetype' type="file" placeholder="Enter here" _after={{ content: `"${inputFileVal.logo.name}"`, fontSize: '12px' }} />
                </Box>
              </Stack>
            </Stack>
            <Stack>
              <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                Postal Code
              </FormLabel>
              <Box className="Ifiled">
                <Input name='zone' type="text" placeholder="Enter here" />
              </Box>
            </Stack>
            <Stack>
              <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                Address
              </FormLabel>
              <Box className="Ifiled">
                <Input name="area" type='text' as={AutoComplete}
                  apiKey={'AIzaSyCGhPs5s8YsqLZuNgu8mbq24-DTXrMlMJU'}
                />
              </Box>
            </Stack>

            <Stack className="btn">
              <Button
                onClick={
                  // () => navigate('/dashboard')
                  submitCreateStore
                }
                isLoading={isLoading}
                padding={'10px 50px'}
                bgImage={yellowBtnTexture}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                bg={'#FFC00F'}
                color={'primaryGreen.100'}
                fontWeight={'500'}
                w={'fit-content'}
                border={'2px solid'}
                textTransform={'uppercase'} borderColor="primaryGreen.100"
              >
                <Text as={'span'}>Submit</Text>
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom={'1px solid'} borderColor={'primaryGreen.100'}>
            <Heading textAlign={'left'} fontSize={'20px'}>Opening Hours</Heading>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Stack direction={'row'} alignItems={'center'} fontWeight={'600'} my={'20px'}>
              <Text flex="1">Week Days</Text>
              <Text flex="1" textAlign={'center'}>Start Time</Text>
              <Text flex="1" textAlign={'center'}>End Time</Text>
            </Stack>
            {
              openingHours.map((val, ind) =>
                <Stack key={val.day} direction={'row'} alignItems={'center'} mb="8px !important">
                  <Text flex={'1'}>
                    <Checkbox colorScheme='yellow' defaultChecked={val?.on} onChange={e => changeOnStatus(ind, e.target.checked)} mr="5px" transform="translateY(2px)"></Checkbox>
                    {val.day}:
                  </Text>
                  <Text flex={'1'}><Input onChange={e => changeStartingTime(ind, e.target.value)} type='time' defaultValue={val.startTime} value={val.startTime} /></Text>
                  <Text flex={'1'}><Input onChange={e => changeEndingTime(ind, e.target.value)} type='time' defaultValue={val.endTime} value={val.endTime} /></Text>
                </Stack>
              )
            }
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isDisclaimerOpen} onClose={onDisclaimerClose} size={'6xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody id="disclaimerForm" px="60px" pb="40px">
            <Stack direction={'row'} alignItems={'center'} justifyContent="center" gap="40px" w="100%" my={'20px'}>
              <Image src={halalLogo} w="150px" />
              <Heading textAlign="center">Application for Halal Certification for Manufacturers</Heading>
            </Stack>
            <Text color="red.700" fontWeight="600" fontSize="18px" py="15px">
              <Icon fontSize="20" m="0 5px -5px 0" as={FaInfoCircle} />
              Important: It is mandatory to complete all relevant fields in this form.
            </Text>
            <Heading fontSize="18px" fontWeight="600" py="15px">Business Information</Heading>
            <Stack direction="row" gap="0px" alignItems="flex-end">
              <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Business Name:</FormLabel>
              <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
            </Stack>
            <Stack direction="row" gap="0px" alignItems="flex-end">
              <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Legal Business Name if Different:</FormLabel>
              <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
            </Stack>
            <Stack direction="row" gap="0px" alignItems="flex-end">
              <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Business Street Address:</FormLabel>
              <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
            </Stack>
            <Stack direction="row" gap="0px" alignItems="flex-end">
              <Box w="33%" display={'flex'} alignItems={'flex-end'}>
                <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">City:</FormLabel>
                <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
              </Box>
              <Box w="33%" display={'flex'} alignItems={'flex-end'}>
                <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Province:</FormLabel>
                <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
              </Box>
              <Box w="33%" display={'flex'} alignItems={'flex-end'}>
                <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Postal Code:</FormLabel>
                <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
              </Box>
            </Stack>
            <Stack direction="row" gap="0px" alignItems="flex-end">
              <Box w="50%" display={'flex'} alignItems={'flex-end'}>
                <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Phone:</FormLabel>
                <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
              </Box>
              <Box w="50%" display={'flex'} alignItems={'flex-end'}>
                <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Email:</FormLabel>
                <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
              </Box>
            </Stack>
            <Heading fontSize="18px" fontWeight="600" p="30px 0 15px">Owner Information</Heading>
            <Stack direction="row" gap="0px" alignItems="flex-end">
              <Box w="33%" display={'flex'} alignItems={'flex-end'}>
                <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">First Name:</FormLabel>
                <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
              </Box>
              <Box w="33%" display={'flex'} alignItems={'flex-end'}>
                <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Middle Name:</FormLabel>
                <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
              </Box>
              <Box w="33%" display={'flex'} alignItems={'flex-end'}>
                <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Last Name:</FormLabel>
                <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
              </Box>
            </Stack>
            <Stack direction="row" gap="0px" alignItems="flex-end">
              <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Street Address:</FormLabel>
              <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
            </Stack>
            <Stack direction="row" gap="0px" alignItems="flex-end">
              <Box w="33%" display={'flex'} alignItems={'flex-end'}>
                <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">City:</FormLabel>
                <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
              </Box>
              <Box w="33%" display={'flex'} alignItems={'flex-end'}>
                <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Province:</FormLabel>
                <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
              </Box>
              <Box w="33%" display={'flex'} alignItems={'flex-end'}>
                <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Postal Code:</FormLabel>
                <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
              </Box>
            </Stack>
            <Heading fontSize="18px" fontWeight="600" p="30px 0 15px">Hours of Operations <sub>(No of Hours)</sub></Heading>
            <Stack direction="row" spacing={0} borderTop="1px solid" borderRight="1px solid" borderLeft={'1px solid'}>
              <Text py="10px" border="1px solid #000" w="15%" textAlign="center" fontWeight="600">Sunday</Text>
              <Text py="10px" border="1px solid #000" w="15%" textAlign="center" fontWeight="600">Monday</Text>
              <Text py="10px" border="1px solid #000" w="15%" textAlign="center" fontWeight="600">Tuesday</Text>
              <Text py="10px" border="1px solid #000" w="15%" textAlign="center" fontWeight="600">Wednesday</Text>
              <Text py="10px" border="1px solid #000" w="15%" textAlign="center" fontWeight="600">Thursday</Text>
              <Text py="10px" border="1px solid #000" w="15%" textAlign="center" fontWeight="600">Friday</Text>
              <Text py="10px" border="1px solid #000" w="15%" textAlign="center" fontWeight="600">Saturday</Text>
            </Stack>
            <Stack direction="row" spacing={0} borderBottom="1px solid" borderRight="1px solid" borderLeft={'1px solid'}>
              <Input textAlign="center" type="number" border="1px solid" _focusVisible={{ outline: 'none' }} borderColor="#000" borderRadius="0" />
              <Input textAlign="center" type="number" border="1px solid" _focusVisible={{ outline: 'none' }} borderColor="#000" borderRadius="0" />
              <Input textAlign="center" type="number" border="1px solid" _focusVisible={{ outline: 'none' }} borderColor="#000" borderRadius="0" />
              <Input textAlign="center" type="number" border="1px solid" _focusVisible={{ outline: 'none' }} borderColor="#000" borderRadius="0" />
              <Input textAlign="center" type="number" border="1px solid" _focusVisible={{ outline: 'none' }} borderColor="#000" borderRadius="0" />
              <Input textAlign="center" type="number" border="1px solid" _focusVisible={{ outline: 'none' }} borderColor="#000" borderRadius="0" />
              <Input textAlign="center" type="number" border="1px solid" _focusVisible={{ outline: 'none' }} borderColor="#000" borderRadius="0" />
            </Stack>
            <Stack direction="row" alignItem="center" p="30px 0 30px" justifyContent="space-between">
              <Heading fontSize="18px" fontWeight="600" lineHeignt="2.2">Product Information</Heading>
              <Stack className="btn" display={hidingEl ? 'none' : 'initial'}>
                <Button
                  onClick={() => {
                    setProductInfo(prev => [...prev, {}])
                  }}
                  padding={'10px 50px'}
                  bgImage={yellowBtnTexture}
                  bgPos={'center'}
                  bgSize={'cover'}
                  bgRepeat={'no-repeat'}
                  bg={'#FFC00F'}
                  color={'primaryGreen.100'}
                  fontWeight={'500'}
                  w={'fit-content'}
                  border={'2px solid'}
                  textTransform={'uppercase'} borderColor="primaryGreen.100"
                >
                  <Text as={'span'}>Add</Text>
                </Button>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={0} borderTop="1px solid" borderRight="1px solid" borderLeft={'1px solid'}>
              <Text py="10px" border="1px solid #000" w="25%" textAlign="center" fontWeight="600">Product Name</Text>
              <Text py="10px" border="1px solid #000" w="25%" textAlign="center" fontWeight="600">Materials</Text>
              <Text py="10px" border="1px solid #000" w="25%" textAlign="center" fontWeight="600">Supplied By:</Text>
              <Text py="10px" border="1px solid #000" w="25%" textAlign="center" fontWeight="600">Supplied To:</Text>
              {
                hidingEl === false ?
                  <Text border="1px solid #000" w="5%" textAlign="center" fontWeight="600"></Text>
                  : null
              }
            </Stack>
            {
              productInfo.length > 0 &&
              productInfo?.map((val, ind) =>
                <Stack key={ind} direction="row" spacing={0} borderTop="1px solid" borderRight="1px solid" borderLeft={'1px solid'}>
                  <Input w="25%" textAlign="center" type="text" border="1px solid" _focusVisible={{ outline: 'none' }} borderColor="#000" borderRadius="0" />
                  <Input w="25%" textAlign="center" type="text" border="1px solid" _focusVisible={{ outline: 'none' }} borderColor="#000" borderRadius="0" />
                  <Input w="25%" textAlign="center" type="text" border="1px solid" _focusVisible={{ outline: 'none' }} borderColor="#000" borderRadius="0" />
                  <Input w="25%" textAlign="center" type="text" border="1px solid" _focusVisible={{ outline: 'none' }} borderColor="#000" borderRadius="0" />
                  <Stack className="btn" w="5%" display={hidingEl ? 'none' : 'initial'}>
                    <Button
                      onClick={() => {
                        let newArr = productInfo.filter((val, index) => ind !== index);
                        setProductInfo(newArr);
                      }}
                      padding={'0px 10px'}
                      bg={'transparent'}
                      color={'primaryGreen.100'}
                      fontWeight={'500'}
                      textTransform={'uppercase'}
                    >
                      <Icon as={FaTimes} />
                    </Button>
                  </Stack>
                </Stack>
              )
            }
            <Stack p="30px 0 0 0" direction="row" gap="0px" alignItems="flex-end">
              <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Do you need a Halal Certificate for Export?</FormLabel>
              <Text>
                <Checkbox colorScheme='yellow' mr="5px" transform="translateY(2px)"></Checkbox>
                Yes
              </Text>
            </Stack>
            <Heading fontSize="18px" fontWeight="600" p="30px 0 15px 0">Additional Information</Heading>
            <Text color="red.700" fontWeight="600" fontSize="16px" pb="15px">
              <Icon fontSize="18" m="0 5px -5px 0" as={FaInfoCircle} />
              (Use this section to provide any additional information that you want BCMA to consider regarding your application)
            </Text>
            <Textarea resize="none" border="1px solid" borderColor="#000" borderRadius={0}></Textarea>
            <Heading fontSize="18px" fontWeight="600" p="15px 0 15px">Terms and Conditions</Heading>
            <FormLabel fontSize="15px" whiteSpace="nowrap" fontWeight={'500'}>Read the terms and conditions:</FormLabel>
            <OrderedList>
              <ListItem fontSize="15px" fontWeight="500" py="10px">You understand that this only an application for Halal Certification and it does not guarantee a Halal Certification
                until it is approved by the BC Muslim Association – Halal Inspection & Certification Canada (BCMA - HIC Canada).</ListItem>
              <ListItem fontSize="15px" fontWeight="500" py="10px">You authorize the BCMA - HIC Canada, where necessary at their sole discretion, to approach other recognized
                authorities and/or any supplier and/or manufacturer of any equipment and/or peripherals used by you to verify its
                conformity with the attached Certification Regulations set by the BCMA – HIC Canada.
              </ListItem>
              <ListItem fontSize="15px" fontWeight="500" py="10px">Once the application is approved, you understand and authorize BCMA – HIC Canada to do random ADHOC
                inspections on your premises throughout the term of the certificate.
              </ListItem>
              <ListItem fontSize="15px" fontWeight="500" py="10px">You have read and understood the BCMA – HIC Canada Regulations and agree to abide by it. The regulations
                outlined are binding once the application is approved.
              </ListItem>
              <ListItem fontSize="15px" fontWeight="500" py="10px">You agree to release BCMA – HIC Canada and its respective affiliates, officials, agents, employees, and permitted
                successors from any lawsuits</ListItem>
              <ListItem fontSize="15px" fontWeight="500" py="10px">You agree to pay the monthly fees for the duration of the contract and agree to BCMA – HIC Canada promoting
                your business on their online portals.</ListItem>
              <ListItem fontSize="15px" fontWeight="500" py="10px">You understand that the Halal Certificate is the property of BCMA – HIC Canada. It must NOT be photocopied or
                given to any other business without explicit written consent of BCMA – HIC Canada. The certificate must be
                returned to BCMA – HIC Canada at the termination your contract or when it expires, whichever comes first.
              </ListItem>
            </OrderedList>
            <Heading fontSize="18px" fontWeight="600" p="30px 0 15px 0">Required Documents</Heading>
            <FormLabel fontSize="15px" whiteSpace="nowrap" fontWeight={'500'}>Copies of the following documents must be submitted with this application:</FormLabel>
            <UnorderedList>
              <ListItem fontSize="15px" fontWeight="500" pt="10px">Current business licence, and</ListItem>
              <ListItem fontSize="15px" fontWeight="500" >Certificate of incorporation</ListItem>
            </UnorderedList>
            <Stack direction="row" gap="0px" py="20px">
              <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0" fontWeight="800">Declaration:</FormLabel>
              <FormLabel fontSize="15px" fontWeight={'500'}>I declare that the information I have provided in this application is truthful, complete and correct and
                knowing that it is of the same force effect as if made under oath.</FormLabel>
            </Stack>
            <Stack direction="row" gap="0px" alignItems="flex-end">
              <Box w="65%" display={'flex'} alignItems={'flex-end'}>
                <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Applicant Signature:</FormLabel>
                <Box display="flex" alignItems="flex-end" gap="20px">
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ className: hidingEl === true ? "signaturePadHide" : "signaturePad" }}
                    ref={signPadRef}
                  />
                  {
                    hidingEl === false ?
                      <Stack className="btn">
                        <Button
                          onClick={() => signPadRef?.current.clear()}
                          padding={'10px 10px'}
                          bgImage={yellowBtnTexture}
                          bgPos={'center'}
                          bgSize={'cover'}
                          bgRepeat={'no-repeat'}
                          bg={'#FFC00F'}
                          color={'primaryGreen.100'}
                          fontWeight={'500'}
                          border={'2px solid'}
                          textTransform={'uppercase'} borderColor="primaryGreen.100"
                        >
                          <Text as={'span'}>Clear</Text>
                        </Button>
                      </Stack>
                      : null
                  }
                </Box>
                {/* <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" /> */}
              </Box>
              <Box w="35%" display={'flex'} alignItems={'flex-end'}>
                <FormLabel fontSize="15px" whiteSpace="nowrap" mb="0">Date:</FormLabel>
                <Input type="text" border="none" borderBottom="1px solid #000" _focusVisible={{ outline: 'none', borderColor: '#000' }} borderRadius="0" />
              </Box>
            </Stack>
            <FormLabel w="fit-content" m="30px 0 15px 0" fontSize="16px" whiteSpace="nowrap" mb="0" fontWeight="600">All information provided in this form is protected under the Freedom of Information and Privacy Act.</FormLabel>
            <FormLabel fontSize="15px" whiteSpace="nowrap" fontWeight={'600'}>You can submit this application by:</FormLabel>
            <UnorderedList>
              <ListItem fontSize="15px" fontWeight="600">Mail: 12300 Blundell Rd Richmond, BC, V6W 1B3, or</ListItem>
              <ListItem fontSize="15px" fontWeight="600" >Email: info@halalbc.ca</ListItem>
            </UnorderedList>
            <Stack p="3px" m="40px 0 0 0" border="1px solid">
              <Text bgColor="#000" color="#fff" p="5px 10px">For Official Use Only – BCMA-HIC</Text>
              <Stack direction="row" gap="0px" alignItems="flex-end" spacing="0">
                <Box w="65%" display={'flex'} alignItems={'flex-end'}>
                  <FormLabel px="10px" m="0" h="40px" lineHeight="2.2" border="1px solid" borderColor="#000" fontSize="15px" whiteSpace="nowrap" mb="0">Applicant Received By:</FormLabel>
                  <Input border="1px solid" borderColor="#000" type="text" value="" _focusVisible={{ outline: 'none', borderColor: 'transparent' }} borderRadius="0" />
                </Box>
                <Box w="35%" display={'flex'} alignItems={'flex-end'}>
                  <FormLabel px="10px" m="0" h="40px" lineHeight="2.2" border="1px solid" borderColor="#000" fontSize="15px" whiteSpace="nowrap" mb="0">Date:</FormLabel>
                  <Input border="1px solid" borderColor="#000" type="text" value="" cursor={'not-allowed'} _focusVisible={{ outline: 'none', borderColor: 'transparent' }} borderRadius="0" />
                </Box>
              </Stack>
              <Stack spacing="0" direction="row" gap="0px" alignItems="flex-end">
                <FormLabel px="10px" m="0" h="40px" lineHeight="2.2" border="1px solid" borderColor="#000" fontSize="15px" whiteSpace="nowrap" mb="0">Inspection Completed:</FormLabel>
                <Input border="1px solid" borderColor="#000" type="text" value="" w="100%" _focusVisible={{ outline: 'none', borderColor: 'transparent' }} borderRadius="0" />
              </Stack>
              <Stack spacing="0" direction="row" gap="0px" alignItems="flex-end">
                <FormLabel w="23%" px="10px" m="0" h="40px" lineHeight="2.2" border="1px solid" borderColor="#000" fontSize="15px" whiteSpace="nowrap" mb="0">Inspection Result:</FormLabel>
                <Input border="1px solid" borderColor="#000" type="text" value="" w="100%" _focusVisible={{ outline: 'none', borderColor: 'transparent' }} borderRadius="0" />
              </Stack>
              <Stack spacing="0" direction="row" gap="0px" alignItems="flex-end">
                <Box w="64%" display={'flex'} alignItems={'flex-end'}>
                  <FormLabel w="100%" px="10px" m="0" h="40px" lineHeight="2.2" border="1px solid" borderColor="#000" fontSize="15px" whiteSpace="nowrap" mb="0">Reviewed By:</FormLabel>
                  <Input border="1px solid" borderColor="#000" type="text" value="" _focusVisible={{ outline: 'none', borderColor: 'transparent' }} borderRadius="0" />
                </Box>
                <Box display={'flex'} alignItems={'flex-end'}>
                  <FormLabel px="10px" m="0" h="40px" lineHeight="2.2" border="1px solid" borderColor="#000" fontSize="15px" whiteSpace="nowrap" mb="0">Approved:</FormLabel>
                  <Input border="1px solid" borderColor="#000" type="text" value="" _focusVisible={{ outline: 'none', borderColor: 'transparent' }} borderRadius="0" />
                </Box>
                <Box display={'flex'} alignItems={'flex-end'}>
                  <FormLabel px="10px" m="0" h="40px" lineHeight="2.2" border="1px solid" borderColor="#000" fontSize="15px" whiteSpace="nowrap" mb="0">Denied:</FormLabel>
                  <Input border="1px solid" borderColor="#000" type="text" value="" _focusVisible={{ outline: 'none', borderColor: 'transparent' }} borderRadius="0" />
                </Box>
                <Box display={'flex'} alignItems={'flex-end'}>
                  <FormLabel px="10px" m="0" h="40px" lineHeight="2.2" border="1px solid" borderColor="#000" fontSize="15px" whiteSpace="nowrap" mb="0">Interview Required:</FormLabel>
                  <Input border="1px solid" borderColor="#000" type="text" value="" _focusVisible={{ outline: 'none', borderColor: 'transparent' }} borderRadius="0" />
                </Box>
              </Stack>
              <Stack spacing="0" direction="row" gap="0px" alignItems="flex-end">
                <Box w="64.5%" display={'flex'} alignItems={'flex-end'}>
                  <FormLabel w="41%" px="10px" m="0" h="40px" lineHeight="2.2" border="1px solid" borderColor="#000" fontSize="15px" whiteSpace="nowrap" mb="0">Certificate No:</FormLabel>
                  <Input border="1px solid" borderColor="#000" type="text" value="" _focusVisible={{ outline: 'none', borderColor: 'transparent' }} borderRadius="0" />
                </Box>
                <Box w="36%" display={'flex'} alignItems={'flex-end'}>
                  <FormLabel px="10px" m="0" h="40px" lineHeight="2.2" border="1px solid" borderColor="#000" fontSize="15px" whiteSpace="nowrap" mb="0">Date Picked Up:</FormLabel>
                  <Input border="1px solid" borderColor="#000" type="text" value="" _focusVisible={{ outline: 'none', borderColor: 'transparent' }} borderRadius="0" />
                </Box>
              </Stack>
            </Stack>
            {
              hidingEl === false ?
                <Stack direction="row" alignItems="center" justifyContent="center">
                  <Stack className="btn" mt="30px">
                    <Button
                      onClick={() => setHidingEl(true)}
                      padding={'10px 50px'}
                      bgImage={yellowBtnTexture}
                      isLoading={isDownloading}
                      bgPos={'center'}
                      bgSize={'cover'}
                      bgRepeat={'no-repeat'}
                      bg={'#FFC00F'}
                      color={'primaryGreen.100'}
                      fontWeight={'500'}
                      w={'fit-content'}
                      border={'2px solid'}
                      textTransform={'uppercase'} borderColor="primaryGreen.100"
                    >
                      <Text as={'span'}>Save</Text>
                    </Button>
                  </Stack>
                </Stack>
                : null
            }
          </ModalBody>
        </ModalContent>
      </Modal >
    </>
  );
}
