import { Text, Box, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import yellowBtnTexture from '../../../assets/images/yellowBtnTexture.svg';

const SignCard = ({ img, text1, text2, main, btn, href }) => {
    return (
        <>
            <Box
                position={"relative"}
                backgroundImage={img}
                backgroundPosition={"center"}
                backgroundSize={"cover"}
                backgroundRepeat={"no-repeat"}
                width={{ base: "90%", md: "645px", lg: "48%", xl: "645px", "2xl": "685px" }}
                height={{ base: "250px", xl: "350px" }}
                borderRadius={'11px'}>
                <Box
                    position={"absolute"}
                    width={"100%"}
                    height={"100%"}
                    bg={"linear-gradient(246deg, rgba(255, 255, 255, 0.00) 0.94%, rgba(3, 8, 31, 0.19) 51.68%, rgba(3, 8, 31, 0.89) 87.9%)"}
                    borderRadius={'11px'}
                    display={"flex"}
                    alignItems={"end"}
                    p={{ base: "20px 5px", xl: "30px 50px", "2xl": "30px 50px" }}>
                    <Box
                        display={{ base: 'none', xl: 'initial' }}
                        position={"absolute"} top={"0"} left={"60px"}
                        width={"250px"} textAlign={"center"} bg={"#28551A"} p={"15px 0px"} borderRadius={"0px 0px 12px 12px"}>
                        <Text fontWeight={"700"} color={"primaryYellow.100"}>{text1}</Text>
                    </Box>
                    <Box>
                        <Text fontSize={{ base: "14px", xl: "17px" }} fontWeight={"500"} color={"primaryYellow.100"}>{text2}</Text>
                        <Text fontSize={{ base: "18px", md: "40px" }} fontWeight={"700"} color={"#FFF"} mb={"15px"}>{main}</Text>
                        <Button
                            bgImage={yellowBtnTexture}
                            bgPos={'center'}
                            bgSize={'cover'}
                            bgRepeat={'no-repeat'}
                            border={"2px solid #FAD700"}
                            transform={"Skew(-8deg)"}
                            as={Link}
                            to={href}
                            _hover={{
                                background: "none",
                                color: "primaryYellow.100"
                            }}>
                            <Text as={"span"} fontSize={{ base: '14px', xl: '16px' }} transform={"Skew(8deg)"}>{btn}</Text>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default SignCard;