import React from 'react'
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Stack,
  Radio,
  Text,
  RadioGroup,
  Image,
  useToast,
  Link,
  Grid,
  GridItem
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import shapeb from '../../../assets/images/icon/black.png'
import LoggedInPic from '../../DashNav/LoggedInPic.js'
import TopInfoBox from '../../Dashboard/Home/TopInfoBox.js'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import foodPic from '../../../assets/images/auth/r6.jpg'
import DashboardHeader from '../../Header/DashboardHeader.js'
import StoreItemBox from '../../Dashboard/Store/StoreItemBox.js';
import RegisteredPartnersSingleItem from '../Home/RegisteredPartners/RegisteredPartnersSingleItem.js'
import { GET } from '../../../utilities/ApiProvider.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Your Sales',
    },
  },
};

export default function HomeWrap() {

  const [popularItems, setPopularItems] = useState([
    {
      pictures: [foodPic],
      name: 'The Halal Guys',
    },
    {
      pictures: [foodPic],
      name: 'The Halal Guys',
    },
    {
      pictures: [foodPic],
      name: 'The Halal Guys',
    },
    {
      pictures: [foodPic],
      name: 'The Halal Guys',
    },
    {
      pictures: [foodPic],
      name: 'The Halal Guys',
    },
    {
      pictures: [foodPic],
      name: 'The Halal Guys',
    },
    {
      pictures: [foodPic],
      name: 'The Halal Guys',
    }
  ]);

  const [weeklyOrder, setWeeklyOrder] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [labels, setLabels] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
  const [weeklyOrder2, setWeeklyOrder2] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [labels2, setLabels2] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
  const toast = useToast();
  const navigate = useNavigate();
  const user = useSelector(state => state?.value);
  const [homeData, setHomeData] = useState({});

  const getHomeData = async () => {
    try {
      let res = await GET(`/admin/analytics`, { authorization: `Bearer ${user?.verificationToken}` });
      if (res.status === 200) {
        setHomeData(res?.data);
      } else {
        toast({
          description: res.message,
          status: 'error',
          position: 'top-right',
          duration: 2500
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
  }

  useEffect(() => {
    if (user) getHomeData();
  }, [user])

  useEffect(() => {
    let label = [];
    let orders = [];
    homeData?.analytics?.storeMap?.length > 0 &&
      homeData?.analytics?.storeMap.map(val => {
        label.push(val?.label)
        orders.push(val?.orders)
      });
    setLabels(label);
    setWeeklyOrder(orders);

    let label2 = [];
    let orders2 = [];
    homeData?.analytics?.userMap?.length > 0 &&
      homeData?.analytics?.userMap.map(val => {
        label2.push(val?.label)
        orders2.push(val?.orders)
      });
    setLabels2(label);
    setWeeklyOrder2(orders);
  }, [homeData])

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: weeklyOrder?.length > 0 ? weeklyOrder : [],
        backgroundColor: '#28551A',
      }
    ],
  };
  
  const data2 = {
    labels2,
    datasets: [
      {
        label: '',
        data: weeklyOrder2?.length > 0 ? weeklyOrder2 : [],
        backgroundColor: '#28551A',
      }
    ],
  };
  return (
    <>
      <DashboardHeader />
      <Stack
        overflowY="auto"
        margin={'0 !important'}
        gap={8}
        borderRadius={'30px 0 0 0'}
        py={10}
        px={6}
        bg={'#fff'}
        height={'100%'}
        backgroundImage={shapeb}
        bgPos={'-32px -100px'}
      >
        <Heading fontSize="24px" fontWeight="600">Analytics</Heading>
        <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'} gap={'2%'} pr={'60px'}>
          <TopInfoBox title="Active Store" value={homeData?.activeStores} />
          <TopInfoBox title="Users" value={homeData?.totalUsers} />
          <TopInfoBox title="Partners" value={homeData?.totalPartners} />
          <TopInfoBox title="Total Revenue" value={homeData?.totalRevenue} />
        </Stack>
        <Stack direction="row" mr="60px !important" justifyContent="space-between">
          <Box w="48%">
            <Heading fontSize="24px" fontWeight="600">Store Statistics</Heading>
            <Text fontSize="14px" mb="15px !important" fontWeight="500">Lorem Ipsum Dolor Sit</Text>
            <Bar options={options} data={data} />
          </Box>
          <Box w="48%">
            <Heading fontSize="24px" fontWeight="600">Users Map</Heading>
            <Text fontSize="14px" mb="15px !important" fontWeight="500">Lorem Ipsum Dolor Sit</Text>
            <Bar options={options} data={data} />
          </Box>
        </Stack>
        <Heading fontSize="24px" fontWeight="600">Best Stores</Heading>
        <Stack direction="row" gap="1.5%" flexWrap="wrap" mr="60px !Important">
          {
            homeData?.bestStores?.length > 0 &&
            homeData?.bestStores.map(val => <RegisteredPartnersSingleItem {...val} w='24%' badgeSize="13px" />)
          }
        </Stack>
      </Stack>
    </>
  )
}
