import { Stack, Heading, Icon, Text, Box, Button, Image, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, useToast, Textarea, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, FormLabel, ModalFooter, useDisclosure, } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiMenuAlt1, HiOutlineClock } from 'react-icons/hi'
import { useEffect, useState } from 'react'
import foodImg from '../../../assets/images/auth/r6.jpg';
import { useSelector } from 'react-redux';
import { GET, POST, PUT } from '../../../utilities/ApiProvider.js';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'

export default function HelpWrap() {

    const toast = useToast();
    const user = useSelector(state => state?.value);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isEditingFaq, setIsEditingFaq] = useState(false);
    const [updatingFaqServer, setUpdatingFaqServer] = useState(false);
    const [newFaq, setNewFaq] = useState({
        question: '',
        answer: ''
    });
    const [faqData, setFaqData] = useState([
        {
            heading: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            heading: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            heading: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
        {
            heading: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        },
    ]);

    const getFaq = async () => {
        try {
            let res = await GET('/admin/faq', { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                setFaqData(res?.data);
            } else {
                toast({
                    description: res?.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 3000
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
    }

    const updateFaq = async () => {
        setUpdatingFaqServer(true);
        try {
            let res = await PUT('/admin/faq/update', { data: faqData }, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                toast({
                    description: 'FAQ updated',
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
                setFaqData(res?.data);
                setIsEditingFaq(false);
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setUpdatingFaqServer(false);
    }

    const addNewFaq = async () => {
        setUpdatingFaqServer(true);
        if (newFaq.question === '' || newFaq.answer === '') {
            toast({
                description: 'Question or Answer cannot be empty!',
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        } else {
            try {
                let res = await POST('/admin/faq', { data: [newFaq] }, { authorization: `Bearer ${user?.verificationToken}` });
                if (res.status === 200) {
                    toast({
                        description: 'FAQ updated',
                        status: 'success',
                        position: 'top-right',
                        duration: 2500
                    });
                    setNewFaq({
                        question: '',
                        answer: ''
                    })
                    getFaq();
                    onClose();
                } else {
                    toast({
                        description: res.message,
                        status: 'error',
                        position: 'top-right',
                        duration: 3000
                    });
                }
            } catch (err) {
                toast({
                    description: err.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        }
        setUpdatingFaqServer(false);
    }

    const updateFaqValHandler = (id, identifier, value) => {
        let newFaqData = faqData.map(val => {
            if (val?._id === id) {
                return {
                    ...val,
                    [identifier]: value
                }
            } else {
                return val
            }
        });
        setFaqData(newFaqData);
    }

    const updateFaqHandler = () => {
        if (!isEditingFaq) {
            setIsEditingFaq(true)
        } else {
            updateFaq()
        }
    }

    useEffect(() => {
        if (user) getFaq();

        return () => { }
    }, []);

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'} mr="60px !important">
                    <Box>
                        <Heading fontSize="24px" fontWeight="600">Help & Contact</Heading>
                        <Text fontSize="16px" mb="20px !important">Frequently Asked Questions</Text>
                    </Box>
                    {
                        user?.role?.name === 'superadmin' &&
                        <Stack direction={'row'} gap="20px">
                            <Stack className='btn'>
                                <Button
                                    onClick={onOpen}
                                    justifyContent={'flex-start'}
                                    padding={'10px 20px'}
                                    w="fit-content"
                                    bg={"primaryGreen.100"}
                                    color={'primaryYellow.100'}
                                    bgImage={greenBtnTexture}
                                    bgPos={'center'}
                                    bgSize={'cover'}
                                    bgRepeat={'no-repeat'}
                                    fontWeight={'400'}
                                    border={'2px solid'}
                                    borderColor={"primaryGreen.100"}
                                    textAlign={'left'}
                                    _hover={{
                                        bg: "primaryGreen.100",
                                        borderColor: "primaryGreen.100",
                                        color: '#fff'
                                    }}
                                >
                                    <Text as={'span'}>Add FAQ</Text>
                                </Button>
                            </Stack>
                            <Stack className='btn'>
                                <Button
                                    isLoading={updatingFaqServer}
                                    onClick={updateFaqHandler}
                                    justifyContent={'flex-start'}
                                    padding={'10px 20px'}
                                    w="fit-content"
                                    bg={"primaryGreen.100"}
                                    color={'primaryYellow.100'}
                                    bgImage={greenBtnTexture}
                                    bgPos={'center'}
                                    bgSize={'cover'}
                                    bgRepeat={'no-repeat'}
                                    fontWeight={'400'}
                                    border={'2px solid'}
                                    borderColor={"primaryGreen.100"}
                                    textAlign={'left'}
                                    _hover={{
                                        bg: "primaryGreen.100",
                                        borderColor: "primaryGreen.100",
                                        color: '#fff'
                                    }}
                                >
                                    <Text as={'span'}>{isEditingFaq ? 'Save' : 'Edit'}</Text>
                                </Button>
                            </Stack>
                        </Stack>
                    }
                </Stack>
                {
                    isEditingFaq ?
                        <Stack w="735px">
                            {
                                faqData?.length > 0 &&
                                faqData?.map(val =>
                                    <Stack val={val?._id}>
                                        <Input disabled={updatingFaqServer} value={val?.question} onChange={e => updateFaqValHandler(val?._id, 'question', e.target.value)} />
                                        <Textarea disabled={updatingFaqServer} value={val?.answer} onChange={e => updateFaqValHandler(val?._id, 'answer', e.target.value)} ></Textarea>
                                    </Stack>
                                )
                            }
                        </Stack>
                        :
                        <Stack w="735px">
                            <Accordion allowMultiple>
                                {
                                    faqData?.length > 0 &&
                                    faqData?.map(val =>
                                        <AccordionItem
                                            key={val?._id}
                                            bgColor="#EAEAEA"
                                            borderRadius="8px"
                                            mb="20px"
                                        >
                                            <h2>
                                                <AccordionButton fontWeight="600" fontSize="16px">
                                                    <Box as="span" flex='1' textAlign='left'>
                                                        {val?.question}
                                                    </Box>
                                                    <AccordionIcon fontSize="25px" />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4} fontSize="14px">
                                                {val?.answer}
                                            </AccordionPanel>
                                        </AccordionItem>)
                                }
                            </Accordion>
                        </Stack>
                }
            </Stack>
            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent
                    bgSize="cover"
                    bgPos="center"
                    backgroundImage={shapeb}
                    p="20px 40px">
                    <ModalHeader fontWeight="600" fontSize={'24px'} textAlign="center">Add New FAQ</ModalHeader>
                    <ModalBody>
                        <FormLabel mt="20px !important" fontWeight="600">Question</FormLabel>
                        <Input
                            type={'text'}
                            placeholder="Enter here"
                            onChange={e => setNewFaq({
                                ...newFaq,
                                question: e.target.value
                            })}
                            value={newFaq.question}
                        />
                        <FormLabel mt="20px !important" fontWeight="600">Answer</FormLabel>
                        <Textarea
                            placeholder='Enter here'
                            onChange={e => setNewFaq({
                                ...newFaq,
                                answer: e.target.value
                            })}
                            value={newFaq.answer}></Textarea>
                    </ModalBody>
                    <ModalFooter justifyContent="center">
                        <Stack className='btn'>
                            <Button
                                onClick={addNewFaq}
                                justifyContent={'center'}
                                isLoading={updatingFaqServer}
                                padding={'10px 20px'}
                                bg={"primaryGreen.100"}
                                w="200px"
                                color={'primaryYellow.100'}
                                fontWeight={'400'}
                                border={'2px solid'}
                                bgImage={greenBtnTexture}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                borderColor={"primaryGreen.100"}
                                textAlign={'left'}
                                _hover={{
                                    bg: "primaryGreen.100",
                                    borderColor: "primaryGreen.100",
                                    color: '#fff'
                                }}
                            >
                                <Text as={'span'}>Add</Text>
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}