import { Stack, Heading, Icon, Text, Box, Button, Image, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Grid, GridItem, useToast, } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiMenuAlt1, HiOutlineClock } from 'react-icons/hi'
import { useEffect, useState } from 'react'
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import eventPic from '../../../assets/images/eventPic.svg'
import { useNavigate } from 'react-router';
import { DELETE, GET, PUT } from '../../../utilities/ApiProvider.js';
import { useSelector } from 'react-redux';
import { imgUrl } from '../../../utilities/config.js';
import { years } from '../../../utilities/helper.js';

export default function HelpWrap() {

    const navigate = useNavigate();
    const toast = useToast();
    const user = useSelector(state => state?.value);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowingArchive, setIsShowingArchive] = useState(false);
    const [eventsList, setEventsList] = useState({
        curEvents: [],
        arcEvents: []
    });
    const [newListOfBlogs, setNewListOfBlogs] = useState({
        past: [],
        upcoming: []
    });
    const options = { day: "numeric", month: "short", year: "numeric" };

    const getEvents = async () => {
        try {
            let res = await GET(`/admin/adminPosts?type=blog`, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                // setNewListOfBlogs(res?.data);
                let curEvents = [];
                let arcEvents = [];
                res?.data?.blogs?.filter(val => {
                    if (val?.isArchive === true) {
                        arcEvents.push(val);
                    } else {
                        curEvents.push(val);
                    }
                });
                setEventsList({
                    curEvents,
                    arcEvents
                })
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
    }

    const updateArcStatus = async (id, archiveValue) => {
        setIsLoading(true);
        try {
            let res = await PUT(`/admin/posts/${id}`, { isArchive: !archiveValue }, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                toast({
                    description: 'Blog updated!',
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
                getEvents();
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    const deleteBlog = async (id) => {
        setIsLoading(true);
        try {
            let res = await DELETE(`/admin/posts/${id}`, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                toast({
                    description: 'Blog Deleted!',
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
                getEvents();
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (user) getEvents();
    }, [user])

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                {
                    isShowingArchive ?
                        <Stack>
                            <Box display={'flex'} alignItems={'center'} gap="15px">
                                <Stack className='btn'>
                                    <Button
                                        onClick={() => setIsShowingArchive(false)}
                                        justifyContent={'center'}
                                        padding={'10px 20px'}
                                        bg={!isShowingArchive ? "primaryGreen.100" : '#D5DADE'}
                                        color={!isShowingArchive ? 'primaryYellow.100' : '#000'}
                                        fontWeight={'400'}
                                        bgImage={!isShowingArchive ? greenBtnTexture : 'none'}
                                        bgPos={'center'}
                                        bgSize={'cover'}
                                        bgRepeat={'no-repeat'}
                                        w="120px"
                                        border={'2px solid'}
                                        borderColor={!isShowingArchive ? "primaryGreen.100" : '#D5DADE'}
                                        textAlign={'center'}
                                        _hover={{
                                            bg: !isShowingArchive ? "primaryGreen.100" : '#D5DADE',
                                            borderColor: !isShowingArchive ? "primaryGreen.100" : '#D5DADE',
                                            color: !isShowingArchive ? '#fff' : '#000'
                                        }}
                                    >
                                        <Text as={'span'}>Blog</Text>
                                    </Button>
                                </Stack>
                                <Stack className='btn'>
                                    <Button
                                        onClick={() => setIsShowingArchive(true)}
                                        justifyContent={'center'}
                                        padding={'10px 20px'}
                                        bg={isShowingArchive ? "primaryGreen.100" : '#D5DADE'}
                                        color={isShowingArchive ? 'primaryYellow.100' : '#000'}
                                        fontWeight={'400'}
                                        w="120px"
                                        bgImage={isShowingArchive ? greenBtnTexture : 'none'}
                                        bgPos={'center'}
                                        bgSize={'cover'}
                                        bgRepeat={'no-repeat'}
                                        border={'2px solid'}
                                        borderColor={isShowingArchive ? "primaryGreen.100" : '#D5DADE'}
                                        textAlign={'center'}
                                        _hover={{
                                            bg: isShowingArchive ? "primaryGreen.100" : '#D5DADE',
                                            borderColor: isShowingArchive ? "primaryGreen.100" : '#D5DADE',
                                            color: isShowingArchive ? '#fff' : '#000'
                                        }}
                                    >
                                        <Text as={'span'}>Archive</Text>
                                    </Button>
                                </Stack>
                            </Box>
                            <Stack my="25px !important">
                                <Heading fontSize={'22px'}>Archive</Heading>
                                <Text fontSize={'16px'} color={'#5A5D64'}>You've {eventsList?.arcEvents.length > 0 ? eventsList?.arcEvents.length : 0} archive events</Text>
                            </Stack>
                            <Stack spacing={0} direction={'row'} alignItems={'center'} gap="2%" flexWrap={'wrap'} mr="60px !important">
                                {
                                    eventsList?.arcEvents.length > 0 &&
                                    eventsList?.arcEvents.map(val => {
                                        let splittedDate = String(val?.createdAt).split('T')[0].split('-');
                                        return (
                                            <Box key={val?._id} mb="40px !important" w="31%">
                                                <Image lazy src={val?.picture ? `${imgUrl}/${val?.picture}` : eventPic} w="550px" h="244px" overflow={'hidden'} objectFit={'cover'} borderRadius={'10px'} mb="10px" />
                                                <Text fontSize={'16px'} color={'#5A5D64'} mb="10px">{years[splittedDate[1]]} {splittedDate[2]}, {splittedDate[0]}</Text>
                                                <Text fontWeight={'600'} fontSize={'18px'} mb="20px" maxW={'450px'}>{val?.name}</Text>
                                                <Stack direction={'row'} alignItems={'center'}>
                                                    <Stack className='btn'>
                                                        <Button
                                                            onClick={() => navigate(`/super-dashboard/blog/${val?._id}`)}
                                                            justifyContent={'center'}
                                                            padding={'10px 20px'}
                                                            bg={"primaryGreen.100"}
                                                            color={'primaryYellow.100'}
                                                            bgImage={greenBtnTexture}
                                                            bgPos={'center'}
                                                            bgSize={'cover'}
                                                            bgRepeat={'no-repeat'}
                                                            fontWeight={'400'}
                                                            border={'2px solid'}
                                                            borderColor={"primaryGreen.100"}
                                                            textAlign={'center'}
                                                            _hover={{
                                                                bg: "primaryGreen.100",
                                                                borderColor: "primaryGreen.100",
                                                                color: '#fff'
                                                            }}
                                                        >
                                                            <Text as={'span'}>View Details</Text>
                                                        </Button>
                                                    </Stack>
                                                    <Stack className='btn'>
                                                        <Button
                                                            isLoading={isLoading}
                                                            onClick={() => updateArcStatus(val?._id, val?.isArchive)}
                                                            justifyContent={'center'}
                                                            padding={'10px 20px'}
                                                            bg={"#D5DADE"}
                                                            color={'#000'}
                                                            fontWeight={'400'}
                                                            border={'2px solid'}
                                                            borderColor={"#D5DADE"}
                                                            textAlign={'center'}
                                                            _hover={{
                                                                bg: "#D5DADE",
                                                                borderColor: "#D5DADE",
                                                                color: '#000'
                                                            }}
                                                        >
                                                            <Text as={'span'}>Restore</Text>
                                                        </Button>
                                                    </Stack>
                                                    <Stack className='btn'>
                                                        <Button
                                                            isLoading={isLoading}
                                                            onClick={() => deleteBlog(val?._id)}
                                                            justifyContent={'center'}
                                                            padding={'10px 20px'}
                                                            bg={"red.700"}
                                                            color={'#fff'}
                                                            fontWeight={'400'}
                                                            border={'2px solid'}
                                                            borderColor={"#D5DADE"}
                                                            textAlign={'center'}
                                                            _hover={{
                                                                bg: "#D5DADE",
                                                                borderColor: "#D5DADE",
                                                                color: '#000'
                                                            }}
                                                        >
                                                            <Text as={'span'}>Delete</Text>
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        )
                                    }
                                    )
                                }
                            </Stack>
                        </Stack>
                        :
                        <Grid
                            templateColumns="repeat(12, 1fr)"
                            gap={{ base: 2, sm: 12 }}
                            mr={'60px !important'}
                        >
                            <GridItem colSpan={{ base: '4', md: '7' }}>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Box display={'flex'} alignItems={'center'} gap="15px">
                                        <Stack className='btn'>
                                            <Button
                                                onClick={() => setIsShowingArchive(false)}
                                                justifyContent={'center'}
                                                padding={'10px 20px'}
                                                bg={!isShowingArchive ? "primaryGreen.100" : '#D5DADE'}
                                                color={!isShowingArchive ? 'primaryYellow.100' : '#000'}
                                                fontWeight={'400'}
                                                bgImage={!isShowingArchive ? greenBtnTexture : 'none'}
                                                bgPos={'center'}
                                                bgSize={'cover'}
                                                bgRepeat={'no-repeat'}
                                                w="120px"
                                                border={'2px solid'}
                                                borderColor={!isShowingArchive ? "primaryGreen.100" : '#D5DADE'}
                                                textAlign={'center'}
                                                _hover={{
                                                    bg: !isShowingArchive ? "primaryGreen.100" : '#D5DADE',
                                                    borderColor: !isShowingArchive ? "primaryGreen.100" : '#D5DADE',
                                                    color: !isShowingArchive ? '#fff' : '#000'
                                                }}
                                            >
                                                <Text as={'span'}>Blog</Text>
                                            </Button>
                                        </Stack>
                                        <Stack className='btn'>
                                            <Button
                                                onClick={() => setIsShowingArchive(true)}
                                                justifyContent={'center'}
                                                padding={'10px 20px'}
                                                bg={isShowingArchive ? "primaryGreen.100" : '#D5DADE'}
                                                color={isShowingArchive ? 'primaryYellow.100' : '#000'}
                                                fontWeight={'400'}
                                                bgImage={isShowingArchive ? greenBtnTexture : 'none'}
                                                bgPos={'center'}
                                                bgSize={'cover'}
                                                bgRepeat={'no-repeat'}
                                                w="120px"
                                                border={'2px solid'}
                                                borderColor={isShowingArchive ? "primaryGreen.100" : '#D5DADE'}
                                                textAlign={'center'}
                                                _hover={{
                                                    bg: isShowingArchive ? "primaryGreen.100" : '#D5DADE',
                                                    borderColor: isShowingArchive ? "primaryGreen.100" : '#D5DADE',
                                                    color: isShowingArchive ? '#fff' : '#000'
                                                }}
                                            >
                                                <Text as={'span'}>Archive</Text>
                                            </Button>
                                        </Stack>
                                    </Box>
                                </Stack>
                                <Stack my="30px">
                                    <Heading fontSize={'22px'}>Blogs</Heading>
                                    <Text fontSize={'16px'} color={'#5A5D64'}>You've {eventsList?.curEvents.length > 0 ? eventsList?.curEvents.length : 0} blogs</Text>
                                </Stack>
                                <Stack>
                                    {
                                        eventsList?.curEvents.length > 0 &&
                                        eventsList?.curEvents.map(val => {
                                            let splittedDate = String(val?.createdAt).split('T')[0].split('-');
                                            return (
                                                <Box mb="30px !important">
                                                    <Image src={val?.picture ? `${imgUrl}/${val?.picture}` : eventPic} w="483px" h="244px" overflow={'hidden'} objectFit={'cover'} borderRadius={'10px'} mb="10px" />
                                                    <Text fontSize={'16px'} color={'#5A5D64'} mb="10px">{years[splittedDate[1]]} {splittedDate[2]}, {splittedDate[0]}</Text>
                                                    <Text fontWeight={'600'} fontSize={'18px'} mb="20px" maxW={'450px'}>{val?.name}</Text>
                                                    <Stack direction={'row'} alignItems={'center'}>
                                                        <Stack className='btn'>
                                                            <Button
                                                                onClick={() => navigate(`/super-dashboard/blog/${val?._id}`)}
                                                                justifyContent={'center'}
                                                                padding={'10px 20px'}
                                                                bg={"primaryGreen.100"}
                                                                color={'primaryYellow.100'}
                                                                w={'180px'}
                                                                bgImage={greenBtnTexture}
                                                                bgPos={'center'}
                                                                bgSize={'cover'}
                                                                bgRepeat={'no-repeat'}
                                                                fontWeight={'400'}
                                                                border={'2px solid'}
                                                                borderColor={"primaryGreen.100"}
                                                                textAlign={'center'}
                                                                _hover={{
                                                                    bg: "primaryGreen.100",
                                                                    borderColor: "primaryGreen.100",
                                                                    color: '#fff'
                                                                }}
                                                            >
                                                                <Text as={'span'}>View Details</Text>
                                                            </Button>
                                                        </Stack>
                                                        <Stack className='btn'>
                                                            <Button
                                                                isLoading={isLoading}
                                                                onClick={() => updateArcStatus(val?._id, val?.isArchive)}
                                                                justifyContent={'center'}
                                                                padding={'10px 20px'}
                                                                bg={"#D5DADE"}
                                                                color={'#000'}
                                                                w={'180px'}
                                                                fontWeight={'400'}
                                                                border={'2px solid'}
                                                                borderColor={"#D5DADE"}
                                                                textAlign={'center'}
                                                                _hover={{
                                                                    bg: "#D5DADE",
                                                                    borderColor: "#D5DADE",
                                                                    color: '#000'
                                                                }}
                                                            >
                                                                <Text as={'span'}>Archive</Text>
                                                            </Button>
                                                        </Stack>
                                                    </Stack>
                                                </Box>
                                            )
                                        })
                                    }
                                </Stack>
                            </GridItem>
                            <GridItem colSpan={{ base: '4', md: '5' }}>
                                <Stack position={'sticky'} top={'0px'}>
                                    <Stack className='btn' w={'fit-content'} ml="auto">
                                        <Button
                                            onClick={() => navigate('/super-dashboard/add-blog')}
                                            justifyContent={'center'}
                                            padding={'10px 20px'}
                                            bg={"primaryGreen.100"}
                                            color={'primaryYellow.100'}
                                            fontWeight={'400'}
                                            border={'2px solid'}
                                            bgImage={greenBtnTexture}
                                            bgPos={'center'}
                                            bgSize={'cover'}
                                            bgRepeat={'no-repeat'}
                                            borderColor={"primaryGreen.100"}
                                            textAlign={'center'}
                                            _hover={{
                                                bg: "primaryGreen.100",
                                                borderColor: "primaryGreen.100",
                                                color: '#fff'
                                            }}
                                        >
                                            <Text as={'span'}>Create New Blog</Text>
                                        </Button>
                                    </Stack>
                                </Stack>
                            </GridItem>
                        </Grid>
                }
            </Stack >
        </>
    )
}