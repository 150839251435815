import { Stack, Heading, Icon, Text, Box, Button, Grid, GridItem, FormLabel, Input, Checkbox, Select, Textarea, useToast } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiMenuAlt1, HiOutlineClock } from 'react-icons/hi'
import { useState, useRef, useEffect } from 'react'
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import { BsPlusCircle } from 'react-icons/bs';
import { POST } from '../../../utilities/ApiProvider.js'
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function HelpWrap() {
    const imgRef = useRef(null);
    const imgRef2 = useRef(null);
    const toast = useToast();
    const [eventPic, setEventPic] = useState({});
    const [eventPic2, setEventPic2] = useState({});
    const navigate = useNavigate();
    const user = useSelector(state => state?.value);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState('');

    const createEvent = async (id, archiveValue) => {
        setIsLoading(true);
        let shouldProceedWithNetwork = true;
        let form = document.getElementById('eventForm');
        let data = new FormData(form);
        // Create a new Date object
        let currentDate = new Date();

        // Get the current date components
        let day = currentDate.getDate(); // Returns the day of the month (1-31)
        let month = currentDate.getMonth() + 1; // Returns the month (0-11), so we add 1 to get the correct month
        let year = currentDate.getFullYear(); // Returns the year (four digits)
        let finalDate = `${year}-${month}-${day}`
        // Display the current date
        for (const pair of data.entries()) {
            if (pair[1] === '') {
                shouldProceedWithNetwork = false;
                let errorKey = pair[0];
                if (errorKey.includes('_')) {
                    errorKey = `${errorKey.split('_')[0]} ${errorKey.split('_')[1]}`
                }
                toast({
                    description: `${errorKey} is required!`,
                    status: 'error',
                    position: 'top-right',
                    isClosable: true,
                    duration: 4000
                })
                break;
            }
        }

        if (shouldProceedWithNetwork) {
            try {
                data.append('post_type', 'blog');
                data.append('description', value)
                data.append('currentDate', finalDate);
                let res = await POST(`/admin/posts`, data, { authorization: `Bearer ${user?.verificationToken}` });
                if (res.status === 200) {
                    toast({
                        description: 'blog created!',
                        status: 'success',
                        position: 'top-right',
                        duration: 2500
                    });
                    navigate('/super-dashboard/blogs')
                } else {
                    toast({
                        description: res.message,
                        status: 'error',
                        position: 'top-right',
                        duration: 2500
                    });
                }
            } catch (err) {
                toast({
                    description: err.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Heading fontSize="24px" fontWeight="600">Create New Blog</Heading>
                <form id="eventForm">
                    <Grid
                        templateColumns="repeat(12, 1fr)"
                        gap={{ base: 2, sm: 0 }}
                        alignItems={'flex-start'}
                        justifyContent="space-between"
                        mr="60px !important"
                    >
                        <GridItem colSpan={{ base: '4', md: '5' }}>
                            <Stack className='addItemForm'>
                                <FormLabel>Blog Name</FormLabel>
                                <Input name="name" placeholder="Enter Here" />
                                <FormLabel>Description</FormLabel>
                                <ReactQuill style={{ height: '250px' }} theme="snow" value={value} onChange={setValue} />
                                {/* <Textarea name="description" placeholder='Enter here' resize={'none'} rows={'8'}></Textarea> */}
                                <Stack className='btn' mt="60px !important">
                                    <Button
                                        isLoading={isLoading}
                                        onClick={() => createEvent()}
                                        justifyContent={'center'}
                                        padding={'10px 20px'}
                                        bg={"primaryGreen.100"}
                                        color={'primaryYellow.100'}
                                        w={'180px'}
                                        bgImage={greenBtnTexture}
                                        bgPos={'center'}
                                        bgSize={'cover'}
                                        bgRepeat={'no-repeat'}
                                        fontWeight={'400'}
                                        border={'2px solid'}
                                        borderColor={"primaryGreen.100"}
                                        textAlign={'left'}
                                        _hover={{
                                            bg: "primaryGreen.100",
                                            borderColor: "primaryGreen.100",
                                            color: '#fff'
                                        }}
                                    >
                                        <Text as={'span'}>Create</Text>
                                    </Button>
                                </Stack>
                            </Stack>
                        </GridItem>
                        <GridItem colSpan={{ base: '4', md: '3' }}>
                        </GridItem>
                        <GridItem colSpan={{ base: '4', md: '4' }}>
                            <Input display={'none'} name="cover" type="file" onChange={e => setEventPic2(e.target.files[0])} ref={imgRef2} />
                            <FormLabel mt="15px" fontWeight="600">Upload Cover Picture <sub>(1400px x 500px)</sub></FormLabel>
                            <Button
                                onClick={() => imgRef2?.current.click()}
                                w="100%"
                                h="115px"
                                border="1px solid #BBBBBB"
                            >
                                <Text display="block" w="100%">
                                    <Icon as={BsPlusCircle} display="block" margin="5px auto" />
                                    {eventPic2?.name ?? 'Add'}</Text>
                            </Button>
                            <Input display={'none'} name="picture" type="file" onChange={e => setEventPic(e.target.files[0])} ref={imgRef} />
                            <FormLabel mt="15px" fontWeight="600">Upload Title Picture <sub>(450px x 350px)</sub></FormLabel>
                            <Button
                                onClick={() => imgRef?.current.click()}
                                w="100%"
                                h="115px"
                                border="1px solid #BBBBBB"
                            >
                                <Text display="block" w="100%">
                                    <Icon as={BsPlusCircle} display="block" margin="5px auto" />
                                    {eventPic?.name ?? 'Add'}</Text>
                            </Button>
                        </GridItem>
                    </Grid>
                </form>
            </Stack>
        </>
    )
}