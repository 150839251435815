import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png';
import ContestWrap from '../../../components/Dashboard/Contest/ContestWrapper';

export default function Contest() {
  return (
    <Stack backgroundImage={shapeb} overflowY="hidden">
      <MainDashboard>
        <ContestWrap />
      </MainDashboard>
    </Stack>
  );
}
