import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import PromotionListWrap from '../../../components/Dashboard/Promotion/PromotionListWrap';
import { Stack } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'

export default function PromotionListDashboard() {
    return (
        <Stack
            backgroundImage={shapeb}
            overflowY="hidden"
        >
            <MainDashboard>
                <PromotionListWrap />
            </MainDashboard>
        </Stack>
    );
}
