import { Box, Text, Stack, Image, Button, useToast } from '@chakra-ui/react';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import foodImg from '../../../assets/images/auth/r6.jpg';
import { imgUrl } from '../../../utilities/config';
import { POST } from '../../../utilities/ApiProvider';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function StoreReqRow({
  _id,
  picture,
  username,
  email,
  createdAt,
  type,
  hideActionBtn,
  document,
  getRequests,
  isCustomer,
}) {
  const user = useSelector(state => state?.value);
  const toast = useToast();
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const [isLoading, setIsLoading] = useState(false);
  const updateReq = async (status, userid) => {
    setIsLoading(true);
    try {
      let res = await POST(
        '/admin/stores/accept',
        { status, userid },
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (res.status === 200) {
        toast({
          description: 'Operation Success!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        getRequests();
      } else {
        toast({
          description: res?.message,
          status: 'error',
          position: 'top-right',
          duration: 3000,
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Box flex={'1'}>
        <Text color="#616161" mb="15px">
          Store Info:
        </Text>
        <Stack direction={'row'}>
          <Image
            src={picture !== '' ? `${imgUrl}/${picture}` : foodImg}
            w="40px"
            h="40px"
            borderRadius={'12px'}
            objectFit={'cover'}
          />
          <Box w={'200px'}>
            <Text fontWeight={'500'}>{username}</Text>
            <Text color="#616161" mb="15px" fontSize={'12px'}>
              {email}
            </Text>
          </Box>
        </Stack>
      </Box>
      <Box flex={'1'}>
        <Text color="#616161" mb="15px">
          Sign Up Date:
        </Text>
        <Text fontWeight={'500'}>
          {new Date(String(createdAt).split('T')[0]).toLocaleDateString(
            'en-US',
            options
          )}
        </Text>
      </Box>
      <Box flex={'1'}>
        <Text color="#616161" mb="15px">
          Store Type:
        </Text>
        <Text fontWeight={'500'} textTransform={'capitalize'}>
          {type}
        </Text>
      </Box>
      {/* {isCustomer && (
                
            )} */}
      <Box flex={'1'}>
        <Text color="#616161" mb="15px">
          Is Customer:
        </Text>
        <Text fontWeight={'500'} textTransform={'capitalize'}>
          {isCustomer ? 'YES' : 'NO'}
        </Text>
      </Box>
      <Box flex={'1'}>
        <Text color="#616161" mb="15px">
          Certificate:
        </Text>
        {document ? (
          <Stack className="btn">
            <Button
              onClick={() => window.open(`${imgUrl}/${document}`, '_blank')}
              justifyContent={'flex-start'}
              padding={'5px 10px'}
              w="fit-content"
              bg={'primaryGreen.100'}
              color={'primaryYellow.100'}
              fontSize={'14px'}
              bgImage={greenBtnTexture}
              bgPos={'center'}
              bgSize={'cover'}
              bgRepeat={'no-repeat'}
              fontWeight={'400'}
              border={'2px solid'}
              borderColor={'primaryGreen.100'}
              textAlign={'left'}
              _hover={{
                bg: 'primaryGreen.100',
                borderColor: 'primaryGreen.100',
                color: '#fff',
              }}
            >
              <Text as={'span'}>View Certificate</Text>
            </Button>
          </Stack>
        ) : (
          <Text color="#CE1004" fontSize={'15px'}>
            No Certificate Found!
          </Text>
        )}
      </Box>
      {hideActionBtn !== true && (
        <Box
          flex={'1'}
          display={'flex'}
          gap={'10px'}
          alignSelf={'end'}
          mb="15px !important"
        >
          <Stack className="btn">
            <Button
              isLoading={isLoading}
              onClick={() => updateReq('accepted', _id)}
              justifyContent={'flex-start'}
              padding={'5px 10px'}
              w="fit-content"
              bg={'primaryGreen.100'}
              color={'primaryYellow.100'}
              fontWeight={'400'}
              bgImage={greenBtnTexture}
              bgPos={'center'}
              bgSize={'cover'}
              bgRepeat={'no-repeat'}
              border={'2px solid'}
              borderColor={'primaryGreen.100'}
              textAlign={'left'}
              _hover={{
                bg: 'primaryGreen.100',
                borderColor: 'primaryGreen.100',
                color: '#fff',
              }}
            >
              <Text as={'span'}>Accept</Text>
            </Button>
          </Stack>
          <Stack className="btn">
            <Button
              isLoading={isLoading}
              onClick={() => updateReq('rejected', _id)}
              justifyContent={'flex-start'}
              padding={'5px 10px'}
              w="fit-content"
              bg={'#CE1004'}
              color={'#fff'}
              fontWeight={'400'}
              border={'2px solid'}
              borderColor={'#CE1004'}
              textAlign={'left'}
              _hover={{
                bg: 'transparent',
                borderColor: '#CE1004',
                color: '#CE1004',
              }}
            >
              <Text as={'span'}>Decline</Text>
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
}
