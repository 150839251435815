// // import { Stack, Text, Box, Image } from "@chakra-ui/react";
// // import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
// // import bannerImg from '../../../assets/images/website/home/aimhalal-banner.png';
// // import WhiteOver from '../../../assets/images/icon/white.png';
// // const ContestBanner = ({ text }) => {
// //     return (
// //         <>
// //             <Box bg={"#28551A"} bgImage={WhiteOver} borderRadius={"10px"} position={"relative"}>
// //                 <Container maxW={"1440px"}>
// //                     <Stack h={{base: "120px", xl: "245px"}} justifyContent={"center"}>
// //                         <Text color={"primaryYellow.100"} fontSize={{ base: "18px", lg: "59.259px" }} fontWeight={"600"} lineHeight={"61.111px"} maxW={"80%"}>{text}</Text>
// //                     </Stack>
// //                     <Box position={"absolute"} bottom={"0"} right={{ base: "100%", xl: "0" }}>
// //                         <Image src={bannerImg} />
// //                     </Box>
// //                 </Container>
// //             </Box>
// //         </>
// //     )
// // }

// // export default ContestBanner;
// import React from 'react';
// import { Box, Text, Container } from "@chakra-ui/react";
// import bannerImg from '../../../assets/images/website/home/aimhalal-banner.png';
// import WhiteOver from '../../../assets/images/icon/white.png';

// const ContestBanner = ({ text, para1, para2 }) => {
//     return (
//         <Box
//             bg="#28551A"
//             bgImage={`url(${bannerImg}), url(${WhiteOver})`}
//             bgSize="cover, auto"
//             bgPosition="center, center"
//             bgRepeat="no-repeat, repeat"
//             borderRadius="10px"
//             position="relative"
//         >
//             <Container maxW="1440px">
//                 <Box
//                     h={{ base: "500px", xl: "500px" }}
//                     display="flex"
//                     flexDirection="column"
//                     alignItems="center"
//                     justifyContent="center"
//                 >
//                     <Text
//                         color="primaryYellow.100"
//                         fontSize={{ base: "18px", lg: "48px" }}
//                         fontWeight="600"
//                         lineHeight={{ base: "normal", lg: "3rem" }}
//                         marginTop="10px"
//                         maxW="100%"
//                         textAlign="center"
//                     >
//                         {text}
//                     </Text>
//                     <Text
//                         color="white"
//                         fontFamily="Poppins"
//                         fontSize={{ base: "16px", lg: "20px" }}
//                         fontWeight="400"
//                         marginTop="10px"
//                         lineHeight={{ base: "normal", lg: "3rem" }}
//                         maxW="50%"
//                         textAlign="center"
//                     >
//                         {para1}
//                     </Text>
//                     <Text
//                          color="white"
//                          fontFamily="Poppins"
//                          fontSize={{ base: "16px", lg: "20px" }}
//                          fontWeight="400"
//                          marginTop="10px"
//                          lineHeight={{ base: "normal", lg: "24px" }}
//                          maxW="50%"
//                          textAlign="center"
//                     >
//                         {para2}
//                     </Text>
//                 </Box>
//             </Container>
//             <Box>
//                 <Text
//                     color="primaryYellow.100"
//                     fontSize={{ base: "18px", lg: "42px" }}
//                     fontWeight="600"
//                     paddingBottom="30px"
//                     lineHeight={{ base: "normal", lg: "3rem" }}
//                     marginTop="10px"
//                     maxW="100%"
//                     textAlign="center"
//                 >
//                     {text}
//                 </Text>
//             </Box>
//         </Box>
//     );
// };

// export default ContestBanner;



import React from 'react';
import { Box, Text, Container, VStack } from "@chakra-ui/react";
import bannerImg from '../../../assets/images/website/home/aimhalal-banner.png';

const ContestBanner = ({ text1, text2, text3, para1, para2 }) => {
    return (
        <Box
            bg="#28551A"
            bgImage={`url(${bannerImg})`}
            bgSize={{ base: "cover, auto", md: "cover, auto" }}
            bgPosition={{ base: "center, center", md: "center, center" }}
            bgRepeat={{ base: "no-repeat, repeat", md: "no-repeat, repeat" }}
            borderRadius={{ base: "0", md: "10px" }}
            position="relative"
        >
            <Container maxW="1440px" px={{base: 4, md: 6, lg: 8}}>
                <VStack
                    spacing={{base: 4, md: 6}}
                    py={{base: "40px", md: "60px", lg: "80px"}}
                    align="center"
                    justify="center"
                >
                    <Text
                        color="primaryYellow.100"
                        fontSize={{base: "24px", sm: "32px", md: "40px", lg: "48px"}}
                        fontWeight="600"
                        lineHeight={{base: "1.2", md: "1.3"}}
                        maxW={{base: "100%", md: "80%", lg: "80%"}}
                        marginTop="70px"
                        textAlign="center"
                    >
                        {text1}
                    </Text>
                    <Text
                        color="primaryYellow.100"
                        fontSize={{base: "24px", sm: "32px", md: "40px", lg: "48px"}}
                        fontWeight="600"
                        lineHeight={{base: "1.2", md: "1.3"}}
                        maxW={{base: "100%", md: "80%", lg: "80%"}}
                        marginTop="0px"
                        textAlign="center"
                    >
                        {text2}
                    </Text>
                    <Text
                        color="white"
                        fontFamily="Poppins"
                        fontSize={{base: "14px", sm: "14px", md: "16px", lg: "20px"}}
                        fontWeight="400"
                        lineHeight={{base: "1.5", md: "1.6"}}
                        maxW={{base: "100%", md: "70%", lg: "50%"}}
                        textAlign="center"
                    >
                        {para1}
                    </Text>
                    <Text
                        color="white"
                        fontFamily="Poppins"
                        fontSize={{base: "14px", sm: "14px", md: "16px", lg: "20px"}}
                        fontWeight="400"
                        lineHeight={{base: "1.5", md: "1.6"}}
                        maxW={{base: "100%", md: "70%", lg: "50%"}}
                        textAlign="center"
                    >
                        {para2}
                    </Text>
                </VStack>
            </Container>
            <Box mt={{base: 4, md: 6}} pb={{base: 3, md: 6}}>
                <Text
                    color="primaryYellow.100"
                    fontSize={{base: "20px", sm: "24px", md: "32px", lg: "38px"}}
                    fontWeight="600"
                    lineHeight={{base: "1.2", md: "1.3"}}
                    maxW="100%"
                    textAlign="center"
                >
                    {text3}
                </Text>
            </Box>
        </Box>
    );
};

export default ContestBanner;