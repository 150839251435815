import React, { useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from "@chakra-ui/react"
import AppRoute from './routes/Route';
import { loadUser } from './reducers/useReducers'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function App() {

  const queryClient = new QueryClient();

  const theme = extendTheme({
    colors: {
      primaryYellow: {
        100: '#FAD700',
      },
      primaryGreen: {
        100: '#28551A',
      }
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (localStorage.getItem('aimHalalUser') !== null) {
        let user = JSON.parse(localStorage.getItem('aimHalalUser') ?? '{}')
        dispatch(loadUser(user))
      } else if (Cookies.get('aimHalalUser') !== undefined) {
        let user = JSON.parse(Cookies.get('aimHalalUser') ?? '{}')
        dispatch(loadUser(user))
      }
    })()
  }, [])

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AppRoute />
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
