import { Stack, Text, Box, Image, Button, OrderedList, ListItem } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import { Link } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
// Components
import HalalAdd from "../../components/Website/Home/halalAdd";
import SignCard from "../../components/Website/Home/signCard";
import CounterBox from "../../components/Website/Home/counterBox";
import HeroBanner from '../../components/Website/Home/heroBanner';
import Header from "../../components/Website/Header/header";
import Footer from "../../components/Website/Footer/Footer";
// Images
import tabmain from '../../assets/images/website/appFeature/tabmain.png'
import funeral from '../../assets/images/website/appFeature/funeral.png'
import culture from '../../assets/images/website/appFeature/culture.png'
import chef from '../../assets/images/website/appFeature/chef.png'
import directories from '../../assets/images/website/appFeature/directories.png'
import butch from '../../assets/images/website/appFeature/butch.png'
import event from '../../assets/images/website/appFeature/event.jpg'
import eat from '../../assets/images/website/appFeature/eat.jpg'
import quran from '../../assets/images/website/appFeature/quran.jpg'
import share from '../../assets/images/website/appFeature/share.png'
import feature1 from '../../assets/images/website/appFeature/feature1.png';
import BlackOver from '../../assets/images/icon/black.png';
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg';
import { signData, tabList } from "../../assets/data/data";
import { useEffect } from "react";

const AppFeature = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, []); // Empty dependency array ensures that this effect runs only once after initial mount

    return (
        <>
            <Header />

            <Box>
                <HeroBanner text={"Eateries"} />
            </Box>

            {/* <Box p={"40px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Stack direction={{ base: "column", lg: "row" }} alignItems={"Center"} justifyContent={"space-between"}>
                        <Box>
                            <Image src={feature1} w={"646px"} />
                        </Box>
                        <Box maxW={"629px"}>
                            <Text fontSize={"26px"} fontWeight={"600"} mb={"20px"}>AimHalal Ambition</Text>
                            <Text fontSize={"16px"} mb={"30px"}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ndesktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</Text>
                            <Button as={Link} to={"#"} bg={"primaryYellow.100"} bgImage={yellowBtnTexture} bgPos={'center'} bgSize={'cover'} bgRepeat={'no-repeat'} p={'0px 30px'} transform={"skew(-8deg)"} border={"1px solid #28551A"} _hover={{ bg: "none" }}>
                                <Text as={'span'} transform={"skew(8deg)"} color={"#28551A"}>Read More</Text>
                            </Button>
                        </Box>
                    </Stack>
                </Container>
            </Box> */}

            <Box bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Stack direction={{ base: "column", xl: "row" }} flexDirection={{ base: "column-reverse", xl: 'initial' }} alignItems={"center"} justifyContent={"space-between"}>
                        <Box p={"30px 0px"} >
                            <Stack direction={{ base: "column", xl: "row" }} flexDirection={{ base: "column-reverse", xl: 'initial' }} alignItems={"center"} justifyContent={"space-between"}>
                                <Box flex={1}>
                                    <Text color={'primaryGreen.100'} fontSize={"24px"} fontWeight={"700"} letterSpacing={"-0.48px"} mb={"20px"}>Halal Eateries</Text>
                                    <Text color={'primaryGreen.100'} fontSize={"15px"} letterSpacing={"-0.24px"} mb={"20px"}>Explore the diverse world of Halal dining with us. Discover and indulge in a variety of Halal cuisines, each dish crafted with care and certified by recognized Halal authorities. Whether you're seeking casual dining or a gourmet adventure, AimHalal ensures that every restaurant featured on our platform aligns with the highest Halal standards, providing a delightful dining experience for every palate.</Text>
                                    <Text color={'primaryGreen.100'} fontSize={"15px"} letterSpacing={"-0.24px"} fontWeight={"600"} mb={"20px"}>Here's what you can expect from our Halal Markets feature:</Text>
                                    {/* <OrderedList fontSize={"15px"} letterSpacing={"-0.24px"}>
                                            <ListItem color={'primaryGreen.100'} mb={"16px"}>Explore a World of Halal Cuisine**: AimHalal's Halal Eateries feature brings you a diverse selection of restaurants, cafes, and food establishments that offer Halal-certified dishes. Whether you're craving traditional Middle Eastern delicacies, international cuisines, or trendy fusion creations, our platform allows you to explore and indulge in a world of Halal flavors.</ListItem>
                                            <ListItem color={'primaryGreen.100'} mb={"16px"}>Locate Halal Eateries Near You**: Our user-friendly interface enables you to easily locate Halal eateries in your vicinity. With just a few taps, you can discover nearby restaurants that meet your Halal requirements, making it convenient to enjoy meals that align with your cultural and religious values</ListItem>
                                            <ListItem color={'primaryGreen.100'} mb={"16px"}>Read Reviews and Recommendations**: Make informed dining choices with the help of our community's reviews and recommendations. AimHalal's Halal Eateries feature provides user ratings, feedback, and insights on the quality of food, service, ambiance, and overall dining experience. Benefit from the experiences of others to ensure memorable and satisfying dining outings</ListItem>
                                            <ListItem color={'primaryGreen.100'} mb={"16px"}>Contribute to the Community**: Share your own dining experiences and contribute to the AimHalal community. Leave reviews, ratings, and recommendations for the Halal eateries you visit, helping fellow users make informed decisions and fostering a supportive ecosystem that celebrates and promotes Halal dining options.</ListItem>
                                        </OrderedList> */}
                                    <OrderedList fontSize={"16px"} letterSpacing={"-0.24px"}>
                                        <ListItem color={'primaryGreen.100'} mb={"15px"}><b>Wide Range of Categories</b>:<br /> Explore a variety of categories for all your needs.</ListItem>
                                        <ListItem color={'primaryGreen.100'} mb={"15px"}><b>Verified Halal Certification</b>:<br /> Trust in our products with verified Halal certification.</ListItem>
                                        <ListItem color={'primaryGreen.100'} mb={"15px"}><b>User-Friendly Interface</b>:<br /> Enjoy easy navigation with our user-friendly interface.</ListItem>
                                        <ListItem color={'primaryGreen.100'} mb={"15px"}><b>Supporting Halal Businesses</b>:<br /> Support Halal businesses directly through our platform.</ListItem>
                                        {/* <ListItem color={'primaryGreen.100'}>Experience the convenience and peace of mind that AimHalal's Halal Markets feature offers. Join our growing community and explore a world of Halal products right at your fingertips. Download the AimHalal app today on Android or iOS and unlock the possibilities of Halal living like never before. Together, let's embrace a Halal lifestyle with AimHalal's Halal Markets feature.</ListItem> */}
                                    </OrderedList>
                                    {/* <Text color={'primaryGreen.100'} fontSize={"15px"} letterSpacing={"-0.24px"} fontWeight={"600"} mb={"20px"}>Discover delightful Halal dining experiences with AimHalal's Halal Eateries feature. Savor the flavors, experience the culinary diversity, and create memorable moments with family and friends.

                                            <br />
                                            Download the AimHalal app today on Android or iOS and embark on a gastronomic journey filled with Halal goodness.
                                            <br />
                                            Together, let's celebrate the richness of Halal cuisine and uncover the best Halal eateries with AimHalal's Halal Eateries feature.</Text> */}
                                </Box>
                                <Box flex={1}>
                                    <Image src={eat} />
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                </Container>
            </Box>

            <Box p={"100px 0px 40px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <HalalAdd />
                    </Box>
                </Container>
            </Box>

            <Box p={"30px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <Stack
                            direction={{ base: "column", xl: "row" }}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            {signData.map((val, key) => {
                                return (
                                    <SignCard img={val.img} text1={val.text1} text2={val.text2} main={val.main} btn={val.btn} href={val.href} key={key} />
                                )
                            })
                            }
                        </Stack>
                    </Box>
                    <Box pt={"40px"}>
                        {/* <CounterBox /> */}
                    </Box>
                </Container>
            </Box>

            <Footer />
        </>
    )
}

export default AppFeature;