import {
  Stack,
  Box,
  Text,
  Heading,
  FormLabel,
  Button,
  PinInput,
  PinInputField,
  HStack,
  Link, Icon, useToast
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import shapeb from '../../assets/images/icon/black.png';
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { POST } from '../../utilities/ApiProvider';

export default function Verificationform() {

  const toast = useToast();
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    let localEmail = localStorage.getItem('emailForOtpVerification');
    if (localEmail) {
      setEmail(localEmail);
    } else {
      navigate('/forgotpassword');
    }
  }, []);

  const verifyCode = async () => {
    setIsLoading(true);
    try {
      if (code === '') {
        toast({
          description: 'Code is required!',
          duration: 2000,
          position: 'top-right',
          status: 'error'
        })
      } else {
        let res = await POST('/auth/verify-otp', {
          email,
          otp: code
        });
        if (res.status === 200) {
          toast({
            description: res.message,
            duration: 2000,
            position: 'top-right',
            status: 'success'
          });
          navigate('/newpassword')
        }else{
          toast({
            description: res.message,
            duration: 2000,
            position: 'top-right',
            status: 'error'
          });
        }
      }
    } catch (err) {
      toast({
        description: err.message,
        duration: 2000,
        position: 'top-right',
        status: 'error'
      })
    }
    setIsLoading(false);
  }

  return (
    <>
      <Stack
        w={'70%'}
        bg={'#fff'}
        py={'80px'}
        m={'0 auto'}
        px={'20px'}
        border={'5px solid'}
        borderColor="primaryYellow.100"
        borderRadius={'8px'}
        backgroundImage={shapeb}
      >
        <Stack mb={16}>
          <Box className="boxlink">
            <Link to={'/forgotpassword'}>
              <Icon fontSize={'17px'} color={'#000'} as={BiArrowBack} />
            </Link>
          </Box>
        </Stack>
        <Stack className="AH mh p1" pb={6}>
          <Heading as={'h3'}>Verification Screen</Heading>
          <Text>Enter the code that we sent you through email</Text>
        </Stack>
        <Stack className="regwarp" gap={12}>
          <Stack mb={4}>
            <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
              code
            </FormLabel>
            <Box className="pfiled">
              <HStack>
                <PinInput value={code} onChange={e => setCode(e)} placeholder="-">
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />

                </PinInput>
              </HStack>
            </Box>
          </Stack>

          <Stack className="btn">
            <Button
              onClick={verifyCode}
              isLoading={isLoading}
              padding={'10px 50px'}
              bg={'#FFC00F'}
              bgImage={yellowBtnTexture}
              bgPos={'center'}
              bgSize={'cover'}
              bgRepeat={'no-repeat'}
              color={'primaryGreen.100'}
              fontWeight={'500'}
              w={'fit-content'}
              border={'2px solid'}
              borderColor="primaryGreen.100"
              textTransform={'uppercase'}
            >
              <Text as={'span'}>Continue</Text>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
