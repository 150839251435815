import React, { useEffect, useState } from 'react';
import { GET, POST } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { Box, Button, Icon, Input, Select, Stack } from '@chakra-ui/react';
import { HiOutlineSearch } from 'react-icons/hi';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';

const PingUserForm = () => {
  const user = useSelector(state => state?.value);
  const [userEmails, setuserEmails] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');

  const getUsers = async searchVal => {
    setIsLoading(true);
    try {
      if (searchVal !== undefined) {
        let res = await GET(`/admin/customers?search=${searchVal}`, {
          authorization: `Bearer ${user?.verificationToken}`,
        });
        if (res.status === 200) {
          setuserEmails(res?.data.activeUsers.map(user => user.email));
          setIsLoading(false);
        } else {
          setIsLoading(false);
          alert('Failed to fetch user data');
          console.log(res.message);
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const pingUser = async () => {
    setIsLoading(true);
    try {
      console.log('Selected User', selectedUser);
      let res = await POST(
        '/adhan-subscribers',
        {
          email: selectedUser,
        },
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (res.status === 200) {
        alert('Ping sent successfully');
        setIsLoading(false);
      } else {
        setIsLoading(false);
        alert('Failed to send ping');
        console.log(res.message);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (user) getUsers(searchValue);
  }, [user, searchValue]);

  return (
    <Stack>
      <Box
        w="367px"
        bgColor="#ECF1F6"
        borderRadius="50px"
        display="flex"
        alignItems="center"
        px="10px"
        gap="5px"
      >
        <Icon as={HiOutlineSearch} fontSize="24px" color="#9CA4AB" />
        <Input
          onChange={e => setSearchValue(e.target.value)}
          placeholder="Search here..."
          border="0"
          fontSize="14px"
        />
      </Box>
      <Box>
        <Select
          pb={4}
          disabled={isLoading}
          w="367px"
          onChange={e => setSelectedUser(e.target.value)}
        >
          <option value="">Select User</option>
          {userEmails.map(email => (
            <option key={email} value={email}>
              {email}
            </option>
          ))}
        </Select>
        <Button
          onClick={() => pingUser()}
          justifyContent={'center'}
          isLoading={isLoading}
          padding={'10px 20px'}
          bg={'primaryGreen.100'}
          w="200px"
          color={'primaryYellow.100'}
          fontWeight={'400'}
          border={'2px solid'}
          bgImage={greenBtnTexture}
          bgPos={'center'}
          bgSize={'cover'}
          bgRepeat={'no-repeat'}
          borderColor={'primaryGreen.100'}
          textAlign={'left'}
          _hover={{
            bg: 'primaryGreen.100',
            borderColor: 'primaryGreen.100',
            color: '#fff',
          }}
        >
          Ping
        </Button>
      </Box>
    </Stack>
  );
};

export default PingUserForm;
