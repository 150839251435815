import React, { useEffect } from 'react'
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Stack,
  Radio,
  Text,
  RadioGroup,
  Image,
  Link,
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Icon,
  useToast
} from '@chakra-ui/react';
import { Link as ReactLink, useNavigate, useParams } from 'react-router-dom';
import shapeb from '../../../assets/images/icon/black.png'
import { useState } from 'react'
import defaultUser from '../../../assets/images/defaultPic.svg'
import HotDog from '../../../assets/images/auth/r6.jpg'
import DashboardHeader from '../../Header/DashboardHeader.js'
import PurchasedItemBox from './PurchasedItemBox.js';
import { FaChevronRight, FaStar } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { GET, POST } from '../../../utilities/ApiProvider.js';
import { imgUrl } from '../../../utilities/config.js';

export default function HomeWrap() {

  const options = { day: "numeric", month: "short", year: "numeric" };
  const params = useParams();
  const navigate = useNavigate();
  const user = useSelector(state => state?.value);
  const toast = useToast();
  const [isBlocking, setIsBlocking] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [singleUserData, setSingleUserData] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);

  const getSingleUser = async () => {
    try {
      let res = await GET(`/admin/customers/${params?.id}/orders`, { authorization: `Bearer ${user?.verificationToken}` });
      if (res.status === 200) {
        setSingleUserData(res?.data);
      } else {
        toast({
          description: res.message,
          status: 'error',
          position: 'top-right',
          duration: 2500
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
  }

  const changeBlockUser = async (id, status) => {
    setIsBlocking(true);
    try {
      let res = await POST('/admin/customers/block', { user: id, isActive: !status }, { authorization: `Bearer ${user?.verificationToken}` });
      if (res.status === 200) {
        toast({
          description: res.message,
          status: 'success',
          position: 'top-right',
          duration: 2500
        });
        getSingleUser();
      } else {
        toast({
          description: res.message,
          status: 'error',
          position: 'top-right',
          duration: 2500
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
    setIsBlocking(false);
  }

  useEffect(() => {
    if (selectedItem !== null) {
      console.log(selectedItem)
      onOpen()
    }
  }, [selectedItem])

  useEffect(() => {
    if (user && params) getSingleUser();
  }, [user, params]);

  return (
    <>
      <DashboardHeader />
      <Stack
        overflowY="auto"
        margin={'0 !important'}
        gap={8}
        borderRadius={'30px 0 0 0'}
        py={10}
        px={6}
        bg={'#fff'}
        height={'100%'}
        backgroundImage={shapeb}
        bgPos={'-32px -100px'}
      >
        <Stack>
          <Heading fontSize="24px" fontWeight="600">User Info</Heading>
        </Stack>
        <Stack direction={'row'} alignItems="center">
          <Box flex="1" display="flex" alignItems="center" gap="15px">
            <Image src={singleUserData?.picture ? singleUserData?.picture : defaultUser} w="96px" h="96px" />
            <Stack>
              <Text color="#616161">Name:</Text>
              <Text fontWeight="500">{singleUserData?.username ?? 'loading'}</Text>
            </Stack>
          </Box>
          <Box flex="1">
            <Text color="#616161" mb="15px">Email:</Text>
            <Text fontWeight="500">{singleUserData?.email ?? 'loading'}</Text>
          </Box>
          <Box flex="1">
            <Text color="#616161" mb="15px">Phone:</Text>
            <Text fontWeight="500">{singleUserData?.number ?? '--'}</Text>
          </Box>
          <Box flex="1">
            <Text color="#616161" mb="15px">Sign Up Date:</Text>
            <Text fontWeight="500">{new Date(String(singleUserData?.createdAt).split('T')[0]).toLocaleDateString("en-US", options)}</Text>
          </Box>
          <Box flex="1">
            {
              singleUserData &&
              <Stack className='btn'>
                <Button
                  isLoading={isBlocking}
                  onClick={() => changeBlockUser(singleUserData?._id, singleUserData?.isActive)}
                  justifyContent={'flex-start'}
                  padding={'10px 20px'}
                  w="fit-content"
                  bg={singleUserData?.isActive ? "#CE1004" : 'primaryGreen.100'}
                  bgImage={!singleUserData?.isActive ? greenBtnTexture : 'none'}
                  bgPos={'center'}
                  bgSize={'cover'}
                  bgRepeat={'no-repeat'}
                  color={singleUserData?.isActive ? "#fff" : 'primaryYellow.100'}
                  fontWeight={'500'}
                  border={'2px solid'}
                  borderColor={singleUserData?.isActive ? "#CE1004" : 'primaryGreen.100'}
                  textAlign={'left'}
                  _hover={{
                    bg: "primaryGreen.100",
                    borderColor: "primaryGreen.100",
                    color: '#fff'
                  }}
                >
                  <Text as={'span'}>{singleUserData?.isActive ? 'Block' : 'Unblock'}</Text>
                </Button>
              </Stack>
            }
          </Box>
        </Stack >
        <Heading fontSize="24px" fontWeight="600">Purchased items:</Heading>
        <Stack direction='row' flexWrap="wrap" spacing="0" margin="0 !important" gap="2%" mr="60px !important">
          {
            singleUserData?.orders?.length > 0 &&
            singleUserData?.orders.map(val => <PurchasedItemBox val={val} setSelectedItem={setSelectedItem} />)
          }
        </Stack>
      </Stack >
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent
          bgSize="cover"
          bgPos="center"
          backgroundImage={shapeb}
          p="20px 40px">
          <ModalBody>
            <Stack direction={'row'} alignItems="center" justifyContent={'space-between'} mb="15px">
              <Heading fontSize={'22px'} fontWeight={'600'}>Order Details</Heading>
              <Box>
                <Text color="#616161">Purchase Date:</Text>
                <Text fontWeight={'600'}>{new Date(String(selectedItem?.createdAt).split('T')[0]).toLocaleDateString("en-US", options)}</Text>
              </Box>
            </Stack>
            <Stack direction={'row'} alignItems="center" mb="30px !important" mt="0 !important" p={"15px 0 !important"}>
              <Image src={selectedItem?.store?.logo ? `${imgUrl}/${selectedItem?.store?.logo}` : HotDog} w="92px" h="92px" objectFit={'cover'} borderRadius="12px" />
              <Flex justifyContent="space-between" w="full">
                <Box>
                  <Heading fontSize="16px" fontWeight="600" textTransform="capitalize" mb="5px">{selectedItem?.store?.store_name}</Heading>
                  <Text fontSize="14px" color="#616161" mb="10px">{selectedItem?.store?.area}</Text>
                  {/* <Text fontSize="14px" color="#78828A"><Icon as={FaStar} color="primaryYellow.100" fontSize="12px" /> 4.8 (1.2K) | 1.5 km</Text> */}
                </Box>
                <Box
                  display="flex"
                  flexDir={'column'}
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* <Icon cursor="pointer" color="primaryYellow.100" as={FaHeart} fontSize="22px" /> */}
                  <Button onClick={() => navigate(`/super-dashboard/partner/${selectedItem?.store?._id}`)} h="fit-content" w="fit-content" p="0" minW="0" minH="0" bg="transparent" alignItems="flex-end">
                    <Icon as={FaChevronRight} fontSize="20px" color="#78828A" />
                  </Button>
                </Box>
              </Flex>
            </Stack>
            <Stack fontSize={'14px'} className='orderDetModal'>
              <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                <Box>Quantity ({selectedItem?.items?.length} items)</Box>
                <Box>$12</Box>
              </Stack>
              <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                <Box>Shipping fee: {selectedItem?.shippingFees}</Box>
                <Box>$23</Box>
              </Stack>
              <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                <Box>Voucher</Box>
                <Box>{selectedItem?.voucher ?? '0'}</Box>
              </Stack>
              <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                <Box>Shipping Distance: {selectedItem?.shippingDistance}</Box>
                {/* <Box>{}</Box> */}
              </Stack>
              <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                <Box fontWeight={'600'}>Total Payment</Box>
                <Box fontWeight={'600'}><strike style={{ color: '#616161', fontSize: '12px', marginRight: '5px' }}>C$0</strike>${selectedItem?.totalPrice}</Box>
              </Stack>
              <Stack py="15px" my="15px" borderTop={'1px solid #adadad'} borderBottom={'1px solid #adadad'} direction="row" alignItems={'center'} justifyContent={'space-between'}>
                <Box fontWeight={'600'}>Payment Method</Box>
                <Box fontWeight={'600'}>Cash</Box>
              </Stack>
            </Stack>
            <Stack className='orderDetPersonal'>
              <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                <Box>Order code</Box>
                <Box>{selectedItem?.orderNo}</Box>
              </Stack>
              <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                <Box>Receiver</Box>
                <Box>{selectedItem?.customer?.username}</Box>
              </Stack>
              <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                <Box>Phone Number</Box>
                <Box>{selectedItem?.customer?.number}</Box>
              </Stack>
              <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                <Box>Address</Box>
                <Box maxW={'250px'}>{selectedItem?.customer?.address}</Box>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
