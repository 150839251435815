import { Box, Image, Stack, Text, Heading, Icon, Button } from '@chakra-ui/react'
import { FaEdit, FaStar } from 'react-icons/fa'
import foodPic from '../../../assets/images/auth/r6.jpg'
import { imgUrl } from '../../../utilities/config'
import { RiEdit2Line, RiEditFill } from 'react-icons/ri'
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { useNavigate } from 'react-router-dom'

export default function StoreItemBox({ _id, pictures, discount, item, w, badgeSize, review, tags }) {
    const navigate = useNavigate();
    return (
        <Box cursor={'pointer'}
            onClick={() => navigate(`/dashboard/single-item/${_id}`)}
            key={_id} boxShadow="0px 0px 30px 0px #adadad" w={w ?? '23%'} pb="20px" mb="35px !important" position="relative" borderRadius="12px" overflow="hidden">
            <Image src={pictures[0] ? `${imgUrl}/${pictures[0]}` : foodPic} w="330px" h="174px" objectFit="cover" mb={"20px"} />
            {/* {
                discount &&
                <Stack className="btn" position="absolute" right="15px" top="134px">
                    <Text
                        fontSize={badgeSize ?? "14px"}
                        justifyContent={'center'}
                        padding={'2px 10px'}
                        bg={"primaryYellow.100"}
                        color={'primaryGreen.100'}
                        fontWeight={'500'}
                        border={'2px solid'}
                        borderColor={"primaryYellow.100"}
                        textAlign={'left'}
                        borderRadius="4px"
                    >
                        <Text as="span">{discount}% off your order</Text>
                    </Text>
                </Stack>
            } */}
            <Box px="20px">
                <Heading fontSize="18px" fontWeight="600" mb="10px">{item ?? 'Loading'}</Heading>
                <Text color="#78828A"><Icon color="primaryYellow.100" as={FaStar} /> {review?.length > 0 ? `4.8(${review?.length})` : `0`} | {tags} </Text>
            </Box>
        </Box>
    )
}