import { Stack, Box, Text } from '@chakra-ui/react';
import LoggedInPic from '../DashNav/LoggedInPic';

export default function DashboardHeader() {
    const options = {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    };
    return (
        <Stack p={"14px 80px 14px 0"} margin={'0 !important'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Box>
                <Text fontWeight={'500'} fontSize={'18px'}>{new Date().toLocaleDateString('en-US', options)}</Text>
            </Box>
            <Box>
                <LoggedInPic />
            </Box>
        </Stack>
    )
}