import { Stack, Heading, Icon, Text, Box, Button, Image, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Grid, GridItem, useToast, } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiMenuAlt1, HiOutlineClock } from 'react-icons/hi'
import { useEffect, useState } from 'react'
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import eventPic from '../../../assets/images/eventPic.svg'
import { useNavigate } from 'react-router';
import { GET, PUT } from '../../../utilities/ApiProvider.js';
import { useSelector } from 'react-redux';
import { imgUrl } from '../../../utilities/config.js';
import { years } from '../../../utilities/helper.js';

export default function HelpWrap() {

    const navigate = useNavigate();
    const toast = useToast();
    const user = useSelector(state => state?.value);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowingArchive, setIsShowingArchive] = useState(0);
    const [eventsList, setEventsList] = useState({
        curEvents: [],
        arcEvents: [],
        pastEvents: []
    });
    const options = { day: "numeric", month: "short", year: "numeric" };

    const getEvents = async () => {
        try {
            let res = await GET(`/admin/adminPosts?type=event`, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                setEventsList(res?.data);
                let curEvents = res?.data?.upcoming;
                let arcEvents = res?.data?.archivedEvents;
                // setEventsList({
                //     arcEvents([res?.data?.archivedEvents]);
                // })
                // res?.data?.upcoming?.filter(val => {
                //     if (val?.isArchive === true) {
                //         arcEvents.push(val);
                //     } else {
                //         curEvents.push(val);
                //     }
                // });
                setEventsList({})
                
                setEventsList({
                    curEvents,
                    arcEvents,
                    pastEvents: res?.data?.past
                })
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
    }

    const updateArcStatus = async (id, archiveValue) => {
        setIsLoading(true);
        try {
            let res = await PUT(`/admin/posts/${id}`, { isArchive: !archiveValue }, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                toast({
                    description: 'Event updated!',
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
                getEvents();
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (user) getEvents();
    }, [user])

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Box display={'flex'} justifyContent={'space-between'} mr="60px !important" alignItems={'center'} gap="15px">
                    <Box flex="1" display={'flex'} gap="15px">
                        <Stack className='btn'>
                            <Button
                                onClick={() => setIsShowingArchive(0)}
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={isShowingArchive === 0 ? "primaryGreen.100" : '#D5DADE'}
                                color={isShowingArchive === 0 ? 'primaryYellow.100' : '#000'}
                                fontWeight={'400'}
                                bgImage={isShowingArchive === 0 ? greenBtnTexture : 'none'}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                border={'2px solid'}
                                borderColor={isShowingArchive === 0 ? "primaryGreen.100" : '#D5DADE'}
                                textAlign={'center'}
                                _hover={{
                                    bg: isShowingArchive === 0 ? "primaryGreen.100" : '#D5DADE',
                                    borderColor: isShowingArchive === 0 ? "primaryGreen.100" : '#D5DADE',
                                    color: isShowingArchive === 0 ? '#fff' : '#000'
                                }}
                            >
                                <Text as={'span'}>Events</Text>
                            </Button>
                        </Stack>
                        <Stack className='btn'>
                            <Button
                                onClick={() => setIsShowingArchive(1)}
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={isShowingArchive === 1 ? "primaryGreen.100" : '#D5DADE'}
                                color={isShowingArchive === 1 ? 'primaryYellow.100' : '#000'}
                                fontWeight={'400'}
                                bgImage={isShowingArchive === 1 ? greenBtnTexture : 'none'}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                border={'2px solid'}
                                borderColor={isShowingArchive === 1 ? "primaryGreen.100" : '#D5DADE'}
                                textAlign={'center'}
                                _hover={{
                                    bg: isShowingArchive === 1 ? "primaryGreen.100" : '#D5DADE',
                                    borderColor: isShowingArchive === 1 ? "primaryGreen.100" : '#D5DADE',
                                    color: isShowingArchive === 1 ? '#fff' : '#000'
                                }}
                            >
                                <Text as={'span'}>Archived Events</Text>
                            </Button>
                        </Stack>
                        <Stack className='btn'>
                            <Button
                                onClick={() => setIsShowingArchive(2)}
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={isShowingArchive === 2 ? "primaryGreen.100" : '#D5DADE'}
                                color={isShowingArchive === 2 ? 'primaryYellow.100' : '#000'}
                                fontWeight={'400'}
                                bgImage={isShowingArchive === 2 ? greenBtnTexture : 'none'}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                border={'2px solid'}
                                borderColor={isShowingArchive === 2 ? "primaryGreen.100" : '#D5DADE'}
                                textAlign={'center'}
                                _hover={{
                                    bg: isShowingArchive === 2 ? "primaryGreen.100" : '#D5DADE',
                                    borderColor: isShowingArchive === 2 ? "primaryGreen.100" : '#D5DADE',
                                    color: isShowingArchive === 2 ? '#fff' : '#000'
                                }}
                            >
                                <Text as={'span'}>Past Events</Text>
                            </Button>
                        </Stack>
                    </Box>
                    <Stack flex="1" position={'sticky'} top={'0px'}>
                        <Stack className='btn' w={'fit-content'} ml="auto">
                            <Button
                                onClick={() => navigate('/super-dashboard/add-event')}
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={"primaryGreen.100"}
                                color={'primaryYellow.100'}
                                fontWeight={'400'}
                                border={'2px solid'}
                                bgImage={greenBtnTexture}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                borderColor={"primaryGreen.100"}
                                textAlign={'center'}
                                _hover={{
                                    bg: "primaryGreen.100",
                                    borderColor: "primaryGreen.100",
                                    color: '#fff'
                                }}
                            >
                                <Text as={'span'}>Create New Event</Text>
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
                {
                    isShowingArchive === 1 ?
                        <Stack>
                            <Stack my="25px !important">
                                <Heading fontSize={'22px'}>Archive Events</Heading>
                                <Text fontSize={'16px'} color={'#5A5D64'}>You've {eventsList?.arcEvents.length ?? 0} archive events</Text>
                            </Stack>
                            <Stack spacing={0} direction={'row'} alignItems={'center'} gap="2%" flexWrap={'wrap'} mr="60px !important">
                                {
                                    eventsList?.arcEvents.length > 0 &&
                                    eventsList?.arcEvents.map(val => {
                                        let splittedDate = String(val?.date).split('T')[0].split('-');
                                        let splittedEndDate = String(val?.endDate).split('T')[0].split('-');
                                        return (
                                            <Box key={val?._id} mb="40px !important" w="31%">
                                                <Image lazy src={val?.picture ? `${imgUrl}/${val?.picture}` : eventPic} w="550px" h="244px" overflow={'hidden'} objectFit={'cover'} borderRadius={'10px'} mb="10px" />
                                                <Text fontSize={'16px'} color={'#5A5D64'} mb="10px">
                                                    {years[splittedDate[1]]} {splittedDate[2]} - {years[splittedEndDate[1]]} {splittedEndDate[2]}, {splittedEndDate[0]}
                                                </Text>
                                                <Text fontSize={'16px'} color={'#5A5D64'} mb="10px">{val?.time} - {val?.endtime}</Text>
                                                <Text fontWeight={'600'} fontSize={'18px'} mb="20px" maxW={'450px'}>{val?.name}</Text>
                                                <Stack direction={'row'} alignItems={'center'}>
                                                    <Stack className='btn'>
                                                        <Button
                                                            onClick={() => navigate(`/super-dashboard/event/${val?._id}`)}
                                                            justifyContent={'center'}
                                                            padding={'10px 20px'}
                                                            bg={"primaryGreen.100"}
                                                            color={'primaryYellow.100'}
                                                            w={'180px'}
                                                            bgImage={greenBtnTexture}
                                                            bgPos={'center'}
                                                            bgSize={'cover'}
                                                            bgRepeat={'no-repeat'}
                                                            fontWeight={'400'}
                                                            border={'2px solid'}
                                                            borderColor={"primaryGreen.100"}
                                                            textAlign={'center'}
                                                            _hover={{
                                                                bg: "primaryGreen.100",
                                                                borderColor: "primaryGreen.100",
                                                                color: '#fff'
                                                            }}
                                                        >
                                                            <Text as={'span'}>View Details</Text>
                                                        </Button>
                                                    </Stack>
                                                    <Stack className='btn'>
                                                        <Button
                                                            isLoading={isLoading}
                                                            onClick={() => updateArcStatus(val?._id, val?.isArchive)}
                                                            justifyContent={'center'}
                                                            padding={'10px 20px'}
                                                            bg={"#D5DADE"}
                                                            color={'#000'}
                                                            w={'180px'}
                                                            fontWeight={'400'}
                                                            border={'2px solid'}
                                                            borderColor={"#D5DADE"}
                                                            textAlign={'center'}
                                                            _hover={{
                                                                bg: "#D5DADE",
                                                                borderColor: "#D5DADE",
                                                                color: '#000'
                                                            }}
                                                        >
                                                            <Text as={'span'}>Restore</Text>
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        )
                                    }
                                    )
                                }
                            </Stack>
                        </Stack>
                        : isShowingArchive === 0 ?
                            <Grid
                                templateColumns="repeat(12, 1fr)"
                                gap={{ base: 2, sm: 12 }}
                                mr={'60px !important'}
                            >
                                <GridItem colSpan={{ base: '4', md: '7' }}>
                                    <Stack my="30px">
                                        <Heading fontSize={'22px'}>Events</Heading>
                                        <Text fontSize={'16px'} color={'#5A5D64'}>You've {eventsList?.curEvents.length ?? 0} events</Text>
                                    </Stack>
                                    <Stack>
                                        {
                                            eventsList?.curEvents.length > 0 &&
                                            eventsList?.curEvents.map(val => {
                                                let splittedDate = String(val?.date).split('T')[0].split('-');
                                                let splittedEndDate = String(val?.endDate).split('T')[0].split('-');
                                                return (
                                                    <Box mb="30px !important">
                                                        <Image src={val?.picture ? `${imgUrl}/${val?.picture}` : eventPic} w="483px" h="244px" overflow={'hidden'} objectFit={'cover'} borderRadius={'10px'} mb="10px" />
                                                        <Text fontSize={'16px'} color={'#5A5D64'} mb="10px">
                                                            {years[splittedDate[1]]} {splittedDate[2]} - {years[splittedEndDate[1]]} {splittedEndDate[2]}, {splittedEndDate[0]}
                                                        </Text>                                                        <Text fontSize={'16px'} color={'#5A5D64'} mb="10px">{val?.time} - {val?.endtime}</Text>
                                                        <Text fontWeight={'600'} fontSize={'18px'} mb="20px" maxW={'450px'}>{val?.name}</Text>
                                                        <Stack direction={'row'} alignItems={'center'}>
                                                            <Stack className='btn'>
                                                                <Button
                                                                    onClick={() => navigate(`/super-dashboard/event/${val?._id}`)}
                                                                    justifyContent={'center'}
                                                                    padding={'10px 20px'}
                                                                    bg={"primaryGreen.100"}
                                                                    color={'primaryYellow.100'}
                                                                    w={'180px'}
                                                                    bgImage={greenBtnTexture}
                                                                    bgPos={'center'}
                                                                    bgSize={'cover'}
                                                                    bgRepeat={'no-repeat'}
                                                                    fontWeight={'400'}
                                                                    border={'2px solid'}
                                                                    borderColor={"primaryGreen.100"}
                                                                    textAlign={'center'}
                                                                    _hover={{
                                                                        bg: "primaryGreen.100",
                                                                        borderColor: "primaryGreen.100",
                                                                        color: '#fff'
                                                                    }}
                                                                >
                                                                    <Text as={'span'}>View Details</Text>
                                                                </Button>
                                                            </Stack>
                                                            <Stack className='btn'>
                                                                <Button
                                                                    isLoading={isLoading}
                                                                    onClick={() => updateArcStatus(val?._id, val?.isArchive)}
                                                                    justifyContent={'center'}
                                                                    padding={'10px 20px'}
                                                                    bg={"#D5DADE"}
                                                                    color={'#000'}
                                                                    w={'180px'}
                                                                    fontWeight={'400'}
                                                                    border={'2px solid'}
                                                                    borderColor={"#D5DADE"}
                                                                    textAlign={'center'}
                                                                    _hover={{
                                                                        bg: "#D5DADE",
                                                                        borderColor: "#D5DADE",
                                                                        color: '#000'
                                                                    }}
                                                                >
                                                                    <Text as={'span'}>Archive</Text>
                                                                </Button>
                                                            </Stack>
                                                        </Stack>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Stack>
                                </GridItem>
                                <GridItem colSpan={{ base: '4', md: '5' }}></GridItem>
                            </Grid>
                            :
                            <Stack>
                                <Stack my="25px !important">
                                    <Heading fontSize={'22px'}>Past Events</Heading>
                                    <Text fontSize={'16px'} color={'#5A5D64'}>You've {eventsList?.pastEvents.length ?? 0} archive events</Text>
                                </Stack>
                                <Stack spacing={0} direction={'row'} alignItems={'center'} gap="2%" flexWrap={'wrap'} mr="60px !important">
                                    {
                                        eventsList?.pastEvents.length > 0 &&
                                        eventsList?.pastEvents.map(val => {
                                            let splittedDate = String(val?.date).split('T')[0].split('-');
                                            let splittedEndDate = String(val?.endDate).split('T')[0].split('-');
                                            return (
                                                <Box key={val?._id} mb="40px !important" w="31%">
                                                    <Image lazy src={val?.picture ? `${imgUrl}/${val?.picture}` : eventPic} w="550px" h="244px" overflow={'hidden'} objectFit={'cover'} borderRadius={'10px'} mb="10px" />
                                                    <Text fontSize={'16px'} color={'#5A5D64'} mb="10px">
                                                        {years[splittedDate[1]]} {splittedDate[2]} - {years[splittedEndDate[1]]} {splittedEndDate[2]}, {splittedEndDate[0]}
                                                    </Text>
                                                    <Text fontWeight={'600'} fontSize={'18px'} mb="20px" maxW={'450px'}>{val?.name}</Text>
                                                    <Stack direction={'row'} alignItems={'center'}>
                                                        <Stack className='btn'>
                                                            <Button
                                                                onClick={() => navigate(`/super-dashboard/event/${val?._id}`)}
                                                                justifyContent={'center'}
                                                                padding={'10px 20px'}
                                                                bg={"primaryGreen.100"}
                                                                color={'primaryYellow.100'}
                                                                w={'180px'}
                                                                bgImage={greenBtnTexture}
                                                                bgPos={'center'}
                                                                bgSize={'cover'}
                                                                bgRepeat={'no-repeat'}
                                                                fontWeight={'400'}
                                                                border={'2px solid'}
                                                                borderColor={"primaryGreen.100"}
                                                                textAlign={'center'}
                                                                _hover={{
                                                                    bg: "primaryGreen.100",
                                                                    borderColor: "primaryGreen.100",
                                                                    color: '#fff'
                                                                }}
                                                            >
                                                                <Text as={'span'}>View Details</Text>
                                                            </Button>
                                                        </Stack>
                                                        {/* <Stack className='btn'>
                                                    <Button
                                                        isLoading={isLoading}
                                                        onClick={() => updateArcStatus(val?._id, val?.isArchive)}
                                                        justifyContent={'center'}
                                                        padding={'10px 20px'}
                                                        bg={"#D5DADE"}
                                                        color={'#000'}
                                                        w={'180px'}
                                                        fontWeight={'400'}
                                                        border={'2px solid'}
                                                        borderColor={"#D5DADE"}
                                                        textAlign={'center'}
                                                        _hover={{
                                                            bg: "#D5DADE",
                                                            borderColor: "#D5DADE",
                                                            color: '#000'
                                                        }}
                                                    >
                                                        <Text as={'span'}>Restore</Text>
                                                    </Button>
                                                </Stack> */}
                                                    </Stack>
                                                </Box>
                                            )
                                        })
                                    }
                                </Stack>
                            </Stack>
                }
            </Stack >
        </>
    )
}