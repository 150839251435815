import { Stack, Text, Box, Image, Button, OrderedList, ListItem, useToast } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import { Link, useNavigate, useParams } from "react-router-dom";
// Components
import CounterBox from "../../components/Website/Home/counterBox";
import Header from "../../components/Website/Header/header";
import HeroBanner from '../../components/Website/Home/heroBanner';
import Footer from "../../components/Website/Footer/Footer";
// Images
import SBlog from "../../assets/images/website/blog/SBlog.png";
import BlackOver from '../../assets/images/icon/black.png';
import { moreBlog } from "../../assets/data/data";
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg';
import { useEffect, useState } from "react";
import { GET } from "../../utilities/ApiProvider";
import { imgUrl } from "../../utilities/config";
import { years } from "../../utilities/helper";

const SingleBlog = () => {

    const params = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const [blogData, setBlogData] = useState(null);
    const [recentData, setRecentData] = useState(null);
    const [customDate, setCustomDate] = useState(null);
    const options = { day: "numeric", month: "short", year: "numeric" };

    const getBlogData = async () => {
        try {
            let res = await GET(`/admin/posts/${params?.id}`);
            if (res.status === 200) {
                setBlogData(res?.data);
                setRecentData(res?.recent?.filter(val => val?.isArchive === false));
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
    }

    useEffect(() => {
        getBlogData();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [params]);

    useEffect(() => {
        if (blogData) {
            let splittedDate = String(blogData?.createdAt).split('T')[0].split('-');
            setCustomDate(splittedDate)
        }
    }, [blogData])

    return (
        <>
            <Header />

            <Box>
                <HeroBanner text={"Embracing a Halal Lifestyle with Aimhalal:"} />
            </Box>

            <Box p={"40px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <Text color={'primaryGreen.100'} fontSize={"14px"} lineHeight={"22px"} mb={"15px"}>{customDate ? `${years[customDate[1]]} ${customDate[2]}, ${customDate[0]}` : 'March 31, 2022'}</Text>
                        <Text color={'primaryGreen.100'} fontSize={"35px"} lineHeight={"44px"} mb={"15px"} fontWeight={"600"} maxW={"1225px"}>{blogData ? blogData?.name : ''}</Text>
                    </Box>
                    <Box p={"20px 0px"} w={"100%"}>
                        {
                            blogData &&
                            <Image src={blogData ? `${imgUrl}/${blogData?.picture}` : SBlog} w="100%" height={'500px'} objectFit={'cover'} />
                        }
                    </Box>
                    <Box maxW={"1220px"}>
                        <div dangerouslySetInnerHTML={{ __html: blogData?.description }} />
                        {/* <Text color={'primaryGreen.100'} fontSize={"18px"} lineHeight={"22px"} mb={"20px"}>{blogData ? blogData?.description : ''}</Text> */}
                        {/* <Text fontSize={"18.8px"} lineHeight={"22px"} fontWeight={"500"} mb={"20px"}>Here's what you can expect from our Halal Markets feature:</Text>
                        <OrderedList>
                            <ListItem fontSize={"14.3px"} lineHeight={"22px"} mb={"20px"}>Wide Range of Categories**: Aimhalal's Halal Markets cover a vast array of product categories, including food, cosmetics, personal care, pharmaceuticals, healthcare, tourism, and apparel fashion. Whether you're searching for Halal-certified food items, ethically produced cosmetics, or travel options that align with your cultural and religious values, our Halal Markets have got you covered</ListItem>
                            <ListItem fontSize={"14.3px"} lineHeight={"22px"} mb={"20px"}>Find Halal Groceries Nearby**: With Aimhalal's Halal Groceries feature, you can effortlessly locate Halal grocery stores in your vicinity. Our user-friendly interface allows you to search for Halal groceries based on your current location, making it convenient to find the nearest stores that offer a wide range of Halal products.</ListItem>
                        </OrderedList>
                        <Text fontSize={"14.3px"} lineHeight={"22px"} mb={"20px"}>At Aimhalal, we recognize the importance of sourcing high-quality Halal meat from trusted suppliers. That's why we're thrilled to introduce our Halal Butchers feature, a reliable resource for finding Halal meat providers that adhere to strict Halal standards</Text>
                        <Text fontSize={"14.3px"} lineHeight={"22px"}>At Aimhalal, we understand the importance of easily accessing Halal groceries that meet your dietary needs and align with your cultural and religious values. That's why we've developed our Halal Groceries feature, a convenient one-window solution for finding Halal grocery stores near you.</Text> */}
                    </Box>
                </Container>
            </Box>

            <Box p={"30px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box mb={"30px"}>
                        <Text fontSize={"28px"} lineHeight={"22px"} fontWeight={"600"} color={'primaryGreen.100'}>Recent Blogs</Text>
                    </Box>
                    <Stack direction={"row"} flexWrap={'wrap'} alignItems={"center"} mb={"30px"}>
                        {recentData?.length > 0 && recentData?.map((val, key) => {
                            return (
                                <Box key={key} mt="20px !important">
                                    <Image
                                        w={{ base: "unset", sm: "unset", md: "unset", lg: "unset", xl: "420px", "2xl": "447px" }}
                                        h={{ base: "unset", sm: "unset", md: "unset", lg: "unset", xl: "320px", "2xl": "339px" }}
                                        src={`${imgUrl}/${val?.picture}`}
                                        mb={"15px"}
                                    />
                                    <Text
                                        color={'primaryGreen.100'}
                                        fontSize={{ base: "unset", sm: "unset", md: "unset", lg: "unset", xl: "20px", "2xl": "22px" }}
                                        fontWeight={"600"}
                                        lineHeight={"38px"}
                                        maxW={"397px"}
                                        mb={"15px"}
                                    >
                                        {val?.name}
                                    </Text>
                                    <Button
                                        onClick={() => navigate(`/singleblog/${val?._id}`)}
                                        bgImage={yellowBtnTexture}
                                        bgPos={'center'}
                                        bgSize={'cover'}
                                        bgRepeat={'no-repeat'}
                                        border={"2px solid #28551A"}
                                        color={"#28551A"}
                                        fontWeight={"600"}
                                        transform={"Skew(-8deg)"}
                                        h={"auto"}
                                        p={"12px 40px"}
                                        _hover={{
                                            background: "none"
                                        }}>
                                        <Text as={"span"} transform={"Skew(8deg)"}>READ MORE</Text>
                                    </Button>
                                </Box>
                            )
                        })}
                    </Stack>
                    <Box p={"40px 0px"}>
                        {/* <CounterBox /> */}
                    </Box>
                </Container>
            </Box>

            <Footer />
        </>
    )
}

export default SingleBlog;