import { Box, Image, Text, Button, Stack } from '@chakra-ui/react'
import { useNavigate } from 'react-router'
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import img from '../../../assets/images/defaultPic.svg'
import { imgUrl } from '../../../utilities/config';

export default function CategoryItem({ _id, picture, fullname, email, createdAt, phone, vehicle, blockThem, changeBlockUser, isBlocking, downShowBlockBtns }) {
    const navigate = useNavigate();
    const options = { day: "numeric", month: "short", year: "numeric" };
    return (
        <>
            <Box flex="1">
                <Text color="#616161">Name:</Text>
                <Stack direction="row" alignItems="center" justifyContent={'flex-start'} >
                    <Image src={picture ? `${imgUrl}/${picture}` : img} w="40px" h="40px" />
                    <Text fontWeight="500" textTransform={'capitalize'}>{fullname}</Text>
                </Stack>
            </Box>
            <Box flex="2">
                <Text color="#616161" mb="15px">Email:</Text>
                <Text fontWeight="500">{email}</Text>
            </Box>
            <Box flex="1">
                <Text color="#616161" mb="15px">Phone:</Text>
                <Text fontWeight="500">{phone || '--'}</Text>
            </Box>
            {/* <Box flex="1">
                <Text color="#616161" mb="15px">Total Orders:</Text>
                <Text fontWeight="500">{totalOrders ?? '--'}</Text>
            </Box> */}
            <Box flex="1">
                <Text color="#616161" mb="15px">Members since:</Text>
                <Text fontWeight="500">{new Date(String(createdAt).split('T')[0]).toLocaleDateString("en-US", options)}</Text>
            </Box>
            <Box flex="1">
                <Text color="#616161" mb="15px">No of Vehicles:</Text>
                <Text fontWeight="500">{vehicle?.length ?? 0}</Text>
            </Box>
            <Box flex="1">
                <Stack className='btn'>
                    <Button
                        onClick={() => navigate(`/super-dashboard/rider/${_id}`)}
                        justifyContent={'center'}
                        padding={'10px 20px'}
                        bg={"primaryGreen.100"}
                        w="200px"
                        bgImage={greenBtnTexture}
                        bgPos={'center'}
                        bgSize={'cover'}
                        bgRepeat={'no-repeat'}
                        color={'primaryYellow.100'}
                        fontWeight={'400'}
                        border={'2px solid'}
                        borderColor={"primaryGreen.100"}
                        textAlign={'left'}
                        _hover={{
                            bg: "primaryGreen.100",
                            borderColor: "primaryGreen.100",
                            color: '#fff'
                        }}
                    >
                        <Text as={'span'}>View Details</Text>
                    </Button>
                </Stack>
            </Box>
            {
                !downShowBlockBtns &&
                <Box flex="1">
                    <Stack className='btn'>
                        <Button
                            onClick={() => changeBlockUser(_id, blockThem)}
                            isLoading={isBlocking}
                            justifyContent={'flex-start'}
                            padding={'10px 20px'}
                            w="fit-content"
                            alignSelf={'end'}
                            bg={blockThem ? "#CE1004" : 'primaryGreen.100'}
                            bgImage={!blockThem ? greenBtnTexture : 'none'}
                            bgPos={'center'}
                            bgSize={'cover'}
                            bgRepeat={'no-repeat'}
                            color={blockThem ? "#fff" : 'primaryYellow.100'}
                            fontWeight={'500'}
                            border={'2px solid'}
                            borderColor={blockThem ? "#CE1004" : 'primaryGreen.100'}
                            textAlign={'left'}
                            _hover={{
                                bg: "primaryGreen.100",
                                borderColor: "primaryGreen.100",
                                color: '#fff'
                            }}
                        >
                            <Text as={'span'}>{blockThem ? 'Block' : 'Unblock'}</Text>
                        </Button>
                    </Stack>
                </Box>
            }
        </>
    )
}