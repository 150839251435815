import { Text, Box, Image, Button } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

import yellowBtnTexture from '../../../assets/images/yellowBtnTexture.svg';
import { imgUrl } from "../../../utilities/config";
import { years } from "../../../utilities/helper.js"

const EventCard = ({ img, date, text, href, description, time, picture, endDate, endtime }) => {
    const navigate = useNavigate();
    const options = { day: "numeric", month: "short", year: "numeric" };
    let splittedDate = String(date).split('T')[0].split('-');
    let splittedEndDate = String(endDate).split('T')[0].split('-');

    return (
        <>
            <Image

                w={{ base: "unset", sm: "unset", md: "unset", lg: "unset", xl: "420px", "2xl": "447px" }}
                h={{ base: "unset", sm: "unset", md: "unset", lg: "unset", xl: "320px", "2xl": "339px" }}
                src={picture ? `${imgUrl}/${picture}` : img}
                my={"15px"}
                objectFit={'cover'}
            />
            <Text
                fontSize={"12px"}
                lineHeight={"22px"}
                mb={"15px"}
                color={'primaryGreen.100'}
            >
                {`${years[splittedDate[1]]} ${splittedDate[2]}, ${splittedDate[0]}`} - {`${years[splittedEndDate[1]]} ${splittedEndDate[2]}, ${splittedEndDate[0]}`}
            </Text>
            <Text
                fontSize={"12px"}
                lineHeight={"22px"}
                mb={"15px"}
                color={'primaryGreen.100'}
            >
                {time ? String(time) : null} - {endtime ? String(endtime) : null}
            </Text>
            <Text
                fontSize={{ base: "unset", sm: "unset", md: "unset", lg: "unset", xl: "20px", "2xl": "22px" }}
                fontWeight={"600"}
                lineHeight={"38px"}
                maxW={"397px"}
                color={'primaryGreen.100'}
                mb={"15px"}
            >
                {text}
            </Text>
            <Button
                onClick={() => navigate(`/singleevent/${href}`)}
                bgImage={yellowBtnTexture}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                border={"2px solid #28551A"}
                color={"#28551A"}
                fontWeight={"600"}
                transform={"Skew(-8deg)"}
                h={"auto"}
                p={"12px 40px"}
                _hover={{
                    background: "none"
                }}>
                <Text as={"span"} transform={"Skew(8deg)"}>View Details</Text>
            </Button>
        </>
    )
}

export default EventCard;