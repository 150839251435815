import { Stack, Text, Box, Image, Button, useToast, Spinner } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import { Link, useNavigate } from "react-router-dom";
// Components
import SignCard from "../../components/Website/Home/signCard";
import CounterBox from "../../components/Website/Home/counterBox";
import Header from "../../components/Website/Header/header";
import HeroBanner from '../../components/Website/Home/heroBanner';
import Footer from "../../components/Website/Footer/Footer";
// Images
import { moreBlog, signData } from '../../assets/data/data';
import Blog1 from '../../assets/images/website/blog/blog1.png';
import BlackOver from '../../assets/images/icon/black.png';
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg'
import { useEffect, useState } from "react";
import { imgUrl } from "../../utilities/config";
import { GET } from "../../utilities/ApiProvider";
import { years } from "../../utilities/helper.js";

const Blog = () => {

    const toast = useToast();
    const navigate = useNavigate();
    const [blogList, setBlogList] = useState({
        curEvents: [
            {
                picture: null,
                createdAt: null,
                name: null,
                description: null
            }
        ],
    });
    const options = { day: "numeric", month: "short", year: "numeric" };
    const [customDate, setCustomDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getBlogs = async () => {
        setIsLoading(true)
        try {
            let res = await GET(`/admin/posts?type=blog`);
            if (res.status === 200) {
                // setBlogList(res?.data);
                let upComingEves = res?.data?.upcoming;
                let curEvents = [];
                let arcEvents = [];
                upComingEves?.filter(val => {
                    if (val?.isArchive === true) {
                        arcEvents.push(val);
                    } else {
                        curEvents.push(val);
                    }
                });
                setBlogList({
                    curEvents,
                    arcEvents
                })
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false)
    }

    useEffect(() => {

        getBlogs();

        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, []); // Empty dependency array ensures that this effect runs only once after initial mount

    useEffect(() => {
        if (blogList?.curEvents?.length > 0) {
            let splittedDate = String(blogList?.curEvents[0]['createdAt']).split('T')[0].split('-');
            setCustomDate(splittedDate)
        }

    }, [blogList]); // Empty dependency array ensures that this effect runs only once after initial mount

    return (
        <>
            <Header />

            <Box>
                <HeroBanner text={"Blogs"} />
            </Box>

            <Box p={"50px 0px"} bgImage={BlackOver}>
                {
                    <Container maxW={"1440px"}>
                        {
                            isLoading ? <Spinner /> :
                                <>
                                    <Box pb={"40px"}>
                                        {
                                            blogList?.curEvents?.length > 0 &&
                                            <>
                                                <Text fontSize={"35px"} fontWeight={"600"} lineHeight={"22px"} mb={"50px"} color={'primaryGreen.100'}>Recent Blog</Text>
                                                <Stack direction={{ base: "column", xl: "row" }} alignItems={"center"} justifyContent={"space-between"} mb={"40px"}>
                                                    <Box flex={1}>
                                                        <Image src={blogList?.curEvents?.length > 0 && blogList?.curEvents[0]['cover'] ? `${imgUrl}/${blogList?.curEvents[0]['cover']}` : ''} w={"685px"} height={'400px'} objectFit={'cover'} />
                                                    </Box>
                                                    <Box flex={1} maxW={"612px"}>
                                                        {customDate === null ? <Spinner color="primaryGreen.100" /> : `${years[customDate[1]]} ${customDate[2]}, ${customDate[0]}`}
                                                        <Text fontSize={{ base: "20px", xl: "26px" }} fontWeight={"600"} color={'primaryGreen.100'} lineHeight={"38px"} mb={"20px"}>{blogList?.curEvents?.length > 0 && blogList?.curEvents[0]['name'] ? blogList?.curEvents[0]['name'] : 'Loading...'}</Text>
                                                        {/* <Text fontSize={"15px"} lineHeight={"22px"} mb={"30px"} color={'primaryGreen.100'}>{blogList?.curEvents?.length > 0 && blogList?.curEvents[0]['description'] ? blogList?.curEvents[0]['description'] : 'Loading...'}</Text> */}
                                                        <Box>
                                                            <Button
                                                                onClick={() => navigate(`/singleblog/${blogList?.curEvents[0]['_id']}`)}
                                                                bgImage={yellowBtnTexture}
                                                                bgPos={'center'}
                                                                bgSize={'cover'}
                                                                bgRepeat={'no-repeat'}
                                                                border={"2px solid #28551A"}
                                                                color={"#28551A"}
                                                                fontWeight={"600"}
                                                                transform={"Skew(-8deg)"}
                                                                h={"auto"}
                                                                p={"12px 40px"}
                                                                _hover={{
                                                                    background: "none"
                                                                }}>
                                                                <Text as={"span"} transform={"Skew(8deg)"}>READ MORE</Text>
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Stack>
                                            </>
                                        }
                                    </Box>
                                    <Box pb={"40px"}>
                                        {
                                            blogList?.curEvents?.length > 0 &&
                                            <Text fontSize={"35px"} fontWeight={"600"} lineHeight={"22px"} mb={"50px"} color={'primaryGreen.100'}>Must Read Blogs</Text>
                                        }
                                        <Stack direction={"row"} flexWrap={'wrap'} justifyContent={"space-between"} spacing={0}>
                                            {
                                                // blogList?.curEvents?.length > 0 ?
                                                blogList?.curEvents?.map(val => {
                                                    let splittedDate = String(val?.date).split('T')[0].split('-');
                                                    return (
                                                        <Box mb="40px" w={{ base: "100%", md: "48%", xl: "32%" }} key={val?._id}>
                                                            <Image
                                                                objectFit={'cover'}
                                                                borderRadius={'12px'}
                                                                w={{ base: "unset", sm: "unset", md: "unset", lg: "unset", xl: "420px", "2xl": "447px" }}
                                                                h={{ base: "unset", sm: "unset", md: "unset", lg: "unset", xl: "320px", "2xl": "339px" }}
                                                                src={`${imgUrl}/${val?.cover}`}
                                                                mb={"15px"}
                                                            />
                                                            <Text
                                                                fontSize={"12px"}
                                                                lineHeight={"22px"}
                                                                mb={"15px"}
                                                                color={'primaryGreen.100'}
                                                            >
                                                                {`${years[splittedDate[1]]} ${splittedDate[2]}, ${splittedDate[0]}`}
                                                            </Text>
                                                            <Text
                                                                color={'primaryGreen.100'}
                                                                fontSize={{ base: "unset", sm: "unset", md: "unset", lg: "unset", xl: "20px", "2xl": "22px" }}
                                                                fontWeight={"600"}
                                                                lineHeight={"38px"}
                                                                maxW={"397px"}
                                                                mb={"15px"}
                                                            >
                                                                {val?.name}
                                                            </Text>
                                                            <Button
                                                                onClick={() => navigate(`/singleblog/${val?._id}`)}
                                                                bgImage={yellowBtnTexture}
                                                                bgPos={'center'}
                                                                bgSize={'cover'}
                                                                bgRepeat={'no-repeat'}
                                                                border={"2px solid #28551A"}
                                                                color={"#28551A"}
                                                                fontWeight={"600"}
                                                                transform={"Skew(-8deg)"}
                                                                h={"auto"}
                                                                p={"12px 40px"}
                                                                _hover={{
                                                                    background: "none"
                                                                }}>
                                                                <Text as={"span"} transform={"Skew(8deg)"}>READ MORE</Text>
                                                            </Button>
                                                        </Box>
                                                    )
                                                })
                                                // :
                                                // moreBlog.map((val, key) => {
                                                //     return (
                                                //         <Box w={{ base: "100%", md: "48%", xl: "32%" }} key={key} mt="20px !important">
                                                //             <Image
                                                //                 w={{ base: "unset", sm: "unset", md: "unset", lg: "unset", xl: "420px", "2xl": "447px" }}
                                                //                 h={{ base: "unset", sm: "unset", md: "unset", lg: "unset", xl: "320px", "2xl": "339px" }}
                                                //                 src={val.img}
                                                //                 mb={"15px"}
                                                //             />
                                                //             <Text
                                                //                 fontSize={"12px"}
                                                //                 lineHeight={"22px"}
                                                //                 mb={"15px"}
                                                //             >
                                                //                 {val.date}
                                                //             </Text>
                                                //             <Text
                                                //                 fontSize={{ base: "unset", sm: "unset", md: "unset", lg: "unset", xl: "20px", "2xl": "22px" }}
                                                //                 fontWeight={"600"}
                                                //                 lineHeight={"38px"}
                                                //                 maxW={"397px"}
                                                //                 mb={"15px"}
                                                //             >
                                                //                 {val.text}
                                                //             </Text>
                                                //             <Button
                                                //                 bgImage={yellowBtnTexture}
                                                //                 bgPos={'center'}
                                                //                 bgSize={'cover'}
                                                //                 bgRepeat={'no-repeat'}
                                                //                 border={"2px solid #28551A"}
                                                //                 color={"#28551A"}
                                                //                 fontWeight={"600"}
                                                //                 transform={"Skew(-8deg)"}
                                                //                 h={"auto"}
                                                //                 p={"12px 40px"}
                                                //                 as={Link}
                                                //                 to={val.href}
                                                //                 _hover={{
                                                //                     background: "none"
                                                //                 }}>
                                                //                 <Text as={"span"} transform={"Skew(8deg)"}>READ MORE</Text>
                                                //             </Button>
                                                //         </Box>
                                                //     )
                                                // })
                                            }
                                        </Stack>
                                    </Box>
                                </>
                        }
                    </Container>
                }
            </Box>

            <Box p={"50px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Box>
                        <Stack
                            direction={{ base: "column", xl: "row" }}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            {signData.map((val, key) => {
                                return (
                                    <SignCard img={val.img} text1={val.text1} text2={val.text2} main={val.main} btn={val.btn} href={val.href} key={key} />
                                )
                            })
                            }
                        </Stack>
                    </Box>
                    <Box pt={"40px"}>
                        {/* <CounterBox /> */}
                    </Box>
                </Container>
            </Box>

            <Footer />
        </>
    )
}

export default Blog;