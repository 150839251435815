import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import PromotionWrap from '../../../components/SuperDashboard/Promotion/PromotionWrap';
import { Stack } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'

export default function SuperPromotionDashboard() {
    return (
        <Stack
            backgroundImage={shapeb}
            overflowY="hidden"
        >
            <MainDashboard>
                <PromotionWrap />
            </MainDashboard>
        </Stack>
    );
}