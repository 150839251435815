import {
  Stack,
  Box,
  Image,
  Text,
  Heading,
  Icon,
  Checkbox,
  FormLabel,
  Input,
  Button,
  Link,
  InputGroup,
  InputLeftAddon,
  Select,
  useToast,
} from '@chakra-ui/react';
import Go from '../../assets/images/icon/go.png';
import Fb from '../../assets/images/icon/fb.png';
import { AiOutlineUser, AiOutlineLock } from 'react-icons/ai';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import { BiArrowBack } from 'react-icons/bi';
import { GoMail } from 'react-icons/go';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import shapeb from '../../assets/images/icon/black.png';
import { POST } from '../../utilities/ApiProvider';
import { baseUrl } from '../../utilities/config';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from '../../reducers/useReducers';
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg';

export default function LoginForm() {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.value);
  const [showpass, setshowpass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    email: '',
    password: '',
  });

  const submitLogin = async () => {
    setIsLoading(true);
    try {
      if (loginInfo.email === '' || loginInfo.password === '') {
        toast({
          description: 'All fields are required!',
          status: 'error',
          position: 'top-right',
          duration: 1000,
        });
      } else {
        let res = await POST(`/auth/login`, loginInfo);
        if (res.status === 200) {
          const isMobileUser = !!res.vendorRequest;
          if (isMobileUser) {
            let custData = {
              name: res.data.username,
              phone: res.data.number,
              email: res.data.email,
              id: res.data._id,
            };
            console.log('Mobile user');
            if (!res?.vendorRequest?.isVendor) {
              if (res?.vendorRequest?.isVendorRequest) {
                toast({
                  description: 'Your vendor request is pending!',
                  status: 'info',
                  position: 'top-right',
                  duration: 3000,
                });
                setIsLoading(false);
                return;
              } else {
                toast({
                  description: res.message ?? res?.messgae,
                  status: 'error',
                  position: 'top-right',
                  duration: 3000,
                });
                localStorage.setItem('custData', JSON.stringify(custData));
                navigate('/register');
                return;
              }
            } else {
              if (res?.data?.store === null) {
                localStorage.setItem('custData', JSON.stringify(custData));
                navigate('/createnewstore');
              } else {
                navigate('/dashboard');
              }
            }
          } else {
            console.log('Not a mobile user');
            if (res.data.role.name === 'vendor') {
              if (res.data.store === null) {
                navigate('/createnewstore');
              } else {
                navigate('/dashboard');
              }
            } else {
              navigate('/super-dashboard');
            }
          }
          toast({
            description: 'Login successful!',
            status: 'success',
            position: 'top-right',
            duration: 3000,
          });
          dispatch(loadUser(res.data));
        } else {
          toast({
            description: res.message ?? res?.messgae,
            status: 'error',
            position: 'top-right',
            duration: 3000,
          });
        }
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'success',
        position: 'top-right',
        duration: 1000,
      });
    }
    setIsLoading(false);
  };

  const handleKeyPress = event => {
    if (event.keyCode === 13) {
      submitLogin();
    }
  };

  useEffect(() => {
    console.log(user);
    if (user) {
      if (user?.role?.name === 'vendor') {
        if (user?.store === null) {
          navigate('/createnewstore');
        } else {
          navigate('/dashboard');
        }
      } else if (
        user?.role?.name === 'superadmin' ||
        user?.role?.name === 'masteradmin' ||
        user?.role?.name === 'admin'
      ) {
        navigate('/super-dashboard');
      } else {
        navigate('/');
      }
    }
  }, [user]);

  return (
    <>
      <Stack
        w={{ base: '95%', xl: '70%' }}
        bg={'#fff'}
        py={'50px'}
        m={'0 auto'}
        px={'20px'}
        border={'5px solid'}
        borderColor="primaryYellow.100"
        borderRadius={'8px'}
        backgroundImage={shapeb}
      >
        <Stack mb={8}>
          <Box className="boxlink">
            <Link to={'/'} as={ReactLink}>
              <Icon fontSize={'17px'} color={'#000'} as={BiArrowBack} />
            </Link>
          </Box>
        </Stack>
        <Stack className="AH mh p1" pb={6}>
          <Heading as={'h3'} fontSize={{ base: '22px', xl: '30px' }}>
            Log In
          </Heading>
          <Text fontSize={{ base: '12px', xl: '16px' }}>
            Enter your details to log in your account
          </Text>
        </Stack>
        <Stack className="regwarp" gap={4} pb={14}>
          <Stack>
            <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
              Email Address
            </FormLabel>
            <Box className="Ifiled">
              <Input
                value={loginInfo.email}
                onChange={e =>
                  setLoginInfo({
                    ...loginInfo,
                    email: e.target.value,
                  })
                }
                type="email"
                placeholder="Enter here"
              />
            </Box>
          </Stack>
          <Stack>
            <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
              Password
            </FormLabel>
            <Box className="Ifiled">
              <Icon fontSize={'25px'} color={'#000'} as={AiOutlineLock} />
              <Input
                value={loginInfo.password}
                onKeyDownCapture={handleKeyPress}
                onChange={e =>
                  setLoginInfo({
                    ...loginInfo,
                    password: e.target.value,
                  })
                }
                type={showpass ? 'text' : 'password'}
                placeholder="Enter here"
              />
              <Button onClick={() => setshowpass(!showpass)}>
                <Icon as={showpass ? BsEye : BsEyeSlash} />
              </Button>
            </Box>
          </Stack>
          <Stack textAlign={'right'} className="cterm">
            <Link
              as={ReactLink}
              fontWeight={500}
              fontSize={'13px'}
              color={'#203863'}
              to={'/forgotpassword'}
            >
              Forgot Password?
            </Link>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={8}>
            <Stack className="btn">
              <Button
                onClick={submitLogin}
                isLoading={isLoading}
                padding={'10px 50px'}
                bg={'#FFC00F'}
                bgImage={yellowBtnTexture}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                color={'primaryGreen.100'}
                fontWeight={'500'}
                w={'fit-content'}
                border={'2px solid'}
                borderColor="primaryGreen.100"
                textTransform={'uppercase'}
              >
                <Text as={'span'}>Login</Text>
              </Button>
            </Stack>
            {/* <Text>or</Text>
            <Box display={'flex'} gap={2}>
              <Button padding={'10px 30px'}>

                <Image maxW={'20px'} src={Go} />
              </Button>
              <Button padding={'10px 30px'}>
                <Image maxW={'20px'} src={Fb} />
              </Button>
            </Box> */}
          </Stack>
        </Stack>
        <Stack textAlign={'center'}>
          <Text fontSize={'13px'} color={'#203863'} fontWeight={'500'}>
            Don’t have an account?{' '}
            <Link
              as={ReactLink}
              fontWeight={600}
              fontSize={'13px'}
              color={'#203863'}
              to={'/register'}
            >
              Sign Up
            </Link>
          </Text>
        </Stack>
      </Stack>
    </>
  );
}
