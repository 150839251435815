import { Stack, Box, Icon, Input, Button, Text, Image, Heading, useToast, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, FormControl, FormLabel, ModalFooter } from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import foodPic from '../../../assets/images/auth/r6.jpg'
import { HiOutlineSearch, HiChevronRight } from 'react-icons/hi'
import { FaStar, FaTimes } from 'react-icons/fa'
import StoreItemBox from '../Store/StoreItemBox.js';
import { useNavigate } from 'react-router-dom';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { useSelector } from 'react-redux';
import { GET, POST, DELETE } from '../../../utilities/ApiProvider.js'
import { BsPlusCircle } from 'react-icons/bs';
import { imgUrl } from '../../../utilities/config.js'
import { MdDeleteForever } from 'react-icons/md'

export default function HomeWrap() {

  const navigate = useNavigate();
  const toast = useToast();
  const imgRef = useRef();
  const user = useSelector(state => state?.value);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [menuImage, setMenuImage] = useState(null);

  const [isShowingAll, setIsShowingAll] = useState(false);
  const [popularItems, setPopularItems] = useState([]);
  const [selectedCat, setSelectedCat] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [storeItems, setStoreItems] = useState(null);

  const [allItems, setAllItems] = useState([
    {
      pictures: [foodPic],
      name: 'Hamburger',
      discount: 4,
    },
    {
      pictures: [foodPic],
      name: 'Hamburger',
      discount: 4,
    },
    {
      pictures: [foodPic],
      name: 'Hamburger',
      discount: 4,
    },
    {
      pictures: [foodPic],
      name: 'Hamburger',
      discount: 4,
    },
    {
      pictures: [foodPic],
      name: 'Hamburger',
      discount: 4,
    },
    {
      pictures: [foodPic],
      name: 'Hamburger',
      discount: 4,
    },
    {
      pictures: [foodPic],
      name: 'Hamburger',
      discount: 4,
    },
    {
      pictures: [foodPic],
      name: 'Hamburger',
      discount: 4,
    },
    {
      pictures: [foodPic],
      name: 'Hamburger',
      discount: 4,
    },
    {
      pictures: [foodPic],
      name: 'Hamburger',
      discount: 4,
    },
  ]);

  const getMenuData = async () => {
    try {
      let res = await GET('/vendor/menus', { authorization: `Bearer ${user?.verificationToken}` });
      if (res.status === 200) {
        setPopularItems(res?.data?.popularthisweek);
        setStoreItems(res?.data.allProducts);
        setStoreData(res.data);
      } else {
        toast({
          description: res?.message,
          status: 'error',
          position: 'top-right',
          duration: 3000
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
  }

  const addCategory = async () => {
    setIsLoading(true);
    try {
      let form = document.getElementById('digitalForm');
      let data = new FormData(form);

      let res = await POST('/vendor/item/digital', data, { authorization: `Bearer ${user?.verificationToken}` });
      if (res.status === 200) {
        toast({
          description: 'Digital Menu Added successfully!',
          status: 'success',
          position: 'top-right',
          duration: 3000
        });
        getMenuData();
        onClose();
      } else {
        toast({
          description: res?.message,
          status: 'error',
          position: 'top-right',
          duration: 3000
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
    setIsLoading(false);
  }

  const deleteDigitalMenu = async (id) => {
    setIsLoading(true);
    try {
      let res = await DELETE(`/vendor/item/digital/${id}`, { Authorization: `Bearer ${user?.verificationToken}` });
      if (res.status === 200) {
        getMenuData()
        toast({
          description: res.message,
          status: 'success',
          position: 'top-right',
          duration: 2500
        });
      } else {
        toast({
          description: res.message,
          status: 'error',
          position: 'top-right',
          duration: 2500
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (user) getMenuData();

    return () => { }
  }, [user]);

  useEffect(() => {
    if (selectedCat) {
      storeData?.categoryWise?.length > 0 &&
        storeData?.categoryWise?.map(val => {
          if (val?.name === selectedCat) setStoreItems(val?.products)
        })
    }

    return () => { }
  }, [selectedCat]);

  useEffect(() => {
    console.log(menuImage)
    return () => { }
  }, [menuImage]);


  return (
    <>
      <DashboardHeader />
      <Stack
        overflowY="auto"
        margin={'0 !important'}
        gap={8}
        borderRadius={'30px 0 0 0'}
        py={10}
        px={6}
        bg={'#fff'}
        height={'100%'}
        backgroundImage={shapeb}
        bgPos={'-32px -100px'}
      >
        {
          !isShowingAll ?
            <>
              <Stack direction="row" justifyContent="space-between" mr="60px">
                <Box w="367px" bgColor="#ECF1F6" borderRadius="50px" display="flex" alignItems="center" px="10px" gap="5px">
                  <Icon as={HiOutlineSearch} fontSize="24px" color="#9CA4AB" />
                  <Input placeholder="Search here..." border="0" fontSize="14px" />
                </Box>
                <Stack direction="row">
                  <Stack className='btn'>
                    <Button
                      onClick={() => onOpen()}
                      justifyContent={'center'}
                      padding={'10px 20px'}
                      bg={"primaryGreen.100"}
                      bgImage={greenBtnTexture}
                      bgPos={'center'}
                      bgSize={'cover'}
                      bgRepeat={'no-repeat'}
                      color={'primaryYellow.100'}
                      fontWeight={'400'}
                      border={'2px solid'}
                      borderColor={"primaryGreen.100"}
                      textAlign={'left'}
                      _hover={{
                        bg: "primaryGreen.100",
                        borderColor: "primaryGreen.100",
                        color: '#fff'
                      }}
                    >
                      <Text as={'span'}>Add Digital Menu</Text>
                    </Button>
                  </Stack>
                  <Stack className='btn'>
                    <Button
                      onClick={() => navigate('/dashboard/add-menu')}
                      justifyContent={'center'}
                      padding={'10px 20px'}
                      bg={"primaryGreen.100"}
                      bgImage={greenBtnTexture}
                      bgPos={'center'}
                      bgSize={'cover'}
                      bgRepeat={'no-repeat'}
                      color={'primaryYellow.100'}
                      fontWeight={'400'}
                      border={'2px solid'}
                      borderColor={"primaryGreen.100"}
                      textAlign={'left'}
                      _hover={{
                        bg: "primaryGreen.100",
                        borderColor: "primaryGreen.100",
                        color: '#fff'
                      }}
                    >
                      <Text as={'span'}>Add New Item</Text>
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
              <Stack>
                <Heading fontSize="24px" fontWeight="600" mb={"10px"}>Category</Heading>
                <Stack direction="row" gap="15px" flexWrap={'wrap'}>
                  {
                    storeData?.categoryWise?.length > 0 &&
                    storeData?.categoryWise?.map(val =>
                      <Button
                        _hover={{
                          bgColor: 'primaryGreen.100',
                          color: 'primaryYellow.100'
                        }}
                        onClick={() => setSelectedCat(val?.name)}
                        key={val?.name}
                        bgColor={selectedCat === val?.name ? 'primaryGreen.100' : "#E3E9ED"}
                        p="5px 25px"
                        borderRadius="50px"
                        color={selectedCat === val?.name ? 'primaryYellow.100' : "#434E58"}
                        fontWeight="600"
                      >
                        {val?.name ?? 'Loading'}
                      </Button>
                    )
                  }
                  <Button
                    _hover={{
                      bgColor: 'primaryGreen.100',
                      color: 'primaryYellow.100'
                    }}
                    onClick={() => {
                      setSelectedCat(null);
                      setStoreItems(storeData?.allProducts);
                    }}
                    bgColor={'primaryGreen.100'}
                    p="5px 10px"
                    borderRadius="50px"
                    color={'primaryYellow.100'}
                    fontWeight="600"
                    mr="20px"
                  >
                    <Icon as={FaTimes} />
                  </Button>
                  {/* <Button bgColor="#E3E9ED" p="5px 25px" borderRadius="50px" color="#434E58" fontWeight="600">Noodles</Button>
                  <Button bgColor="#E3E9ED" p="5px 25px" borderRadius="50px" color="#434E58" fontWeight="600">Rice</Button>
                  <Button bgColor="#E3E9ED" p="5px 25px" borderRadius="50px" color="#434E58" fontWeight="600">Tacos</Button> */}
                </Stack>
              </Stack>
              {
                selectedCat === null && popularItems?.length > 0 &&
                <Stack mr="60px !important">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Heading fontSize="24px" fontWeight="600" mb="30px">Popular Items This Week</Heading>
                    <Stack className='btn'>
                      <Button
                        fontSize="14px"
                        justifyContent={'center'}
                        padding={'10px 20px'}
                        bg={"primaryGreen.100"}
                        color={'primaryYellow.100'}
                        fontWeight={'400'}
                        bgImage={greenBtnTexture}
                        bgPos={'center'}
                        bgSize={'cover'}
                        bgRepeat={'no-repeat'}
                        border={'2px solid'}
                        borderColor={"primaryGreen.100"}
                        textAlign={'left'}
                        _hover={{
                          bg: "primaryGreen.100",
                          borderColor: "primaryGreen.100",
                          color: '#fff'
                        }}
                        onClick={() => setIsShowingAll(true)}
                      >
                        <Text as={'span'}>View More</Text>
                      </Button>
                    </Stack>
                  </Box>
                  <Stack direction="row" gap="1.5%" flexWrap="wrap" spacing={0}>
                    {
                      popularItems?.length > 0 &&
                      popularItems.map(val => <StoreItemBox {...val} w='18.2%' badgeSize="13px" />)
                    }
                  </Stack>
                </Stack>
              }
              <hr style={{ backgroundColor: '#A8A8A8', padding: '1px', margin: '0 60px 0 0' }} />
              <Box display="flex" alignItems="center" justifyContent="space-between" mr="60px !important">
                <Heading fontSize="24px" fontWeight="600" mb="10px">All Items</Heading>
                <Stack className='btn'>
                  <Button
                    fontSize="14px"
                    justifyContent={'center'}
                    padding={'10px 20px'}
                    bg={"primaryGreen.100"}
                    bgImage={greenBtnTexture}
                    bgPos={'center'}
                    bgSize={'cover'}
                    bgRepeat={'no-repeat'}
                    color={'primaryYellow.100'}
                    fontWeight={'400'}
                    border={'2px solid'}
                    borderColor={"primaryGreen.100"}
                    textAlign={'left'}
                    _hover={{
                      bg: "primaryGreen.100",
                      borderColor: "primaryGreen.100",
                      color: '#fff'
                    }}
                    onClick={() => setIsShowingAll(true)}
                  >
                    <Text as={'span'}>View More</Text>
                  </Button>
                </Stack>
              </Box>
              <Stack direction="row" gap="1.5%" flexWrap="wrap" mr="60px !important" spacing={0}>
                {
                  storeItems?.length > 0 &&
                  storeItems.map(val => <StoreItemBox {...val} w='18.2%' badgeSize="13px" />)
                }
              </Stack>
              {
                storeData?.menu?.menu2?.length > 0 &&
                <Heading fontSize="24px" fontWeight="600" my="20px">Digital Menus</Heading>
              }
              <Stack direction="row" pb="30px" flexWrap="wrap" gap="2.5%" spacing="0">
                {
                  storeData?.menu?.menu2?.length > 0 &&
                  storeData?.menu?.menu2?.map(val =>
                    <Box position="relative" key={val?._id} w="23%" h="300px">
                      <Image objectFit={'contain'} src={`${imgUrl}/${val?.picture}`} w="100%" />
                      {
                        user?.role?.name === 'vendor' ?
                          <Box position="absolute" right="-3px" top="-3px" bgColor="red.700" color="#fff" w="fit-content" borderRadius="100%" display="flex" alignItems="center" justifyContent="center">
                            <Icon
                              fontSize="28px"
                              as={MdDeleteForever}
                              cursor="pointer"
                              color="#fff"
                              onClick={() => { deleteDigitalMenu(val?._id) }}
                            />
                          </Box>
                          : null
                      }
                    </Box>
                  )
                }
              </Stack>
            </>
            :
            <>
              <Stack direction="row" justifyContent="space-between" mr="60px">
                <Box w="367px" bgColor="#ECF1F6" borderRadius="50px" display="flex" alignItems="center" px="10px" gap="5px">
                  <Icon as={HiOutlineSearch} fontSize="24px" color="#9CA4AB" />
                  <Input placeholder="Search here..." border="0" fontSize="14px" />
                </Box>
                <Stack className='btn'>
                  <Button
                    onClick={() => setIsShowingAll(false)}
                    justifyContent={'center'}
                    padding={'10px 20px'}
                    bg={"primaryGreen.100"}
                    bgImage={greenBtnTexture}
                    bgPos={'center'}
                    bgSize={'cover'}
                    bgRepeat={'no-repeat'}
                    color={'primaryYellow.100'}
                    fontWeight={'400'}
                    border={'2px solid'}
                    borderColor={"primaryGreen.100"}
                    textAlign={'left'}
                    _hover={{
                      bg: "primaryGreen.100",
                      borderColor: "primaryGreen.100",
                      color: '#fff'
                    }}
                  >
                    <Text as={'span'}>Back</Text>
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="row" gap="1.5%" flexWrap="wrap" mr="60px !important" spacing={0}>
                {
                  storeItems?.length > 0 &&
                  storeItems.map(val => <StoreItemBox {...val} w='18.2%' badgeSize="13px" />)
                }
              </Stack>
            </>
        }
      </Stack >
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent
          bgSize="cover"
          bgPos="center"
          backgroundImage={shapeb}
          p="20px 40px">
          <form id="digitalForm">
            <ModalHeader fontWeight="600" fontSize={'24px'} textAlign="center">Add Digital Menu</ModalHeader>
            <ModalBody>
              <FormLabel mt="20px !important" fontWeight="600">Digital Menu Images</FormLabel>
              <Button
                onClick={() => imgRef?.current.click()}
                w="100%"
                h="115px"
                border="1px solid #BBBBBB"
              >
                <Text display="block" w="100%">
                  <Icon as={BsPlusCircle} display="block" margin="5px auto" />
                  {menuImage?.name ? 'Images Selected!' : "Add"}</Text>
              </Button>
              <Box className="Ifiled">
                <Input
                  ref={imgRef}
                  display="none"
                  onChange={e => setMenuImage(e.target.files[0])}
                  multiple
                  type={'file'}
                  placeholder="Enter here"
                  name="pictures"
                />
              </Box>
            </ModalBody>
            <ModalFooter justifyContent="center">
              <Stack className='btn'>
                <Button
                  onClick={addCategory}
                  justifyContent={'center'}
                  isLoading={isLoading}
                  padding={'10px 20px'}
                  bg={"primaryGreen.100"}
                  w="200px"
                  color={'primaryYellow.100'}
                  fontWeight={'400'}
                  border={'2px solid'}
                  bgImage={greenBtnTexture}
                  bgPos={'center'}
                  bgSize={'cover'}
                  bgRepeat={'no-repeat'}
                  borderColor={"primaryGreen.100"}
                  textAlign={'left'}
                  _hover={{
                    bg: "primaryGreen.100",
                    borderColor: "primaryGreen.100",
                    color: '#fff'
                  }}
                >
                  <Text as={'span'}>Submit</Text>
                </Button>
              </Stack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal >
    </>
  )
}
