import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import AnalyticsWrap from '../../../components/SuperDashboard/Analytics/AnalyticsWrap';
import { Stack } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'

export default function DashboardHome() {
    return (
        <Stack
            backgroundImage={shapeb}
            overflowY="hidden"
        >
            <MainDashboard type={'admin'}>
                <AnalyticsWrap />
            </MainDashboard>
        </Stack>
    );
}
