import React, { useEffect } from 'react'
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Stack,
  Radio,
  Text,
  RadioGroup,
  Image,
  Link,
  Grid,
  GridItem,
  useToast
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import shapeb from '../../../assets/images/icon/black.png'
import LoggedInPic from '../../DashNav/LoggedInPic.js'
import TopInfoBox from './TopInfoBox.js'
import OrderTable from './OrderTable.js'
import PopularITems from './PopularItem/PopularItems.js'
import { useState } from 'react'
import HotDog from '../../../assets/images/icon/hotdog.svg'
import CategoryItem from './CategoryItem.js'
import DashboardHeader from '../../Header/DashboardHeader.js'
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import { GET } from '../../../utilities/ApiProvider.js'
import { useDispatch, useSelector } from 'react-redux';
import { loadVendorData } from '../../../reducers/useReducers.js';

export default function HomeWrap() {

  const toast = useToast();
  const dispatch = useDispatch();
  const user = useSelector(state => state.value);
  const vendorInfo = useSelector(state => state.vendorDashboard);
  const [selectedTable, setSelectedTable] = useState('All Orders');
  const buttonLabels = ['All Orders', 'Accepted', 'Preparing', 'Delivering', 'Completed'];

  const [buttonFilter, setButtonFilter] = useState([]);
  const [orderTableData, setOrderTableData] = useState([]);

  const getVendorDashboardData = async () => {
    try {
      let res = await GET('/vendor', { authorization: `Bearer ${user?.verificationToken}` });
      dispatch(loadVendorData(res?.data));
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500
      });
    }
  }

  useEffect(() => {
    if (vendorInfo) {
      setOrderTableData(vendorInfo?.orders?.all);
      setButtonFilter([vendorInfo?.orders?.all, vendorInfo?.orders?.accepted, vendorInfo?.orders?.preparing, vendorInfo?.orders?.delivery, vendorInfo?.orders?.completed]);
    }
  }, [vendorInfo]);

  useEffect(() => {
    if (user) (async () => {
      getVendorDashboardData();
    })();
  }, [user])

  return (
    <>
      <DashboardHeader />
      <Stack
        overflowY="auto"
        margin={'0 !important'}
        gap={8}
        borderRadius={'30px 0 0 0'}
        py={10}
        px={6}
        bg={'#fff'}
        height={'100%'}
        backgroundImage={shapeb}
        bgPos={'-32px -100px'}
      >
        <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'} gap={'2%'} pr={'60px'}>
          <TopInfoBox title="Active Orders" value={vendorInfo?.orderStatus?.new ?? 0} />
          <TopInfoBox title="Overdue Orders" value={vendorInfo?.orderStatus?.overdueOrder ?? 0} />
          <TopInfoBox title="Completed Orders" value={vendorInfo?.orderStatus?.completetedOrder ?? 0} />
          <TopInfoBox title="Average Time Delivery" value={vendorInfo?.orderStatus?.averageTimeDelivery ?? 0} subIndicator="minutes" />
        </Stack>
        <Stack direction={'row'} gap='1%'>
          {
            buttonFilter.length > 0 &&
            buttonFilter?.map((val, index) =>
              <Stack className='btn' key={index}>
                <Button
                  onClick={() => {
                    setOrderTableData(buttonFilter[index])
                    setSelectedTable(buttonLabels[index])
                  }}
                  justifyContent={'flex-start'}
                  padding={'10px'}
                  w={'193px !important'}
                  bgImage={buttonLabels[index] === selectedTable ? greenBtnTexture : 'none'}
                  bgPos={'center'}
                  bgSize={'cover'}
                  bgRepeat={'no-repeat'}
                  bg={buttonLabels[index] === selectedTable ? "primaryGreen.100" : '#D5DADE'}
                  color={buttonLabels[index] === selectedTable ? '#fff' : '#000'}
                  fontWeight={'400'}
                  border={'2px solid'}
                  borderColor={buttonLabels[index] === selectedTable ? "primaryGreen.100" : '#D5DADE'}
                  textAlign={'left'}
                  _hover={{
                    bg: buttonLabels[index] === selectedTable ? "primaryGreen.100" : '#D5DADE',
                    borderColor: buttonLabels[index] === selectedTable ? "primaryGreen.100" : '#D5DADE',
                    color: buttonLabels[index] === selectedTable ? '#fff' : '#000'
                  }}
                >
                  <Text as={'span'}>{buttonLabels[index]} ({val?.length})</Text>
                </Button>
              </Stack>
            )
          }
        </Stack>
        <Stack>
          <Grid
            templateColumns="repeat(12, 1fr)"
            gap={{ base: 2, sm: 12 }}
          >
            <GridItem colSpan={{ base: '4', md: '7' }}>
              <OrderTable orderTableData={orderTableData} />
            </GridItem>
            <GridItem colSpan={{ base: '4', md: '5' }}>
              <PopularITems popularItems={vendorInfo?.popularItems} address={user?.store?.area} />
            </GridItem>
          </Grid>
        </Stack>
        <Stack borderRadius={'5px'} padding="15px 35px" boxShadow={'0px 0px 35px -15px #000'} bgColor="#fff">
          <Heading fontSize="20px" pb="20px">Categories</Heading>
          {/* <Text color="#606060" mb="20px !important">Lorem ipsum dolor sit amet consectetur</Text> */}
          <Stack direction="row" flexWrap="wrap" gap="1.5%" spacing="0" pb={'20px'}>
            {
              vendorInfo?.categories?.length > 0 &&
              vendorInfo?.categories.map(val =>
                <CategoryItem {...val} />
              )
            }
          </Stack>
        </Stack>
      </Stack >
    </>
  )
}
