import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import CreatePartnerWrap from '../../../components/SuperDashboard/Partners/CreatePartnerWrap.js';
import { Stack } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'

export default function CreatePartnerDashboard() {
    return (
        <Stack
            backgroundImage={shapeb}
            overflowY="hidden"
        >
            <MainDashboard>
                <CreatePartnerWrap />
            </MainDashboard>
        </Stack>
    );
}