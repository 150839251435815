import { Stack, Heading, Icon, Text, Box, Button, Grid, GridItem, FormLabel, Input, useToast, Textarea, Checkbox } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiMenuAlt1, HiOutlineClock } from 'react-icons/hi'
import { useState, useRef } from 'react'
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import { BsPlusCircle } from 'react-icons/bs';
import AutoComplete from "react-google-autocomplete";
import { POST } from '../../../utilities/ApiProvider.js';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

export default function HelpWrap() {

    const navigate = useNavigate();
    const toast = useToast();
    const [location, setLocation] = useState('');
    const user = useSelector(state => state?.value);

    const [isLoading, setIsLoading] = useState(false);
    const imgRef = useRef(null);
    const [eventPic, setEventPic] = useState({});
    const [isPaid, setIsPaid] = useState(false);
    const [price, setPrice] = useState();
    const [isRegistrationReq, setIsRegistrationReq] = useState(false);
    const [googleFormLink, setGoogleFormLink] = useState(null);
    const [isVirtual, setIsVirtual] = useState(false);
    const [fullAddress, setFullAdrdess] = useState(null);
    const [conferanceLink, setConferanceLink] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);


    function formatTime(isoString) {
        let date = new Date(isoString);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let period = hours >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        hours = hours % 12 || 12;

        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')} ${period}`;
        return formattedTime;
    }

    const createEvent = async (id, archiveValue) => {
        setIsLoading(true);
        let shouldProceedWithNetwork = true;
        let form = document.getElementById('eventForm');
        let data = new FormData(form);

        // for (const pair of data.entries()) {
        //     if (pair[1] === '') {
        //         shouldProceedWithNetwork = false;
        //         let errorKey = pair[0];
        //         if (errorKey.includes('_')) {
        //             errorKey = `${errorKey.split('_')[0]} ${errorKey.split('_')[1]}`
        //         }
        //         toast({
        //             description: `${errorKey} is required!`,
        //             status: 'error',
        //             position: 'top-right',
        //             isClosable: true,
        //             duration: 4000
        //         })
        //         break;
        //     }
        // }

        if (shouldProceedWithNetwork) {
            if (isPaid) {
                if (price === '') {
                    toast({
                        description: 'Event price is required!',
                        status: 'error',
                        position: 'top-right',
                        duration: 2500
                    });
                } else if (!eventPic?.name) {
                    data.delete('picture')
                    // toast({
                    //     description: 'Event Image is required!',
                    //     status: 'error',
                    //     position: 'top-right',
                    //     duration: 2500
                    // });
                } else if (isRegistrationReq && googleFormLink === null) {
                    toast({
                        description: 'Google Form Link is Required!',
                        status: 'error',
                        position: 'top-right',
                        duration: 2500
                    });
                } else {
                    if (price === undefined) {
                        data.append('price', 0)
                    } else {
                        data.append('price', price)
                    }
                    data.append('paid', isPaid)
                    data.append('isRegistrationReq', isRegistrationReq)
                    data.append('googleFormLink', googleFormLink)
                    data.append('isVirtual', isVirtual)
                    if (isVirtual) {
                        data.delete('location')
                        data.append('conferanceLink', conferanceLink)
                    } else {
                        data.append('fullAddress', fullAddress)
                    }
                }
            } else {
                if (price === undefined) {
                    data.append('price', 0)
                } else {
                    data.append('price', price)
                }
                data.append('paid', isPaid)
                data.append('isRegistrationReq', isRegistrationReq)
                data.append('googleFormLink', googleFormLink)
                data.append('isVirtual', isVirtual)
                if (isVirtual) {
                    data.delete('location')
                    data.append('conferanceLink', conferanceLink)
                } else {
                    data.append('fullAddress', fullAddress)
                }
            }
            try {
                let res = await POST(`/admin/posts`, data, { authorization: `Bearer ${user?.verificationToken}` });
                if (res.status === 200) {
                    toast({
                        description: 'Event created!',
                        status: 'success',
                        position: 'top-right',
                        duration: 2500
                    });
                    navigate('/super-dashboard/events')
                } else {
                    toast({
                        description: res.message,
                        status: 'error',
                        position: 'top-right',
                        duration: 2500
                    });
                }
            } catch (err) {
                toast({
                    description: err.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Heading fontSize="24px" fontWeight="600">Create New Event</Heading>
                <form id="eventForm">
                    <Grid
                        templateColumns="repeat(12, 1fr)"
                        gap={{ base: 2, sm: 0 }}
                        alignItems={'flex-start'}
                        justifyContent="space-between"
                        mr="60px !important"
                    >
                        <GridItem colSpan={{ base: '4', md: '5' }}>
                            <Stack className='addItemForm'>
                                <FormLabel>Event Name</FormLabel>
                                <Input name="name" placeholder="Enter Here" />
                                <FormLabel>Organizer</FormLabel>
                                <Input name="contactPerson" type="text" placeholder="Enter Here" />
                                <FormLabel>Contact Number</FormLabel>
                                <Input name="contactNumber" placeholder="Enter Here" />
                                <Stack mt="15px !important" direction="row" alignItems="flex-start" spacing="0" gap="40px">
                                    <Box w="48%">
                                        <FormLabel>Event Start Date</FormLabel>
                                        <Input name="date" type='date' />
                                    </Box>
                                    <Box w="48%">
                                        <FormLabel>Event End Date</FormLabel>
                                        <Input name="endDate" type='date' />
                                    </Box>
                                </Stack>
                                <Stack mt="15px !important" direction="row" alignItems="flex-start" spacing="0" gap="40px">
                                    <Box w="36%" h="75px">
                                        <FormLabel>Paid Event</FormLabel>
                                        <Checkbox value={isPaid} onChange={e => setIsPaid(e.target.checked)} w="50%">
                                            <Text fontSize={'14px'} color={'#66707A'} fontWeight="500">
                                                Yes
                                            </Text>
                                        </Checkbox>
                                    </Box>
                                    {
                                        isPaid &&
                                        <Box w="60%">
                                            <FormLabel>Event Price</FormLabel>
                                            <Input onChange={e => setPrice(Number(e.target.value))} value={price} type='number' placeholder="C$0" />
                                        </Box>
                                    }
                                </Stack>
                                <Stack mt="15px !important" direction="row" alignItems="flex-start" spacing="0" gap="40px">
                                    <Box w="36%" h="75px">
                                        <FormLabel>Registration Required?</FormLabel>
                                        <Checkbox value={isRegistrationReq} onChange={e => {
                                            if (e.target.checked) {
                                                setIsRegistrationReq(e.target.checked)
                                            } else {
                                                setIsRegistrationReq(e.target.checked)
                                                setGoogleFormLink(null)
                                            }
                                        }} w="50%">
                                            <Text fontSize={'14px'} color={'#66707A'} fontWeight="500">
                                                Yes
                                            </Text>
                                        </Checkbox>
                                    </Box>
                                    {
                                        isRegistrationReq &&
                                        <Box w="60%">
                                            <FormLabel>Google Form Link:</FormLabel>
                                            <Input onChange={e => setGoogleFormLink(e.target.value)} value={googleFormLink} type='text' placeholder="" />
                                        </Box>
                                    }
                                </Stack>
                                <Stack mt="15px !important" direction="row" alignItems="flex-start" spacing="0" gap="40px">
                                    <Box w="36%" h="75px">
                                        <FormLabel>Is Event Virtual?</FormLabel>
                                        <Checkbox value={isVirtual} onChange={e => {
                                            if (e.target.checked) {
                                                setIsVirtual(e.target.checked)
                                                setFullAdrdess(null)
                                            } else {
                                                setConferanceLink(null)
                                                setIsVirtual(e.target.checked)
                                            }
                                        }} w="50%">
                                            <Text fontSize={'14px'} color={'#66707A'} fontWeight="500">
                                                Yes
                                            </Text>
                                        </Checkbox>
                                    </Box>
                                    {
                                        !isVirtual ?
                                            <Box w="60%">
                                                <>
                                                    <FormLabel>Location</FormLabel>
                                                    <Input name="location" type='text' as={AutoComplete}
                                                        apiKey={'AIzaSyCGhPs5s8YsqLZuNgu8mbq24-DTXrMlMJU'}
                                                        onPlaceSelected={(place) => setLocation(place)}
                                                    />
                                                    <FormLabel mt="15px">Full Address:</FormLabel>
                                                    <Input onChange={e => setFullAdrdess(e.target.value)} value={fullAddress} type='text' placeholder="" />
                                                </>
                                            </Box>
                                            :
                                            <Box w="60%">
                                                <FormLabel mt="15px">Conference Link:</FormLabel>
                                                <Input onChange={e => setConferanceLink(e.target.value)} value={conferanceLink} type='text' placeholder="" />
                                            </Box>
                                    }
                                </Stack>
                                <Stack mt="15px !important" direction="row" alignItems="flex-start" spacing="0" gap="40px">
                                    <Box w="48%">
                                        <FormLabel>Event Start Time</FormLabel>
                                        <Input name="time" placeholder="Enter Here" onChange={e => setStartTime(e.target.value)} value={startTime} type="time" />
                                    </Box>
                                    <Box w="48%">
                                        <FormLabel>Event End Time</FormLabel>
                                        <Input name="endtime" onChange={e => setEndTime(e.target.value)} value={endTime} type='time' />
                                    </Box>
                                </Stack>
                                <FormLabel>Description</FormLabel>
                                <Textarea name="description" placeholder='Enter here' resize={'none'} rows={'8'}></Textarea>
                                <Stack className='btn' mt="60px !important">
                                    <Button
                                        isLoading={isLoading}
                                        onClick={createEvent}
                                        justifyContent={'center'}
                                        padding={'10px 20px'}
                                        bg={"primaryGreen.100"}
                                        color={'primaryYellow.100'}
                                        w={'180px'}
                                        bgImage={greenBtnTexture}
                                        bgPos={'center'}
                                        bgSize={'cover'}
                                        bgRepeat={'no-repeat'}
                                        fontWeight={'400'}
                                        border={'2px solid'}
                                        borderColor={"primaryGreen.100"}
                                        textAlign={'left'}
                                        _hover={{
                                            bg: "primaryGreen.100",
                                            borderColor: "primaryGreen.100",
                                            color: '#fff'
                                        }}
                                    >
                                        <Text as={'span'}>Create</Text>
                                    </Button>
                                </Stack>
                            </Stack>
                        </GridItem>
                        <GridItem colSpan={{ base: '4', md: '3' }}>
                        </GridItem>
                        <GridItem colSpan={{ base: '4', md: '4' }}>
                            <Input display={'none'} name="picture" type="file" onChange={e => setEventPic(e.target.files[0])} ref={imgRef} />
                            <FormLabel mt="15px" fontWeight="600">Upload Picture <sub>(450px x 350px)</sub></FormLabel>
                            <Button
                                onClick={() => imgRef?.current.click()}
                                w="100%"
                                h="115px"
                                border="1px solid #BBBBBB"
                            >
                                <Text display="block" w="100%">
                                    <Icon as={BsPlusCircle} display="block" margin="5px auto" />
                                    {eventPic?.name ?? 'Add'}</Text>
                            </Button>
                        </GridItem>
                    </Grid>
                </form>
            </Stack>
        </>
    )
}