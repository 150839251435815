import {
  Stack,
  Box,
  Image,
  Text,
  Heading,
  Icon,
  Checkbox,
  FormLabel,
  Input,
  Button,
  Link,
  InputGroup,
  InputLeftAddon,
  Select,
  useToast
} from '@chakra-ui/react';

import { BsEyeSlash, BsEye } from 'react-icons/bs';
import { AiOutlineLock } from 'react-icons/ai';
import { BiArrowBack } from 'react-icons/bi';
import React, { useState, useEffect } from 'react';
import shapeb from '../../assets/images/icon/black.png';
import { Link as ReactLink } from 'react-router-dom';
import yellowBtnTexture from '../../assets/images/yellowBtnTexture.svg'
import {POST} from '../../utilities/ApiProvider.js'
import {useNavigate} from 'react-router-dom'

export default function NewPassform() {

  const toast = useToast();
  const navigate = useNavigate();
  const [showpass, setshowpass] = useState(false);
  const [reshowpass, setreshowpass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [passInfo, setPassInfo] = useState({
    password: '',
    confirmPassword: ''
  })

  let submitNewPass = async () => {
    setIsLoading(true);
    try {
      if (passInfo.password === '' || passInfo.confirmPassword === '') {
        toast({
          description: 'All fields is required!',
          duration: 2000,
          position: 'top-right',
          status: 'error'
        })
      } else {
        let res = await POST('/auth/change-password', {
          email,
          ...passInfo
        });
        if (res.status === 200) {
          localStorage.removeItem('emailForOtpVerification')
          toast({
            description: res.message,
            duration: 2000,
            position: 'top-right',
            status: 'success'
          });
          navigate('/login')
        } else {
          toast({
            description: res.message,
            duration: 2000,
            position: 'top-right',
            status: 'error'
          });
        }
      }
    } catch (err) {
      toast({
        description: err.message,
        duration: 2000,
        position: 'top-right',
        status: 'error'
      })
    }
    setIsLoading(false);
  }

  useEffect(() => {
    let localEmail = localStorage.getItem('emailForOtpVerification');
    if (localEmail) {
      setEmail(localEmail);
    } else {
      navigate('/forgotpassword');
    }
  }, []);

  return (
    <>
      <Stack
        w={'70%'}
        m={'0 auto'}
        bg={'#fff'}
        pt={'80px'}
        pb={'120px'}
        px={'20px'}
        border={'5px solid'}
        borderColor="primaryYellow.100"
        borderRadius={'8px'}
        backgroundImage={shapeb}
      >
        <Stack mb={12}>
          <Box className="boxlink">
            <Link to={'/'}>
              <Icon fontSize={'17px'} color={'#000'} as={BiArrowBack} />
            </Link>
          </Box>
        </Stack>
        <Box className="AH mh p1" pb={4}>
          <Heading as={'h3'}>New Password</Heading>
          <Text>Set new password and login</Text>
        </Box>
        <Stack className="regwarp" gap={4}>
          <Stack>
            <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
              Password
            </FormLabel>
            <Box className="Ifiled">
              <Icon fontSize={'25px'} color={'#000'} as={AiOutlineLock} />
              <Input
                type={showpass ? 'text' : 'password'}
                placeholder="Enter here"
                onChange={e => setPassInfo({
                  ...passInfo,
                  password: e.target.value
                })}
                value={passInfo.password}
              />
              <Button onClick={() => setshowpass(!showpass)}>
                <Icon as={showpass ? BsEye : BsEyeSlash} />
              </Button>
            </Box>
          </Stack>
          <Stack pb={2}>
            <FormLabel fontWeight={400} m={0} color={'#000'} fontSize={'14px'}>
              Re-type Password
            </FormLabel>
            <Box className="Ifiled">
              <Icon fontSize={'25px'} color={'#000'} as={AiOutlineLock} />
              <Input
                type={reshowpass ? 'text' : 'password'}
                placeholder="Enter here"
                onChange={e => setPassInfo({
                  ...passInfo,
                  confirmPassword: e.target.value
                })}
                value={passInfo.confirmPassword}
              />
              <Button onClick={() => setreshowpass(!reshowpass)}>
                <Icon as={reshowpass ? BsEye : BsEyeSlash} />
              </Button>
            </Box>
          </Stack>

          <Stack className='btn'>
            <Button
              padding={'10px 50px'}
              onClick={submitNewPass}
              bg={'#FFC00F'}
              isLoading={isLoading}
              bgImage={yellowBtnTexture}
              bgPos={'center'}
              bgSize={'cover'}
              bgRepeat={'no-repeat'}
              color={'primaryGreen.100'}
              fontWeight={'500'}
              w={'fit-content'}
              border={'2px solid'}
              borderColor="primaryGreen.100"
              textTransform={'uppercase'}
            >
              <Text as={'span'}>Submit </Text>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
