import { Stack, Heading, Icon, Text, Box, Button, Image, useToast, FormLabel, Checkbox, Input, RadioGroup, Radio, useDisclosure, Modal, ModalFooter, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiOutlineShoppingBag, HiOutlineChevronDown } from 'react-icons/hi'
import { useState, useEffect, useRef } from 'react'
import foodPic from '../../../assets/images/auth/r6.jpg'
import storeBg from '../../../assets/images/bg2.jpg';
import storePic from '../../../assets/images/storePic.svg';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import StoreItemBox from './StoreItemBox.js'
import { FaStar, FaTimes } from 'react-icons/fa'
import { MdContentCopy } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux';
import { imgUrl } from '../../../utilities/config.js'
import { GET, POST, PUT } from '../../../utilities/ApiProvider.js'
import { useNavigate, useParams } from 'react-router';
import { BsPlusCircle } from 'react-icons/bs'
import AutoComplete from "react-google-autocomplete";
import yellowBtnTexture from '../../../assets/images/yellowBtnTexture.svg'
import { loadStore } from '../../../reducers/useReducers.js'
import html2canvas from 'html2canvas';
import Cropper from 'react-easy-crop'

export default function OrdersWrap() {

    const params = useParams()
    const toast = useToast();
    const dispatch = useDispatch();
    const bannerRef = useRef();
    const logoRef = useRef();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isCropOpen, onOpen: onCropOpen, onClose: onCropClose } = useDisclosure();
    const user = useSelector(state => state?.value);
    const [isLoading, setIsLoading] = useState(false);
    const [storeData, setStoreData] = useState(null);
    const [selectedCat, setSelectedCat] = useState(null);
    const [storeItems, setStoreItems] = useState(null);
    const [inputFileVal, setInputFileVal] = useState({
        banner: {
            name: 'Choose Image (1600px x 325px)'
        },
        logo: {
            name: 'Choose Image (180px x 180px)'
        }
    });

    // Crop Features
    const [bannerImage, setBannerImage] = useState("");
    const [imageName, setImageName] = useState("");
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(4 / 3);
    const [blogUrl, setBlogUrl] = useState(null);

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels)
    }

    const getCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(bannerImage, croppedArea);
            const finalDataUrl = await getTextAfterFirstComma(croppedImage);

            let form = document.getElementById('logoImg');
            let data = new FormData(form);
            data.append('name', imageName);

            let imageElement = document.getElementById('logoImage');
            if (imageElement) {
                data.append('dataUrl', imageElement.src);
            }

            let res = await PUT(`/vendor/store/convertData`,
                data, {
                authorization: `Bearer ${user?.verificationToken}`
            });
            if (res.status === 200) {
                dispatch(loadStore(res?.data));
                navigate('/dashboard/store')
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2000
                })
            }
        } catch (error) {
            console.error('Error cropping image:', error);
        }
    };

    const getTextAfterFirstComma = async (text) => {
        const base64Data = text.split(',')[1];
        const binaryString = window.atob(base64Data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'image/png' });
        const blobUrl = URL.createObjectURL(blob);
        setBlogUrl(blobUrl);

        return blobUrl;
    }

    const getCroppedImg = async (imageSrc, croppedAreaPixels) => {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const image = document.createElement('img');
            image.src = imageSrc;

            image.onload = function () {
                canvas.width = croppedAreaPixels.width;
                canvas.height = croppedAreaPixels.height;

                const scaleX = image.naturalWidth / image.width;
                const scaleY = image.naturalHeight / image.height;
                const scaledX = croppedAreaPixels.x * scaleX;
                const scaledY = croppedAreaPixels.y * scaleY;
                const scaledWidth = croppedAreaPixels.width * scaleX;
                const scaledHeight = croppedAreaPixels.height * scaleY;

                ctx.drawImage(
                    image,
                    scaledX,
                    scaledY,
                    scaledWidth,
                    scaledHeight,
                    0,
                    0,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height
                );

                const dataUrl = canvas.toDataURL('image/jpeg');
                resolve(dataUrl);
            };

            image.onerror = function (error) {
                reject(error);
            };
        });
    };

    const [openingHours, setOpeningHours] = useState([
        {
            on: true,
            day: 'Monday',
            startTime: '09:00',
            endTime: '22:00'
        },
        {
            on: true,
            day: 'Tuesday',
            startTime: '09:00',
            endTime: '22:00'
        },
        {
            on: true,
            day: 'Wednesday',
            startTime: '09:00',
            endTime: '22:00'
        },
        {
            on: true,
            day: 'Thursday',
            startTime: '09:00',
            endTime: '22:00'
        },
        {
            on: true,
            day: 'Friday',
            startTime: '09:00',
            endTime: '22:00'
        },
        {
            on: true,
            day: 'Saturday',
            startTime: '09:00',
            endTime: '22:00'
        },
        {
            on: true,
            day: 'Sunday',
            startTime: '09:00',
            endTime: '22:00'
        }
    ]);
    function changeOnStatus(itemIndex, value) {
        let modifiedArray = openingHours.map((val, ind) => {
            if (ind === itemIndex) {
                return {
                    ...val,
                    on: value
                }
            } else return val
        });
        setOpeningHours(modifiedArray);
    }
    function changeStartingTime(itemIndex, value) {
        let modifiedArray = openingHours.map((val, ind) => {
            if (ind === itemIndex) {
                return {
                    ...val,
                    startTime: value
                }
            } else return val
        });
        setOpeningHours(modifiedArray);
    }

    function changeEndingTime(itemIndex, value) {
        let modifiedArray = openingHours.map((val, ind) => {
            if (ind === itemIndex) {
                return {
                    ...val,
                    endTime: value
                }
            } else {
                return val
            }
        });
        setOpeningHours(modifiedArray);
    }

    const getStoreData = async (_id) => {
        try {
            let res;
            if (_id) {
                res = await GET(`/admin/partners/${_id}`, { authorization: `Bearer ${user?.verificationToken}` });
            } else {
                res = await GET('/vendor/my-store', { authorization: `Bearer ${user?.verificationToken}` });
                if (res?.status === 200) {
                    setOpeningHours(res?.data?.store?.opening_hours)
                }
            }
            if (res.status === 200) {
                setStoreItems(res?.data.initialProducts);
                setStoreData(res.data);
            } else {
                toast({
                    description: res?.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 3000
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
    }

    const submitCreateStore = async () => {
        setIsLoading(true);
        try {
            let hasEmptyValues = false;
            let formTag = document.getElementById('storeForm');
            let data = new FormData(formTag);

            for (const pair of data.entries()) {
                if (pair[1] === '') {
                    hasEmptyValues = true;
                    break;
                }
            }

            if (hasEmptyValues) {
                toast({
                    description: 'All fields are required!',
                    status: 'error',
                    position: 'top-right',
                    duration: 2000
                })
            } else {
                data.append('opening_hours', JSON.stringify(openingHours));
                let res = await PUT(`/vendor/store/profile`, data, {
                    authorization: `Bearer ${user?.verificationToken}`
                });
                if (res.status === 200) {
                    dispatch(loadStore(res?.data));
                    navigate('/dashboard/store')
                } else {
                    toast({
                        description: res.message,
                        status: 'error',
                        position: 'top-right',
                        duration: 2000
                    })
                }
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2000
            })
        }
        setIsLoading(false);
    }

    const copySlug = () => {
        navigator.clipboard.writeText(`${imgUrl}/store/${storeData?.store?.slug}`)
        toast({
            description: 'Link Copied!',
            status: 'success',
            duration: 2000,
            isClosable: true
        });
    }

    const handleBannerChange = (event, name) => {
        if (name === 'logo') {
            setInputFileVal({
                ...inputFileVal,
                logo: event.target.value
            })
        } else {
            setInputFileVal({
                ...inputFileVal,
                banner: event.target.value
            })
        }
        // if (event.target.files && event.target.files.length > 0) {
        //     const reader = new FileReader();
        //     reader.readAsDataURL(event.target.files[0]);
        //     reader.onload = function (e) {
        //         if (name == 'banner') {
        //             setAspectRatio(3 / 1);
        //         } else {
        //             setAspectRatio(1 / 1);
        //         }
        //         setBannerImage(reader.result);
        //         setImageName(name);
        //     }
        // }
    }

    useEffect(() => {
        if (bannerImage !== "") {
            onCropOpen()
        }
    }, [bannerImage])

    useEffect(() => {
        if (params) { getStoreData(params?.id) }
        else if (user) { getStoreData(); }
        else return

        return () => { }
    }, [user, params]);

    useEffect(() => {
        if (selectedCat) {
            console.log(storeData?.categorWiseData)
            storeData?.categorWiseData?.length > 0 &&
                storeData?.categorWiseData?.map(val => {
                    if (val?.name === selectedCat) setStoreItems(val?.products)
                })
        }

        return () => { }
    }, [selectedCat]);

    useEffect(() => {
        if (blogUrl !== null) {
            getCroppedImage()
        }

        return () => { }
    }, [selectedCat]);

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                height={'100%'}
                margin={'0 !important'}
                gap={8}
                bg={'#fff'}
                backgroundImage={shapeb}
                borderTopLeftRadius={'40px'}
                bgPos={'-32px -100px'}
            >
                <Box position={'relative'} zIndex={0}>
                    <Image objectFit={'cover'} width="100%" height="325px" src={storeData?.store?.banner ? `${imgUrl}/${storeData?.store?.banner}` : storeBg} />
                    <Box position={'absolute'} top="0" left="0" bgColor={'#ffffff69'} display={'flex'} alignItems={'center'} justifyContent={'center'} w="100%" h="325px">
                        <Stack className='btn'>
                            <Button
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={"primaryGreen.100"}
                                bgImage={greenBtnTexture}
                                bgPos={'center'}
                                onClick={() => bannerRef?.current?.click()}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                color={'primaryYellow.100'}
                                fontWeight={'400'}
                                border={'2px solid'}
                                borderColor={"primaryGreen.100"}
                                textAlign={'left'}
                                _hover={{
                                    bg: "primaryGreen.100",
                                    borderColor: "primaryGreen.100",
                                    color: '#fff'
                                }}
                            >
                                <Text as={'span'}>Change Banner</Text>
                            </Button>
                        </Stack>
                    </Box>
                </Box>
                <Stack
                    margin={'0 !important'}
                    gap={8}
                    pb={10}
                    px={6}
                    mr="60px !important"
                    w="650px"
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Box flex={2} display="flex" gap="30px">
                            <form id="logoImg">
                                <Image id="logoImage" position={'relative'} name="dataUrl" zIndex={1} border={'4px solid #fff'} borderRadius={'100%'} src={blogUrl ? blogUrl : storeData?.store?.logo ? `${imgUrl}/${storeData?.store?.logo}` : foodPic} w="180px" h="180px" objectFit="cover" mt="-70px" />
                            </form>
                            <Stack className='btn'>
                                <Button
                                    justifyContent={'center'}
                                    padding={'10px 20px'}
                                    bg={"primaryGreen.100"}
                                    bgImage={greenBtnTexture}
                                    bgPos={'center'}
                                    bgSize={'cover'}
                                    bgRepeat={'no-repeat'}
                                    onClick={() => logoRef?.current?.click()}
                                    color={'primaryYellow.100'}
                                    fontWeight={'400'}
                                    border={'2px solid'}
                                    borderColor={"primaryGreen.100"}
                                    textAlign={'left'}
                                    _hover={{
                                        bg: "primaryGreen.100",
                                        borderColor: "primaryGreen.100",
                                        color: '#fff'
                                    }}
                                >
                                    <Text as={'span'}>Change Logo</Text>
                                </Button>
                            </Stack>
                        </Box>
                    </Stack>
                    <form id="storeForm">
                        <Stack className="regwarp" gap={2}>
                            <Stack>
                                <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                    Store Name
                                </FormLabel>
                                <Box className="Ifiled">
                                    <Input defaultValue={storeData?.store?.store_name} name="store_name" type="text" placeholder="Enter here" />
                                </Box>
                            </Stack>
                            <Stack>
                                <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                    Website Url
                                </FormLabel>
                                <Box className="Ifiled">
                                    <Input defaultValue={storeData?.store?.store_url} name="store_url" type="text" placeholder="Enter here" />
                                </Box>
                            </Stack>
                            <Stack>
                                <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                    About Me
                                </FormLabel>
                                <Box className="Ifiled">
                                    <Input defaultValue={storeData?.store?.aboutus} name="aboutus" type="text" placeholder="Enter here" />
                                </Box>
                            </Stack>
                            <Stack>
                                <FormLabel
                                    fontWeight={500}
                                    m={0}
                                    color={'#000'}
                                    fontSize={'14px'}
                                >
                                    Opening Hours
                                </FormLabel>
                                <Button
                                    w="100%"
                                    border="1px solid #BBBBBB"
                                    onClick={onOpen}
                                >
                                    <Text w="100%" display={'flex'} alignItems={'center'} gap={'5px'} justifyContent={'center'}>
                                        <Icon as={BsPlusCircle} />
                                        Add</Text>
                                </Button>
                            </Stack>
                            <Stack direction={'row'} alignItems={'end'} gap={4}>
                                <Stack display="none">
                                    <FormLabel
                                        fontWeight={500}
                                        m={0}
                                        color={'#000'}
                                        fontSize={'14px'}
                                    >
                                        Upload Banner
                                    </FormLabel>
                                    <Box className="Ifiled">
                                        <Input
                                            ref={bannerRef}
                                            onChange={e => handleBannerChange(e, 'banner')} name='banner' className='filetype' type="file" placeholder="Enter here" _after={{ content: `"${inputFileVal.banner.name}"`, fontSize: '12px' }} />
                                    </Box>
                                </Stack>
                                <Stack display="none">
                                    <FormLabel
                                        fontWeight={500}
                                        m={0}
                                        color={'#000'}
                                        fontSize={'14px'}
                                    >
                                        Upload Logo
                                    </FormLabel>
                                    <Box className="Ifiled">
                                        <Input
                                            ref={logoRef}
                                            onChange={e => handleBannerChange(e, 'logo')} name='logo' className='filetype' type="file" placeholder="Enter here" _after={{ content: `"${inputFileVal.banner.name}"`, fontSize: '12px' }} />
                                    </Box>
                                </Stack>
                            </Stack>
                            <Stack>
                                <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                    Postal Code
                                </FormLabel>
                                <Box className="Ifiled">
                                    <Input defaultValue={storeData?.store?.zone} name='zone' type="text" placeholder="Enter here" />
                                </Box>
                            </Stack>
                            <Stack>
                                <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                    Address
                                </FormLabel>
                                <Box className="Ifiled">
                                    <Input
                                        defaultValue={storeData?.store?.area}
                                        name="area" type='text' as={AutoComplete}
                                        apiKey={'AIzaSyCGhPs5s8YsqLZuNgu8mbq24-DTXrMlMJU'}
                                    />
                                </Box>
                            </Stack>
                            <Stack direction="row" alignItems="flex-end" gap="10px">
                                <Box w="100%">
                                    <FormLabel fontWeight={500} m={0} color={'#000'} fontSize={'14px'}>
                                        Marketing Slug
                                    </FormLabel>
                                    <Box className="Ifiled">
                                        <Input
                                            name="slug"
                                            defaultValue={storeData?.store?.slug}
                                            type="text"
                                            readOnly
                                            placeholder="" />
                                    </Box>
                                </Box>
                                <Stack className="btn">
                                    <Button
                                        onClick={() => copySlug()}
                                        isLoading={isLoading}
                                        padding={'10px 30px'}
                                        bgImage={yellowBtnTexture}
                                        bgPos={'center'}
                                        bgSize={'cover'}
                                        bgRepeat={'no-repeat'}
                                        bg={'#FFC00F'}
                                        color={'primaryGreen.100'}
                                        fontWeight={'500'}
                                        w={'fit-content'}
                                        border={'2px solid'}
                                        textTransform={'uppercase'} borderColor="primaryGreen.100"
                                    >
                                        <Icon as={MdContentCopy} />
                                    </Button>
                                </Stack>
                            </Stack>

                            <Stack className="btn">
                                <Button
                                    onClick={
                                        // () => navigate('/dashboard')
                                        submitCreateStore
                                    }
                                    isLoading={isLoading}
                                    padding={'10px 50px'}
                                    bgImage={yellowBtnTexture}
                                    bgPos={'center'}
                                    bgSize={'cover'}
                                    bgRepeat={'no-repeat'}
                                    bg={'#FFC00F'}
                                    color={'primaryGreen.100'}
                                    fontWeight={'500'}
                                    w={'fit-content'}
                                    border={'2px solid'}
                                    textTransform={'uppercase'} borderColor="primaryGreen.100"
                                >
                                    <Text as={'span'}>Submit</Text>
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </Stack>
                <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader borderBottom={'1px solid'} borderColor={'primaryGreen.100'}>
                            <Heading textAlign={'left'} fontSize={'20px'}>Opening Hours</Heading>
                            <ModalCloseButton />
                        </ModalHeader>
                        <ModalBody>
                            <Stack direction={'row'} alignItems={'center'} fontWeight={'600'} my={'20px'}>
                                <Text flex="1">Week Days</Text>
                                <Text flex="1" textAlign={'center'}>Start Time</Text>
                                <Text flex="1" textAlign={'center'}>End Time</Text>
                            </Stack>
                            {
                                openingHours.map((val, ind) =>
                                    <Stack key={val.day} direction={'row'} alignItems={'center'} mb="8px !important">
                                        <Text flex={'1'}>
                                            <Checkbox colorScheme='yellow' defaultChecked={val?.on} onChange={e => changeOnStatus(ind, e.target.checked)} mr="5px" transform="translateY(2px)"></Checkbox>
                                            {val.day}:</Text>
                                        <Text flex={'1'}><Input onChange={e => changeStartingTime(ind, e.target.value)} type='time' defaultValue={val.startTime} value={val.startTime} /></Text>
                                        <Text flex={'1'}><Input onChange={e => changeEndingTime(ind, e.target.value)} type='time' defaultValue={val.endTime} value={val.endTime} /></Text>
                                    </Stack>
                                )
                            }
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <Modal isOpen={isCropOpen} onClose={onCropClose} size={'full'} height="100vh" closeOnOverlayClick={false}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader borderBottom={'1px solid'} borderColor={'primaryGreen.100'}>
                            <Heading textAlign={'left'} fontSize={'20px'}>Crop Image</Heading>
                            <ModalCloseButton />
                        </ModalHeader>
                        <ModalBody >
                            <Stack>
                                <Cropper
                                    image={bannerImage}
                                    aspect={aspectRatio}
                                    crop={crop}
                                    zoom={zoom}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                    style={{
                                        containerStyle: {
                                            width: '100%',
                                            height: '60%',
                                            backgroundColor: '#fff',
                                            marginTop: '50px'
                                        }
                                    }}
                                />
                            </Stack>
                        </ModalBody>
                        <ModalFooter justifyContent="center">
                            <Stack>
                                {/* <Stack direction='row' justifyContent='center'>
                                    <RadioGroup onChange={setAspectRatio} value={aspectRatio}>
                                        <Stack direction='row'>
                                            <input onClick={e => setAspectRatio(e.target.value)} type="radio" name="aspectRatio" isChecked={aspectRatio == '1 / 1'} value={1 / 1} /><Text>1:1</Text>
                                            <input onClick={e => setAspectRatio(e.target.value)} type="radio" name="aspectRatio" isChecked={aspectRatio == '3 / 1'} value={3 / 1} /><Text>3:1</Text>
                                            <input onClick={e => setAspectRatio(e.target.value)} type="radio" name="aspectRatio" isChecked={aspectRatio == '3 / 2'} value={3 / 2} /><Text>3:2</Text>
                                            <input onClick={e => setAspectRatio(e.target.value)} type="radio" name="aspectRatio" isChecked={aspectRatio == '4 / 3'} value={4 / 3} /><Text>4:3</Text>
                                            <input onClick={e => setAspectRatio(e.target.value)} type="radio" name="aspectRatio" isChecked={aspectRatio == '5 / 3'} value={5 / 3} /><Text>5:3</Text>
                                            <input onClick={e => setAspectRatio(e.target.value)} type="radio" name="aspectRatio" isChecked={aspectRatio == '5 / 4'} value={5 / 4} /><Text>5:4</Text>
                                            <input onClick={e => setAspectRatio(e.target.value)} type="radio" name="aspectRatio" isChecked={aspectRatio == '16 / 9'} value={16 / 9} /><Text>16:9</Text>
                                        </Stack>
                                    </RadioGroup>
                                </Stack> */}
                                <Stack mt="30px !important" direction='row' justifyContent='center'>
                                    <Stack className='btn'>
                                        <Button
                                            justifyContent={'center'}
                                            padding={'10px 20px'}
                                            bg={"primaryGreen.100"}
                                            bgImage={greenBtnTexture}
                                            onClick={() => {
                                                getCroppedImage()
                                            }}
                                            bgPos={'center'}
                                            bgSize={'cover'}
                                            bgRepeat={'no-repeat'}
                                            color={'primaryYellow.100'}
                                            fontWeight={'400'}
                                            border={'2px solid'}
                                            borderColor={"primaryGreen.100"}
                                            textAlign={'left'}
                                            _hover={{
                                                bg: "primaryGreen.100",
                                                borderColor: "primaryGreen.100",
                                                color: '#fff'
                                            }}
                                        >
                                            <Text as={'span'}>Crop and Submit</Text>
                                        </Button>
                                    </Stack>
                                    <Stack className='btn'>
                                        <Button
                                            onClick={() => {
                                                setBannerImage("");
                                                onCropClose();
                                            }}
                                            justifyContent={'center'}
                                            padding={'10px 20px'}
                                            bg={"primaryGreen.100"}
                                            bgImage={greenBtnTexture}
                                            bgPos={'center'}
                                            bgSize={'cover'}
                                            bgRepeat={'no-repeat'}
                                            color={'primaryYellow.100'}
                                            fontWeight={'400'}
                                            border={'2px solid'}
                                            borderColor={"primaryGreen.100"}
                                            textAlign={'left'}
                                            _hover={{
                                                bg: "primaryGreen.100",
                                                borderColor: "primaryGreen.100",
                                                color: '#fff'
                                            }}
                                        >
                                            <Text as={'span'}>Cancel</Text>
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Stack>
        </>
    )
}
