import { Stack, useDisclosure, Heading, Icon, Text, Box, Button, Image, Spinner, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalFooter } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiOutlineShoppingBag, HiOutlineChevronDown } from 'react-icons/hi'
import { useState, useEffect } from 'react'
import foodPic from '../../../assets/images/auth/r6.jpg'
import storeBg from '../../../assets/images/bg2.jpg';
import storePic from '../../../assets/images/storePic.svg';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import StoreItemBox from './StoreItemBox.js'
import { FaStar, FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux';
import { imgUrl } from '../../../utilities/config.js'
import { GET, DELETE } from '../../../utilities/ApiProvider.js'
import { useNavigate, useParams } from 'react-router';
import { MdDeleteForever } from 'react-icons/md'

export default function OrdersWrap() {

    const params = useParams()
    const toast = useToast();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const user = useSelector(state => state?.value);
    const [storeData, setStoreData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCat, setSelectedCat] = useState(null);
    const [selectedCatToDelete, setSelectedCatToDelete] = useState(null);
    const [storeItems, setStoreItems] = useState(null);

    const getStoreData = async (_id) => {
        setIsLoading(true);
        try {
            let res;
            if (_id) {
                res = await GET(`/admin/partners/${_id}`, { authorization: `Bearer ${user?.verificationToken}` });
            } else {
                res = await GET('/vendor/my-store', { authorization: `Bearer ${user?.verificationToken}` });
            }
            if (res.status === 200) {
                setStoreItems(res?.data.initialProducts);
                setStoreData(res.data);
            } else {
                toast({
                    description: res?.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 3000
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    const removeCatFromStore = async () => {
        setIsLoading(true);
        try {
            let res = await DELETE(`/vendor/category/${selectedCatToDelete}`, { Authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                getStoreData()
                onClose()
                toast({
                    description: res.message,
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    const deleteDigitalMenu = async (id) => {
        setIsLoading(true);
        try {
            let res = await DELETE(`/vendor/item/digital/${id}`, { Authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                getStoreData()
                toast({
                    description: res.message,
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (params) { getStoreData(params?.id) }
        else if (user) { getStoreData(); }
        else return

        return () => { }
    }, [user, params]);

    useEffect(() => {
        if (selectedCat) {
            console.log(storeData?.categorWiseData)
            storeData?.categorWiseData?.length > 0 &&
                storeData?.categorWiseData?.map(val => {
                    if (val?.name === selectedCat) setStoreItems(val?.products)
                })
        }

        return () => { }
    }, [selectedCat]);

    useEffect(() => {
        console.log(selectedCatToDelete)

        return () => { }
    }, [selectedCatToDelete]);

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                height={'100%'}
                margin={'0 !important'}
                gap={8}
                bg={'#fff'}
                backgroundImage={shapeb}
                borderTopLeftRadius={'40px'}
                bgPos={'-32px -100px'}
            >
                {
                    isLoading ?
                        <Spinner m="20px" color="primaryGreen.100" />
                        :
                        <>
                            <Image objectFit={'cover'} width="100%" height="325px" src={storeData?.store?.banner ? `${imgUrl}/${storeData?.store?.banner}` : storeBg} />
                            <Stack
                                margin={'0 !important'}
                                gap={8}
                                pb={10}
                                px={6}
                                mr="60px !important"
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                >
                                    <Box flex={2} display="flex" gap="30px">
                                        <Image border={'4px solid #fff'} borderRadius={'100%'} src={storeData?.store?.logo ? `${imgUrl}/${storeData?.store?.logo}` : storePic} w="180px" h="180px" objectFit="cover" mt="-70px" />
                                        <Box>
                                            <Heading color="#171725" fontSize="32px" fontWeight="600">{storeData?.store?.store_name ?? 'Loading'}</Heading>
                                            <Text fontSize="16px" fontWeight="500">{storeData?.store?.area ?? 'Loading'}</Text>
                                            <Text fontSize="16px" fontWeight="500">{storeData?.store?.aboutus ?? 'Loading'}</Text>
                                            <Text color="#008D00" fontWeight="500" mb="20px">Opening</Text>
                                            {
                                                storeData?.categorWiseData?.length > 0 &&
                                                storeData?.categorWiseData?.map(val =>
                                                    <Button
                                                        _hover={{
                                                            bgColor: 'primaryGreen.100',
                                                            color: 'primaryYellow.100'
                                                        }}
                                                        _activeFocus={{
                                                            bgColor: '#E3E9ED',
                                                            color: '#434E58'
                                                        }}
                                                        _activeVisible={{
                                                            bgColor: '#E3E9ED',
                                                            color: '#434E58'
                                                        }}
                                                        onClick={() => { setSelectedCat(val?.name) }}
                                                        key={val?.name}
                                                        bgColor={selectedCat === val?.name ? 'primaryGreen.100' : "#E3E9ED"}
                                                        p="5px 25px"
                                                        borderRadius="50px"
                                                        color={selectedCat === val?.name ? 'primaryYellow.100' : "#434E58"}
                                                        fontWeight="600"
                                                        m="0 10px 10px 0"
                                                    >
                                                        {val?.name ?? 'Loading'}
                                                        {
                                                            user?.role?.name === 'vendor' ?
                                                                <Box position="absolute" right="-3px" bottom="-3px" bgColor="red.700" color="#fff" w="fit-content" borderRadius="100%" display="flex" alignItems="center" justifyContent="center">
                                                                    <Icon
                                                                        fontSize="22px"
                                                                        as={MdDeleteForever}
                                                                        cursor="pointer"
                                                                        color="#fff"
                                                                        onClick={() => {
                                                                            setSelectedCatToDelete(val?._id)
                                                                            onOpen()
                                                                        }}
                                                                    />
                                                                </Box>
                                                                : null
                                                        }
                                                    </Button>
                                                )
                                            }
                                            {
                                                selectedCat !== null &&
                                                <Button
                                                    _hover={{
                                                        bgColor: 'primaryGreen.100',
                                                        color: 'primaryYellow.100'
                                                    }}
                                                    onClick={() => {
                                                        setSelectedCat(null);
                                                        setStoreItems(storeData?.initialProducts);
                                                    }}
                                                    bgColor={'transparent'}
                                                    p="5px 10px"
                                                    borderRadius="50px"
                                                    color={'primaryGreen.100'}
                                                    fontWeight="600"
                                                    m="0 10px 10px 0"
                                                    border="2px solid"
                                                    borderColor="primaryGreen.100"
                                                    fontSize="14px"
                                                >
                                                    Clear Filter
                                                    <Icon ml="10px" as={FaTimes} />
                                                </Button>
                                            }
                                            {/* <Button bgColor="#E3E9ED" p="5px 25px" borderRadius="50px" color="#434E58" fontWeight="600" mr="20px">Rice</Button>
                                <Button bgColor="#E3E9ED" p="5px 25px" borderRadius="50px" color="#434E58" fontWeight="600" mr="20px">Tacos</Button> */}
                                        </Box>
                                    </Box>
                                    <Box flex={1} display="flex" flexDir="column" justifyContent="space-between" alignItems="flex-end">
                                        <Box display="flex" gap="10px" alignItems="center">
                                            <Text h="22px" borderBottom="2px solid #171725" color="#171725" fontSize="14px">Reviews</Text>
                                            <Icon as={FaStar} color="primaryYellow.100" />
                                            <Text display={'flex'}>4.8 <Text color="#66707A" as="span" fontWeight="500">({storeData?.reviews?.length})</Text></Text>
                                            <Icon as={HiOutlineShoppingBag} />
                                            <Text display={'flex'} color="#66707A" fontWeight="500">{storeData?.totalOrders} orders</Text>
                                            {/* <Button h="0px" minW="0px"><Icon fontSize='22px' as={HiOutlineChevronDown} /></Button> */}
                                        </Box>
                                        {
                                            user?.role?.name === 'vendor' &&
                                            <Stack direction="row" gap="10px" mb="10px">
                                                {/* <Stack className='btn'>
                                        <Button
                                            onClick={() => { }}
                                            justifyContent={'center'}
                                            padding={'10px 20px'}
                                            bg={"primaryGreen.100"}
                                            bgImage={greenBtnTexture}
                                            bgPos={'center'}
                                            bgSize={'cover'}
                                            bgRepeat={'no-repeat'}
                                            color={'primaryYellow.100'}
                                            fontWeight={'400'}
                                            border={'2px solid'}
                                            borderColor={"primaryGreen.100"}
                                            textAlign={'left'}
                                            _hover={{
                                                bg: "primaryGreen.100",
                                                borderColor: "primaryGreen.100",
                                                color: '#fff'
                                            }}
                                        >
                                            <Text as={'span'}>Edit Category</Text>
                                        </Button>
                                    </Stack> */}
                                                <Stack className='btn'>
                                                    <Button
                                                        onClick={() => navigate('/dashboard/add-menu')}
                                                        justifyContent={'center'}
                                                        padding={'10px 20px'}
                                                        bg={"primaryGreen.100"}
                                                        bgImage={greenBtnTexture}
                                                        bgPos={'center'}
                                                        bgSize={'cover'}
                                                        bgRepeat={'no-repeat'}
                                                        color={'primaryYellow.100'}
                                                        fontWeight={'400'}
                                                        border={'2px solid'}
                                                        borderColor={"primaryGreen.100"}
                                                        textAlign={'left'}
                                                        _hover={{
                                                            bg: "primaryGreen.100",
                                                            borderColor: "primaryGreen.100",
                                                            color: '#fff'
                                                        }}
                                                    >
                                                        <Text as={'span'}>Add New Item</Text>
                                                    </Button>
                                                </Stack>
                                            </Stack>
                                        }
                                    </Box>
                                </Stack>
                                <Stack direction="row" flexWrap="wrap" gap="2.5%" spacing="0">
                                    {
                                        storeItems?.length > 0 &&
                                        storeItems?.map(val => <StoreItemBox w="330px" {...val} />)
                                    }

                                </Stack>
                                {
                                    storeData?.store?.menu2?.length > 0 &&
                                    <Heading fontSize="24px" fontWeight="600" my="20px">Digital Menus</Heading>
                                }
                                <Stack direction="row" pb="30px" flexWrap="wrap" gap="2.5%" spacing="0">
                                    {
                                        storeData?.store?.menu2?.length > 0 &&
                                        storeData?.store?.menu2?.map(val =>
                                            <Box position="relative" key={val?._id} w="23%" h="300px">
                                                <Image objectFit={'contain'} src={`${imgUrl}/${val?.picture}`} w="100%" />
                                                {
                                                    user?.role?.name === 'vendor' ?
                                                        <Box position="absolute" right="-3px" top="-3px" bgColor="red.700" color="#fff" w="fit-content" borderRadius="100%" display="flex" alignItems="center" justifyContent="center">
                                                            <Icon
                                                                fontSize="28px"
                                                                as={MdDeleteForever}
                                                                cursor="pointer"
                                                                color="#fff"
                                                                onClick={() => { deleteDigitalMenu(val?._id) }}
                                                            />
                                                        </Box>
                                                        : null
                                                }
                                            </Box>
                                        )
                                    }
                                </Stack>
                            </Stack>
                        </>
                }
            </Stack>

            <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
                <ModalOverlay />
                <ModalContent p="0" textAlign={'center'}>
                    <ModalHeader>
                        <ModalCloseButton />
                    </ModalHeader>
                    <Heading color="primaryGreen.100" fontSize={'24px'} m="20px 0">Deleting Category!</Heading>
                    <Text fontSize={'16px'} fontWeight={'500'}>Are you sure you want to delete this category. <br /> Removing a category will be a permanent proceedure and cannot be un-done.</Text>
                    <ModalFooter mt="30px" display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Stack className='btn'>
                            <Button
                                onClick={() => {
                                    setSelectedCatToDelete(null)
                                    onClose()
                                }}
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={"primaryGreen.100"}
                                bgImage={greenBtnTexture}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                color={'primaryYellow.100'}
                                fontWeight={'400'}
                                border={'2px solid'}
                                borderColor={"primaryGreen.100"}
                                textAlign={'left'}
                                _hover={{
                                    bg: "primaryGreen.100",
                                    borderColor: "primaryGreen.100",
                                    color: '#fff'
                                }}
                            >
                                <Text as={'span'}>Cancel</Text>
                            </Button>
                        </Stack>
                        <Stack className='btn'>
                            <Button
                                onClick={() => {
                                    removeCatFromStore()
                                }}
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={"red.700"}
                                ml="10px"
                                color={'#fff'}
                                fontWeight={'400'}
                                border={'2px solid'}
                                borderColor={"red.700"}
                                textAlign={'left'}
                                _hover={{
                                    bg: "red.700",
                                    borderColor: "red.700",
                                    color: '#fff'
                                }}
                            >
                                <Text as={'span'}>Delete</Text>
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
