import React from 'react'
import Rimg from '../../assets/images/auth/r1.jpg';
import { Container, Stack, Grid, GridItem, Image } from '@chakra-ui/react';

export default function AuthPic({Picsrc}) {
  return (
    <>
       <Stack className="rimg" position={'absolute'} right={0}>
          <Image
            borderLeft={'10px solid'}
            borderLeftColor="primaryYellow.100"
            borderRadius={'14% 0 0 14%'}
            src={Picsrc ? Picsrc : Rimg}
          />
        </Stack>
    </>
  )
}
