import {
    Stack, Heading, Text, Box, Button, Image, Grid, GridItem, useToast, Input, FormLabel, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiMenuAlt1, HiOutlineClock } from 'react-icons/hi'
import { useEffect, useRef, useState } from 'react'
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import eventPic from '../../../assets/images/eventPic.svg'
import { useNavigate } from 'react-router';
import { DELETE, GET, POST, PUT } from '../../../utilities/ApiProvider.js';
import { useSelector } from 'react-redux';
import { imgUrl } from '../../../utilities/config.js';
import { years } from '../../../utilities/helper.js';

export default function BannersWrap() {

    const navigate = useNavigate();
    const banRef = useRef(null);
    const imgRef = useRef(null);
    const toast = useToast();
    const user = useSelector(state => state?.value);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const [isShowingArchive, setIsShowingArchive] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [archiveBanners, setArchiveBanners] = useState([]);
    // const options = { day: "numeric", month: "short", year: "numeric" };
    const [bannerImages, setBannerImages] = useState({
        banner: {
            name: 'Choose Image (1327px x 280px)'
        },
        mobile_banner: {
            name: 'Choose Image (330px x 174px)'
        }
    })
    const options = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    };

    const getEvents = async () => {
        try {
            let res = await GET(`/admin/banner`, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                let activeData = [];
                let archiveData = [];
                res?.data?.map(val => {
                    if (val?.isArchive === true) {
                        archiveData.push(val)
                    } else {
                        activeData.push(val)
                    }
                })
                setEventsList(activeData)
                setArchiveBanners(archiveData)
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
    }

    const updateArcStatus = async (id, val) => {
        setIsLoading(true);
        let data = {
            isArchive: !val
        }
        if (val) {
            // Create a new Date object
            let currentDate = new Date();

            // Get the current date components
            let day = currentDate.getDate(); // Returns the day of the month (1-31)
            let month = currentDate.getMonth() + 1; // Returns the month (0-11), so we add 1 to get the correct month
            let year = currentDate.getFullYear(); // Returns the year (four digits)
            let finalDate = `${year}-${month}-${day}`

            data.startDate = finalDate;
        }
        try {
            let res = await PUT(`/admin/banner/archive/${id}`, data, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                toast({
                    description: res?.message,
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
                getEvents();
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    const deleteBan = async (id) => {
        setIsLoading(true);
        try {
            let res = await DELETE(`/admin/banner/${id}`, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                toast({
                    description: 'Banner Deleted!',
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
                getEvents();
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    const addbanner = async () => {
        setIsLoading(true);
        let form = document.getElementById('AddBannerForm');
        let data = new FormData(form);
        // Create a new Date object
        let currentDate = new Date();

        // Get the current date components
        let day = currentDate.getDate(); // Returns the day of the month (1-31)
        let month = currentDate.getMonth() + 1; // Returns the month (0-11), so we add 1 to get the correct month
        let year = currentDate.getFullYear(); // Returns the year (four digits)
        let finalDate = `${year}-${month}-${day}`
        // Display the current date
        data.append('startDate', finalDate);
        try {

            let res = await POST(`/admin/banner`, data, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                toast({
                    description: 'Banner Added!',
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
                setBannerImages({
                    banner: {
                        name: 'Choose Image (1327px x 280px)'
                    },
                    mobile_banner: {
                        name: 'Choose Image (330px x 174px)'
                    }
                })
                getEvents();
                onClose();
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (user) getEvents();
    }, [user])

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Stack m="0 60px 30px 0" direction={'row'} justifyContent={'space-between'}>
                    <Box>
                        <Heading fontSize={'22px'}>Banners</Heading>
                        <Text fontSize={'16px'} color={'#5A5D64'}>You've {eventsList.length} banners</Text>
                    </Box>
                    <Box display="flex" gap="10px">
                        <Stack className='btn' w={'fit-content'} ml="auto">
                            <Button
                                isLoading={isLoading}
                                onClick={onOpen}
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={"primaryGreen.100"}
                                color={'primaryYellow.100'}
                                fontWeight={'400'}
                                border={'2px solid'}
                                bgImage={greenBtnTexture}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                borderColor={"primaryGreen.100"}
                                textAlign={'center'}
                                _hover={{
                                    bg: "primaryGreen.100",
                                    borderColor: "primaryGreen.100",
                                    color: '#fff'
                                }}
                            >
                                <Text as={'span'}>Add New Banners</Text>
                            </Button>
                        </Stack>
                        <Stack className='btn' w={'fit-content'} ml="auto">
                            <Button
                                onClick={() => setIsShowingArchive(!isShowingArchive)}
                                isLoading={isLoading}
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={"primaryGreen.100"}
                                color={'primaryYellow.100'}
                                fontWeight={'400'}
                                border={'2px solid'}
                                bgImage={greenBtnTexture}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                borderColor={"primaryGreen.100"}
                                textAlign={'center'}
                                _hover={{
                                    bg: "primaryGreen.100",
                                    borderColor: "primaryGreen.100",
                                    color: '#fff'
                                }}
                            >
                                <Text as={'span'}>View {!isShowingArchive ? 'Archived' : 'Active'} Banners</Text>
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
                <Grid
                    templateColumns="repeat(12, 1fr)"
                    gap={{ base: 2, sm: 12 }}
                    mr={'60px !important'}
                >
                    <GridItem colSpan={{ base: '4', md: '12' }}>
                        <Stack direction="row" flexWrap="wrap" justifyContent={'space-between'}>
                            {
                                !isShowingArchive ?
                                    eventsList?.map(val => {
                                        let splittedDate = String(val?.startDate).split('T')[0].split('-');
                                        let splittedEndDate = String(val?.endDate).split('T')[0].split('-');
                                        return (
                                            <Box w="100%" mb="30px !important" display={'flex'} gap="40px" alignItems={'center'}>
                                                <Box w="48%">
                                                    <FormLabel textAlign={'center'}>Web Banner</FormLabel>
                                                    <Image src={val?.banner ? `${imgUrl}/${val?.banner}` : eventPic} w="100%" h="280px" overflow={'hidden'} objectFit={'contain'} borderRadius={'10px'} mb="10px" />
                                                </Box>
                                                <Box w="48%">
                                                    <FormLabel textAlign={'center'}>Mobile Banner</FormLabel>
                                                    <Image src={val?.mobile_banner ? `${imgUrl}/${val?.mobile_banner}` : eventPic} w="100%" h="280px" overflow={'hidden'} objectFit={'contain'} borderRadius={'10px'} mb="10px" />
                                                </Box>
                                                <Box>
                                                    {
                                                        val?.duration > 0 ?
                                                            <Text fontSize="14px" textAlign="right" mb="10px">
                                                                {years[splittedDate[1]]} {splittedDate[2]} - {years[splittedEndDate[1]]} {splittedEndDate[2]}, {splittedEndDate[0]}

                                                            </Text>
                                                            :
                                                            <Text textAlign="right" mb="10px">Infinite</Text>
                                                    }
                                                    <Stack mb="15px" className='btn' w={'fit-content'} ml="auto">
                                                        <Button
                                                            isLoading={isLoading}
                                                            onClick={() => { updateArcStatus(val?._id, val?.isArchive) }}
                                                            justifyContent={'center'}
                                                            padding={'10px 20px'}
                                                            bg={"primaryGreen.100"}
                                                            color={'primaryYellow.100'}
                                                            fontWeight={'400'}
                                                            border={'2px solid'}
                                                            bgImage={greenBtnTexture}
                                                            bgPos={'center'}
                                                            bgSize={'cover'}
                                                            bgRepeat={'no-repeat'}
                                                            borderColor={"primaryGreen.100"}
                                                            textAlign={'center'}
                                                            _hover={{
                                                                bg: "primaryGreen.100",
                                                                borderColor: "primaryGreen.100",
                                                                color: '#fff'
                                                            }}
                                                        >
                                                            <Text as={'span'}>Archive</Text>
                                                        </Button>
                                                    </Stack>
                                                    <Stack className='btn'>
                                                        <Button
                                                            isLoading={isLoading}
                                                            onClick={() => deleteBan(val?._id)}
                                                            justifyContent={'center'}
                                                            padding={'10px 20px'}
                                                            bg={"#D5DADE"}
                                                            color={'#000'}
                                                            w={'180px'}
                                                            fontWeight={'400'}
                                                            border={'2px solid'}
                                                            borderColor={"#D5DADE"}
                                                            textAlign={'center'}
                                                            _hover={{
                                                                bg: "#D5DADE",
                                                                borderColor: "#D5DADE",
                                                                color: '#000'
                                                            }}
                                                        >
                                                            <Text as={'span'}>Remove</Text>
                                                        </Button>
                                                    </Stack>
                                                </Box>
                                            </Box>)
                                    })
                                    :
                                    archiveBanners?.map(val =>
                                        <Box w="100%" mb="30px !important" display={'flex'} gap="40px" alignItems={'center'}>
                                            <Box w="48%">
                                                <FormLabel textAlign={'center'}>Web Banner</FormLabel>
                                                <Image src={val?.banner ? `${imgUrl}/${val?.banner}` : eventPic} w="100%" h="280px" overflow={'hidden'} objectFit={'contain'} borderRadius={'10px'} mb="10px" />
                                            </Box>
                                            <Box w="48%">
                                                <FormLabel textAlign={'center'}>Mobile Banner</FormLabel>
                                                <Image src={val?.mobile_banner ? `${imgUrl}/${val?.mobile_banner}` : eventPic} w="100%" h="280px" overflow={'hidden'} objectFit={'contain'} borderRadius={'10px'} mb="10px" />
                                            </Box>
                                            <Box>
                                                <Stack mb="15px" className='btn' w={'fit-content'} ml="auto">
                                                    <Button
                                                        isLoading={isLoading}
                                                        justifyContent={'center'}
                                                        onClick={() => { updateArcStatus(val?._id, val?.isArchive) }}
                                                        padding={'10px 20px'}
                                                        bg={"primaryGreen.100"}
                                                        color={'primaryYellow.100'}
                                                        fontWeight={'400'}
                                                        border={'2px solid'}
                                                        bgImage={greenBtnTexture}
                                                        bgPos={'center'}
                                                        bgSize={'cover'}
                                                        bgRepeat={'no-repeat'}
                                                        borderColor={"primaryGreen.100"}
                                                        textAlign={'center'}
                                                        _hover={{
                                                            bg: "primaryGreen.100",
                                                            borderColor: "primaryGreen.100",
                                                            color: '#fff'
                                                        }}
                                                    >
                                                        <Text as={'span'}>Restore</Text>
                                                    </Button>
                                                </Stack>
                                                <Stack className='btn'>
                                                    <Button
                                                        isLoading={isLoading}
                                                        onClick={() => deleteBan(val?._id)}
                                                        justifyContent={'center'}
                                                        padding={'10px 20px'}
                                                        bg={"#D5DADE"}
                                                        color={'#000'}
                                                        w={'180px'}
                                                        fontWeight={'400'}
                                                        border={'2px solid'}
                                                        borderColor={"#D5DADE"}
                                                        textAlign={'center'}
                                                        _hover={{
                                                            bg: "#D5DADE",
                                                            borderColor: "#D5DADE",
                                                            color: '#000'
                                                        }}
                                                    >
                                                        <Text as={'span'}>Remove</Text>
                                                    </Button>
                                                </Stack>
                                            </Box>
                                        </Box>)
                            }
                        </Stack>
                    </GridItem>
                </Grid>
            </Stack >
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Upload Banners</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form id="AddBannerForm">
                            <FormLabel mt="20px !important" fontWeight="600">Select Web Banner Image</FormLabel>
                            <Box className="Ifiled" onClick={() => banRef?.current.click()}>
                                <Input
                                    cursor={'pointer'}
                                    readOnly
                                    value={bannerImages.banner?.name ?? 'Select File'}
                                />
                                <Input
                                    type="file"
                                    name="banner" ref={banRef}
                                    onChange={e => setBannerImages({
                                        ...bannerImages,
                                        banner: e.target.files[0]
                                    })}
                                    display={'none'}
                                />
                            </Box>
                            <FormLabel mt="20px !important" fontWeight="600">Select Mobile Banner Image</FormLabel>
                            <Box className="Ifiled" onClick={() => imgRef?.current.click()}>
                                <Input
                                    cursor={'pointer'}
                                    readOnly
                                    value={bannerImages.mobile_banner?.name ?? 'Select File'}
                                />
                                <Input
                                    type="file"
                                    name="mobile_banner" ref={imgRef}
                                    onChange={e => setBannerImages({
                                        ...bannerImages,
                                        mobile_banner: e.target.files[0]
                                    })}
                                    display={'none'}
                                />
                            </Box>
                            {/* <FormLabel mt="20px !important" fontWeight="600">Start Date</FormLabel>
                            <Box className="Ifiled" >
                                <Input type="date" name="startDate" />
                            </Box> */}
                            <FormLabel mt="20px !important" fontWeight="600">Duration</FormLabel>
                            <Box className="Ifiled" >
                                <Input type="number" name="duration" defaultValue="0" />
                            </Box>
                        </form>
                    </ModalBody>

                    <ModalFooter>
                        <Stack className='btn' w={'fit-content'} ml="auto">
                            <Button
                                isLoading={isLoading}
                                onClick={addbanner}
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={"primaryGreen.100"}
                                color={'primaryYellow.100'}
                                fontWeight={'400'}
                                border={'2px solid'}
                                bgImage={greenBtnTexture}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                borderColor={"primaryGreen.100"}
                                textAlign={'center'}
                                _hover={{
                                    bg: "primaryGreen.100",
                                    borderColor: "primaryGreen.100",
                                    color: '#fff'
                                }}
                            >
                                <Text as={'span'}>Create</Text>
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}