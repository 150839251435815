import { Table, Thead, Tbody, Tr, Td, Th, Stack, Text, Icon } from '@chakra-ui/react';
import { HiOutlineClock } from 'react-icons/hi'

export default function OrderTable({ orderTableData }) {

    return (
        <Stack borderRadius={'5px'} padding="15px 10px" boxShadow={'0px 0px 35px -15px #000'}>
            <Table>
                <Thead bgColor="primaryYellow.100">
                    <Tr borderRadius="5px" overflow="hidden">
                        <Th fontWeight="700">#</Th>
                        <Th fontWeight="700">TIme</Th>
                        <Th fontWeight="700">Customer</Th>
                        <Th fontWeight="700">Store</Th>
                        <Th fontWeight="700">Status</Th>
                        <Th fontWeight="700">ETA</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        orderTableData?.length > 0 &&
                        orderTableData?.map(val =>
                            <Tr key={val._id} fontSize={'13px'} borderBottom="2px solid #C4C4C4" h="20px !important">
                                <Td>{val?.orderNo}</Td>
                                <Td>{String(val.updatedAt).split('T')[1].split('.')[0]}</Td>
                                <Td>{val.customer?.username}</Td>
                                <Td>{val.store?.store_name}</Td>
                                <Td><Text fontSize={'13px'} bgColor="#EAFFEA" w="fit-content" p="0 10px" color="#027800" textTransform={'capitalize'} fontWeight="500">{val?.orderStatus}</Text></Td>
                                <Td><Icon as={HiOutlineClock} m='0 5px -2px 0' />{val.estimatedTime}</Td>
                            </Tr>
                        )
                    }
                </Tbody>
            </Table>
            {
                !orderTableData?.length > 0 &&
                <Text textAlign="center" p="10px 0" w="100%" fontWeight={'500'} color={'primaryGreen.100'}>No Record found!</Text>
            }
        </Stack>
    )
}
