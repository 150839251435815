import { Stack, Text, Box, Image, Heading, useToast, Spinner, Button } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
// Components
import Header from "../../components/Website/Header/header";
import HalalAdd from "../../components/Website/Home/halalAdd";
import SignCard from "../../components/Website/Home/signCard";
import CounterBox from "../../components/Website/Home/counterBox";
import HeroBanner from "../../components/Website/Home/heroBanner";
import Footer from "../../components/Website/Footer/Footer";
// Images
import about1 from '../../assets/images/website/aboutUs/about1.png';
import about2 from '../../assets/images/website/aboutUs/about2.png';
import about3 from '../../assets/images/website/aboutUs/rocket.png';
import target from '../../assets/images/website/aboutUs/target.svg';
import miniLogo from '../../assets/images/website/aboutUs/miniLogo.svg';

import SinglePartnerBox from '../../components/SuperDashboard/Partners/SinglePartnerBox.js';
import BlackOver from '../../assets/images/icon/black.png';
import { teamMember, signData } from "../../assets/data/data";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import { GET, POST } from "../../utilities/ApiProvider";
import greenBtnTexture from '../../assets/images/greenBtnTexture.svg'
import ReactPaginate from 'react-paginate'

const StoreListCategoryWise = () => {

    const params = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const [isLoadingPartnersInfo, setIsLoadingPartnersInfo] = useState(false);
    const [partnersList, setPartnersList] = useState([]);

    const getPartners = async (searchVal) => {
        setIsLoadingPartnersInfo(true);
        try {
            let res = await POST(`/app/store/web/${params?.name}`);
            if (res.status === 200) {
                setPartnersList(res?.data)
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // Add smooth scrolling behavior
                });
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoadingPartnersInfo(false);
    }

    useEffect(() => {
        getPartners();
    }, [params])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Add smooth scrolling behavior
        });
    }, []); // Empty dependency array ensures that this effect runs only once after initial mount

    return (
        <>

            <Header />

            <Box>
                <HeroBanner text={params?.name ?? ''} />
            </Box>

            <Box p={"80px 0 50px 0px"} bgImage={BlackOver}>
                <Container maxW={"1440px"}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mr="60px">
                        <Box display={'flex'} gap="20px" alignItems={'center'}>
                            <Box>
                                <Heading fontSize="24px" fontWeight="600">{params?.name} {isLoadingPartnersInfo && <Spinner size={'sm'} color='primaryGreen.100' />}</Heading>
                                <Text fontSize="16px" mb="20px !important">You've {partnersList?.length} stores</Text>
                            </Box>
                        </Box>
                    </Stack>
                    {
                        partnersList.length > 0 ?
                            <Stack direction={'row'} gap={'1%'} flexWrap={'wrap'} justifyContent={'space-between'} spacing={0} mr="60px !important">
                                {
                                    partnersList?.length > 0 &&
                                    partnersList?.map((val, ind) =>
                                        <Stack key={val?._id} mb="1% !important" w="49%" borderRadius={'5px'} padding="15px 35px" boxShadow={'0px 0px 35px -15px #000'} >
                                            <SinglePartnerBox hideDelete={true} isAdminCreated={false} getPartners={getPartners} {...val} />
                                        </Stack>
                                    )
                                }
                            </Stack>
                            : null
                    }
                </Container>
            </Box>

            <Footer />
        </>
    )
}

export default StoreListCategoryWise;