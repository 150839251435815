import { Stack, Image, Box, Heading, Text, Icon, Button, Flex } from "@chakra-ui/react";
import foodImg from '../../../assets/images/auth/r6.jpg';
import { FaStar, FaHeart, FaChevronRight } from 'react-icons/fa';
import { imgUrl } from "../../../utilities/config";

export default function PurchasedItemBox({ val, setSelectedItem }) {
    return (
        <Stack key={val?._id} borderRadius={'5px'} boxShadow={'0px 0px 35px -15px #000'} w="32%" direction={'row'} alignItems="center" mb="30px !important" mt="0 !important" p={"15px !important"} borderBottom="1px solid #BFC6CC">
            <Image src={val?.store?.logo ? `${imgUrl}/${val?.store?.logo}` : foodImg} w="92px" h="92px" objectFit={'cover'} borderRadius="12px" />
            <Flex justifyContent="space-between" w="full">
                <Box>
                    <Heading fontSize="16px" fontWeight="600" textTransform="capitalize" mb="5px">{val?.store?.store_name ?? 'Loading'}</Heading>
                    <Text fontSize="14px" color="#616161" mb="10px" w='250px' overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>{val?.store?.aboutus ?? 'Loading'}</Text>
                    <Text fontSize="14px" color="#78828A"> {val?.store?.area ?? 'loading'}</Text>
                    {/* <Icon as={FaStar} color="primaryYellow.100" fontSize="12px" /> */}
                </Box>
                <Box
                    display="flex"
                    flexDir={'column'}
                    justifyContent="center"
                    alignItems="center"
                >
                    {/* <Icon cursor="pointer" color="primaryYellow.100" as={FaHeart} fontSize="22px" /> */}
                    <Button onClick={() => setSelectedItem(new Object(val))} h="fit-content" w="fit-content" p="0" minW="0" minH="0" bg="transparent" alignItems="flex-end">
                        <Icon as={FaChevronRight} fontSize="20px" color="#78828A" />
                    </Button>
                </Box>
            </Flex>
        </Stack>
    )
}