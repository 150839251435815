import { Stack, Text, Box, Button, Image, useToast } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiMenuAlt1, HiOutlineClock } from 'react-icons/hi'
import { useState, useEffect } from 'react'
import evePic from '../../../assets/images/eventPic.svg';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { formatTime } from '../../../utilities/helper.js';
import { GET, PUT } from '../../../utilities/ApiProvider.js';
import { imgUrl } from '../../../utilities/config.js';
import { years } from '../../../utilities/helper.js';

export default function HelpWrap() {

    const params = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const user = useSelector(state => state?.value);

    const [isLoading, setIsLoading] = useState(false);
    const [eventData, setEventData] = useState(null);
    const [customDate, setCustomDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const options = { day: "numeric", month: "short", year: "numeric" };

    const getEventData = async () => {
        try {
            let res = await GET(`/admin/posts/${params?.id}`, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                setEventData(res?.data);
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
    }

    const updateArcStatus = async () => {
        setIsLoading(true);
        try {
            let res = await PUT(`/admin/posts/${params?.id}`, { isArchive: !eventData?.isArchive }, { authorization: `Bearer ${user?.verificationToken}` });
            if (res.status === 200) {
                toast({
                    description: 'Event updated!',
                    status: 'success',
                    position: 'top-right',
                    duration: 2500
                });
                navigate('/super-dashboard/eventS');
            } else {
                toast({
                    description: res.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
        } catch (err) {
            toast({
                description: err.message,
                status: 'error',
                position: 'top-right',
                duration: 2500
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (user) getEventData();
    }, [user]);

    useEffect(() => {
        if (eventData) {
            let splittedDate = String(eventData?.date).split('T')[0].split('-');
            let splittedEndDate = String(eventData?.endDate).split('T')[0].split('-');

            setCustomDate(splittedDate)
            setCustomEndDate(splittedEndDate)
        }
    }, [eventData]);

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Stack direction={'row'} alignItems={'center'} gap="20px" justifyContent={'space-between'} mr="60px !important">
                    <Box>
                        <Box mb="20px" display={'flex'} alignItems={'center'} gap="20px">
                            {
                                (customDate && customEndDate) &&
                                <Text fontSize={'16px'} color={'#5A5D64'}>Date: {years[customDate[1]]} {customDate[2]} - {years[customEndDate[1]]} {customEndDate[2]}, {customEndDate[0]}</Text>
                            }
                        </Box>
                        <Text mb="20px" fontSize={'16px'} color={'#5A5D64'}>Time: <Text as="span" color={'#000'} fontWeight={'500'}>{eventData?.time ? String(eventData?.time) : null} - {eventData?.endtime ? String(eventData?.endtime) : null}</Text></Text>
                        <Box mb="20px" display={'flex'} alignItems={'center'} gap="20px">
                            <Text fontSize={'16px'} color={'#5A5D64'}>Organizer: <Text as="span" color={'#000'} fontWeight={'500'}>{eventData?.contactPerson ?? 'Loading'}</Text></Text>
                            {
                                eventData?.contactNumber &&
                                <Text fontSize={'16px'} color={'#5A5D64'}>Contact: <Text as="span" color={'#000'} fontWeight={'500'}>{eventData?.contactNumber ?? 'Loading'}</Text></Text>
                            }
                        </Box>
                        {
                            eventData?.paid &&
                            <Text mb="20px" fontSize={'16px'} color={'#5A5D64'}>Price: <Text as="span" color={'#000'} fontWeight={'500'}>C${eventData?.price ?? 'Loading'}</Text></Text>
                        }
                        {
                            eventData?.location &&
                            <Text mb="20px" fontSize={'16px'} color={'#5A5D64'}>Location: <Text as="span" color={'#000'} fontWeight={'500'}>{eventData?.location ?? 'Loading'}</Text></Text>
                        }
                        {
                            eventData?.googleFormLink &&
                            <Text mb="20px" fontSize={'16px'} color={'#5A5D64'}>Form: <Text as="span" color={'#000'} fontWeight={'500'} onClick={() => window.open(`${eventData?.googleFormLink ?? ''}`, '_blank')} cursor="pointer">{eventData?.googleFormLink ?? 'Loading'}</Text></Text>
                        }
                        {
                            eventData?.conferanceLink &&
                            <Text mb="20px" fontSize={'16px'} color={'#5A5D64'}>Conference Link: <Text as="span" color={'#000'} fontWeight={'500'} onClick={() => window.open(`${eventData?.conferanceLink ?? ''}`, '_blank')} cursor="pointer">{eventData?.conferanceLink ?? 'Loading'}</Text></Text>
                        }
                    </Box>
                    <Stack className='btn'>
                        <Button
                            isLoading={isLoading}
                            onClick={updateArcStatus}
                            justifyContent={'center'}
                            padding={'10px 20px'}
                            bg={"primaryGreen.100"}
                            color={'primaryYellow.100'}
                            w={'180px'}
                            bgImage={greenBtnTexture}
                            bgPos={'center'}
                            bgSize={'cover'}
                            bgRepeat={'no-repeat'}
                            fontWeight={'400'}
                            border={'2px solid'}
                            borderColor={"primaryGreen.100"}
                            textAlign={'center'}
                            _hover={{
                                bg: "primaryGreen.100",
                                borderColor: "primaryGreen.100",
                                color: '#fff'
                            }}
                        >
                            <Text as={'span'}>{eventData?.isArchive ? 'Restore' : 'Archive'}</Text>
                        </Button>
                    </Stack>
                </Stack>
                <Image lazy src={eventData?.picture ? `${imgUrl}/${eventData?.picture}` : evePic} w="665px" h="400px" overflow={'hidden'} objectFit={'cover'} borderRadius={'10px'} mb="10px" />
                <Text fontWeight={'600'} fontSize={'22px'} mb="20px" maxW={'650px'}>{eventData?.name}</Text>
                <Text fontSize={'16px'} color={'#5A5D64'} mb="10px" maxW={'650px'}>{eventData?.description}</Text>
            </Stack >
        </>
    )
}