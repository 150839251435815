import { Stack, Heading, Menu, MenuButton, Icon, MenuList, MenuItem, Button, Text } from "@chakra-ui/react";
import { BsThreeDots } from 'react-icons/bs';
import { FaCircle } from 'react-icons/fa'
import RegisteredPartnersSingleItem from './RegisteredPartnersSingleItem.js'

export default function PopularItems({ registeredPartners }) {

    return (
        <Stack borderRadius={'5px'} padding="15px 35px" boxShadow={'0px 0px 35px -15px #000'}>
            <Stack direction={'row'} alignItems="center" justifyContent="space-between" mb="5px">
                <Heading fontSize="20px">Registered Partners</Heading>
                {/* <Menu>
                    <MenuButton textAlign={'center'} as={Button} backgroundColor={'transparent'} padding={0} _hover={{ backgroundColor: 'transparent' }} _active={{ backgroundColor: 'transparent' }}>
                        <Icon as={BsThreeDots} fontSize={'24px'} />
                    </MenuButton>
                    <MenuList fontSize={14}>
                        <MenuItem><FaCircle size={6} color={'primaryYellow.100'} /><Text ml={2}>Filter 1</Text></MenuItem>
                        <MenuItem><FaCircle size={6} color={'primaryYellow.100'} /><Text ml={2}>Filter 2</Text></MenuItem>
                        <MenuItem><FaCircle size={6} color={'primaryYellow.100'} /><Text ml={2}>Filter 3</Text></MenuItem>
                    </MenuList>
                </Menu> */}
            </Stack>
            {
                registeredPartners?.length > 0 &&
                registeredPartners?.map(val =>
                    <RegisteredPartnersSingleItem {...val} />
                )
            }
        </Stack>
    )
}