import {
  Stack,
  Heading,
  Icon,
  Text,
  Box,
  Button,
  Image,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png';
import { useEffect, useRef, useState } from 'react';
import DashboardHeader from '../../Header/DashboardHeader.js';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { AiOutlineLock } from 'react-icons/ai';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import defaultPic from '../../../assets/images/defaultPic.svg';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg';
import { useDispatch, useSelector } from 'react-redux';
import { PUT } from '../../../utilities/ApiProvider.js';
import { imgUrl } from '../../../utilities/config.js';
import {
  loadUserName,
  loadStoreLogo,
  loadAdminPic,
} from '../../../reducers/useReducers.js';
import PingUserForm from './PingUserForm.js';

export default function HelpWrap() {
  const toast = useToast();
  const imgReg = useRef(null);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showpass, setshowpass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [username, setUsername] = useState('');
  const [number, setNumber] = useState('');
  const [pass, setPass] = useState({
    password: '',
    confirm_password: '',
  });
  const user = useSelector(state => state?.value);

  const updateUser = async () => {
    let data;
    if (pass.password !== pass.confirm_password) {
      toast({
        description: 'Password doesnot match!',
        status: 'error',
        position: 'top-right',
        duration: 2500,
      });
    } else {
      if (pass.password === '') {
        data = {
          username,
          // number
        };
      } else {
        data = {
          username,
          // number,
          ...pass,
        };
      }
      setIsLoading(true);
      try {
        let res = await PUT(
          user?.role?.name === 'vendor'
            ? '/vendor/store/settings'
            : '/admin/store/settings',
          data,
          { authorization: `Bearer ${user?.verificationToken}` }
        );
        if (res.status === 200) {
          toast({
            description: 'Settings updated!',
            status: 'success',
            position: 'top-right',
            duration: 2500,
          });
          setPass({
            password: '',
            confirm_password: '',
          });
          dispatch(loadUserName(res?.data?.user?.username));
        } else {
          toast({
            description: res?.message,
            status: 'error',
            position: 'top-right',
            duration: 2500,
          });
        }
      } catch (err) {
        toast({
          description: err.message,
          status: 'error',
          position: 'top-right',
          duration: 2500,
        });
      }
    }
    setIsLoading(false);
  };

  const uploadProfilePic = async () => {
    setUploadingImage(true);
    try {
      let form = document.getElementById('profilePicForm');
      let data = new FormData(form);
      let res = await PUT(
        user?.role?.name === 'vendor'
          ? '/vendor/store/picture'
          : '/admin/store/picture',
        data,
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (res?.status === 200) {
        toast({
          description: 'Profile Picture Updated!',
          status: 'success',
          position: 'top-right',
          duration: 2500,
        });
        if (user?.role?.name === 'vendor') {
          dispatch(loadStoreLogo(res?.data?.logo));
        } else {
          dispatch(loadAdminPic(res?.data?.picture));
        }
      } else {
        toast({
          description: res?.message,
          status: 'error',
          position: 'top-right',
          duration: 2500,
        });
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500,
      });
    }
    setUploadingImage(false);
  };

  useEffect(() => {
    setUsername(user?.username);
    setNumber(user?.number);
  }, [user]);

  return (
    <>
      <DashboardHeader />
      <Stack
        overflowY="auto"
        margin={'0 !important'}
        gap={8}
        borderRadius={'30px 0 0 0'}
        py={10}
        px={6}
        bg={'#fff'}
        height={'100%'}
        backgroundImage={shapeb}
        bgPos={'-32px -100px'}
      >
        <Stack
          direction="row"
          alignItems="center"
          mr="60px !important"
          justifyContent="space-between"
        >
          <Heading fontSize="24px" fontWeight="600">
            Setting
          </Heading>
          <Stack className="btn">
            <Button
              onClick={onOpen}
              justifyContent={'center'}
              padding={'10px 20px'}
              bg={'primaryGreen.100'}
              color={'primaryYellow.100'}
              bgImage={greenBtnTexture}
              bgPos={'center'}
              bgSize={'cover'}
              bgRepeat={'no-repeat'}
              w="200px"
              fontWeight={'400'}
              border={'2px solid'}
              borderColor={'primaryGreen.100'}
              textAlign={'left'}
              _hover={{
                bg: 'primaryGreen.100',
                borderColor: 'primaryGreen.100',
                color: '#fff',
              }}
            >
              <Text as={'span'}>Change Password</Text>
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap="30px">
          <Box
            p="5px"
            w="138px"
            h="138px"
            borderRadius="100%"
            border={'4px solid'}
            borderColor="primaryGreen.100"
            overflow="hidden"
          >
            <Image
              src={
                user?.role?.name === 'vendor' && user?.store?.logo
                  ? `${imgUrl}/${user?.store?.logo}`
                  : user?.role?.name === 'superadmin' && user?.picture
                  ? `${imgUrl}/${user?.picture}`
                  : defaultPic
              }
              w="100%"
              h="100%"
              objectFit="cover"
            />
          </Box>
          <Box>
            <form id="profilePicForm">
              <Input
                ref={imgReg}
                display={'none'}
                type="file"
                onChange={uploadProfilePic}
                name="logo"
              />
            </form>
            <Stack className="btn" mb="20px">
              <Button
                isLoading={uploadingImage}
                onClick={() => imgReg?.current.click()}
                justifyContent={'center'}
                padding={'10px 20px'}
                w="200px"
                bgImage={greenBtnTexture}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                bg={'primaryGreen.100'}
                color={'primaryYellow.100'}
                fontWeight={'400'}
                border={'2px solid'}
                borderColor={'primaryGreen.100'}
                textAlign={'left'}
                _hover={{
                  bg: 'primaryGreen.100',
                  borderColor: 'primaryGreen.100',
                  color: '#fff',
                }}
              >
                <Text as={'span'}>Upload Photo</Text>
              </Button>
            </Stack>
            {/* <Stack className='btn'>
                            <Button
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                w="200px"
                                bgImage={greenBtnTexture}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                bg={"primaryGreen.100"}
                                color={'primaryYellow.100'}
                                fontWeight={'400'}
                                border={'2px solid'}
                                borderColor={"primaryGreen.100"}
                                textAlign={'left'}
                                _hover={{
                                    bg: "primaryGreen.100",
                                    borderColor: "primaryGreen.100",
                                    color: '#fff'
                                }}
                            >
                                <Text as={'span'}><Icon as={RiDeleteBin6Line} mb="-2px" /> Delete</Text>
                            </Button>
                        </Stack> */}
          </Box>
        </Stack>
        <Stack>
          <Heading fontSize="24px" fontWeight="600" mb="30px">
            General Settings
          </Heading>
          <Stack
            className="settingsForm"
            maxW="85%"
            direction="row"
            flexWrap="wrap"
            spacing="0"
            alignItems="flex-end"
          >
            <Box w="48%">
              <FormLabel>Full Name</FormLabel>
              <Input
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </Box>
            <Box w="48%" ml="40px !important">
              <FormLabel>Email Address</FormLabel>
              <Input value={user?.email} readOnly cursor={'not-allowed'} />
            </Box>
            <Box w="48%" mt="30px !important">
              <FormLabel>Phone Number</FormLabel>
              <Input
                value={number}
                onChange={e => setNumber(e.target.value)}
                readOnly
                cursor={'not-allowed'}
              />
            </Box>
            <Box ml="40px !important">
              <Stack className="btn">
                <Button
                  isLoading={isLoading}
                  onClick={updateUser}
                  justifyContent={'center'}
                  padding={'10px 20px'}
                  bg={'primaryGreen.100'}
                  color={'primaryYellow.100'}
                  w="270px"
                  bgImage={greenBtnTexture}
                  bgPos={'center'}
                  bgSize={'cover'}
                  bgRepeat={'no-repeat'}
                  fontWeight={'400'}
                  border={'2px solid'}
                  borderColor={'primaryGreen.100'}
                  textAlign={'left'}
                  _hover={{
                    bg: 'primaryGreen.100',
                    borderColor: 'primaryGreen.100',
                    color: '#fff',
                  }}
                >
                  <Text as={'span'}>Save</Text>
                </Button>
              </Stack>
            </Box>
          </Stack>
          {/* <Stack className='btn' mt="80px !important">
                        <Button
                            justifyContent={'center'}
                            padding={'10px 20px'}
                            bg={"primaryGreen.100"}
                            color={'primaryYellow.100'}
                            w="270px"
                            fontWeight={'400'}
                            border={'2px solid'}
                            borderColor={"primaryGreen.100"}
                            textAlign={'left'}
                            _hover={{
                                bg: "primaryGreen.100",
                                borderColor: "primaryGreen.100",
                                color: '#fff'
                            }}
                        >
                            <Text as={'span'}>Change Password</Text>
                        </Button>
                    </Stack> */}
        </Stack>
        <PingUserForm />
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent
          bgSize="cover"
          bgPos="center"
          backgroundImage={shapeb}
          p="20px 40px"
        >
          <ModalHeader fontWeight="600" fontSize={'24px'} textAlign="center">
            Change Password
          </ModalHeader>
          <ModalBody>
            <Heading fontSize="20px" mb="10px !important" fontWeight="600">
              New Password
            </Heading>
            <FormLabel mt="20px !important" fontWeight="600">
              New Password
            </FormLabel>
            <Box className="Ifiled">
              <Icon fontSize={'25px'} color={'#000'} as={AiOutlineLock} />
              <Input
                type={showpass ? 'text' : 'password'}
                placeholder="Enter here"
                value={pass.password}
                onChange={e =>
                  setPass({
                    ...pass,
                    password: e.target.value,
                  })
                }
              />
              <Button onClick={() => setshowpass(!showpass)}>
                <Icon as={showpass ? BsEye : BsEyeSlash} />
              </Button>
            </Box>
            <FormLabel mt="20px !important" fontWeight="600">
              Re-type Password
            </FormLabel>
            <Box className="Ifiled">
              <Icon fontSize={'25px'} color={'#000'} as={AiOutlineLock} />
              <Input
                type={showpass ? 'text' : 'password'}
                placeholder="Enter here"
                value={pass.confirm_password}
                onChange={e =>
                  setPass({
                    ...pass,
                    confirm_password: e.target.value,
                  })
                }
              />
              <Button onClick={() => setshowpass(!showpass)}>
                <Icon as={showpass ? BsEye : BsEyeSlash} />
              </Button>
            </Box>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Stack className="btn">
              <Button
                onClick={updateUser}
                justifyContent={'center'}
                isLoading={isLoading}
                padding={'10px 20px'}
                bg={'primaryGreen.100'}
                w="200px"
                color={'primaryYellow.100'}
                fontWeight={'400'}
                border={'2px solid'}
                bgImage={greenBtnTexture}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                borderColor={'primaryGreen.100'}
                textAlign={'left'}
                _hover={{
                  bg: 'primaryGreen.100',
                  borderColor: 'primaryGreen.100',
                  color: '#fff',
                }}
              >
                <Text as={'span'}>Save</Text>
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
