import { Stack, Heading, Icon, Text, Box, Button, Image, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Input, Spinner, useToast, } from '@chakra-ui/react';
import shapeb from '../../../assets/images/icon/black.png'
import DashboardHeader from '../../Header/DashboardHeader.js'
import { HiMenuAlt1, HiOutlineClock, HiOutlineSearch } from 'react-icons/hi'
import { useEffect, useState } from 'react'
import foodImg from '../../../assets/images/auth/r6.jpg';
import SinglePartnerBox from './SinglePartnerBox.js';
import { GET } from '../../../utilities/ApiProvider.js';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import greenBtnTexture from '../../../assets/images/greenBtnTexture.svg'
import ReactPaginate from 'react-paginate';

export default function HelpWrap() {

    const toast = useToast();
    const navigate = useNavigate();
    const user = useSelector(state => state?.value);
    const [isLoadingPartnersInfo, setIsLoadingPartnersInfo] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [showingIsAdmin, setShowingIsAdmin] = useState(0);
    const [PageCount, setPageCount] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalLength, setTotalLength] = useState(0);
    const [partnersList, setPartnersList] = useState({
        byUser: [],
        byAdmin: [],
        blocked: [],
    });

    const getPartners = async (searchVal) => {
        if (searchVal !== undefined) {
            setIsLoadingPartnersInfo(true);
            try {
                let res = await GET(`/admin/partners?search=${searchVal}&page=${currentPage}`, { authorization: `Bearer ${user?.verificationToken}` });
                if (res.status === 200) {
                    setPartnersList({
                        byUser: res?.websiteCreatedStores,
                        byAdmin: res?.data,
                        blocked: res?.blocked,
                    })
                    setPageCount(res?.pagination?.totalPages)
                    setTotalLength(res?.pagination?.totalLength)
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth' // Add smooth scrolling behavior
                    });
                } else {
                    toast({
                        description: res.message,
                        status: 'error',
                        position: 'top-right',
                        duration: 2500
                    });
                }
            } catch (err) {
                toast({
                    description: err.message,
                    status: 'error',
                    position: 'top-right',
                    duration: 2500
                });
            }
            setIsLoadingPartnersInfo(false);
        }
    }

    useEffect(() => {
        if (user) getPartners(searchValue);
    }, [user, currentPage, searchValue])

    return (
        <>
            <DashboardHeader />
            <Stack
                overflowY="auto"
                margin={'0 !important'}
                gap={8}
                borderRadius={'30px 0 0 0'}
                py={10}
                px={6}
                bg={'#fff'}
                height={'100%'}
                backgroundImage={shapeb}
                bgPos={'-32px -100px'}
            >
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mr="60px">
                    <Box display={'flex'} gap="20px" alignItems={'center'}>
                        <Box>
                            <Heading fontSize="24px" fontWeight="600">Partners {isLoadingPartnersInfo && <Spinner size={'sm'} color='primaryGreen.100' />}</Heading>
                            <Text fontSize="16px" mb="20px !important">You've {totalLength} partners</Text>
                        </Box>
                        <Box w="367px" bgColor="#ECF1F6" borderRadius="50px" display="flex" alignItems="center" px="10px" gap="5px">
                            <Icon as={HiOutlineSearch} fontSize="24px" color="#9CA4AB" />
                            <Input onChange={e => setSearchValue(e.target.value)} placeholder="Search here..." border="0" fontSize="14px" />
                        </Box>
                    </Box>
                    <Stack className='btn'>
                        <Button
                            justifyContent={'center'}
                            padding={'10px 20px'}
                            bg={"primaryGreen.100"}
                            onClick={() => navigate(`/super-dashboard/create-store-by-admin`)}
                            bgImage={greenBtnTexture}
                            bgPos={'center'}
                            bgSize={'cover'}
                            bgRepeat={'no-repeat'}
                            color={'primaryYellow.100'}
                            fontWeight={'400'}
                            border={'2px solid'}
                            borderColor={"primaryGreen.100"}
                            textAlign={'left'}
                            _hover={{
                                bg: "primaryGreen.100",
                                borderColor: "primaryGreen.100",
                                color: '#fff'
                            }}
                        >
                            <Text as={'span'}>Create New Store</Text>
                        </Button>
                    </Stack>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mr="60px !important">
                    <Box display={'flex'} alignItems={'center'} gap="15px">
                        <Stack className='btn'>
                            <Button
                                onClick={() => {
                                    setShowingIsAdmin(0)
                                }}
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={showingIsAdmin === 0 ? "primaryGreen.100" : '#D5DADE'}
                                color={showingIsAdmin === 0 ? 'primaryYellow.100' : '#000'}
                                fontWeight={'400'}
                                bgImage={showingIsAdmin === 0 ? greenBtnTexture : 'none'}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                w="120px"
                                border={'2px solid'}
                                borderColor={showingIsAdmin === 0 ? "primaryGreen.100" : '#D5DADE'}
                                textAlign={'center'}
                                _hover={{
                                    bg: showingIsAdmin === 0 ? "primaryGreen.100" : '#D5DADE',
                                    borderColor: showingIsAdmin === 0 ? "primaryGreen.100" : '#D5DADE',
                                    color: showingIsAdmin === 0 ? '#fff' : '#000'
                                }}
                            >
                                <Text as={'span'}>Partners</Text>
                            </Button>
                        </Stack>
                        <Stack className='btn'>
                            <Button
                                onClick={() => {
                                    setShowingIsAdmin(1)
                                }}
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={showingIsAdmin === 1 ? "primaryGreen.100" : '#D5DADE'}
                                color={showingIsAdmin === 1 ? 'primaryYellow.100' : '#000'}
                                fontWeight={'400'}
                                bgImage={showingIsAdmin === 1 ? greenBtnTexture : 'none'}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                border={'2px solid'}
                                borderColor={showingIsAdmin === 1 ? "primaryGreen.100" : '#D5DADE'}
                                textAlign={'center'}
                                _hover={{
                                    bg: showingIsAdmin === 1 ? "primaryGreen.100" : '#D5DADE',
                                    borderColor: showingIsAdmin === 1 ? "primaryGreen.100" : '#D5DADE',
                                    color: showingIsAdmin === 1 ? '#fff' : '#000'
                                }}
                            >
                                <Text as={'span'}>Admin Created Partners</Text>
                            </Button>
                        </Stack>
                        <Stack className='btn'>
                            <Button
                                onClick={() => {
                                    setShowingIsAdmin(2)
                                }}
                                justifyContent={'center'}
                                padding={'10px 20px'}
                                bg={showingIsAdmin === 2 ? "primaryGreen.100" : '#D5DADE'}
                                color={showingIsAdmin === 2 ? 'primaryYellow.100' : '#000'}
                                fontWeight={'400'}
                                bgImage={showingIsAdmin === 2 ? greenBtnTexture : 'none'}
                                bgPos={'center'}
                                bgSize={'cover'}
                                bgRepeat={'no-repeat'}
                                border={'2px solid'}
                                borderColor={showingIsAdmin === 2 ? "primaryGreen.100" : '#D5DADE'}
                                textAlign={'center'}
                                _hover={{
                                    bg: showingIsAdmin === 2 ? "primaryGreen.100" : '#D5DADE',
                                    borderColor: showingIsAdmin === 2 ? "primaryGreen.100" : '#D5DADE',
                                    color: showingIsAdmin === 2 ? '#fff' : '#000'
                                }}
                            >
                                <Text as={'span'}>Blocked Partners</Text>
                            </Button>
                        </Stack>
                    </Box>
                    <Box>
                        <ReactPaginate
                            previousLabel={'<<'}
                            nextLabel={'>>'}
                            breakLabel={'...'}
                            pageCount={PageCount}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={(data) => { setCurrentPage(data.selected + 1) }}
                            containerClassName={'pagination justify-content-center'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                        />
                    </Box>
                </Stack>
                {
                    showingIsAdmin === 1 ?
                        <Stack direction={'row'} gap={'1%'} flexWrap={'wrap'} justifyContent={'space-between'} spacing={0} mr="60px !important">
                            {
                                partnersList?.byAdmin?.length > 0 &&
                                partnersList?.byAdmin?.map((val, ind) =>
                                    <Stack key={val?._id} mb="1% !important" w="49%" borderRadius={'5px'} padding="15px 35px" boxShadow={'0px 0px 35px -15px #000'} >
                                        <SinglePartnerBox isAdminCreated={true} getPartners={getPartners} setSearchValue={setSearchValue} {...val} />
                                    </Stack>
                                )
                            }
                        </Stack>
                        : showingIsAdmin === 0 ?
                            <Stack direction={'row'} gap={'1%'} flexWrap={'wrap'} justifyContent={'space-between'} spacing={0} mr="60px !important">
                                {
                                    partnersList?.byUser?.length > 0 &&
                                    partnersList?.byUser?.map((val, ind) =>
                                        <Stack key={val?._id} mb="1% !important" w="49%" borderRadius={'5px'} padding="15px 35px" boxShadow={'0px 0px 35px -15px #000'} >
                                            <SinglePartnerBox getPartners={getPartners} setSearchValue={setSearchValue} {...val} />
                                        </Stack>
                                    )
                                }
                            </Stack>
                            :
                            <Stack direction={'row'} gap={'1%'} flexWrap={'wrap'} justifyContent={'space-between'} spacing={0} mr="60px !important">
                                {
                                    partnersList?.blocked?.length > 0 &&
                                    partnersList?.blocked?.map((val, ind) =>
                                        <Stack key={val?._id} mb="1% !important" w="49%" borderRadius={'5px'} padding="15px 35px" boxShadow={'0px 0px 35px -15px #000'} >
                                            <SinglePartnerBox getPartners={getPartners} setSearchValue={setSearchValue} {...val} />
                                        </Stack>
                                    )
                                }
                            </Stack>
                }
            </Stack >
        </>
    )
}